import { TagAttribute, TagQuality, VType } from "../../lib/tag";
import { RegisterWidget, Widget } from '../../lib/widget';
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './multiselect.html';
import { type Accordion } from "../../layout/accordion/accordion";
import { type TagCheckbox } from "../checkbox/checkbox";
import ArrowIcon from '../../../images/icons/arrow_right_filled.svg';
import { type TagName } from "../../taginfo/tagname/tagname";
import { type DateTimeSetpoint } from "../datetime/datetime";

//TODO: This needs a more generic implementation that doesn't rely on tags

@RegisterWidget({tag:'multi-select', roles:['WeeklyMultiSchedule'], template: template, displayName: 'Numeric Setpoint'})
export class MultiSelect extends Widget {
    accordion: Accordion;
    toggle: HTMLElement;
    selectedDaysList: HTMLElement;
    dayFolder: Tag;
    @TagAttribute({
        displayName: 'Folder Tag',
        supportedTypes: ['folder']
    }) tagFolder: TagDefinition;
    protected connectedCallback(): void {
        this.accordion = this.shadowRoot?.getElementById('accordion') as Accordion;
        this.accordion.defaultOpen = true;

        this.selectedDaysList = this.shadowRoot?.getElementById('selected-days') as HTMLElement;
        let toggleImage = this.shadowRoot?.getElementById('accordion-icon') as HTMLImageElement;
        toggleImage.src = ArrowIcon;
        this.toggle = this.shadowRoot?.getElementById('accordion-toggle') as HTMLElement;
        this.toggle.onclick = () => {
            this.accordion.toggle();
            toggleImage.style.rotate = this.accordion.isOpen ? '90deg' : '0deg';
        }
    }

    protected enliven(): void {
        let folder = this.tagFolder.tag;
        this.dayFolder = folder.findChild("Days")!;
        if (!this.dayFolder)
            throw(new Error("Invalid Weekly Schedule folder"));

        let dayFolderName = this.shadowRoot?.getElementById('day-folder') as TagName;
        dayFolderName.nameTag = {tag: this.dayFolder};
        this.dayFolder.children.forEach(child => {
            let row = document.createElement('div');
            row.classList.add('day-row');
            let check = document.createElement('se-tag-checkbox') as TagCheckbox;
            check.toggleTag = {tag: child};
            row.appendChild(check);
            let title = document.createElement('tag-name') as TagName;
            title.nameTag = {tag: child};
            row.appendChild(title);
            this.accordion.append(row);
            this.subscribeToTag(child);
        });
        let startTime = folder.findChildByRole("StartSecondOfDay");
        let endTime = folder.findChildByRole("EndSecondOfDay");
        if (!startTime || !endTime)
            throw(new Error("Invalid Weekly Schedule folder"));
        let startTimeSetpoint = this.shadowRoot?.getElementById('start-time') as DateTimeSetpoint;
        startTimeSetpoint.setpoint = {tag: startTime};
        let endTimeSetpoint = this.shadowRoot?.getElementById('end-time') as DateTimeSetpoint;
        endTimeSetpoint.setpoint = {tag: endTime};
    }

    update(tag: Tag): void {
        if (tag.quality !== TagQuality.TQ_GOOD)
            return;
        this.rebuildDays();
    }

    rebuildDays() {
        this.selectedDaysList.innerHTML = '';
        for (let child of this.dayFolder.children) {
            if (child.quality !== TagQuality.TQ_GOOD)
                return;
            if (child.getValue()) {
                let badge = document.createElement('div');
                badge.textContent = child.getDisplayName();
                badge.classList.add('day-badge');
                this.selectedDaysList.appendChild(badge);
            }
        }
    }
}

@RegisterWidget({tag:'folder-multi-select', template: template, displayName: 'Numeric Setpoint'})
export class FolderMultiSelect extends MultiSelect {
    @TagAttribute({
        displayName: 'Folder Tag',
        supportedTypes: ['folder']
    }) tagFolder: TagDefinition;

    protected enliven(): void {
        let folder = this.tagFolder.tag;
        for (let child of folder.children) {
            if (!(child.vtype == VType.VT_BOOL))
                throw(new Error(`Invalid child type in folder '${folder.getDisplayName()}' at '${child.absolutePath}`));
            let container = document.createElement('div');
            container.textContent = child.getDisplayName();
            this.accordion.append(container);
        }
    }

    update(tag: Tag): void {
    }
}