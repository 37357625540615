import {createElement} from '../elements';
import assert from '../debug';
import DigitalGauge from '../digitalguage';
import { HDRequest, PumpSystemGraph } from '../graph';
import { PumpBank } from '../pumpbank';
import owner from '../../owner';
import BarGraph from '../bargraph';
import './pumpstation.css';
import View from './view';
import TankView from './tankview';
import Cruncher from '../cruncher';
import Helper from '../helper';
import { Role } from '../role';
import { getHash, getRouteAndProperties } from '../router/router';

// PumpStation creates all of the visual elements to display a PumpStation on the web page.
export default class PumpStation extends View  {
    constructor(device, ldc) {
		super();
        this.ldc		= ldc;			// The LiveDataClient
        this.device 	= device;
		this.fAxis  	= true;		// flag that determines whether we need to fully redraw the graph
		this.graphID 	= owner.ldc.registerGraph(this);
		this.cruncher 	= new Cruncher();
		this.fPumpCurvesComplete = false;
        assert (this.device.isTreeComplete(), 'Device must already have a complete node tree');
	};

	initialize(parent) {
		super.initialize(parent);
		const rootNode  = this.device.tree.nodes[0];
        this.pumpSystem	= rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
		if (!this.pumpSystem)
			throw(new Error(`Pump station built without a pump bank at ${this.device.siteName}`));
		this.dpoFolder	= rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);

		this.settingsBreak = {
			fPseudo:true,
			parent:undefined,
			getDisplayName:()=>{return '';},
			fBreak:true,
			children: []
		}

		this.wrapper 				= createElement('div', 'overview__page-wrapper',this.parent) // horizontal flex wrapper
		this.parameterWrapper		= createElement('div', 'overview__parameter-wrapper', this.wrapper)
		var overviewWrapper 		= createElement('div', 'overview__overview-graph-wrapper', this.wrapper); // center div that holds the gauges and chart

		let parameterContainer		= createElement('div', 'overview__parameter-wrapper__value-container',this.parameterWrapper);
		this.parameterTable 		= createElement('div', 'overview__parameter-table', parameterContainer);
		this.pumpBankWrapper 		= createElement('div', 'overview__pump-bank-wrapper', overviewWrapper);
		this.pumpTable 				= createElement('div', 'overview__parameter-table', parameterContainer)

		var overviewGraphContainer 	= createElement('div', 'overview__overview-graph-container', overviewWrapper); // graph flex wrapper
		this.graphRow				= createElement('div', 'overview__overview-graph-wrapper__graph-row', overviewGraphContainer);
		this.controlDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
		this.graphDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__graph', this.graphRow);
		this.legendDiv				= createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
		this.tankGaugeTable			= createElement('div', 'overview__tank-gauge-wrapper__tank-gauge-container', this.tankGaugeWrapper);
		this.fLandscape 			= window.innerHeight < 420;

		var flow = this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);
		new DigitalGauge(createElement('div', 'PumpStationGauge PumpStationFlow', this.parameterTable), {node: flow}).initialize();

		let sec = this.pumpSystem.findChildByRole(Role.ROLE_SEC);
		let seGauge = createElement('div', 'PumpStationGauge PumpStationSEC', this.parameterTable)
		new DigitalGauge(seGauge, {node: sec, label: 'Specific Energy (' + sec.getUnitsText() + ')'}).initialize();
		seGauge.onclick = () => {
			window.location = getHash(...getRouteAndProperties(location.hash,{'tab':'Specific Energy Map'}));
		};

		let costPerVolume = this.pumpSystem.findChildByRole("CostPerVolume");
		if (costPerVolume)
			new DigitalGauge(createElement('div', 'PumpStationGauge PumpStationCPV', this.parameterTable), {node: costPerVolume, label: 'Cost Per Volume (' + costPerVolume.getUnitsText() + ')'}).initialize();

		let suction = this.pumpSystem.findChildByRole(Role.ROLE_SUCTION_PRESSURE);
		if (suction)
			new DigitalGauge(createElement('div', 'PumpStationGauge', this.parameterTable), {node: suction}).initialize();

		let discharge = this.pumpSystem.findChildByRole(Role.ROLE_DISCHARGE_PRESSURE);
		if (discharge)
			new DigitalGauge(createElement('div', 'PumpStationGauge PumpStationDP', this.parameterTable), {node: discharge}).initialize();

		let chlorine = this.dpoFolder && this.dpoFolder.findChildByRole(Role.ROLE_CHLORINE_RESIDUAL);
		if (chlorine)
			new DigitalGauge(createElement('div', 'PumpStationGauge PumpStationChlorine', this.parameterTable), {node: chlorine}).initialize();

		let turbidity = this.dpoFolder && this.dpoFolder.findChild('Turbidity');
		if (turbidity)
			new DigitalGauge(createElement('div', 'PumpStationGauge PumpStationTurbidity', this.parameterTable), {node: turbidity}).initialize();

		let otherGauges = rootNode.findByRole(Role.ROLE_OVERVIEW_GAUGE);
		for (let gauge of otherGauges)
			new DigitalGauge(createElement('div', 'PumpStationGauge', this.parameterTable), {node: gauge}).initialize();

		let modelPumpSystem = this.pumpSystem.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM );
		let timeInPOR = modelPumpSystem && modelPumpSystem.findChild('AnyOutsidePOR');
		if (timeInPOR) {
			let timeInPORDayDiv		= createElement('div', 'PumpStationGauge PumpStationPOR', this.parameterTable);
			createElement('div', 'digital-label', timeInPORDayDiv, '24-hr Time in POR (%)');
			let timeInPORMonthDiv	= createElement('div', 'PumpStationGauge PumpStationPOR', this.parameterTable);
			createElement('div', 'digital-label', timeInPORMonthDiv, '30-day Time in POR (%)');

			let timeInPORDay 		= createElement('div', 'digital-value', timeInPORDayDiv);
			let timeInPORMonth 		= createElement('div', 'digital-value', timeInPORMonthDiv);
			let updatePORTime 		= () => {
				let end        			= new Date();
				let start      			= new Date(end.getTime() - 86400 * 1000);
				this.cruncher.getAverage(start, end, timeInPOR, 60, (value) => {
					let time = ((1 - value) * 100).toFixed(2);
					timeInPORDay.textContent = time;
				});
				let monthStart      = new Date(end.getTime() - 30 * 86400 * 1000);
				this.cruncher.getAverage(monthStart, end, timeInPOR, 3600, (value) => {
					let time = ((1 - value) * 100).toFixed(2);
					timeInPORMonth.textContent = time;
				});
			}
			this.intervalID = setInterval(updatePORTime.bind(this), 60000);
			updatePORTime();
		}
		owner.navBar.registerHelp('Help with Operation Tab', ()=>this.buildHelper())

		this.tankContainer	= createElement('div', 'overview__parameter-wrapper__tank-container hide', this.parameterWrapper);
		this.resizeCallback = () => this.resize();
		this.device.requestPumpTwins(this);

		this.fInitialized = true;
		return this;
	};

	onPumpTwinsComplete(pumpTwins) {
		this.fPumpCurvesComplete = true;
		this.pumpBank = new PumpBank(this.pumpSystem, this.fLandscape? this.parameterTable : this.pumpBankWrapper, this.dpoFolder);
		this.graph = new PumpSystemGraph(this.ldc, this.pumpSystem, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, true, this.controlDiv, this.legendDiv);
		window.addEventListener('resize', this.resizeCallback);
		this.resize(); // graph is statically sized, so go ahead and kick off a resize
	}

	buildHelper() {
		this.helper =  new Helper('Overview Tab', [
			{title:'Overview Tab',	body:`The Overview page provides a summary of current operating conditions. The screen includes Digital Gauges, Pump Widgets, and Historical Trend. Click 'Next' to learn more. `, element:this.wrapper},
			{title:'Pumps', 		body:`Click the pump header to navigate to the pump's overview screen. Or toggle Curves/Settings/None to display performance on the pump curve, settings, or collapse the header to expand the chart view. The color in the top left corner of the header determines each pump's fill color on the chart.` , element:this.fLandscape? this.parameterTable : this.pumpBankWrapper},
			{title:'Pump Header ',    body:`The header displays flow, speed (if on a VFD), time since last start/stop. Its fill color indicates the pump's status: running (green), off (gray), or faulted (orange). For sites operating in Advisory Mode, a red bubble atop the header provides a % speed recommendation. `, element:this.pumpBank.pumps[0]?.pumpHeader},
			{title:'Digital Gauges',	body:`The outputs of key operating parameters are shown here.`, element:this.parameterTable},
			{title:'Historical Trend',        	body:`The graph charts the operating history of the station. Graphed tags are displayed in the legend at the bottom. The color of the legend entry matches the color of the line. Tags may be hidden from graph view by clicking the tag in the legend. `, element:this.graphDiv},
			{title:'Tank Level Constraints',  		body:`If configured, a summary  of source and target tank constraints will appear here.`, element: this.tankContainer},
		]).initialize();
	}

	onViewHidden() {
        owner.navBar.unregisterHelper('Help with Operation Tab');
		window.removeEventListener('resize', this.resizeCallback);
    }

    onViewShown() {
        super.onViewShown();
        owner.navBar.registerHelp('Help with Operation Tab', ()=>this.buildHelper());
		window.addEventListener('resize', this.resizeCallback);
    }

	resize() {
		if (!this.fInitialized || !this.fPumpCurvesComplete)
			return;
		this.createGauges(this.tankContainer);

		let options = {};
		if (window.innerWidth < 620) {
			options = {drawXAxis:false,drawYAxis:false,xLabelWidth:0,yLabelWidth:0,rightGap:0}
		}
		else if (window.innerWidth >= 620) {
			options = {drawXAxis:true,drawYAxis:true}
		}
		if (this.fLandscape != window.innerHeight < 420) {
			this.fLandscape = !this.fLandscape;
			this.pumpBank.destroy();
			this.pumpBank = new PumpBank(this.pumpSystem, this.fLandscape? this.parameterTable : this.pumpBankWrapper, this.dpoFolder);
		}

		if(this.pumpBank) // We may not have constructed the pump bank yet because we're waiting on curve data - in which case resize will be called again when we have curve data
			this.pumpBank.resize();

		if (this.graph && this.graph.graph) {
			this.graph.resize(Math.floor(this.graphDiv.clientWidth), Math.floor(this.graphDiv.clientHeight));
			this.graph._updateTimer();
		}
	};

	refresh() {
		this.graph && this.graph.refresh();
	};

	destroy () {
		owner.navBar.unregisterHelper('Help with Operation Tab');
		window.removeEventListener('resize', this.resizeCallback)
		this.parent.destroyWidgets(true);	// First, detach all LiveData nodes from all DOM elements
		this.parent.removeChildren();		// Second, delete all child DOM elements
		clearInterval(this.intervalID);
	};

	createGauges (element) {
		element.destroyWidgets(true);
		element.removeChildren();

		// Add a gauge for each tank:
		var sourceFolder = this.dpoFolder && this.dpoFolder.findChildByRole(Role.ROLE_TLC_SOURCE_TANK_FOLDER);
		var targetFolder = this.dpoFolder && this.dpoFolder.findChildByRole(Role.ROLE_TLC_TARGET_TANK_FOLDER);


		if (sourceFolder != null || targetFolder != null) {
			this.tankContainer.classList.remove('hide');
			if (sourceFolder) {	// No tank is guaranteed to exist
				let tanks = [];
				let sourceTankWrapper = createElement('div', 'overview__parameter-wrapper__tank-container__wrapper', element);
				createElement('div', 'overview__parameter-wrapper__tank-container__wrapper__title', sourceTankWrapper, 'Source Tanks')
				//createElement('div', 'overview__parameter-wrapper__tank-container__title', element, 'Source Tanks');
				tanks.push(...sourceFolder.children);
				for (var i=0, node; node = tanks[i]; ++i){
					let tankContainer 	= createElement('div', 'overview__parameter-wrapper__tank-container__tanks', sourceTankWrapper);
					tankContainer.onclick = () => location.hash = getHash(getRouteAndProperties(location.hash, {'tab':'Controls'}));
					new TankView(node, node.findChildByRole(Role.ROLE_CYCLE_TANK_VOLUME_SO_FAR)? true : false, false, false, {fShuttles: true}).initialize(tankContainer);
				};
			}
			if (targetFolder) {
				let tanks = [];
				let targetTankWrapper = createElement('div', 'overview__parameter-wrapper__tank-container__wrapper', element);
				createElement('div', 'overview__parameter-wrapper__tank-container__wrapper__title', targetTankWrapper, 'Target Tanks')
				//createElement('div', 'overview__parameter-wrapper__tank-container__title', element, 'Target Tanks');
				tanks.push(...targetFolder.children);
				for (var i=0, node; node = tanks[i]; ++i){
					let tankContainer 	= createElement('div', 'overview__parameter-wrapper__tank-container__tanks', targetTankWrapper);
					new TankView(node, node.findChildByRole(Role.ROLE_CYCLE_TANK_VOLUME_SO_FAR)? true : false, true, false, {fShuttles: true}).initialize(tankContainer);
				};
			}
		};
	};

	createTanks (height, tanksWrapper, role, color) {
		// Create tank representations (source tanks first, target tanks next).
		tanksWrapper.destroyWidgets(true);
		tanksWrapper.removeChildren();

		var tankFolder	 	= this.dpoFolder.findChildByRole(role);
		if (tankFolder === null)	// No tank is guaranteed to exist
			return;

		// Add a bar graph for each tank:
		var tanks			= tankFolder.findByRole(Role.ROLE_TLC_TANK_LEVEL);
		for (var i=0, node; node = tanks[i]; ++i){
			// Create the bar graph:
			if (tanksWrapper.clientHeight != 0) {
				var element = createElement('div', 'ps-tank', tanksWrapper);
				new BarGraph(element, {
					fBackgroundGradient: false,
					fBarGradient:   false,
					node:			node,
					width:			30,
					height:			height - 30,
					fVertical:		true,
					minMaxPosition:	'right',
					scalePosition:	'left',
					color: 			color})
				.addShuttle(node, 'left')	// add shuttle on left side of bar
				.initialize();				// initialize the bar graph

				// Create the tank label (use node name if node has no description):
				var label = (node.description || node.getDisplayName()).substring(0, 10);
				var units = node.getUnitsText();
				if (units.length > 0)	// node has units:
					label += ' (' + units + ')';
				createElement('div', 'tank-label', element, label);
			}
		}
	}
};
