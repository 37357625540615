import { Widget } from "./widget";
import {createElement} from './elements';
import {Node, VType} from './node';
import './logical.css';
import assert from './debug';

export default class Logical extends Widget {
    constructor(element, properties) {
        super();
        assert (element, 'element is a required property');
        this.element = element;
        this.element.classList.add('logical-container');	// Add the class name this container needs
        this.registerAsWidget(element);
        
        // Initial values
        this.redXMask		= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.onText			= 'ON';
        this.offText		= 'OFF';
        this.onClass		= 'logical-on';
        this.offClass		= 'logical-off';
        
        // Second step: copy properties over to the bar graph object:
        this.copy(properties);
        
        assert(this.node, "Logical needs a node!");	// Need a node for this guy
        assert(this.node.vtype == VType.VT_BOOL, 'Logical needs a Boolean!');
        
        this.on 	= createElement('div', this.onClass, this.element, this.onText);		// Creating the element for the 'on' portion of the logical.
        this.off 	= createElement('div', this.offClass, this.element, this.offText);	// Creating the element for the 'off' portion of the logical.

        if (this.width) {
            this.on.style.width = this.width + 'px';
            this.off.style.width = this.width + 'px';
        }

        this.off.classList.add('collapse');	// This way, both members don't appear for a little while, but take up the appropriate space
        this.on.classList.add('collapse');
        
        // Make sure they both take the width of the bigger button
        if (this.on.clientWidth < this.off.clientWidth)
            this._width = this.off.clientWidth + 'px'; 
        else
            this._width = this.on.clientWidth + 'px';

        this.node.subscribe(this, element, this.redXMask);	// Subscribe to the boolean they gave us
    };

    update(node) {
        assert (this.node == node);			// Only one node should be calling us back
        this.off.classList.remove('collapse');
        this.on.classList.remove('collapse');
        
        if(node.getValue(this.fPending)) {
            this.off.classList.add('hide');
            this.on.classList.remove('hide');
        } else {
            this.off.classList.remove('hide');
            this.on.classList.add('hide');
        }
    };

    destroy() {
        this.element.removeChildren();
        
        this.element.classList.remove('logical-container');
        
        this.node.unsubscribe(this);
        this.unregisterAsWidget();
    };
};
