import Page  from "./page";
import { createElement } from "../elements";
import './mappage.css';
import ChartView from "../views/chartview";
import './chartpage.css'
import { LoggedFilter } from '../tagfilter';
import TagView, { TreeViewTypes, TreeViewProperties } from '../views/treeview';
import { Node } from "../node";
import owner from "../../owner";


interface ChartPageProps {

}

export default class ChartPage extends Page {
    wrapper: HTMLElement;
    tagWrapper: HTMLElement;
    chartView: ChartView;
    constructor(parent: HTMLElement, props: ChartPageProps) {
        super(parent);
        this.wrapper = createElement('div', 'chart-page__wrapper', parent);
        this.chartView = new ChartView(owner.ldc).initialize(this.wrapper);
        this.wrapper.onresize = () => this.resize();
        owner.navBar.setTitle('Chart');
    }

    resize() {
        this.chartView.resize()
    }

    setProps() {}
}