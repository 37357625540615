import { Node, NodeFlags, VType } from './node';
import { Widget } from './widget';
import Localization from './localization';
import assert from './debug';
import { createElement } from './elements';
import owner from '../owner';
import './graph.css';
import { Role } from './role';
import { TagUnit, convert } from './widgets/lib/tagunits';

const MaxPixelsPerPoint = 1;

/**
 * This is the vanilla graph, the base of all the others. This should not be created
 * without a wrapping class to configure it.
 * @param {Object} [creator] The wrapping class that created us and will configure us
 * @param {LiveDataClient} [ldc] The live data client we will query historical data through
 * @param {DOM Element} [parentElement] The element we will instantiate this graph in
 * @param {Number} [width] The width (in pixels) that we wish the graph to be
 * @param {Number} [height] The height (in pixels) that we wish the graph to be
 * @param {Date} [start] The initial minimum value of the x axis of the graph
 * @param {Date} [end] The initial maximum value of the x axis of the graph
 * @param {Boolean} [fNoControls] If true, will not create the division for legend and controls
 */
 export class GenericGraph extends Widget {
    constructor(ldc, parentElement, width, height, start, end, fNoControls, controlDiv, legendDiv, options) {
        super();
        this.ldc = ldc;	// The LiveDataClient which has the socket to Whoville. Store a pointer so we can request data
        this.width = width;
        this.height = height;
        // Set up basic DOM structure this graph needs
        this.registerAsWidget(parentElement);	// Register the parent element as a widget
        this.element = createElement('div', null, parentElement);	// The element we are attaching the graph to

        if (fNoControls != true) {
            this.controlDiv = controlDiv;
            if (this.controlDiv) {
                this.controlDiv.removeChildren();
            }
            else this.controlDiv = createElement('div', 'dygraph-control', parentElement);

            // The left column of the graph gets all the controls
            this.dateSelect = createElement('div', 'dateSelection', this.controlDiv);	// Where we will put our radio buttons for interval selection
            this.controls = createElement('div', 'graphButtons', this.controlDiv);	// Where will put all of our controls (if any get added)

            // The right column is the legend's division
            this.legend = legendDiv;
            if (this.legend) {
                this.legend.removeChildren();
            }
            else this.legend = createElement('div', 'legend', this.controlDiv);	// The element we are putting the legend data in
        }

        this.legendIndicators = [];						// Create an empty array to hold legend indicators
        this.timerID = null;						// ID of any timer that is currently counting down
        this.fUpdating = true;						// Once we get data, start trying to update it
        this.fInteractive = false;					// Not interactive until they tell us to be
        if (ldc)
            this.clientID = ldc.registerGraph(this);	// No clientID until the LDC assigns us one

        // Default Dygraph options
        this.options = {
            width: width,							// Set the graph to whatever weight the passed in
            height: height,							// Set the graph to whatever height the passed in
            titleHeight: 22,								// Pixel size of any title we add on
            xLabelHeight: 15,								// Pixel size of any x axis title we add on
            yLabelWidth: 14,								// Pixel size of any y axis title we add on
            xAxisLabelWidth: 75,								// Give the x axis labels enough room to be legible
            xAxisLines: false,
            includeZero: true,							// Graph zero instead of say, 40-50 on the y-axis
            yAxisLineColor: owner.colors.getTheme()['--color-onSurfaceVariant'],	// X axis line color
            xAxisLineColor: owner.colors.getTheme()['--color-onSurfaceVariant'],	// Y axis line color
            gridLineColor: owner.colors.hex('--color-tertiary'),	// Grid line color
            rightGap: 0,
            axisLineWidth: 0.5,							// Make axis lines a little thicker
            gridLineWidth: 0.2,							// Make grid lines a little thicker
            highlightCircleSize: 0,								// By default, no circles
            minorYLines: 0,								// 1 horizontal minor line between major lines
            minorXLines: 0,								// 3 vertical minor line between major lines, SKUN-109 - 4 minor gridlines requested
            //fillAlpha: 0.20,							    // Make fills a little darker than the default
            axisLabelColor: owner.colors.getTheme()['--color-onSurface'],	// Axis label color -- dark green
            drawCallback: this._onDraw.bind(this),		// This function is called back every time the graph is drawn
            customAxis: [],								// We we calculate this programattically
            digitsAfterDecimal: 1,                      // If we get funky numbers on the right axis, one digit, please.
            fGroupCommonUnits: false                    // Whether or not we want to plot nodes with common units on a common range
        };

        console.log(this.options["gridLineColor"])

        for (var key in options)		// Put any options they gave us into the dygraph
            this.options[key] = options[key];

        if (start && end)	// Only create an empty graph if we got a start and end (StaticGraph doesn't use this)
            this._createEmptyGraph([start.getTime(), end.getTime()]);	// Create an empty grab to start off with (will fill with data, or user will drop nodes)
    };

    /**
     * Remove all data we can about the graph. This includes detaching DOM elements that
     * the graph created and removing all references to data.
     */
    destroy() {
        if (this.ldc)
            this.ldc.unregisterGraph(this.clientID);
        if (this.graph)
            this.graph.destroy();	// Should clear out all the graphs member's, make things get killed by the garbage collector

        if (this.element) {
            while (this.element.hasChildNodes())		// Remove any and all divisions we created
                this.element.removeChild(this.element.firstChild);
            this.element.parentNode.removeChild(this.element)
        }

        this.unregisterAsWidget();	// The final step to being a good widget
        this._stopTimer();			// Kill the timer if we have one outstanding

        for (var key in this)
            delete this[key];		// Remove all of our graph member variables
    };

    _createLegendIndicator(path, color, res, node) {
        if (path[0] == '/') { 	// Removing the first two slashes and replacing the rest if they exist
            var subPath = path.substr(1, path.length);
            var secondSlash = subPath.indexOf('/');

            // Set name to either the string after the second slash or the rest of the parital path
            path = (secondSlash != -1) ? subPath.substr(secondSlash + 1, subPath.length) : subPath;
        }

        path = path.replace('/', ' ');	// Replacing '/' with a space

        // Create an individual legend display
        var entry = createElement('div', 'legendEntry', this.legend, path);
        entry.style.backgroundColor = color;

        if (node) {
            entry.setAttribute('hasnode', true);	// Give is a cursor pointer CSS rule
            entry._se_node = node;		// Give the indicator a pointer back to the node
            entry._se_graph = this;	// Give the indicator a pointer back to the graph
            entry.onclick = function () { this._se_graph._toggleVisiblity(this._se_node); };
        }
        this.legendIndicators.push(entry);
    };

    _removeLegendIndicator(index) {
        this.legend.removeChild(this.legendIndicators[index]);		// Remove the indicator from its parent
        this.legendIndicators.splice(index, 1);						// Remove the indicator from our array
    };

    axesCanChange() {
        this.options.axisClickCallback = this._changeAxis.bind(this);	// Give dygraph a callback that says to call us when the y axes are clicked
        this.leftAxisIndex = 0;	// Initial node to plot on the left axis
        this.rightAxisIndex = 1;	// Initial node to plot on the right axis
        this.maxAxis = 0;	// Max axis property so we can change the axis
    };

    /**
     * Tell the graph to populate a legend upon mouseover events (and graph creation) in
     * a division created by the GenericGraph constructor.
     */
    createLegend(names) {
        this.options.legend = true;		// True if the graph should draw a lgend
    };

    /**
     * Call this to make the graph no longer highlights points on mouse over.
     */
    stopHighlighting() {
        this.options.stophighlighting = true;
    };

    /**
     * Call this to make the graph respond to pan upon click and drags and zoom upon
     * mouse wheel events.
     * @param {Boolean} [fButtons] Set to true to make the graph create 'live' and 'static' radio buttons
     */
    makeInteractive(fButtons) {
        assert(this.live == null);				// Shouldn't have a live control yet
        this.fInteractive = true;				// Make a note that we should remain interactive
        this.graph.createUserInterface(true);	// Tell the graph to allow all mouse events

        // Create two radio buttons for live vs. static graph control
        if (fButtons) {
            var radioName = '_graph' + GenericGraph.radioGroupID;
            for (var i = 0; i < GenericGraph.liveStrings.length; ++i) {
                var buttonID = '_button' + GenericGraph.radioButtonID++;
                var input = createElement('input', 'radio-buttons__input', this.controls);	// Create an input element
                input.setAttribute('type', 'radio');	// Make it a radio button
                input.setAttribute('Name', radioName);	// Give all the buttons the same name
                input.setAttribute('id', buttonID);		// Each button needs to match the label

                var label = createElement('label', 'radioButtonLabel', this.controls, GenericGraph.liveStrings[i]);	// Label for the input
                if (i == 0) {				// Live button
                    input.checked = true;	// Start off with the live button checked
                    this.live = input;		// Save a pointer to the live button
                } else						// Static button
                    this.stat = input;		// Save a pointer to the static button
                label.setAttribute('for', buttonID);	// Each input/label must have the same unique 'id'/'for' attribute:

                input.onchange = this._changeUpdateStatus.bind(this);	// Give us a heads up when a click makes this change
            }
            ++GenericGraph.radioGroupID;	// This group id has been used
        }
    };

    /**
     * Create radio buttons and a start date selector in a division above the graph
     */
    createDateSelection() {
        var buttons = this.createRadioButtons(GenericGraph.radioStrings.length, GenericGraph.radioStrings);

        for (var i = 0; i < buttons.length; ++i) {
            var input = buttons[i];
            input.setAttribute('Value', GenericGraph.radioIntervals[i]);	// Set the value to the new interval (in seconds)
            input.onclick = function () {
                var interval = this.getAttribute('Value');	// Get the value of the interval
                if (interval != 'null') {					// For the custom case
                    var dates = this._grapher.graph.xAxisRange();	// Get how much the window has selected
                    dates[0] = dates[1] - this.getAttribute('Value') * 1000;	// Start at the same end point, but graph the selected interval

                    var fUpdating = this._grapher.fUpdating;		// Store whether or not the graph was live
                    this._grapher.selectedButton = this;			// Store which button is selected for convenience
                    this._grapher.updateWindow(dates[0], dates[1]);

                    this._grapher.fUpdating = fUpdating;			// Reset this variable (that was unset in _onDraw)
                    if (fUpdating)									// If we were updating
                        this._grapher._startTimer(this.interval);	// Start the timer again to call us again to keep updating
                }
                if (this === this._grapher.customButton && !this._grapher.live)            // if we click our custom button
                    this._grapher.makeInteractive(true)             // make sure this graph is interactive
            };
            this.customButton = input;					// Store a pointer to the last button (the custom button)
            if (GenericGraph.radioStrings[i] == 'D') {	// Start off with the day button checked
                input.checked = true;				// Check this input at the start
                this.selectedButton = input;			// Store this as the selected button
            }
        }
    };

    createToDateSelection() {
        var buttons = this.createRadioButtons(5, ["Year", "YTD", "Month", "MTD", "Day"]);
        buttons[0].getStartTime = function () { var d = new Date().getTime(); return [d - 1000 * 365 * 24 * 3600, d]; };								// Year
        buttons[1].getStartTime = function () { var d = new Date(); var s = d.getTime(); d.setMonth(0, 1); return [d.setHours(0, 0, 0, 0), s]; };	// YTD
        buttons[2].getStartTime = function () { var d = new Date().getTime(); return [d - 1000 * 30 * 24 * 3600, d]; };								// Month
        buttons[3].getStartTime = function () { var d = new Date(); var s = d.getTime(); d.setDate(1); return [d.setHours(0, 0, 0, 0), s]; };		// MTD
        buttons[4].getStartTime = function () { var d = new Date().getTime(); return [d - 1000 * 24 * 3600, d]; };									// Day

        for (var i = 0; i < buttons.length; ++i)
            buttons[i].onclick = function () { var d = this.getStartTime(); this._grapher.updateWindow(d[0], d[1]); };
    };

    createRadioButtons(count, labelNames) {
        var radioName = '_graph' + GenericGraph.radioGroupID;

        var buttons = [];
        for (var i = 0; i < count; ++i) {
            var buttonID = '_button' + GenericGraph.radioButtonID;
            ++GenericGraph.radioButtonID;		// This button id has been used
            var input = createElement('input', 'radio-buttons__input', this.dateSelect);	// Create an input element
            input.setAttribute('type', 'radio');	// Make it a radio button
            input.setAttribute('Name', radioName);	// Give all the buttons the same name
            input.setAttribute('id', buttonID);	// Each button needs to match the label
            input._grapher = this;

            var label = createElement('label', 'radioButtonLabel', this.dateSelect, labelNames[i]);
            label.setAttribute('for', buttonID);	// Each input/label must have the same unique 'id'/'for' attribute:
            buttons.push(input);
        }
        ++GenericGraph.radioGroupID;	// This group id has been used
        return buttons;
    };

    /**
     * Make a selection of boolean nodes appear as a filled map on the graph
     * @param {Array} [runningNodes] Array of boolean live data nodes
     */
    createBooleanFill(runningNodes, fAddColors) {
        assert(runningNodes.length > 0, "Must give more than 0 nodes to createBooleanFill!");
        assert(!this.boolFill, "Don't call createBooleanFill twice!");
        this.booleanFill = new BooleanFill(this, runningNodes, fAddColors);
    };

    createPumpFlowFill(modelPumps, flow) {
        assert(modelPumps.length > 0, "Must give more than 0 nodes to createPumpFlowFill!");
        assert(!this.boolFill, "Don't call createBooleanFill twice!");
        this.booleanFill = new PumpFlowFill(this, modelPumps, flow);
    };

    /**
     * Generic code for creating a button, setting the text, and adding the 'onclick' callback.
     * All buttons are appended to the controls division created in the GenericGraph constructor.
     * @param {String} [text] The text for the button
     * @param {Function} [onclick] A method to tie back to the onclick callback of the button
     * @return {Object} [button] The button that was just created, already attached to the DOM tree
     * @private
     */
    _createButton = function (text, onclick) {
        var button = createElement('input', 'graphButton', this.controls);	// Create an input object
        button.setAttribute('type', 'button');			// Make it a button (as opposed to text input space)
        button.value = Localization.toLocal(text);							// Set the words on the button
        button.addEventListener('click', onclick);		// Call the method they have asked for
        return button;									// Return the button we created
    };

    /**
     * Generic code for creating a drop down box, setting option text, and adding the 'onchange' callback.
     * All boxes are appended to the controls division created in the GenericGraph constructor.
     * @param {Array} [strings] An array of strings holding all options that should be added to the box
     * @param {Function} [onchange] A method to tie back to the onchange call back of the button
     * @return {Object} [box] The drop down box that was just created, already attached to the DOM tree
     * @private
     */
    _createDropDownBox = function (strings, onchange) {
        var box = createElement('select', 'graphButton', this.controls);	// Create a drop down box
        for (var i = 0; i < strings.length; ++i) {					// Add all the options in the array
            var newOption = createElement('option');			// Create a new option element
            newOption.text = Localization.toLocal(strings[i]);							// Set the text as the option
            box.add(newOption);										// Add it to the selector
        }
        box.addEventListener('change', onchange);	// Add whatever method they want called
        return box;									// Return the box we created
    };

    _toggleVisiblity = function (node) {
        var index = null;	// Find the index of the node that needs to be set
        for (var i = 0; i < this.orderedNodes.length; ++i) {
            if (this.orderedNodes[i].node === node) {	// This is our node
                index = i;	// Store the index
                break;		// No need to look through any more nodes
            }
        }
        assert(index !== null, "Didn't find the node we wanted.");
        this.options.visibility = this.graph.visibility();
        var fVisible = this.options.visibility[index];	// Toggle the visibility
        this.options.visibility[index] = !fVisible;
        this.graph.setVisibility(index, !fVisible);	// Set the line's new visibility
        this.legendIndicators[index].setAttribute('gray', fVisible);	// Make the indicator grayed out
    };

    /**
     * Create a drop down box for selecting nodes added to the graph.
     */
    createNodeSelector = function () {
        if (!this.nodeSelector)	// If we don't have one yet, create the node selector
            this.nodeSelector = this._createDropDownBox([], this._onFocusNodeChange.bind(this));
    };

    /**
     * Create two buttons that will remove nodes from the graph. Clear All removes all lines
     * currently attached to the graph. Remove Line removes online the line selected by the node
     * selector. This will create a node selector if that is not done already.
     */
    createClearControl = function () {
        this.clearall = this._createButton('Clear All', this._clearAll.bind(this));		// Create the clear all button
        this.removeline = this._createButton('Remove Line', this._removeLine.bind(this));	// Create the remove single line button
        this.createNodeSelector();	// If we haven't created a node selector, do so now
    };

    /**
     * Create a drop down box for selecting the color of the node selected by the node selector.
     * This will create a node selector if that is not done already.
     */
    createColorControl = function () {
        this.createNodeSelector();	// If we haven't created a node selector, do so now
        this.nodeColor = this._createDropDownBox(GenericGraph.colorStrings, this._onColorChange.bind(this));	// Create and populate the color selector
    };

    /**
     * Create a drop down box that allows plotting min/max data for the node selected by the
     * node selector. This will create a node selector if that is not done already.
     */
    createMinMaxControl = function () {
        this.createNodeSelector();	// If we haven't created a node selector, do so now
        // Create and populate the min/max options selector
        this.nodeBars = this._createDropDownBox(GenericGraph.minMaxStrings, this._onMinMaxDataRequested.bind(this));
    };

    /**
     * Call the Dygraph resize method, which is intelligent about redrawing itself. That _changeUpdateStatus
     * call will preserve the live/static status of the graph
     */
    refresh = function () {
        //this.graph.resize();
        if (!this.fInteractive) // We need to check so we dont update static graphs
            return;
        var range = this.graph.xAxisRange();		// Get the current range of the graph
        this.graph.doZoomXDates_(range[0], range[1]);
    };

    resize(width, height) {
        this.width = width;
        this.height = height;
        this.graph.resize(width, height);
    }

    /**
     * Remove a node from the graph. It will be be completely detached from our objects and the
     * graph will be redrawn sans line. If it was the last line, an empty graph will be drawn.
     * @param {Node} [node] The SE node that should be removed from the graph
     * @private
     */
    _removeNode = function (node) {
        if (this.orderedNodes.length == 0)	// We have no nodes, there's no way we can remove any nodes
            return;

        for (var i = 0; i < this.orderedNodes.length; ++i) {
            if (this.orderedNodes[i].node == node) {	// This is the graph node holding the node
                if (this.nodeSelector) {
                    this.nodeSelector.remove(i);	// Clear the option from the drop down if it exists
                    this._onFocusNodeChange();		// Fill out any options with the correct
                }

                var nodeToRemove = this.orderedNodes.splice(i, 1)[0];	// Remove the GraphNode and keep a reference (Note, splice returns an array)
                for (var j = 0; j < this.deviceNodes.length; ++j) {
                    var index = this.deviceNodes[j].nodes.indexOf(nodeToRemove);
                    if (index != -1) {			// We found the GraphNode
                        this.deviceNodes[j].nodes.splice(index, 1);	// Remove the graph node
                        break;
                    }
                }

                if (this.options.legend)				// If there's a legend being drawn
                    this._removeLegendIndicator(i);	// Remove the indicator for this node

                if (i == this.leftAxisIndex)		// If this was our left axis, change it
                    this._changeAxis(0);
                else if (i == this.rightAxisIndex) 	// Ditto for the right axis
                    this._changeAxis(1);

                this.options.customAxis.pop();		// Reduce custom axis size by one.
                this.graph.removeLine(nodeToRemove.name, this.options);	// Remove the line by name from the Dygraph
                --this.maxAxis;
                if (this.onNodesChanged)
                    this.onNodesChanged(null);
                return;	// No need to look through any more nodes
            }
        }
        assert(false, "Didn't find the node they wanted us to remove!");
    };

    /**
     * Determine the interval that should exist between points of the current graph based upon
     * the width of the graph and the times plotted.
     * @return {Number} interval The current graph's interval in seconds
     * @private
     */
    _determineInterval = function () {
        assert(this.graph != null);		// Should have created a graph before this is called.
        var xRange = this.graph.xAxisRange();
        return this._calculateInterval(xRange[0], xRange[1]);
    };

    /**
     * Calculate the interval that should exist between points graph based upon the width of the
     * graph and the times passed in. It calculates the  desired point interval in seconds, based
     * on graph pixel width and graph time width. This uses a constant value that limits point
     * density to a maximum of one point per 'x' pixels, where x is tunable. For now, x = 4.
     *
     * @param {Number} [start] The start time stamp (in milliseconds)
     * @param {Number} [end] The end time stamp (in milliseconds)
     * @return {Number} [interval] The calculated interval in seconds
     * @private
     */
    _calculateInterval = function (start, end) {
        assert(start < end, "Bad interval passed in!");
        let intervals = GenericGraph.intervals;
        /*
        if (this.element.clientWidth == 0)	// If this graph is hidden //TODO: Do we need this? It causes a performance hit on reflow
            return 0;						// This tells the graph not to request more data right no
        */

        let seconds = (end - start) / 1000;								// Number of seconds we are currently viewing
        let maxPoints = Math.min(1000, this.width / MaxPixelsPerPoint);
        for (var index = 0; index < intervals.length - 1; ++index) {
            let points = seconds / intervals[index];					// Number of data points displayed should we choose this interval
            if (points < maxPoints)											// Select the first interval that will load up fewer than x000 data points
                return intervals[index];
        }
        return intervals.back();
    };

    /**
     * Request historical data of the live data client. Corrects the start and end  times so
     * that they are and even interval apart. Also updates internal records of  what exactly
     * what data has been queried.
     * @param {Number} [deviceID] The id of the device we want to query for data
     * @param {Number} [start] The time stamp of the beginning of the interval (in milliseconds)
     * @param {Number} [end] The time stamp of the end of the interval (in milliseconds)
     * @param {Number} [interval] The time between points (in seconds)
     * @param {Array}  [nodes] An array of SE nodes that we want data for
     */
    requestHistoricalData = function (deviceID, start, end, interval, nodes, fForce) {
        var devNode;	// Find the device node for this device id
        for (var i = 0; i < this.deviceNodes.length; ++i) {	// Heck all devies
            if (this.deviceNodes[i].id === deviceID) {		// This is the device for this node
                devNode = this.deviceNodes[i];	// Found the device
                break;	// No need to look further
            }
        }
        if (!this.ldc.isLoggedIn() || (nodes.length < 1) || devNode === undefined || devNode.request !== undefined)	// User should be logged in and we should have a good count of nodes
            return;

        start = Math.floor(start / 1000 / interval) * interval;	// Convert the time stamps to seconds
        end = Math.floor(end / 1000 / interval) * interval;
        //	end		= start + interval * (Math.floor((end - start) / interval));	// Fix up the end to be an even interval away

        // If you hit either of these, let Danno know and let him look at your stack!
        assert(start > 1000000, "Bad start date requested");
        assert(interval > 0, "Bad interval requested");

        var fNewStart = nodes.some(node => start < node.start);		// True if we need to request older data
        var fNewEnd = nodes.some(node => end > node.end);			// True if we need to request more recent data
        var fNewInterval = this.interval != interval;	            // True if this data doesn't match our old data

        // Check a few invariants we want to catch. An interval less than 1 is invalid, the end time must be
        // greater than the start time, and the start time must be a reasonable timestamp. If all of those are
        // true and we actually need new data for some reason, procede with the data request.
        if ((interval > 0) && (end > start) && (start > 1000000) && (fNewStart || fNewEnd || fNewInterval || (fForce === true))) {
            if (fNewStart || fNewInterval)	// If this is the oldest data we have queried, remember that
                devNode.dataStart = start;
            if (fNewEnd || fNewInterval)	// If this is the newest data we have queried, remember that
                devNode.dataEnd = end;
            if (fNewInterval)
                this.fIntervalChanging = true;

            var starts = [], ends = [], graphNodes = [];
            for (var i = 0; i < nodes.length; ++i) {
                if (fNewInterval || start < nodes[i].start || nodes[i].end < end) {
                    graphNodes.push(nodes[i]);
                    if (fNewInterval || fForce) {
                        nodes[i].start  = Number.POSITIVE_INFINITY;
                        nodes[i].end    = Number.NEGATIVE_INFINITY;
                        starts.push(start);
                        ends.push(end);
                    }
                    else {
                        if (start < nodes[i].start)
                            starts.push(start);
                        else
                            starts.push(nodes[i].end == Number.NEGATIVE_INFINITY ? start : nodes[i].end); // Request data starting at our newest existing data
                        if (end > nodes[i].end)
                            ends.push(end);
                        else
                            ends.push(nodes[i].start == Number.POSITIVE_INFINITY ? end : nodes[i].start); // Request data up to our oldest existing data
                    }
                    assert(starts.back() < ends.back(), "Bad request");
                }
            }
            assert(graphNodes.length * (ends[0] - starts[0]) / interval < 1000000);
            if (graphNodes.length == 0) {	// Actually have all the data
                if (this.booleanFill) {		// If we have something to fix up boolean data
                    var data = [[]];
                    this.booleanFill.fixFillData(data, [], start, end, interval);
                    this.options.dateWindow = this.graph.xAxisRange();	// Make sure the graph is looking at the right window
                    this.options.visibility = this.graph.visibility();	// Keep our visibility in sync when giving new data
                    this.fLocked = true;					// We haven't updated start or end time yet. Prevent an endless loop
                    this.graph.addData(data, this.options);	// Redraw the graph
                    this.fLocked = false;					// Unlock
                }
            } else if (graphNodes.length > 0) {
                    this.ldc.getGraphData(this.clientID, deviceID, interval, starts, ends, graphNodes, fNewInterval || fForce);
                    devNode.request = new HDRequest(start, end, interval, graphNodes);	// Add this request to our list of requested
            }
        }
    };

    /**
     * Request historical data of the live data client for all devices. Request the supplied
     * interval for all nodes on each of those devices.
     * @param {Number} [start] The time stamp of the beginning of the interval (in milliseconds)
     * @param {Number} [end] The time stamp of the end of the interval (in milliseconds)
     * @param {Number} [interval] The time between points (in seconds)
     * @param {Number} [line] The line this query is for
     */
    requestDataForAllDevices(start, end, interval) {
        let requestInterval = interval ?? this._calculateInterval(start, end);
        for (var i = 0; i < this.deviceNodes.length; ++i)
            this.requestHistoricalData(this.deviceNodes[i].id, start, end, requestInterval, this.deviceNodes[i].nodes);
    };

    convertArray(array, conversion) {
        if (array)
            for (var i = 0; i < array.length; ++i)
                if (array[i] !== null)
                    array[i] *= conversion;
    };

    /**
     * A response to our historical data request has come back. Parse it. If it matches one of
     * our historical requests, extract data and replot the graph.
     * @param {Number} [interval] The data interval requested in seconds
     * @param {Array} [data] The data -- data[0] is node names, followed by [times], [mins], [avgs], [maxes]
     */
    onGraphDataResponse(interval, data, deviceID) {
        var devNode = this.getDeviceNode(deviceID);
        if (devNode === undefined || devNode.request === undefined || devNode.request.interval !== interval || devNode.request.nodes.length !== data[0].length)
            return;
        for (var i = 0; i < devNode.request.nodes.length; ++i) {			// Check all of our nodes
            if (data[1+i*4].length == 0){    // If no data came back
                if (devNode.request.start < devNode.request.nodes[i].start) {            // If we are requesting data before data we have -- then there's no data to be had
                    data[1+i*4].push(devNode.request.start*1000, devNode.request.end * 1000);    // Fill the area in with nulls
                    data[2+i*4].push(null, null);
                    data[3+i*4].push(null, null);
                    data[4+i*4].push(null, null);
                } else {                    // This is actually a manually logged node trying to get current. Just trim it out for now
                    data[0].splice(i, 1);    // Remove the name
                    data.splice(1+i*4, 4);    // Remove the data rows
                    devNode.request.nodes.splice(i, 1);    // Remove it from the request
                    --i;                    // Decrement so the loop works
                    continue;                // Nothing else to do for this node
                }
            }
            if (devNode.request.start < devNode.request.nodes[i].start && data[1 + i * 4][0] > devNode.request.start * 1000) {
                data[1 + i * 4].unshift(data[1 + i * 4][0]);
                data[2 + i * 4].unshift(null);
                data[3 + i * 4].unshift(null);
                data[4 + i * 4].unshift(null);
            }
            if (!devNode.request.nodes[i].fMax)
                data[2 + i * 4] = data[4 + i * 4] = null;

            devNode.request.nodes[i].start = Math.min(devNode.request.nodes[i].start, data[1 + i * 4].front() / 1000);
            devNode.request.nodes[i].end = Math.max(devNode.request.nodes[i].end, data[1 + i * 4].back() / 1000 + interval);
        }

        if (this.booleanFill)								// If we have something to fix up boolean data
            this.booleanFill.fixFillData(data, devNode.request.nodes, devNode.request.start, devNode.request.end, interval);	// Fix up the boolean data

        var fUpdating = this.fUpdating;			// Need to store if graph is updating. Will change on the graph update callback
        this.options.dateWindow = this.graph.xAxisRange();	// Make sure the graph is looking at the right window
        this.options.visibility = this.graph.visibility();	// Keep our visibility in sync when giving new data

        var nodes = devNode.request.nodes;
        devNode.request = undefined;	    // Done with this request
        if (interval != this.interval) {	// This is an entirely new set of data. Clean house
            this.interval = interval;		// Store what resolution the data is at
            this.fIntervalChanging = false;	// The table has officially changed.
            var sortedData = data;				// If we don't need to sort, just pass in the data
            if (this.deviceNodes.length > 1) {	// If we have more than one device, need to sort the data in order
                sortedData = [[]];			// Build an empty array
                for (var i = 0; i < this.orderedNodes.length; ++i) {	// For each node in order
                    sortedData[0].push(this.orderedNodes[i].name);		// Ad the name
                    var index = nodes.indexOf(this.orderedNodes[i]);	// See if we got data for it
                    if (index === -1)							// No data received
                        sortedData.push([], null, [], null);	// Just reserve space
                    else										// We have data
                        sortedData.push(data[1 + index * 4], data[2 + index * 4], data[3 + index * 4], data[4 + index * 4]);
                }
            }
            this.graph.updateOptions(sortedData, this.options);	// This drops all old data. (Slower call)
        } else
            this.graph.addData(data, this.options);			// Just add data to the graph (Faster call)

        this.fUpdating = fUpdating;							// Reset this variable (that was unset in _onDraw)
        if (fUpdating) {									// If we were updating and they didn't block the redraw
            this.graph.hover(this.options.dateWindow[1]);	// Manually hover on the right side to show current values
            this._startTimer(this.interval);				// Start the timer again to call us again
        }
    };

    getDeviceNode(deviceID) {
        for (var i = 0; i < this.deviceNodes.length; ++i) {
            if (this.deviceNodes[i].id === deviceID)    // This is the device for this node
                return this.deviceNodes[i];		        // Found the device node
        }
    };

    /**
     * Update the date interval that the Dygraph is plotting.
     * @param {Number} [start] The starting timestamp that should be plotted (in milliseconds)
     * @param {Number} [end] The ending timestamp that should be plotted (in milliseconds)
     */
    updateWindow(start, end) {
        this.graph.doZoomXDates_(start, end);	// Replot at a new data window (no reparsing of data)
    };

    /**
     * The graph has just been drawn. Does nothing on the initial draw. On all redraws, this
     * determine if more data is needed or if the graph needs to be redrawn.
     * @param {Dygraph} [graph] The Dygraph object
     * @param {Boolean} [initialDraw] Whether or not this is the first draw
     * @private
     */
    _onDraw(graph, initialDraw) {
        if (initialDraw || this.fLocked) 	// Only do stuff on redraws with interactive graphs
            return;
        var date = new Date().getTime();		// Current timestamp in milliseconds
        var range = graph.xAxisRange();		// The range the graph is showing currently

        if (date < range[1]) {	// Before we do anything, make sure they haven't scrolled too far
            this.updateWindow(date - (range[1] - range[0]), date);	// No showing the future, but keep the same interval
            return;	// This method was called again through the updateWindow method. Just leave
        }

        var newInterval = this._determineInterval();	// See if they have changed logging table
        if (!this.fIntervalChanging && newInterval > 0) {	// Don't request any more data while we have a request for interval change outstanding
            if (newInterval != this.interval) {			// If the resolution of the data has changed
                for (var i = 0; i < this.deviceNodes.length; ++i)
                    this.deviceNodes[i].request = undefined;	// Clear all old requests. No longer want this data at a different resolution
                this.requestDataForAllDevices(range[0], range[1], newInterval);
            } else {	// Check if we need to query more data based on current range plotted
                for (var i = 0; i < this.deviceNodes.length; ++i) {
                    var devNode = this.deviceNodes[i];
                    var msecStart = devNode.dataStart * 1000;
                    var msecEnd = devNode.dataEnd * 1000;
                    // Check if they have done a big slide and gotten completely out of the old range we had plotted.
                    // If this is the case, we don't want to query all the data in between. Just request the new data
                    // that we want. We do this by resetting the interval so the dygraph will clear out the old data
                    if ((range[1] < msecStart) || msecEnd < range[0]) {
                        this.interval = -1;	// Reset the interval
                        devNode.request = undefined;		// Remove any requests for this line
                        this.requestHistoricalData(devNode.id, range[0], range[1], newInterval, devNode.nodes);
                    } else { // They are panning or zooming a little bit
                        // FIXME: Check the row count and make sure it isn't too big for the whole series in Dygraph
                        // Notice the next two statements are not mutually exclusive. If they scroll out, we should check both sides
                        if (range[0] < msecStart) 	// They have scrolled the graph back and want more data
                            this.requestHistoricalData(devNode.id, range[0], msecStart, newInterval, devNode.nodes);
                        if (msecEnd < range[1]) 	// They want newer data, but not from the future just yet
                            this.requestHistoricalData(devNode.id, msecEnd, range[1], newInterval, devNode.nodes);
                    }
                }
            }
        }

        if (this.selectedButton) {
            if (range[1] - range[0] != 1000 * (new Number(this.selectedButton.getAttribute('Value'))))
                this.customButton.checked = true;	// Set the custom radio button if the interval doesn't match the selected button
        }

        //if (this.onDateChange)
        //    this.onDateChange(range[0], range[1]);

        // Whatever the case, the user just zoomed or panned. They are no longer interested in live
        // data. Stop the query for updating the graph.
        this._stopTimer();	// Kill the update timer if it is running
    };

    /**
     * The user has changed the nodeSelector drop down box to a different node. Populate
     * any other controls with data representing this new node.
     * @private
     */
    _onFocusNodeChange() {
        var option = this.nodeSelector.options[this.nodeSelector.selectedIndex];
        if (!option)	// Nothing valid selected
            return;

        // Fill out any created user options with this node's info
        var graphNode = option.graphNode;
        if (this.nodeColor)
            this.nodeColor.selectedIndex = GenericGraph.colorStrings.indexOf(this.options[graphNode.name].color);
        if (this.nodeBars)
            this.nodeBars.selectedIndex = graphNode.fMax ? 1 : 0;	// Average only is index 0
    };

    /**
     * The user has changed the colorSelector drop down box. Change the color of
     * the node selected by the node selector.
     * @private
     */
    _onColorChange() {
        var option = this.nodeSelector.options[this.nodeSelector.selectedIndex];
        if (!option)	// Nothing valid selected
            return;

        var newColor = this.nodeColor.options[this.nodeColor.selectedIndex].value;
        option.graphNode.color = newColor;						// Save the new color on the graph node
        this.options[option.graphNode.name].color = newColor;	// Set the color as they want
        var i = this.orderedNodes.indexOf(option.graphNode);	// Find the index of the graph node
        if (i == this.leftAxisIndex)							// If this is the node we have on the left axis
            this._updateLeftAxis(option.graphNode);				// Update the axis color
        else if (i == this.rightAxisIndex)						// If this is the node we have on the right axis
            this._updateRightAxis(option.graphNode);			// Update the axis color
        this.legendIndicators[this.orderedNodes.indexOf(option.graphNode)].style.backgroundImage = '-webkit-linear-gradient(top, ' + (new Color(newColor).lighten(0.45)) + ', ' + (new Color(newColor).darken(.20)) + ')';
        this.graph.updateOptions(null, this.options);			// Tell the graph to look at these new colors right now
    };

    /**
     * The user has changed the min/max drop down box. Change the min/max setting of
     * the node selected by the node selector.
     * @private
     */
    _onMinMaxDataRequested() {
        var option = this.nodeSelector.options[this.nodeSelector.selectedIndex];
        if (!option)	// Nothing valid selected
            return;

        var graphNode = option.graphNode;					// Convenience reference
        if (this.nodeBars.selectedIndex === 0) {			// Average only
            graphNode.fMax = false;							// Reset the flag back to only the average
            this.graph.removeMinMaxData(graphNode.name);	// Remove the min and max data from Dygraph
        } else {
            graphNode.fMax = true;							// Add the max and mins lines
            var oldInterval = this.interval;				// Save our old interval
            this.interval = -1;								// Reset the interval to force the slow redraw, which makes dygraph reload everything
            var range = this.graph.xAxisRange();
            this.requestDataForAllDevices(range[0], range[1], oldInterval);	// Rerequest the data with the new flag
        }
    };

    updateRange(node, min, max) {
        for (var i = 0; i < this.orderedNodes.length; ++i) {
            var gn = this.orderedNodes[i];
            if (gn.node !== node)
                continue;
            gn.nodeMin = min;
            gn.nodeMax = max;

            if (i == this.leftAxisIndex)
                this._updateLeftAxis(gn);
            else if (i == this.rightAxisIndex)
                this._updateRightAxis(gn);

            this.options[gn.name].seriesRange = [min, max];
            this.graph.updateOptions(null, this.options);
        }
    };

    _updateLeftAxis(gn) {	// Update left axis options
        if (!gn)
            return;
        this.options.valueRange = (gn.nodeMax != undefined && gn.nodeMin != undefined) ? [gn.nodeMin, gn.nodeMax] : gn.node.flags & NodeFlags.NF_RANGE ? [gn.node.engMin, gn.node.engMax] : (gn.node.vtype == VType.VT_BOOL ? [0, 1] : undefined);
        this.options.yAxisLineColor = gn.color;		// Line color
        this.options.ylabel = (window.innerWidth < 768) ? "" : gn.text;		// Text label
        this.options.ylabelcolor = gn.color;		// Text color
    };

    _updateRightAxis(gn) {	// Update right axis options
        if (!gn)
            return;
        this.options.secondAxisRange = (gn.nodeMax != undefined && gn.nodeMin != undefined) ? [gn.nodeMin, gn.nodeMax] : gn.node.flags & NodeFlags.NF_RANGE ? [gn.node.engMin, gn.node.engMax] : (gn.node.vtype == VType.VT_BOOL ? [0, 1] : undefined);
        this.options.y2AxisLineColor = gn.color;		// Line color
        this.options.y2label = (window.innerWidth < 768) ? "" : gn.text;		// Text label
        this.options.y2labelcolor = gn.color;		// Text color
    };

    _changeAxis(axis) {	// The graph y axis was clicked
        if (axis == 0) {	// Left axis was clicked
            if (++this.leftAxisIndex >= this.maxAxis)	// Increment left axis index and clamp
                this.leftAxisIndex = 0;
            this._updateLeftAxis(this.orderedNodes[this.leftAxisIndex]);	// Update left axis options
        } else {			// Right axis was clicked
            this.options.customAxis[this.rightAxisIndex] = false;
            if (++this.rightAxisIndex >= this.maxAxis)	// Increment right axis index and clamp
                this.rightAxisIndex = 0;
            this.options.customAxis[this.rightAxisIndex] = true;
            this._updateRightAxis(this.orderedNodes[this.rightAxisIndex]);	// Update right axis options
        }
        this.options.dateWindow = this.graph.xAxisRange();	// Enter the current graph range to so we don't go too far
        this.graph.updateOptions(null, this.options);		// Update the graph
        this._updateTimer();
    };

    /**
     *  Add a new node to the graph. Live or historical, this function should take
     *  care of pretty much everything under the hood by either querying for more
     *  data or start to record live data as appropriate.
     */
    addNode(node, fBlock, optName, color, fAxis, nodeMax, nodeMin, fMax = false) {
        if (node.flags & NodeFlags.NF_ALIAS)	// If the node is an alias node
            return this.addNode(node.tree.nodes[node.sourceID], fBlock, optName, color, fAxis, nodeMax, nodeMin, fMax);	// Try to add the source node (don't pass in the indicator name)
        if (!(node.flags & NodeFlags.NF_LOG) && !(node.flags & NodeFlags.NF_DERIVED))	// Have to be historically logged
            return null;
        if ((node.vtype < VType.VT_BOOL) || (node.vtype > VType.VT_F64))	// Have to be a numeric node (no strings or bools)
            return null;
        for (var i = 0; i < this.orderedNodes.length; ++i) {
            if (this.orderedNodes[i].node === node)	// If we are already plotting this node
                return null;						// Just leave
        }

        if (!color) {
            color = 'black';				// Default color to black, just in case
            var fColorSelected = false;		// This is initialized to false so we don't immediately leave the for loop
            for (var j = 0; (j < GenericGraph.colorStrings.length) && !fColorSelected; ++j) {
                color = GenericGraph.colorStrings[j];
                fColorSelected = true;		// Assume this color is free until proven otherwise
                for (var i = 0; i < this.orderedNodes.length; ++i) {
                    if (color == this.options[this.orderedNodes[i].name].color) {
                        fColorSelected = false;	// This color is taken. Need to go around again, if possible.
                        break;					// Don't look through the rest of the nodes for this color
                    }
                }
            }
        }
        if (!optName) {
            let localNode = node;
            let text = Localization.toLocal(localNode.getDisplayName(false, false));
            while(localNode.parent && this.orderedNodes.some(orderedNode => orderedNode.text === text)) {
                localNode = localNode.parent;
                text = Localization.toLocal(localNode.getDisplayName(false, false)) + ' ' + text;
            }
            optName = text;
        }
        else
            optName = Localization.toLocal(optName);
        var newNode = new GraphNode(node, color, optName, nodeMax, nodeMin, fMax);	// Create the new graph node
        var readablePath = node.getDeviceRelativePath(true);
        this.orderedNodes.push(newNode);				// Add this new graph node to our ordered set
        this.options[newNode.name] = { color: color, digits: node.digits, units: node.getUnitsText() };

        this.options.customAxis.push(this.orderedNodes.length == 2);	// Only the second node gets true on the custom axis. We only care that axis is plotted if we have more than one node
        if (this.orderedNodes.length == 1 && this.options.axisClickCallback)		// If this is the first node
            this._updateLeftAxis(newNode);											// Scale it on the left axis
        else if (this.orderedNodes.length == 2 && this.options.axisClickCallback)	// If this is the second node
            this._updateRightAxis(newNode);											// Scale it on the right axis

        if (node.flags & NodeFlags.NF_RANGE)	{										// If this node has a range
            if (this.options.fGroupCommonUnits) {
                let max = (nodeMax != undefined && nodeMin != undefined)? nodeMax : node.engMax;
                let min = (nodeMax != undefined && nodeMin != undefined)? nodeMin : node.engMin;
                for (let i=0;i<this.orderedNodes.length - 1;i++) {
                    let oNode = this.orderedNodes[i];
                    if (oNode.node.units == newNode.node.units) {
                        max = Math.max(max, this.options[oNode.name].seriesRange[1]);
                        min = Math.min(min, this.options[oNode.name].seriesRange[0]);
                        this.options[oNode.name].seriesRange = [min, max];	// Plot it on the existing units range
                    }
                }
                this.options[newNode.name].seriesRange = [min, max];	// Plot it on the existing units range
            }
            else if (nodeMax != undefined && nodeMin != undefined)
                this.options[newNode.name].seriesRange = [nodeMin, nodeMax];	// Plot it on a custom range
            else
                this.options[newNode.name].seriesRange = [node.engMin, node.engMax];	// Plot it on the node's range
        }
        else if (node.vtype == VType.VT_BOOL && !(nodeMax != undefined && nodeMin != undefined))
            this.options[newNode.name].seriesRange = [0, 1];
        if (fAxis)
            ++this.maxAxis;

        if (this.options.legend)
            this._createLegendIndicator(Localization.toLocal(optName || readablePath), color, node.digits, node);

        var deviceNode = null;					// No device until proven otherwise
        var id = node.tree.device.id;	// Convenience reference
        for (var i = 0; i < this.deviceNodes.length; ++i) {
            if (this.deviceNodes[i].id === id) {		// This is the device for this node
                deviceNode = this.deviceNodes[i];		// No need to make a new device node
                break;									// no need to look further
            }
        }
        if (deviceNode === null) {	// Didn't find the device up above
            var range = this.graph.xAxisRange();
            deviceNode = new DeviceNode(id, range[0] / 1000, range[1] / 1000);	// Create the new device node
            this.deviceNodes.push(deviceNode);	// Add it to the array of device nodes
        }
        deviceNode.nodes.push(newNode);	// Add this new graph node to the device node's array

        if (this.nodeSelector) {										// If we have some control elements
            var newOption = document.createElement('option');	// Create a new option element
            newOption.text = readablePath;						// Set the text as the device relative path
            newOption.deviceNode = deviceNode;						// Give it attributes so we can look up the node easier later
            newOption.graphNode = newNode;							// Give him a reference to the graph node as well
            this.nodeSelector.add(newOption);							// Add this option to the node selector

            if (this.nodeSelector.children.length == 1)
                this._onFocusNodeChange();								// Make sure it is filled out with the correct info
        }

        if (fBlock !== true) {				// Have to give us true, specifcally
            var interval = this.interval > 0 ? this.interval : this._determineInterval();	// Determine which interval to use
            var domain = this.dataEnd > 0 ? [deviceNode.dataStart * 1000, deviceNode.dataEnd * 1000] : this.graph.xAxisRange();	// Determine what range to use
            this.requestHistoricalData(id, deviceNode.dataStart * 1000, deviceNode.dataEnd * 1000, interval, [newNode], true);	// Request historical data
            if (this.onNodesChanged)
                this.onNodesChanged(node);
        }
        return newNode;
    };

    clear() {
        this._clearAll();
    }

    /**
     * The user has pressed the Clear All button. Call removeNode until there's nothing
     * left to remove
     * @private
     */
    _clearAll() {
        this._createEmptyGraph(this.graph.xAxisRange());	// Destroy everything
        this.graph.createUserInterface(true);				// Tell the graph to allow all mouse events
        this.maxAxis = 0;
        this.options.customAxis.length = 0;
        if (this.onNodesChanged)
            this.onNodesChanged(null);
    };

    /**
     * The user has pressed the Remove Line button. Detach the node in the node selector
     * from the graph. Through a few callbacks, this will call the removeNode method and
     * clean up the graph.
     * @private
     */
    _removeLine() {
        var option = this.nodeSelector.options[this.nodeSelector.selectedIndex];	// Determine what line is in the drop down box
        this._removeNode(option.graphNode.node);	// Remove the node from the graph
    };

    /**
     * Create an empty graph with nothing plotted. This is also used to set several
     * values initially in the GenericGraph constructor.
     * @param {Array} [range] An array of two timestamps representing the interval that should be plotted (in milliseconds)
     * @private
     */
    _createEmptyGraph(range) {
        if (this.nodeSelector) {						// If this control was created
            while (this.nodeSelector.hasChildNodes()) 	// Remove everything from the node selector
                this.nodeSelector.removeChild(this.nodeSelector.lastChild);
        }

        this.deviceNodes = [];	// Clear any device nodes
        this.orderedNodes = [];	// Clear all ordered nodes
        this.interval = -1;	// Reset interval to an invalid number

        // Fix our start and end times for each line. Specifically create a new number for each array so they can be different
        if (this.graph)				// If we have an older graph sitting around
            this.graph.destroy();	// Kill the old graph right now

        var legend = this.options.legend;	// Save the legend option
        var mouseover = this.options.nomouseover;
        this.options.legend = false;				// Put the legend display at never (so we don't display empty data that we have graphed)
        this.options.dateWindow = range;
        this.options.nomouseover = true;

        // Create an empty graph looking at the same time interval they gave us
        var data = [['fake'], range, null, null, null];				// Fill with fake data
        this.graph = new Dygraph(this.element, data, this.options);	// Plot the empty graph
        this.fIntervalChanging = false;	// No outstanding interval changing requests, either

        this.options.legend = legend;	// Set the legend option back to how it started
        this.options.nomouseover = mouseover;

        while (this.legendIndicators.length)	// Clear the legend
            this._removeLegendIndicator(0);
    };

    /**
     * Pass in static graph data in the SE data format. Should only be called once per StaticGraph.
     * @param {Array} [data] The data to be plotted in Dygraph's SE data format
     */
    giveStaticData(data, fFullModel) {
        this._stopTimer();	// Static data? No update timer allowed!
        this.graph = new Dygraph(this.element, data, this.options);
        this.graph.createUserInterface(fFullModel === true);	// Full full model allowed here
    };

    makeLive() {
        var range = this.graph.xAxisRange();		// Get the current range of the graph
        var date = new Date().getTime();			// Current date
        this.updateWindow(date - (range[1] - range[0]), date);	// Look at now (in the same interval they had before)
        this._startTimer(this._determineInterval());	// Start the update timer, giving it the current graph's interval
    }

    makeStatic() {
        this._stopTimer();	// Static data, not live data
    }

    /**
     * Either start or stop polling for updated historical data depending on the current updating status.
     * @private
     */
    _changeUpdateStatus() {
        if (this.live.checked) {	// If we are currently on the live version of the graph
            this.makeLive()
        } else
            this.makeStatic();	// Static data, not live data
    };

    /**
     * Kick off a timer to make us poll for the most recent historical data. Set the live image, if it exists.
     *
     */
    _startTimer(interval) {
        if (interval > 0) {		// If they gave us a good interval and we are updating still
            if (this.timerID !== null)	// Have a previously started timer
                this._stopTimer();

            // Should have killed any active timers by now (only one should go at a time)
            assert(this.timerID === null, "Have more than one update timer active!");

            // The bind call gives the _updateTimer method the correct 'this' scope instead
            // of the DOMwindow as 'this' in the callback. Thank you, internet.
            this.timerID = setTimeout(this._updateTimer.bind(this), interval * 1000 / 10);
            this.fUpdating = true;				// We are updating

            if (this.live)	// If there are controls created
                this.live.checked = true;
        }
    };

    /**
     * Kill any timers that we have running for us. Reset the live image, if it exists.
     * @private
     */
    _stopTimer() {
        if (this.timerID !== null) {			// If we have a timer running...
            window.clearTimeout(this.timerID);	// Cancel the timer based upon the ID
            this.timerID = null;				// Remove the stored timer ID (the timer is gone)
            this.fUpdating = false;			// No longer updating
            if (this.stat)						// If there are controls created
                this.stat.checked = true;		// Set the live control to the static setting
        }
    };

    /**
     * A timer has expired. Keep the graph in sync with the world, if we have historical data nodes. If
     * not, update the plot so any live data we've passed to the graph gets drawn.
     * @private
     */
    _updateTimer() {
        this.timerID = null;	// This timer has fired and this ID is no good anymore

        if (!this.fUpdating || this.orderedNodes.length == 0)	// Only continue if we are still a live graph and we have nodes we are plotting
            return;

        // Request data for the new interval. When it comes back, the replot graph method will move time forward
        var interval = this._determineInterval();
        if (interval > 0) {
            var date = new Date().getTime();		// Current timestamp in milliseconds
            var range = this.graph.xAxisRange();	// The range the graph is showing currently
            if (this.element.offsetParent !== null)
                this.updateWindow(date - (range[1] - range[0]), date);	// Jump the graph forward (which will request new data)
            var fRequest = false;								// Assume there's no outstanding request until proven otherwise
            for (var i = 0; i < this.deviceNodes.length; ++i) {	// Check all devices
                if (this.deviceNodes[i].request) {				// If this node has a request out
                    fRequest = true								// Make a note of it
                    break;										// No need to look further
                }
            }

            if (!fRequest)					// If there's no request out
                this._startTimer(interval);	// Start the timer
        }
    };

    // Options for the drop down boxes -- static for all generic graph instances
    static colorStrings = ['red', 'black', 'blue', 'green', 'purple', '#76B7B2', 'maroon', 'gray', 'lightgreen', 'orange', 'pink', '#e6194b', '#3cb44b', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#ffffff', '#000000'];
    static minMaxStrings = ['Avg only', 'Min/Max'];
    static radioStrings = ['Mi', 'H', 'D', 'W', 'Mo', 'Y', 'Custom'];
    static radioIntervals = [60, 3600, 86400, 604800, 2592000, 31536000, null];
    static liveStrings = ['Live', 'Static'];
    static radioGroupID = 0;
    static radioButtonID = 0;
    static intervals = [1, 10, 60, 600, 3600, 21600, 86400];	// Possible data intervals we will request for
};

/**
 * This graph will load up flow, SEC, target levels, source levels, and will fill a colored mask in the
 * background for the pump running status(all by role and going through the pump station). The graph will
 * have panning and zooming abilities, but no other controls. This graph defaults to showing the last day
 * for all nodes.
 * @param {LiveDataClient} [ldc] The live data client we will query historical data through
 * @param {Node} [pumpBank] The pump station node folder
 * @param {DOM Element} [parentElement] The element we will instantiate this graph in
 * @param {Number} [height] The height (in pixels) that we wish the graph to be
 */
export class PumpSystemGraph extends GenericGraph {
    constructor(ldc, pumpBank, parentElement, width, height, fInteractive, controlDiv, legendDiv, options) {
        var end = new Date();								// Current time
        var endTime = end.getTime();
        var start = new Date(end.getTime() - 1000 * 3600 * 24);	// Default to the last day (in milliseconds)
        var startTime = start.getTime();
        super(ldc, parentElement, width, height, start, end, false, controlDiv, legendDiv, options)

        if (fInteractive) this.makeInteractive(true);	// Let them pan and zoom and such
        else this.createUserInterface(true);	// Tell the graph to allow all mouse events

        this.createLegend();			// Make a legend happen
        this.createDateSelection();	// Give them buttons that change the range of data presented
        this.axesCanChange();			// Axis can change between attached nodes

        // Query the nodes we want -- flow and SEC
        var flow = pumpBank.findChildByRole(Role.ROLE_TOTAL_FLOW);
        var sec = pumpBank.findChildByRole(Role.ROLE_SEC);
        assert(flow, "Couldn't find the total flow node");
        assert(sec, "Couldn't find the total SEC node");

        // We pass true as the second argument to the addNode methods to block data requesting. We will manually
        // request data as a group after all nodes are added instead of making a request for each node.
        this.addNode(flow, true, 'Flow', owner.colors.hex('--color-flow-1'), true);	// The last true means we can change the axis to this
        this.addNode(sec, true, 'Specific Energy', owner.colors.hex('--color-se-1'), true);
        var suctions    = pumpBank.findByRole(Role.ROLE_SUCTION_PRESSURE);
        if (suctions.length == 1)
            this.addNode(suctions[0], true, 'Suction Pressure', 'black', true);
        else {
            for (var i = 0; i < suctions.length; ++i)
                this.addNode(suctions[i], true, null, null, true);
        }

        var discharges = pumpBank.findByRole(Role.ROLE_DISCHARGE_PRESSURE);
        if (discharges.length == 1)
            this.addNode(discharges[0], true, 'Discharge Pressure', 'purple', true);
        else {
            for (var i = 0; i < discharges.length; ++i)
                this.addNode(discharges[i], true, null, null, true);
        }

        let cpv = pumpBank.findChildByRole('CostPerVolume');
        if (cpv)
            this.addNode(cpv, true, null, '#85bb65', true);

        var rootNode = pumpBank.tree.nodes[0];	// Get the root node
        var tankLevels = rootNode.findByRole(Role.ROLE_TLC_TANK_LEVEL);
        tankLevels.push(...rootNode.findByRole(Role.ROLE_TARGET_TANK_LEVEL));
        for (var i = 0; i < tankLevels.length; ++i)
            this.addNode(tankLevels[i], true, undefined, undefined, true);	// Plot these on the right axis, supply alternate name
        for (var i = 0; options && options.nodes && i < options.nodes.length; ++i)
            this.addNode(options.nodes[i], true, options.nodes[i].getDisplayName(), undefined, true);

        let superSpecials = rootNode.findByRole(Role.ROLE_GRAPH_VALUE);
		for(let i = 0; i < superSpecials.length; ++i)
			this.addNode(superSpecials[i], true, superSpecials[i].name, undefined, true, superSpecials[i].engMax, superSpecials[i].engMin);

        var modelPumps = pumpBank.findByRole(Role.ROLE_MODEL_PUMP);
        if (modelPumps.length > 0)						// If we got pump model flow nodes
            this.createPumpFlowFill(modelPumps, flow);	// Create a special fill for these guys. True to block the call for data

        // Now request data for all added nodes (which should just be what we attached above)
        this.requestDataForAllDevices(startTime, endTime, this._calculateInterval(startTime, endTime), 0);
    };
};

/**
 * This graph will load up flow, SEC, target levels, source levels, and will fill a colored mask in the
 * background for the pump running status(all by role and going through the pump station). The graph will
 * have panning and zooming abilities, but no other controls. This graph defaults to showing the last day
 * for all nodes.
 * @param {LiveDataClient} [ldc] The live data client we will query historical data through
 * @param {Node} [pumpBank] The pump station node folder
 * @param {DOM Element} [parentElement] The element we will instantiate this graph in
 * @param {Number} [height] The height (in pixels) that we wish the graph to be
 */
export class PumpSystemCardGraph extends GenericGraph {
    constructor(ldc, pumpBank, parentElement, width, height, fInteractive, controlDiv, legendDiv, options) {
        var end = new Date();								// Current time
        var endTime = end.getTime();
		var start		= new Date(endTime - 24*1000*3600);	// Default to the last hour
        var startTime = start.getTime();
        super(ldc, parentElement, width, height, start, end, false, controlDiv, legendDiv, options);

        this.stopHighlighting();

        // Query the nodes we want -- flow and SEC
        var flow = pumpBank.findChildByRole(Role.ROLE_TOTAL_FLOW);
        assert(flow, "Couldn't find the total flow node");
        assert(flow != null);
        // We pass true as the second argument to the addNode methods to block data requesting. We will manually
        // request data as a group after all nodes are added instead of making a request for each node.
        this.addNode(flow, true, 'Flow', 'blue', true);	// The last true means we can change the axis to this

        var rootNode = pumpBank.tree.nodes[0];	// Get the root node
        var tankLevels = rootNode.findByRole(Role.ROLE_TLC_TANK_LEVEL);

        var modelPumps = pumpBank.findByRole(Role.ROLE_MODEL_PUMP);
        if (modelPumps.length > 0)						// If we got pump model flow nodes
            this.createPumpFlowFill(modelPumps, flow);	// Create a special fill for these guys. True to block the call for data

        // Now request data for all added nodes (which should just be what we attached above)
        this.requestDataForAllDevices(startTime, endTime, this._calculateInterval(startTime, endTime), 0);
    };
};

export class NodeGraph extends GenericGraph {
    constructor(ldc, nodes, element, width, height, options) {
        var end = new Date();								// Current time
        var endTime = end.getTime();
        var start = new Date(end.getTime() - 1000 * 3600 * 24);	// Default to the last day (in milliseconds)
        var startTime = start.getTime();
        super(ldc, element, width, height, start, end, true, undefined, undefined, options);


        this.createLegend();			// Make a legend happen
        for (var i = 0; i < nodes.length; ++i)
            this.addNode(nodes[i], true, undefined, options.colors ? options.colors[i] : undefined);

        // Now request data for all added nodes (which should just be what we attached above)
        this.requestDataForAllDevices(startTime, endTime, this._calculateInterval(startTime, endTime), 0);
    };
};

export class PumpRunGraph extends GenericGraph {
    constructor(ldc, pumps, parentElement, width, height, options) {
        var end = new Date();								// Current time
        var endTime = end.getTime();
        var start = new Date(end.getTime() - 1000 * 3600 * 24);	// Default to the last day (in milliseconds)
        var startTime = start.getTime();
        super(ldc, parentElement, width, height, start, end, true, options)

        this.stopHighlighting();		// No highlighting points on mouse over

        var running = [];
        for (var i = 0; i < pumps.length; ++i)
            running.push(pumps[i].findChildByRole(Role.ROLE_BOOL_RUNNING));

        if (running.length > 0)		// If we got pump running status nodes
            this.createBooleanFill(running, false);	// Create a special fill for these guys. True to block the call for data

        // Now request data for all added nodes (which should just be what we attached above)
        this.requestDataForAllDevices(startTime, endTime, this._calculateInterval(startTime, endTime), 0);
        this._startTimer(this._calculateInterval(startTime, endTime));	// Keep the graph live
    };
};

/**
 * This graph will allow HTML5 drag and drop nodes to be plotted with tons of different controls
 * available. Specifically, lines can be cleared, colors can be changed, min and max are
 * optionally plottable (for historical nodes only), and the user can can choose to plot lines
 * on the left or right axis. The full interaction model is built up, meaning the graph is zoomable
 * and pannable. It will also fill up a legend on a mouse over event. The graph defaults to
 * showing the past 24 hours of data.
 * @param {LiveDataClient} [ldc] The live data client we will query historical data through
 * @param {DOM Element} [parentElement] The element we will instantiate this graph in
 * @param {Number} [height] The height (in pixels) that we wish the graph to be
 */
export class DragDropGraph extends GenericGraph {
    constructor(ldc, parentElement, width, height, start, end, fInteractive, fDateSelection, fLegend, controlDiv, legendDiv, options) {
        super(ldc, parentElement, width, height, start, end, false, controlDiv, legendDiv, options);

        //graph.createClearControl();		// Can remove lines
        //graph.createColorControl();		// Can select colors
        //graph.createMinMaxControl();	// Can get min and max data
        if (fInteractive)
            this.makeInteractive(true);	// Let them pan and zoom and such

        if (fDateSelection)
            this.createDateSelection();	// Give them buttons that change the range of data presented
        if (fLegend)
            this.createLegend();		// Make a legend happen
        this.axesCanChange();			// Axis can change between attached nodes
        return this;
    };
};

/**
 * This graph will plot static data that you pass it. No node calls, though you do get a generic
 * options object. No mouseover events and no updating.
 * @param {LiveDataClient} [ldc] The live data client we will query historical data through
 * @param {DOM Element} [parentElement] The element we will instantiate this graph in
 * @param {Number} [width] The width (in pixels) that we wish the graph to be
 * @param {Number} [height] The height (in pixels) that we wish the graph to be
 * @param {Array} [data] The data to plot in the Dygraph's SE data format
 * @param {Object} [options] Any dygraph options to add to the graph
 */
export class StaticGraph extends GenericGraph{
    constructor(ldc, parentElement, width, height, data, options, fHighlight, fFullModel) {
        super(ldc, parentElement, width, height, null, null, true, null, null, options);
        if (fHighlight !== true)
            this.stopHighlighting();	// No highlighting points on mouse over
        if (!options.drawCallback)
            delete this.options.drawCallback;	// No option to create a legend with this guy, so don't try and call that method
        this.giveStaticData(data, fFullModel);	// Give it data once. Graph should be static after this call
        this.dygraph = this.graph;
    };
};

/**
 * This is a simple storage structure so we can confirm that our data requests come back correctly.
 * @param {Number} [start] The start time of the data request (in seconds)
 * @param {Number} [end] The end time of the data request (in seconds)
 * @param {Number} [interval] The interval between points (in seconds)
 * @param {Array} [nodes] An array of SE nodes that we want data for
 */
export class HDRequest {
    constructor(start, end, interval, nodes) {
        this.start = start;	// Store the start time stamp of the data interval we are requesting
        this.end = end;		// Store the end time stamp of the data interval we are requesting
        this.interval = interval;	// Store the interval between data points
        this.nodes = nodes;	// Store a pointer to the array of graph nodes they have passed us

        // All the nodes we get should be logged and should be from the same device
        assert(nodes[0].node.tree)
        var device = nodes[0].node.tree.device;
        for (var i = 0; i < nodes.length; ++i) {
            assert((nodes[i].node.flags & (NodeFlags.NF_LOG | NodeFlags.NF_DERIVED)) > 0, "Node " + nodes[i].node.name + " is not logged, but is requesting historical data.");
            assert(nodes[i].node.tree.device === device, "Nodes requesting historical data have multiple devices.");
        }
    };
};

/**
 * This is a simple storage structure so we can sort queried nodes by device.
 * @param {Number} [id] The device id of the Device this represents
 */
export class DeviceNode {
    constructor(id, start, end) {
        this.id = id;	// Device ID
        this.dataStart = start;
        this.dataEnd = end;
        this.nodes = [];	// Array of GraphNodes
    };
};

/**
 * This is a simple storage structure to hold all graphing information about a node.
 * @param {Node} [node] The SE Node this GraphNode holds information about
 * @param {String} [color] The color this node should be when plotted
 */
export class GraphNode {
    constructor(node, color, optName, nodeMax, nodeMin, fMax=false) {
        this.node = node;							// Pointer to the live data node
        this.path = node.getDeviceRelativePath();	// Will need this fairly often. Only build it up once
        this.name = node.tree.device.key + this.path;	// Save the name of the device we will pass to dygraph
        this.fMax = fMax;						// What we are requesting (by default, average only)
        this.color = color;						// Save the color of the node
        this.text = optName;
        this.start = Number.POSITIVE_INFINITY;
        this.end = Number.NEGATIVE_INFINITY;
        this.nodeMax = nodeMax;
        this.nodeMin = nodeMin;
        this._end    = undefined;
        this._start  = undefined;
    };
};

/**
 * This guy manages drawing a filled background to the graph based upon boolean nodes
 * @param {GenericGraph} [graph] The graph that created the fill
 * @param {Array} [nodes] All nodes that should be plotted as a background fill
 * @param {Boolean} [fBlock] Whether to block the request for historical data or not
 */
export class BooleanFill {
    constructor(g, nodes, fAddColors) {
        this.nodes = [];	// Save the array of graph nodes for future reference
        for (var i = 0; i < nodes.length; ++i) {
            assert(nodes[i].flags & NodeFlags.NF_LOG, "All fill nodes should be logged!");
            var graphNode = g.addNode(nodes[i], true);	// Add each node to the graph
            if (g.options.legend) 	// Hide the legend indicator if we have a legend
                g.legend.removeChild(g.legendIndicators.back());	// Hide the legend indicator
            this.nodes.push(graphNode);
            g.options[graphNode.name] = {
                strokeWidth: 0, 		// Don't draw lines for these guys
                fillAlpha: 0.6,		// All the way opaque (as we have selected colors appropriately)
                color: fAddColors ? BooleanFill.colorStrings[i] : undefined,
                nomouseover: true,
                seriesRange: [0, nodes.length],	// max is the count of pumps
                drawBehind: true
            };	// Draw behind the grid lines
        }
    };

    onLegendUpdate() { }	// All of our legend entries are hidden
    fixFillData(data, requestedNodes, start, end, interval) {
        // Go through all the nodes we have and fix up data.
        if (interval !== this.interval) {
            this.data = [];
            for (var i = 0; i < this.nodes.length; ++i)
                this.data.push([], [], [], []);
            this.interval = interval;
        }

        var dates = [];	// All lines will have the same date array
        var avgs = [];	// Each pump gets a separate average data set. Average for both the flow max and ouside POR regions
        var maxes = [];	// Each pump gets a separate max data set
        var points = [];// How many pump running data points we've already iterated through in the data response
        var lastMaxs = [];
        for (var i = 0; i < this.nodes.length; ++i) {	// For each pump
            avgs.push([]);		// Put in an array to hold each pump's flow average line
            maxes.push([]);		// Put in an array to hold each pump's flow max line
            points.push(0);		// Start at the first flow point
            lastMaxs.push(0);

            var index = requestedNodes.indexOf(this.nodes[i]);
            if (index >= 0) {
                index = 1 + (index * 4);
                if (this.data[4 * i].length == 0 || this.data[4 * i].back() <= data[index].front()) {
                    this.data[4 * i + 0] = this.data[4 * i + 0].concat(data[index]);
                    this.data[4 * i + 1] = this.data[4 * i + 1].concat(data[index + 2]);
                } else {
                    this.data[4 * i + 0] = data[index].concat(this.data[4 * i + 0]);
                    this.data[4 * i + 1] = data[index + 2].concat(this.data[4 * i + 1]);
                }

                data[index] = dates;			// Everyone gets the same date array
                data[index + 1] = avgs[i];		// Put in the minimums for this line
                data[index + 2] = maxes[i];		// Put in the averages for this line
            } else {
                data[0].push(this.nodes[i].name);
                data.push(dates, avgs[i], maxes[i], null);
            }
        }

        var fFirstPass = true;
        for (var time = start * 1000; time < end * 1000; time += interval * 1000) {	// For each point in the interval requested
            var total = 0;				// Flow total starts at 0
            var newAvgs = [], newMaxs = [];
            var fDifferent = false;
            for (var i = 0; i < this.nodes.length; ++i) {	// For each pump
                newAvgs.push(total);
                total += this.getValueAtTime(time, 4 * i, points, i) > 0.5 ? 1 : 0;	// Accrue total running pumps
                newMaxs.push(total);			// The maximum is the current total
                if (newMaxs[i] != lastMaxs[i])	// If this is different, we need to add the point
                    fDifferent = true;			// Note the difference
            }
            if (fDifferent || fFirstPass) {	// If the running pumps changed
                dates.push(time);			// Add the date for our point
                if (!fFirstPass)			// Not first pass? Add two points so the line is vertical
                    dates.push(time);
                for (var i = 0; i < this.nodes.length; ++i) {	// For each pump
                    if (!fFirstPass) {	// Fake up a point (just like the last one) so the run time graphs look sharp
                        avgs[i].push(avgs[i].back());
                        maxes[i].push(maxes[i].back());
                    }

                    avgs[i].push(newAvgs[i]);	// Add the average as whatever the last line ended as
                    maxes[i].push(newMaxs[i]);	// The maximum is the current total
                }
                fFirstPass = false;				// No longer the first past
                lastMaxs = newMaxs.slice(0);	// Keep track of the last point
            }
        }
        dates.push(time);				// Add one more end point so the graph goes to the most recent time
        for (var i = 0; i < this.nodes.length; ++i) {	// For each pump
            avgs[i].push(newAvgs[i]);	// Add the point one more time
            maxes[i].push(newMaxs[i]);
        }
    }
    getValueAtTime(time, index, pointArray, i) {	// Find the value at a given time from the historical data array
        while (this.data[index][pointArray[i]] < time)		// While the data we have is too old
            ++pointArray[i];								// Keep looking, incrementing point array so we only iterate through the data once for each node
        return this.data[index + 1][pointArray[i]];			// Return the data
    }
    /*
    static colorStrings(index) {
        this.theme = owner.colors.getTheme();
        return this.theme['--color-graph-'+(index+1)];
    }*/

    static colorStrings = ['#FF7F0E60', '#1F77B460', '#2CA02C60', '#D6272860', '#9467BD60', '#E377C260', '#17BECF60', '#BCBD2260', '#8C564B60', '#e6194b60', '#3cb44b60', '#ffe11960', '#4363d860', '#f5823160', '#911eb460', '#46f0f060', '#f032e660', '#bcf60c60', '#fabebe60', '#00808060', '#e6beff60', '#9a632460', '#fffac860', '#80000060', '#aaffc360', '#80800060', '#ffd8b160', '#00007560', '#80808060', '#00000060'];	// Colors specific to the background
};
/**
 * This guy draws a fill similar to BooleanFill, but does it based on per pump flows
 * @param {GenericGraph} [graph] The graph that created the fill
 * @param {Array} [modelPumps] All ModelPump nodes
 */
export class PumpFlowFill {	// Graph pump flows as shaded regions underneath the total flow
    constructor(g, modelPumps, totalFlow) {
        this.flows = [];			// Pump flow GraphNodes live here
        this.outsidePOR = [];			// Pump outside POR GraphNodes live here
        this.graph = g;			// Generic graph
        this.image = document.getElementById('fill');	// Fill image
        this.insidePor = 'rgba(255,255,255, 0.6)';			// Inside POR class
        this.outsidePor = 'rgba(255,255,255, 0.6)';//'repeating-linear-gradient(135deg, transparent, transparent 5px, ' + owner.colors.getTheme()['--color-error'] + '60' + ' 5px, ' + owner.colors.getTheme()['--color-error'] + '60' + ' 10px), rgba(255,255,255, 0.6)';	// Outside POR class name

        for (var i = 0; i < modelPumps.length; ++i) {	// For each pump
            if (!modelPumps[i]) continue;
                this.outsidePOR[i] = this.addNode(g, i, totalFlow, modelPumps[i].findChild("OutsidePOR"), true);	// Add each outside POR to the graph
                this.flows[i] = this.addNode(g, i, totalFlow, modelPumps[i].findChild("Flow"), false);		// Add each flow node to the graph
        }
        g.options.legendCallback = this.onLegendUpdate.bind(this);		// We call this when the legend is updated
        g.options.outsidePOR = {
            strokeWidth: 0, 			// Don't draw lines for these guys
            nomouseover: true,		// Don't give us a legend entry for this
            fillAlpha: 1,			// All the way opaque (as we have selected colors appropriately)
            seriesRange: [totalFlow.engMin, totalFlow.engMax],
            color: owner.colors.getTheme()['--color-error']
        };	// Specify the color
        g.options.greenThing = {
            strokeWidth: 0, 			// Don't draw lines for these guys
            pointSize: 0,			// No points for this guy
            nomouseover: true,				// Don't give us a legend entry for this
            fillAlpha: 1,					// All the way opaque (as we have selected colors appropriately)
            seriesRange: [totalFlow.engMin, totalFlow.engMax],
            color: 'rgb(0,160,0)'
        };	// Specify the color
        this.negativeValue = totalFlow.engMax / 100;							// Create the minimum value we will use for plotting inside/outside POR summaries
        g.options.customAxis.push(false, false);								// Add two entries on the left axis. We will add these two lines manually
    };

    static colors = ['#FF7F0E60', '#1F77B460', '#2CA02C60', '#D6272860', '#9467BD60', '#E377C260', '#17BECF60', '#BCBD2260', '#8C564B60', '#e6194b', '#3cb44b', '#ffe119', '#4363d8', '#f58231', '#911eb4', '#46f0f0', '#f032e6', '#bcf60c', '#fabebe', '#008080', '#e6beff', '#9a6324', '#fffac8', '#800000', '#aaffc3', '#808000', '#ffd8b1', '#000075', '#808080', '#000000'];

    addNode(g, i, flow, node, fFill) {
        var graphNode = g.addNode(node, true);													// Add each flow node to the graph
        g.options[graphNode.name] = {
            strokeWidth: 0, 								// Don't draw lines for these guys
            highlightCircleSize: 0, 								// No highlighting points on these lines
            fillAlpha: 0.4,								// All the way opaque (as we have selected colors appropriately)
            seriesRange: [flow.engMin, flow.engMax],		// Each is scaled like the total flow
            color: g.options.fillColors ? g.options.fillColors[i] : BooleanFill.colorStrings[i],	// Specify the color
            noLegendEntry: fFill,							// Outside POR doesn't get a legend entry
            fillImage: fFill ? this.image : null,		// Use the fill image (but only if they told us to)
            drawBehind: true
        };
        if (g.options.legend)						// Draw behind the grid lines
            g.legend.removeChild(g.legendIndicators.back());
        return graphNode;	// Return the graph node
    };

    onLegendUpdate(x, points) {					// Update the legend
        var prevValue = 0;									// Previous value -- dygraph only has integral
        var window = this.graph.graph.xAxisRange();			// Get the window of the graph
        var fNull = points[0].value === null;
        for (let i = 0; i < this.flows.length; ++i) {		// For each line
            var index = points.length - (this.flows.length - i) * 2;	// Get the index of the pump flow in the graph
            var point = points[index + 1];					// Convenience reference to the point
            if (point.value === null)
                continue;

            var flow = point.value - prevValue;				// Calculate pump flow from difference in lines
            var fOutsidePOR = points[index].value !== null;	// Figure out if we were outside of POR from the legend data
            point.div.innerHTML = flow.toFixed(this.flows[i].node.digits) + " " + this.flows[i].node.getUnitsText();	// Put in the pump flow
            point.div.style.background = fOutsidePOR ? this.outsidePor : this.insidePor;	// Update the background
            point.div.style.color = PumpFlowFill.colors[i];			// Update to our own special color
            point.div.classList.toggle('hide', fNull);
            if (x > (window[1] + window[0]) / 2)			// If the are on the left side of the graph, location changed when we changed text
                point.div.style.left = (points[0].div.offsetLeft + (points[0].div.offsetWidth - point.div.offsetWidth)) + 'px';	// Offset from total flow graph
            prevValue = point.value;						// Save value so we can calculate the next pump's flow
        }
    }

    getValueAtTime(time, index, pointArray, i) {	// Find the value at a given time from the historical data array
        while (this.data[index][pointArray[i]] < time)		// While the data we have is too old
            ++pointArray[i];								// Keep looking, incrementing point array so we only iterate through the data once for each node
        return this.data[index + 1][pointArray[i]];			// Return the data
    }

    fixFillData(data, requestedNodes, start, end, interval) {
        if (interval !== this.interval) {
            this.data = [];
            for (var i = 0; i < this.flows.length; ++i)
                this.data.push([], [], [], []);
            this.interval = interval;
        }

        var dates = [];	// All lines will have the same date array
        var avgs = [];	// Each pump gets a separate average data set. Average for both the flow max and ouside POR regions
        var maxes = [];	// Each pump gets a separate max data set
        var badDates = [];
        var badAvgs = [];
        var bad = [];	// Each pump gets a separate outside POR data set

        var fWasOutside = [];
        var flowPoints = [];		// How many pump flow data points we've already iterated through in the data response
        var outsidePoints = [];		// How many pump speed data points we've already iterated through in the data response
        for (var i = 0; i < this.flows.length; ++i) {	// For each pump
            avgs.push([]);			// Put in an array to hold each pump's flow average line
            maxes.push([]);			// Put in an array to hold each pump's flow max line
            bad.push([]);			// Put in an array to hold each pump's outside POR max line
            badAvgs.push([]);
            badDates.push([]);
            flowPoints.push(0);		// Start at the first flow point
            outsidePoints.push(0);	// Start at the first speed point
            fWasOutside.push(false);

            var flowIndex = requestedNodes.indexOf(this.flows[i]);
            if (flowIndex >= 0) {
                flowIndex = 1 + (flowIndex * 4);
                if (this.data[4 * i].length == 0 || this.data[4 * i].back() <= data[flowIndex].front()) {
                    this.data[4 * i + 0] = this.data[4 * i + 0].concat(data[flowIndex]);
                    this.data[4 * i + 1] = this.data[4 * i + 1].concat(data[flowIndex + 2]);
                } else {
                    this.data[4 * i + 0] = data[flowIndex].concat(this.data[4 * i + 0]);
                    this.data[4 * i + 1] = data[flowIndex + 2].concat(this.data[4 * i + 1]);
                }
                //				for (var j = 1; j < this.data[4*i+0].length; ++j) {
                //					if (this.data[4*i+0][j] <= this.data[4*i+0][j-1]) {
                //						console.log(this.data[4*i+0]);
                //						assert(this.data[4*i+0][j] >= this.data[4*i+0][j-1], "Bad data order");
                //					}
                //				}
                data[flowIndex] = dates;			// Everyone gets the same date array
                data[flowIndex + 1] = avgs[i];		// Put in the minimums for this line
                data[flowIndex + 2] = maxes[i];		// Put in the averages for this line
            } else {
                data[0].push(this.flows[i].name);
                data.push(dates, avgs[i], maxes[i], null);
            }

            var outsideIndex = requestedNodes.indexOf(this.outsidePOR[i]);
            if (outsideIndex >= 0) {
                outsideIndex = 1 + (outsideIndex * 4);
                if (this.data[4 * i + 2].length == 0 || this.data[4 * i + 2].back() < data[outsideIndex].front()) {
                    this.data[4 * i + 2] = this.data[4 * i + 2].concat(data[outsideIndex]);
                    this.data[4 * i + 3] = this.data[4 * i + 3].concat(data[outsideIndex + 2]);
                } else {
                    this.data[4 * i + 2] = data[outsideIndex].concat(this.data[4 * i + 2]);
                    this.data[4 * i + 3] = data[outsideIndex + 2].concat(this.data[4 * i + 3]);
                }
                data[outsideIndex] = badDates[i];
                data[outsideIndex + 2] = bad[i];		// Put in the minimums for this line
                data[outsideIndex + 3] = badAvgs[i];	// Put in the averages for this line
            } else {
                data[0].push(this.outsidePOR[i].name);
                data.push(badDates[i], null, bad[i], badAvgs[i]);
            }
        }

        var otherDates = [];			// Dates for the green and orange regions at the bottom of the graph
        var mins = [];					// Mins for the green and orange regions
        var outside = [];				// True when 1 or more pumps is outside or POR
        var green = [];					// Dates for the green region
        var fOldOutside, fOldNull;
        let tinyFlow = convert(1, TagUnit.TU_GPM, this.flows[0].node.units);
        for (var time = start * 1000; time < end * 1000; time += interval * 1000) {	// For each point in the interval requested
            dates.push(time);			// Add the date for our point
            var total = 0;				// Flow total starts at 0
            var fAnyOutside = false;
            var fNull = false;
            for (var i = 0; i < this.flows.length; ++i) {	// For each pump
                var flow = this.getValueAtTime(time, 4 * i, flowPoints, i);					// Find this pump's flow at the time
                var fOutside = this.getValueAtTime(time, 4 * i + 2, outsidePoints, i) > 0.05;	// Find this pump's outside POR most of the time
                let fOff= flow < tinyFlow && !fOutside;
                avgs[i].push(fOff ? null : total);	// Add the average as whatever the last line ended as

                if (flow === null)	// If flow is NULL, don't add the flow in
                    fNull = true;	// But set the null flag
                else				// Flow is valid
                    total += flow;	// Accrue total flow

                if (fWasOutside[i] != fOutside) {	// If a pump just started being outisde, add two points so everything is crisp
                    badDates[i].push(dates.back(), dates.back());
                    badAvgs[i].push(avgs[i].back(), avgs[i].back());
                    bad[i].push(fWasOutside[i] ? total : null, fOutside ? total : null);
                    fWasOutside[i] = fOutside;
                } else if (fOutside) {				// If we are still outside, add a single point
                    badDates[i].push(dates.back());
                    badAvgs[i].push(avgs[i].back());
                    bad[i].push(total);
                }
                fAnyOutside = fAnyOutside || fOutside;	// Make a note if ANY pump is currently outside POR
                maxes[i].push(fOff ? null : total);	// The maximum is the current total
            }

            if (fOldOutside !== fAnyOutside || fOldNull !== fNull) {	// If we just started or stopped a pump being outside POR, start or stop orange region
                otherDates.push(dates.back(), dates.back());
                mins.push(this.negativeValue, this.negativeValue);
                outside.push(fOldOutside ? 0 : null, fAnyOutside ? 0 : null);
                green.push(fOldNull ? null : 0, fNull ? null : 0);
                fOldOutside = fAnyOutside;
                fOldNull = fNull;
            }
        }
        for (var i = 0; i < this.flows.length; ++i) {	// For each pump
            badDates[i].push(dates.back());
            badAvgs[i].push(avgs[i].back());
            bad[i].push(fWasOutside[i] ? total : null);
        }

        otherDates.push(dates.back());
        mins.push(this.negativeValue);
        outside.push(fOldOutside ? 0 : null);
        green.push(fOldNull ? null : 0);

        data[0].push('greenThing', 'outsidePOR');	// Add two lines that will show if ALL pumps are inside POR
        data.push(otherDates, mins, green, null);	// Add our two regions at the end of the graph
        data.push(otherDates, mins, outside, null);
    }
};
