import {Node, NodeSubscriber}   from './node';
import {Widget}                 from './widget';
import {createElement}          from './elements';
import {RadioButtons}           from './radiobuttons';
import { StaticGraph }          from './graph';
import createSVGElement         from './svgelements';
import ColoredHeader            from './coloredheader';
import createWidget             from './createwidget'
import assert                   from './debug';
import NodeManager              from './nodemanager';
import owner                    from '../owner';
import './pump.css';
import { ConfiguredAlarm, Alarm } from './alarm';
import { PumpTwinResponder } from './device';
import { PumpCurves, PumpTwin, PumpTwins } from './curves';
import { PumpBank } from './pumpbank';
import { TagUnit, TagUnitQuantity, convert } from './widgets/lib/tagunits';
import { TagQuality } from './widgets/lib/tag';
import { Role } from './role';
import { CreateWidget } from './widgets/lib/createwidget';
import { getHash, getRouteAndProperties } from './router/router';
import { TagRadioButton } from './widgets/input/radio/radiobutton';

export class Pump implements NodeSubscriber {
    node: Node;
    modelPump: Node | undefined;
    parent: HTMLElement;
    pumpNode: Node;
    color: string;
    index: number;
    pumpPanel: HTMLElement;
    running: Node;
    curveDiv: HTMLElement;
    settingsDiv: HTMLElement;
    maxHoas: number;
    hoas: Node[];
    maxSpeeds: number;
    maxFaults: number;
    pumpCurve: PumpCurveWidget;
    hoaDiv: HTMLElement;
    speedDiv: HTMLElement;
    faultDiv: HTMLElement;
    mpDiv: HTMLElement;
    pumpBank: PumpBank | null;
    toggleCallback: ()=>void;
    name: string | undefined;
    pumpHeader: HTMLElement;
    constructor(pumpNode: Node, parent: HTMLElement, color: string, index: number, modelPump?: Node, pumpBank?: PumpBank, name?: string) {
        this.node			= pumpNode;		// Pump folder node
        this.modelPump		= modelPump;
        this.parent			= parent;		// Pump Station parent DOM element
        this.pumpNode       = pumpNode;
        this.color          = color;
        this.index          = index;
        this.pumpBank       = pumpBank ?? null;
        this.pumpPanel		= createElement('div', 'pumppanel', parent);	// The main division for this panel
        this.name           = name;
        if (this.name)
            createElement('div', 'pumppanel__name', this.pumpPanel, this.name);
        this.initialize();
    };

    initialize() {
        // Now create all the gooey guts:
        this.running		= this.pumpNode.findChildByRole(Role.ROLE_BOOL_RUNNING)!;
        var runTimeNode		= this.pumpNode.findChildByRole(Role.ROLE_PUMP_CURRENT_RUN_TIME);
        var actSpeedNode	= this.pumpNode.findChildByRole(Role.ROLE_ACT_SPEED);
        var healthNode		= this.pumpNode.findChildByRole(Role.ROLE_PUMP_HEALTH_METRIC);
        var faultNode		= this.pumpNode.findChildByRole(Role.ROLE_BOOL_FAULTED);
        var startFail		= this.pumpNode.findChild('StartFail');
        var stopFail		= this.pumpNode.findChild('StopFail');
        var sealFail        = this.pumpNode.findChild('SealFail');
        var overtemp        = this.pumpNode.findChild('Overtemp');
        var mpLockout       = this.pumpNode.findChild('EnableMotorProtectionLockout');
        var useAOR			= this.pumpNode.findChild('UseAOR');
        var outsidePor		= this.modelPump ? this.modelPump.findChild('OutsidePOR') : null;
        var outsideAor		= this.modelPump ? this.modelPump.findChild('OutsideAOR') : null;

        var lockoutEnabled = false;
        if(mpLockout)
            lockoutEnabled = mpLockout.getValue();

        // This creates the first section of the panel, which contains the pump name.
        let titleDiv		= createElement('div', 'pump-title-bar', this.pumpPanel, '', 'coloredHeader');

        // Create the colored header, which will be blue if the pump is running, then red if faulted, then gray
        // if neither. We will only add the nodes we find. Also create and pass in class name for each case
        var nodeArray: Node[] = [], classArray: string[] = [], vetos: (Node | null)[] = [], vetoClasses: (string| null)[] = [];
        if(outsideAor) {
            nodeArray.push(outsideAor);
            classArray.push('outsideAOR');
            vetos.push(null);
            vetoClasses.push(null);
        }
        if(outsidePor) {
            nodeArray.push(outsidePor);
            classArray.push('outsidePOR');
            vetos.push(useAOR);
            vetoClasses.push(classArray[0]);
        }
        if (faultNode) {					// Found a faulted node
            nodeArray.push(faultNode);		// Add the node to the array
            classArray.push('faulted');		// Add a class name for the faulted status
            vetos.push(null);
            vetoClasses.push(null);
        }
        if (startFail) {					// Found a faulted node
            nodeArray.push(startFail);		// Add the node to the array
            classArray.push('faulted');		// Add a class name for the faulted status
            vetos.push(null);
            vetoClasses.push(null);
        }
        if (stopFail) {						// Found a faulted node
            nodeArray.push(stopFail);		// Add the node to the array
            classArray.push('faulted');		// Add a class name for the faulted status
            vetos.push(null);
            vetoClasses.push(null);
        }
        // if (sealFail && lockoutEnabled) {   // Found a seal fail node, and lockouts are enabled
        //     nodeArray.push(sealFail);       // Add the node to the array
        //     classArray.push('faulted');     // Add a class name for the faulted status
        // }
        // if (overtemp && lockoutEnabled) {   // Found an overtemp node, and lockouts are enabled
        //     nodeArray.push(overtemp);       // Add the node to the array
        //     classArray.push('faulted');     // Add a class name for the faulted status
        // }
        assert(this.running, "If we don't have a running node, why is this a pump?");	// We should have this
        nodeArray.push(this.running);			// Add the node to the array
        classArray.push('running');			// Add a class name for the running status
        vetos.push(null);
        vetoClasses.push(null);

        this.pumpHeader = createElement('div', 'pump__header', titleDiv);
        var pumpTitleRow = createElement('div', 'pump__header__title-row', this.pumpHeader)

        new ColoredHeader(titleDiv, {nodes: nodeArray, trueClass: classArray, vetos: vetos, vetoClasses: vetoClasses});	// Actually create the colored header

        // Add the wrapper for the non-bargraph portion of the title bar:

        // Create a little SVG triangle to show the color this pump is on the graph
        if (this.color) {	// They had to give us a color to display. Else, just skip this guy
            var svg = createSVGElement('svg', 'pump-triangle-svg', pumpTitleRow);	// The SVG wrapper element
            createSVGElement('path', 'pump-triangle-fill', svg, {
                d:		'M 0 0 L 0 20 L 20 0 Z',	// Move to 0,0. Line to 0,20. Line to 20,0. End path.
                fill:	'var(--color-surfaceContainerLowest)'						// Make the triangle whatever color they gave us
            });
            createSVGElement('path', 'pump-triangle-fill', svg, {
                d:		'M 0 0 L 0 20 L 20 0 Z',	// Move to 0,0. Line to 0,20. Line to 20,0. End path.
                fill:	this.color						// Make the triangle whatever color they gave us
            });
            if (this.modelPump){
                svg.addEventListener('click', (e) => {
                    let [routeName, props] = getRouteAndProperties(location.href); // Get the current route and properties
                    location.href   = getHash(routeName, {...props, 'tab':'Pumps','index':this.index.toString()}); // Change the tab and index properties
                    e.stopPropagation();
                });
            }
        }
        // flow display in the pump panel
        let pumpDataRow = createElement('div', 'pump__header__data-row', this.pumpHeader);

        if(this.modelPump) {
            createElement('div', 'pump__header__title-row__title', pumpTitleRow, this.pumpNode.getDisplayName());
            let flowNode = this.modelPump.findChildByRole(Role.ROLE_MODEL_PUMP_FLOW);
            var flowWrapper = createElement('div', 'pump__data-row__data-wrapper', pumpDataRow);
            createElement('numeric-gauge', 'pump__header__value', flowWrapper, '', {valueTag: {tag: flowNode!}, showUnits: true});
            //new ValueDisplay(createElement('div', 'pump__header__value', flowWrapper), {node: flowNode, fShowUnits: true}).initialize();	// Show individual pump flow
        }
        else
            createElement('div', 'pump__header__title-row__title', pumpTitleRow, this.pumpNode.getDisplayName());	// Add the pump name to the title division

        titleDiv.addEventListener('click', () => {
            let [routeName, props] = getRouteAndProperties(location.href); // Get the current route and properties
            location.href   = getHash(routeName, {...props, 'tab':'Pumps','index':this.index.toString()}); // Change the tab and index properties
        });

        if (runTimeNode) {
            let runTimeWrapper = createElement('div', 'pump__data-row__data-wrapper', pumpDataRow);
            createElement('numeric-gauge', 'pump__header__value', runTimeWrapper, '', {valueTag: {tag: runTimeNode!}, showUnits: true});
        }

        if (actSpeedNode) {	// Create an indicator in the title division for the actual speed node if the node exists
            var actSpeedWrapper = createElement('div', 'pump__data-row__data-wrapper', pumpDataRow);
            createElement('numeric-gauge', 'pump__header__value', actSpeedWrapper, '', {valueTag: {tag: actSpeedNode!}, showUnits: true, valueOverride: {
                0: 'OFF'
            }});
        }

        createElement('pre', 'pump__data-row__auto-wrapper', pumpTitleRow);
        var autoWrapper = createElement('div', 'pump__data-row__auto-wrapper', pumpTitleRow);
        createElement('pump-auto', 'pump__header__value', autoWrapper, '', {pumpTag: {tag: this.pumpNode!}});

        //new PumpAutoWidget(createElement('div', 'pump__header__value', autoWrapper), this.pumpNode);

        // Create a wrapper for all the detailed information below the speed graph, like HOA data
        this.curveDiv	    = createElement('div', 'pumppanelCurve'/* + (this.running.getValue() ?'':' zeroHeight')*/, this.pumpPanel);
        this.settingsDiv    = createElement('div', 'pump__settings', this.pumpPanel);
        this.hoaDiv          = createElement('div', 'pump__settings__sub', this.settingsDiv);
        this.maxHoas        = 0;
        this._getHOAs();
        if (this.hoas.length > 0) {	// Only create the speed control section if we found hoas of some sort
            createElement('span', 'pump__settings__title', this.hoaDiv, 'On/Off Control');	    // Add in a span with a label
            let hoaWrapper = createElement('div', 'pump__settings__hoas', this.hoaDiv);	// A division to wrap our HOAs

            for (var i = this.hoas.length - 1; i >=0; --i) {// Reverse iterate so the controls with precedence are on bottom
                var hoa = this._createLabeledControl(hoaWrapper, this.hoas[i].name, 'pumpspeed-control');
                createElement('tag-radio-button', '', hoa, '', {valueTag: {tag: this.hoas[i]}, equalButtonWidths: true});
                this.maxHoas++;
            }
        }

        // -- Pump Auto Speed Switches and Reference Speed under 'Speed Control' label --
        // Create all the speed control stuff, but only if the controls exist
        this.maxSpeeds      = 0;
        let autoSwitchNode = this.pumpNode.findChildByRole(Role.ROLE_AUTO_SPEED_SWITCH);
        this.speedDiv         = createElement('div', 'pump__settings__sub', this.settingsDiv)
        if (autoSwitchNode) {
            createElement('span', 'pump__settings__title', this.speedDiv, 'Speed Control');				// Add in a span with a label
            var speedWrapper = createElement('div', 'pump__settings__hoas', this.speedDiv);	// Wrapper for the speed elements
            // Attach a control for the auto speed switch
            this._createLabeledControl(speedWrapper, 'Auto Speed Switch').appendChild(CreateWidget(autoSwitchNode));
            //createWidget(this._createLabeledControl(speedWrapper, 'Auto Speed Switch'), autoSwitchNode, {fPending:true});
            this.maxSpeeds++;
            // Check for the presence of an auto speed switch stack
            var autoSpeedStack = this.pumpNode.findChildByRole(Role.ROLE_PUMP_EXTERNAL_ASE_SWITCHES);
            if (autoSpeedStack) {	// If we found the folder
                for (var i = 0; i < autoSpeedStack.children.length; ++i) {	// Create a toggle for each of it's children
                    var node = autoSpeedStack.children[i];					// The boolean feedback node
                    createElement('digital-gauge', '', this._createLabeledControl(speedWrapper, node.name), '', {valueTag: {tag: node}, valueOverride: {
                        '0': 'Manual',
                        '1': 'Auto'
                    }});
                    this.maxSpeeds++;
                }
            }

            // If there's a auto speed switch, there should be a manual speed
            let widget = CreateWidget(this.node.findChildByRole(Role.ROLE_MAN_SPEED)!);
            //@ts-ignore
            widget.showUnits = true;
            this._createLabeledControl(speedWrapper, 'Reference Speed', 'manualspeed-control').appendChild(widget);
            this.maxSpeeds++
        }

        // Pump fault reset(s)
        this.faultDiv           = createElement('div', 'pump__settings__sub', this.settingsDiv);
        this.maxFaults          = 0;
        if(startFail && stopFail) {
            createElement('span', 'pump__settings__title', this.faultDiv, 'Fault Reset');
            var resetsWrapper = createElement('div', 'pump__settings__hoas', this.faultDiv);
            createWidget(this._createLabeledControl(resetsWrapper, 'Start Fail'), startFail, {fPushButton: true, fPending: true});
            this.maxFaults++;
            createWidget(this._createLabeledControl(resetsWrapper, 'Stop Fail'), stopFail, {fPushButton: true, fPending: true});
            this.maxFaults++;
            var faultReset		= this.pumpNode.findChildByRole(Role.ROLE_RESET_FAULT);
            if (faultReset) {
           		createWidget(this._createLabeledControl(resetsWrapper, 'Reset Fault'), faultReset, {fPushButton: true, fPending: true});
           	 	this.maxFaults++;
            }
        }
        this.mpDiv           = createElement('div', 'pump__settings__sub', this.settingsDiv);
        if(sealFail && overtemp) {
            createElement('span', 'pump__settings__title', this.mpDiv, 'Motor Protection');
            var motorProtectionWrapper = createElement('div', 'pump__settings__hoas', this.mpDiv);
            createWidget(this._createLabeledControl(motorProtectionWrapper, 'Seal Fail'), sealFail, {fPushButton: false, fPending: true});
            createWidget(this._createLabeledControl(motorProtectionWrapper, 'Overtemp'), overtemp, {fPushButton: false, fPending: true});

        }

        this.settingsDiv.classList.add('hide')

        if (this.modelPump)
            this.pumpCurve = new PumpCurveWidget(this.curveDiv, this.pumpNode, this.modelPump, this);
        else {
            let dummyContainer = createElement('div', 'pump__dummy__container', this.curveDiv);
            createElement('div', '', dummyContainer, 'No Pump Curve Data')
        }
        this.running.subscribe(this);
    }

    _getHOAs() {
        // Build up an internal array of HOAs. They will be arranged in order of control. This means that
		// the first member of the array takes precedence over all the others.
		assert(!this.hoas, "Why would you call _getHOAs twice?");
		this.hoas = [];	// Start off with an empty array

		// In this external HOA folder, the order of children is important. First child has precedence.
		let hoaFolder = this.node.findChildByRole(Role.ROLE_PUMP_EXTERNAL_HOAS);	// Find the external HOA node folder
		if (hoaFolder) {	// This folder doesn't have to exist, but if it does, it will have an external HOA in it
			assert(Node.isFolder(hoaFolder), "External HOA found was not a folder!");
			assert(hoaFolder.children, "External HOA folder didn't have any children!");
			for (var i = 0; i < hoaFolder.children.length; ++i)
				this.hoas.push(hoaFolder.children[i]);	// Add on an HOA control
		}

		// Check for a local, software HOA, which is submissive to all the other HOA controls.
		var softwareHOA = this.node.findChildByRole(Role.ROLE_PUMP_SOFTWARE_HOA);	// Find our locally created HOA
		if (softwareHOA)	// If we have a software HOA (meaning we control start/stop of the pump)
			this.hoas.push(softwareHOA);	// Add on an HOA control
    }

    showSettings() {
        this.curveDiv.classList.add('hide');
        this.settingsDiv.classList.remove('hide');
    }

    showCurves() {
        this.settingsDiv.classList.add('hide');
        this.curveDiv.classList.remove('hide');
    }

    showNone() {
        this.settingsDiv.classList.add('hide');
        this.curveDiv.classList.add('hide');
    }

    update(node: Node) {
        switch (node) {
            case this.running:
                if (this.running.getValue()) {}
                    //this.pumpDataRow.classList.remove('hide')
                else {}
                    //this.pumpDataRow.classList.add('hide')
            default: break;
        }
    }
    onNodeChanged(node: Node) {
        this.pumpPanel.destroyWidgets(true);
        this.pumpPanel.removeChildren();
        this.initialize();
    }

	onNodeRemoved: (node: Node) => void;
	onAlarm: (node: Node, alarm: Alarm, fAdded: boolean, fChanged: boolean, fDeleted: boolean) => void;
	onConfiguredAlarm: (node: Node, configuredAlarm: ConfiguredAlarm, fAdded: boolean) => void;

	_createLabeledControl(parentDiv: HTMLElement, label: string, className?: string): HTMLElement {
		//var wrapper = createElement('div', 'pump__settings__wrapper', parentDiv);	// Create a wrapper for the label and indicator
		createElement('span', 'pump__settings__label', parentDiv, label);			// Create a label for the indicator
		//createElement('div', 'spacer', wrapper);				// Create a spacer
		return createElement('div', `${className ?? ''} pump__settings__value flex__row justify__end`, parentDiv);		// Create a div they can enliven
	}

	toggleCollapse() {
        if (this.curveDiv.classList.contains('zeroHeight') && this.modelPump) {	// All is hidden
            this.curveDiv.classList.remove('zeroHeight');			// Show pump curve
            this.toggleCallback = () => this.resize();
            this.curveDiv.addEventListener('transitionend', this.toggleCallback, {once:true});
        }
		else {														// Pump curve is showing
			this.curveDiv.classList.add('zeroHeight');				// Hide pump curve
        }
    }

    resize(fSmall: boolean = false) {
        this.curveDiv.removeEventListener('transitionend', this.toggleCallback);
        if (!fSmall) {
            let scale = Math.min(1,Math.max(0.5, this.parent.clientWidth / 280));
            this.pumpPanel.style.transform = 'scale(' + scale + ')';
            this.parent.style.height = (this.pumpPanel.clientHeight * scale) + 36 +  'px';
        }
        else {
            this.pumpPanel.style.transform = 'scale(1)';
            this.parent.style.height = this.pumpPanel.clientHeight + 'px';
        }
        if (this.pumpCurve && this.pumpCurve.fInitialized)             // Not all pumps have pump curves due to missing discharge pressure sensors, etc.
            this.pumpCurve.resize();
    }
};

export class PumpCurveWidget extends Widget implements PumpTwinResponder {
    parent: HTMLElement;
    element: HTMLElement;
    width: number;
    height: number;
    fResize: boolean = true;
    parentPump: Pump;
    fInitialized: boolean = false;
    flowLabel: HTMLElement;
    headLabel: HTMLElement;
    effLabel: HTMLElement;
    pump: Node;
    nodeManager: NodeManager;
    flow: Node;
    head: Node;
    power: Node;
    minBep: Node;
    maxBep: Node;
    minAor: Node;
    maxAor: Node;
    fUseAOR: Node;
    running: Node;
    actSpeed: Node;
    minSpeed: Node;
    maxSpeed: Node;
    healthInput: Node;
    max: number;
    flowConversion: number;
    headConversion: number;
    factor: number;
    badFlows: number[]  = [];
    badHeads: number[]  = [];
    badMaxes: number[]  = [];	// Outside AOR shaded region
    aorFlows: number[]  = [];
    aorHeads: number[]  = [];
    aorMaxes: number[]  = [];	// AOR shaded region
    porFlows: number[]  = [];
    porHeads: number[]  = [];
    porMaxes: number[]  = [];	// POR shaded region
    bepFlows: number[]  = [];
    bepHeads: number[]  = [];						    // Line over BEP
    fsFlows: number[]   = [];
    fsHeads: number[]   = [];
    fsPowers: number[]  = [];	// Full speed flow head and power. Used to compute actual speed stuff
    asFlows: number[]   = [];
    asHeads: number[]   = [];
    asEffs: number[]    = [];   // Actual speed flow, head, and efficiency lines
    cFlow: number[]     = [NaN];
    cHead: number[]     = [NaN];
    cEff: number[]      = [NaN];// Current flow and efficieny points
    headLineFlow: number[]	= [0, 0];
    headLineHead: number[]  = [0, 0];	    // Horizontal, dashed head line
    effLineFlow: number[]	= [0, 0];
    effLineHead: number[]	= [0, 0];	    // Horizontal, dashed efficiency line
    verFlows: number[]		= [0, 0];
    verHeads: number[]		= [0, 100];	    // Vertical, dashed flow line
    data: ((number | string)[] | null)[];
    options: any;
    curves: PumpCurves;
    twin: PumpTwin;
    graph: StaticGraph;
    isAboutToRedraw: boolean = false;
    constructor(parent: HTMLElement, pump: Node, modelPump: Node, parentPump: Pump) {
        super();
        this.parent         = parent;
        this.element		= createElement('div', 'pumpPanelGraph', parent);	// Create a division for the graph
        this.width          = this.parent.clientWidth;
        this.height         = this.parent.clientHeight;
        this.fResize        = true;
        this.parentPump     = parentPump;
        this.fInitialized   = false;

        this.flowLabel		= createElement('div', 'pumpFlow', parent);	// Make a label for flow over the graph
        this.headLabel		= createElement('div', 'pumpHead', parent);	// Make a label for head over the graph
        this.effLabel		= createElement('div', 'pumpEff', parent);	// Make a label for efficiency over the graph
        //this.bepLabel		= createElement('div', 'pumpBEP', parent);	// Make a label for percent BEP flow over the graph

        this.pump			= pump;			// Save the reference to the pump folder node
        this.registerAsWidget(parent);		// Register with the element like a good little widget

        this.nodeManager	= new NodeManager(this);
        this.flow			= this.nodeManager.addNodeByRole(modelPump, Role.ROLE_MODEL_PUMP_FLOW);
        this.head			= this.nodeManager.addNodeByRole(modelPump, Role.ROLE_MODEL_PUMP_HEAD);
        this.power			= this.nodeManager.addNodeByRole(pump, Role.ROLE_SHAFT_POWER);
        this.minBep			= this.nodeManager.addNodeByRole(pump, Role.ROLE_PUMP_MIN_BEP_RATIO);
        this.maxBep			= this.nodeManager.addNodeByRole(pump, Role.ROLE_PUMP_MAX_BEP_RATIO);
        this.minAor			= this.nodeManager.addNodeByRole(pump, Role.ROLE_PUMP_MIN_AOR);
        this.maxAor			= this.nodeManager.addNodeByRole(pump, Role.ROLE_PUMP_MAX_AOR);
        this.fUseAOR		= this.nodeManager.addNodeByName(pump, "UseAOR");
        this.running		= this.nodeManager.addNodeByRole(pump, Role.ROLE_BOOL_RUNNING);
        this.actSpeed		= this.nodeManager.addNodeByRole(pump, Role.ROLE_ACT_SPEED);			// Might not exist. We'll check later
        this.minSpeed		= this.nodeManager.addNodeByRole(pump, Role.ROLE_MIN_SPEED);
        this.maxSpeed		= this.nodeManager.addNodeByRole(pump, Role.ROLE_MAX_SPEED);
        this.healthInput    = this.nodeManager.addNodeByRole(pump, Role.ROLE_PUMP_HEALTH_METRIC_INPUT);
        let maxSpeedNode	= pump.parent.findChildByRole(Role.ROLE_MAX_SPEED_HZ)!;
        let maxSpeedValue 	= maxSpeedNode.getValue();
        this.max		    = convert(maxSpeedValue, maxSpeedNode.units, pump.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) ?? TagUnit.TU_HZ, maxSpeedValue);
        this.flowConversion	= convert(1, TagUnit.TU_GPM, this.flow.units);
        this.headConversion	= convert(1, TagUnit.TU_FEET_HEAD, this.head.units);
        this.factor	        = convert(1.8866337e-2 / this.flowConversion / this.headConversion, TagUnit.TU_KW, this.power.units);
        this.badFlows       = [],       this.badHeads = [],   this.badMaxes = [];	// Outside AOR shaded region
        this.aorFlows       = [],       this.aorHeads = [],   this.aorMaxes = [];	// AOR shaded region
        this.porFlows       = [],       this.porHeads = [],   this.porMaxes = [];	// POR shaded region
        this.bepFlows       = [],       this.bepHeads = [];						    // Line over BEP
        this.fsFlows        = [],       this.fsHeads  = [],   this.fsPowers = [];	// Full speed flow head and power. Used to compute actual speed stuff
        this.asFlows        = [],       this.asHeads  = [],   this.asEffs   = [];   // Actual speed flow, head, and efficiency lines
        this.cFlow          = [NaN],    this.cHead    = [NaN], this.cEff     = [NaN];// Current flow and efficieny points
        this.headLineFlow	= [0, 0],   this.headLineHead		= [0, 0];	    // Horizontal, dashed head line
        this.effLineFlow	= [0, 0],   this.effLineHead		= [0, 0];	    // Horizontal, dashed efficiency line
        this.verFlows		= [0, 0],   this.verHeads			= [0, 100];	    // Vertical, dashed flow line

        this.data = [['notPOR', 'AOR', 'POR', 'BEP', 'HeadLine', 'EffLine', 'Vert', 'Head', 'Eff', 'CurrentHead', 'CurrentEff'],
                    this.badFlows,		null,	this.badHeads,		this.badMaxes,
                    this.aorFlows,		null,	this.aorHeads,		this.aorMaxes,
                    this.porFlows,		null,	this.porHeads,		this.porMaxes,
                    this.bepFlows,		null,	this.bepHeads,		null,
                    this.headLineFlow,	null,	this.headLineHead,	null,
                    this.effLineFlow,	null,	this.effLineHead,	null,
                    this.verFlows,		null,	this.verHeads,		null,
                    this.asFlows,		null,	this.asHeads,		null,
                    this.asFlows,		null,	this.asEffs,		null,
                    this.cFlow,		    null,	this.cHead,			null,
                    this.cFlow,		    null,	this.cEff,			null];

        var dottedOptions = {strokeWidth: 1, color: 'red'};	// Thinner line, dashed, and round to nearest half pixel for clarity
        this.options = {								// Dygraph options for all pump curve graphs
            stophighlighting:		true,				// No highlighting
            colors:					['#EF5350', 'gold', '#2E7D32', '#2E7D32', '#555', '#555', '#555', 'dodgerblue', 'red', 'dodgerblue', 'red'],	// Colors
            customAxis: 			[false, false, false, false, false, true, true, false, true, false, true],	// Plot all the efficiencies on the right axis
            pointSize:				0,					// How big to make the points
            xAxisAsNumber: 			true, 				// Interpret the x-axis as numbers, not time stamps
            strokeWidth:			1.5,				// How wide to make the lines
            secondAxisRange:		[0, 100],			// Efficiencies are scaled 0-100
            drawXAxis:				false,				// Don't draw any axes liknes
            drawYAxis:				false,
            drawYGrid:              false,
            drawXGrid:              false,
            rightGap:				0,					// No gap to the right of the graph
            fillAlpha:              0.4,
            HeadLine:				dottedOptions,		// Show these lines as dashed
            EffLine:				dottedOptions,
            Vert:					dottedOptions,
            POR: 					{connectSeparatedPoints: false},	// Show these fills without a line
            AOR:                    {connectSeparatedPoints: false},    // Show these fills without a line
            notPOR: 				{connectSeparatedPoints: false},	// Show these fills without a line
            CurrentHead:			{drawPoints: true, pointSize: 4},	// Show the dot for current head
            CurrentEff:				{drawPoints: true, pointSize: 4}    // Show the dot for current efficiency
        };

        this.pump.tree.device.requestPumpTwins(this);
    };

    onPumpTwinsComplete(pumpTwins: PumpTwins) {
        this.refreshCurves(pumpTwins);
        if (!this.fInitialized) {
            this.fInitialized = true;
            this.nodeManager.subscribe();						// Subscribe to all of our nodes
        }
        else
            this.redraw();
    }

    refreshCurves(pumpTwins: PumpTwins) {
        this.twin   = pumpTwins.getTwin(this.pump)!;
        this.curves = this.twin.latestCurves;
        let maxShutoffHead = this.parentPump.pumpBank !== null ? this.parentPump.pumpBank.maxShutoffHead : this.twin.shutoffHead;
        let maxZeroHeadFlow = this.parentPump.pumpBank !== null ? this.parentPump.pumpBank.maxZeroHeadFlow : this.twin.zeroHeadFlow;
        this.options.valueRange = [0, maxShutoffHead];
        this.options.dateWindow = [0, maxZeroHeadFlow];

        let headPoly: number[] = [];
        let powerPoly: number[] = [];
        if (this.healthInput) {
            let WF = this.healthInput.getValue() / 100;
		    for (let i = 0; i < this.curves.headPolynomial.length; ++i)
		    	headPoly.push(this.curves.headPolynomial[i] * Math.pow(WF, 2 - i));
		    headPoly[1] -= (1 - WF) * headPoly[0] / maxZeroHeadFlow;
		    for (let i = 0; i < this.curves.powerPolynomial.length; ++i)
		    	powerPoly.push(this.curves.powerPolynomial[i] * Math.pow(WF, 2 - i));
        }
        else {
            headPoly = this.curves.headPolynomial;
            powerPoly = this.curves.powerPolynomial;
        }

        let bepFlow = findBestEfficiencyFlow(this.twin.zeroHeadFlow, headPoly, powerPoly);  // Calculate the bep flow

        if (this.fInitialized)
            this.fsFlows.length = this.fsHeads.length = this.fsPowers.length = 0;
        for (var flow = 0; flow <= this.twin.zeroHeadFlow*1.01; flow += this.twin.zeroHeadFlow / 100) {	// Compute the full speed curves
            this.fsFlows.push(flow);											    // Save flow
            this.fsHeads.push(headPoly.evaluatePolynomial(flow));		// Calculate the head at this flow
            this.fsPowers.push(powerPoly.evaluatePolynomial(flow));		// Calculate the power at this flow
        }
        this.effLineFlow[1] = maxZeroHeadFlow;

        this.badFlows.length = this.badHeads.length = this.badMaxes.length = 0;	// Clear out any previously calculated regions
        this.aorFlows.length = this.aorHeads.length = this.aorMaxes.length = 0;
        this.porFlows.length = this.porHeads.length = this.porMaxes.length = 0;
        this.bepFlows.length = this.bepHeads.length = 0;

        var minS = this.minSpeed ? Math.min(this.minSpeed.getValue() / this.max, 0.975) : 0.975;	// Min speed as a ratio
        var maxS = this.maxSpeed ? this.maxSpeed.getValue() / this.max : 1.0;						// Max speed as a ratio
        var minP = this.minBep.convertValue(TagUnit.TU_RATIO);
        var maxP = this.maxBep.convertValue(TagUnit.TU_RATIO);
        var fAor = this.fUseAOR && this.fUseAOR.getValue();
        var minA = fAor ? Math.min(this.minAor.convertValue(TagUnit.TU_RATIO), minP) : minP;
        var maxA = fAor ? Math.max(this.maxAor.convertValue(TagUnit.TU_RATIO), maxP) : maxP;

        var bepHead = headPoly.evaluatePolynomial(bepFlow);	// Calculate BEP line
        for (var speed = minS; speed <= maxS; speed += 0.01) {
            this.bepFlows.push(speed*bepFlow);
            this.bepHeads.push(speed*speed*bepHead);
        }

        findOperationRanges(this.porFlows, this.porHeads, this.porMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, minP, maxP, minS, maxS);
        findOperationRanges(this.aorFlows, this.aorHeads, this.aorMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, minA, minP, minS, maxS);
        findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, 0, minA, minS, maxS);
        this.badFlows.push(NaN);	// These are put in each array so the two outside POR sections aren't connected
        this.badHeads.push(NaN);
        this.badMaxes.push(NaN);
        this.aorFlows.push(NaN);
        this.aorHeads.push(NaN);
        this.aorMaxes.push(NaN);
        findOperationRanges(this.aorFlows, this.aorHeads, this.aorMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, maxP, maxA, minS, maxS);
        findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, headPoly, powerPoly, this.twin.zeroHeadFlow, maxA, this.twin.zeroHeadFlow / bepFlow, minS, maxS);
    }

    update(node: Node) {
        if (node.quality != TagQuality.TQ_GOOD && (node != this.flow && node != this.head && node != this.power)) {   // Bad quality on this node. Let model nodes fix themselves
            return;
        }
        if (this.fsFlows.length == 0) {			// If we don't have the full speed line yet
            // console.log('pump curve widget, no full speed line')
            return;								// Bail out
        }

        switch (node) {	// Switch on node that is updating
            case this.flow:
                this.cFlow[0] = this.flow.getValue();
                this.effLineHead[0] = this.effLineHead[1] = this.cEff[0];
                this.verFlows[0] = this.verFlows[1] = this.headLineFlow[1] = this.effLineFlow[0] = this.cFlow[0];
                this.flowLabel.textContent = this.flow.getFormattedText(true);
                //this.bepLabel.textContent = (100*this.cFlow[0] * factor / this.pump.pumpCurve.bepFlow).toFixed(1)+'% BEP';
            break;

            case this.head:
                this.cHead[0] = this.head.getValue();
                this.headLineHead[0] = this.headLineHead[1] = this.cHead[0];
                this.headLabel.textContent = this.head.getFormattedText(true);
            break;

            case this.power:
                this.cEff[0] = this.flow.getValue() * this.head.getValue() / this.power.getValue() * this.factor;
            break;

            case this.actSpeed:
            case this.running:
                var flowFactor	= this.actSpeed ? this.actSpeed.getValue() / this.max : (this.running.getValue() ? 1 : 0);	// Calculate the affinity law ratios once
                var headFactor	= flowFactor * flowFactor;
                var powerFactor	= headFactor * flowFactor;

                for (var i = 0; i < this.fsFlows.length; ++i){		// Recompute the points based on the speed factors
                    this.asFlows[i]	= flowFactor * this.fsFlows[i];	// Scale full speed flow by affinity laws
                    this.asHeads[i]	= headFactor * this.fsHeads[i];	// Scale full speed head by affinity laws
                    var power	= powerFactor * this.fsPowers[i];	// Scale full speed power by affinity laws
                    if (this.asFlows[i] > 0 && this.asHeads[i] > 0 && power > 0)	// If we are in a good place, calculate the efficiency
                        this.asEffs[i]	= this.asFlows[i] * this.asHeads[i] / convert(power, TagUnit.TU_KW, this.power.units ) * this.factor;
                    else										// Not in a good place
                        this.asEffs[i]	= 0;						// Clamp efficiency to 0
                }
            break;

            case this.minSpeed:	// I expect these nodes to change very, very rarely. Hence last in the switch
            case this.maxSpeed:
            case this.minBep:
            case this.maxBep:
            case this.minAor:
            case this.maxAor:
            case this.fUseAOR:
            case this.healthInput:
                this.pump.tree.device.requestPumpTwins(this);
                break;
        }
        this.redraw();
    };

    onNodeChanged() {
        this.fResize = true;
        this.flowConversion	= convert(1, TagUnit.TU_GPM, this.flow.units);
        this.headConversion	= convert(1, TagUnit.TU_FEET_HEAD, this.head.units);
        this.factor	        = 1.8866337e-2 / this.flowConversion / this.headConversion;
        this.pump.tree.device.requestPumpTwins(this);
    }

    resize() {
        if (this.parent.clientHeight == 0) return;
        this.fResize = true;
    }

    redraw() {
        if (this.isAboutToRedraw)
            return;
        this.isAboutToRedraw = true;
        queueMicrotask(() => {
            this.isAboutToRedraw = false;
            if (this.fResize || this.width == 0 || this.height == 0) {
                this.width          = this.parent.clientWidth;
                this.height         = this.parent.clientHeight;
                this.fResize        = false;
            }
            if (this.graph)				// If we've drawn a graph before
                this.graph.destroy();	// Delete it first

            this.cEff[0] = this.flow.getValue() * this.head.getValue() / this.power.getValue() * this.factor;
            this.graph = new StaticGraph(owner.ldc, this.element, this.width, this.height, this.data, this.options);		// Redraw the graph
            if (this.graph.graph?.plotter_) {
                this.flowLabel.style.left = (this.graph.graph.toDomXCoord(this.cFlow[0])+3) + 'px';		// Update label poistions
                this.headLabel.style.top = (this.graph.graph.toDomYCoord(this.cHead[0])-17) + 'px';
                //this.bepLabel.style.left = this.flowLabel.style.left;
                if (!isNaN(this.cEff[0])) {
                    this.effLabel.textContent = this.cEff[0].toFixed(1) + ' %';
                    this.effLabel.style.top = (this.graph.graph.toDomYCoord(this.cEff[0], 1)-17) + 'px';
                }
            }
        })

    }

    destroy() {
        this.nodeManager.destroy();							// Unsubscribe from all nodes
        this.unregisterAsWidget();							// No longer a widget
    };
};

export function findOperationRanges(porFlows, porHeads, porMaxes, headCurve, powerCurve, zeroHeadFlow, minPOR, maxPOR, minSpeed, maxSpeed) {
	// First, find the limits of our operation ranges
	var bepFlow = findBestEfficiencyFlow(zeroHeadFlow, headCurve, powerCurve);
	var minPORFlow	= minPOR * bepFlow;
	var maxPORFlow	= maxPOR * bepFlow;

	// Calculate all the min speed ranges
	var minSpeedFlowFactor	= Math.max(0.001, minSpeed);
	var minSpeedHeadFactor	= minSpeedFlowFactor * minSpeedFlowFactor;
	var minSpeedLowFlow		= minPORFlow * minSpeedFlowFactor;
	var minSpeedLowHead		= headCurve.evaluatePolynomial(minSpeedLowFlow / minSpeedFlowFactor) * minSpeedHeadFactor;
	var minSpeedHighFlow	= maxPORFlow * minSpeedFlowFactor;
	var minSpeedHighHead	= headCurve.evaluatePolynomial(minSpeedHighFlow / minSpeedFlowFactor) * minSpeedHeadFactor;

	// Calculate the max speed ranges
	var maxSpeedFlowFactor	= maxSpeed;
	var maxSpeedHeadFactor	= maxSpeedFlowFactor * maxSpeedFlowFactor;
	var maxSpeedLowFlow		= minPORFlow * maxSpeedFlowFactor;
	var maxSpeedLowHead		= headCurve.evaluatePolynomial(maxSpeedLowFlow / maxSpeedFlowFactor) * maxSpeedHeadFactor;
	var maxSpeedHighFlow	= maxPORFlow * maxSpeedFlowFactor;
	var maxSpeedHighHead	= headCurve.evaluatePolynomial(maxSpeedHighFlow / maxSpeedFlowFactor) * maxSpeedHeadFactor;

	// Left boundary is the lower BEP edge across speeds -- parabolic curve
	var lowA = (maxSpeedLowHead - minSpeedLowHead) / (maxSpeedLowFlow*maxSpeedLowFlow - minSpeedLowFlow*minSpeedLowFlow);
	var lowB = maxSpeedLowHead - lowA*maxSpeedLowFlow*maxSpeedLowFlow;

	// Right boundary is the higher BEP edge across speeds -- parabolic curve
	var highA = (maxSpeedHighHead - minSpeedHighHead) / (maxSpeedHighFlow*maxSpeedHighFlow - minSpeedHighFlow*minSpeedHighFlow);
	var highB = maxSpeedHighHead - highA*maxSpeedHighFlow*maxSpeedHighFlow;

	// Compute all the points to draw the shape
	var points = Math.ceil((maxSpeedHighFlow - minSpeedLowFlow) / (zeroHeadFlow / 250));
	for (var i = 0; i <= points; ++i) {
		var flow = (maxSpeedHighFlow - minSpeedLowFlow) * i / points + minSpeedLowFlow;
		porFlows.push(flow);

		if (flow < maxSpeedLowFlow) 	// Flow is less than where the full speed POR range starts
			porMaxes.push(lowA * flow * flow + lowB);	// Follow the left boundary instead of the max speed curve
		else
			porMaxes.push(headCurve.evaluatePolynomial(flow / maxSpeedFlowFactor) * maxSpeedHeadFactor);	// On max speed curve

		if (minSpeedHighFlow < flow)	// Flow is higher than where the min speed POR range ends
			porHeads.push(highA * flow * flow + highB);	// Follow the right bounary instead of the min speed curve
		else
			porHeads.push(headCurve.evaluatePolynomial(flow / minSpeedFlowFactor) * minSpeedHeadFactor);	// On min speed curve
	}
};

// Global methods so WhatIf can use it by importing this file
export function findBestEfficiencyFlow(zeroHeadFlow, headCurve, powerCurve) {
	// Golden Segment search to find the maximum efficiency. No units are honored here.
	var resphi = 2 - (1 + Math.sqrt(5)) / 2;
	var minFlow = 0;
	var maxFlow = zeroHeadFlow;
	var midFlow = maxFlow - resphi * (maxFlow - minFlow);

	var midEff = midFlow * headCurve.evaluatePolynomial(midFlow) / powerCurve.evaluatePolynomial(midFlow);
	var newFlow;
	while (maxFlow - minFlow > 0.05) {
		if (maxFlow - midFlow > midFlow - minFlow)
			newFlow = midFlow + resphi * (maxFlow - midFlow);
		else
			newFlow = midFlow - resphi * (midFlow - minFlow);

		var newEff = newFlow * headCurve.evaluatePolynomial(newFlow) / powerCurve.evaluatePolynomial(newFlow);
		if (newEff > midEff) {	// We find a closer point to the maximum
			midEff = newEff;
			if (maxFlow - midFlow > midFlow - minFlow) {
				minFlow = midFlow;
				midFlow = newFlow;
			} else {
				maxFlow = midFlow;
				midFlow = newFlow;
			}
		} else {				// We just found a better edge point
			if (maxFlow - midFlow > midFlow - minFlow)
				maxFlow = newFlow;
			else
				minFlow = newFlow;
		}
	}
	return midFlow;	// Return the flow that produces this efficiency
};