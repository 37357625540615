import Checkbox from "./checkbox";
import Toggle from "./toggle";
import ValueDisplay from "./valuedisplay";
import { RadioButtons } from "./radiobuttons";
import assert from './debug';
import Spinner from './spinner';
import {VType} from './node';
import Logical from './logical';
import PushButton from './pushbutton';
import TextInput from "./components/textinput";
import TextField from "./textfield";
import { Role } from "./role";

// This is the one method that should know how to create a Widget
export default function createWidget(element, node, options) {
	assert(node, 'createWidget needs a node');
	assert(element.parentNode, 'Element needs to be attached to the DOM');

	var opt = {node: node};				// Set the node as the first option
	for (var option in options)			// If they passed in any options
		opt[option] = options[option];	// Set them on the member we are adding

	switch(node.vtype){
		case VType.VT_BOOL:
			if (node.isWriteable) {
				if (options && options.fCheckbox)
					new Checkbox(element, opt).initialize();
				else if (options && options.fPushButton)
					new PushButton(element, opt).initialize();
				else
					new Toggle(element, opt).initialize();
			} else
				new Logical(element, opt).initialize();
		break;

		case VType.VT_STRING:
			if (node.isWriteable)
				new TextField(element, opt).initialize();
			else
				new ValueDisplay (element, opt).initialize();
		break;

		default:	// numeric:
			if(node.roles.has(Role.ROLE_PUMP_AUTO_HOA_SWITCH) || node.roles.has(Role.ROLE_PUMP_SOFTWARE_HOA)|| node.roles.has(Role.ROLE_PUMP_OVERALL_HOA))
				new RadioButtons(element, opt).initialize();	// Create Radio Buttons for HOA's
				//new ValueDisplay (element, opt).initialize();
			else if (node.isWriteable)
				new Spinner(element, opt).initialize();
			else {
				if (opt.fShowUnits === undefined)
					opt.fShowUnits = true;
				new ValueDisplay (element, opt).initialize();
			}
		break;
	}
};
