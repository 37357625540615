import { NodeQuality } from './node';
import NodeManager from './nodemanager';
import { Widget } from './widget';
import assert from './debug';
import { TagUnitQuantity, UnitsMap } from './widgets/lib/tagunits';
import { Role } from './role';

export default class PumpWearCostWidget extends Widget {
    constructor(element, pumpSystem, pumpWearCost) {
        super();
        assert (element, 'Need to get the element we attach to on the DOM!');
        this.registerAsWidget(element);	// Register ourselves as a good little widget
        this.element		= element;

        // Find the three nodes we need to compute the pump wear cost
        this.nodeManager	= new NodeManager(this);
        this.wornPumpCost	= pumpWearCost ? this.nodeManager.addNode(pumpWearCost): this.nodeManager.addNodeByRole(pumpSystem, Role.ROLE_WORN_PUMP_COST);
        this.wornPumpTime	= this.nodeManager.addNodeByRole(pumpSystem, Role.ROLE_WORN_PUMP_SECONDS);
        this.energyCost		= this.nodeManager.addNodeByRole(pumpSystem, Role.ROLE_ENERGY_COST);
        this.currency		= UnitsMap.get((this.energyCost.units - TagUnitQuantity.TUQ_ENERGYCOST) + TagUnitQuantity.TUQ_CURRENCY).abbrev;
        this.nodeManager.subscribe();
    };

    update(node) {
        assert(node == this.wornPumpCost || node === this.wornPumpTime || node === this.energyCost, "Unknown node called us back!");
        if (this.wornPumpCost.quality != NodeQuality.NQ_GOOD) {	// Bad quality on this node
            this.element.textContent = '';
            return;
        }
        // Prorate the energy cost from the seconds actually used to accrue to the full year available
        var kWhLost = this.wornPumpCost.getValue()*365*24*3600/this.wornPumpTime.getValue()*this.energyCost.getValue();
        if (isNaN(kWhLost))	// Clamp NaNs to 0
            kWhLost = 0;
        this.element.classList.toggle('AssetRed', kWhLost < 0);

        // If they haven't set an energy cost, give them the cost in energy per year instead of dollars
        this.element.innerHTML = Math.abs(kWhLost).toCurrency(this.currency) + "/yr";	// Energy cost is tangible
    };

    destroy = function() {
        this.element.textContent = '';	// Blank out our element's text (if any)
        this.nodeManager.destroy();		// Unsubscribe to the nodes we were using
        this.unregisterAsWidget();		// Unregister like a good little widget
    };
};
