import { Node, NodeSubscriber } from './node';
import { type Banner } from './widgets/status/banner/banner';
const bannerText = 'Live writes enabled for '

export default class SettingsManager {
    fPersistWriteMode: boolean
    pendingWrites: Map<Node, Map<NodeSubscriber, any>> = new Map();
    banner: Banner;
    interval: NodeJS.Timeout;
    timeout: NodeJS.Timeout;
    onWriteCallback: (pendingWrites: Map<Node, any>) => void;
    constructor(onWriteCallback: (pendingWrites: Map<Node, any>) => void = () => {}, bannerElement: HTMLElement) {
        this.onWriteCallback = onWriteCallback;
        this.banner = document.createElement('se-banner') as Banner;
        this.banner.backgroundColor = 'red';
        this.banner.isAnimated = true;
        this.banner.textColor = '#ffffff';
        this.banner.isCancellable = true;
        this.banner.oncancel = () => this.disablePersistedWriteMode();
        bannerElement.appendChild(this.banner);
        this.banner.setText(`${bannerText}10:00`);
    }

    pushPending(refNode: Node, refValue: any, refWidget: NodeSubscriber) {
        if (!this.fPersistWriteMode) {
            let hasNode = this.pendingWrites.has(refNode);
            if (!hasNode)
                this.pendingWrites.set(refNode, new Map());

            let pendingWrite = this.pendingWrites.get(refNode)!;
            if (refValue === refNode.getValue()) { // We already had a pending change, but they changed the value back to its original value
                pendingWrite.delete(refWidget);
                if (pendingWrite.size === 0)
                    this.pendingWrites.delete(refNode);
            }
            else
                pendingWrite.set(refWidget, refValue);

            refNode._updateSubscribers();
            this.onWriteCallback(this.pendingWrites);
        }
        else {
            refNode.setValue(refValue)
        }
    }

    enablePersistedWriteMode(timeMS: number) {
        this.fPersistWriteMode = true;
        let startTime = (new Date()).getTime();
        this.interval = setInterval(() => {
            let timeLeft = Math.round((timeMS - ((new Date()).getTime() - startTime)) / 1000);
            this.banner.setText(`${bannerText}${Math.floor(timeLeft / 60)}:${timeLeft % 60} `)
        }, 1000)
        this.timeout = setTimeout(() => {
            this.disablePersistedWriteMode();
        }, timeMS)

        this.banner.show();
    }

    disablePersistedWriteMode() {
        clearInterval(this.interval);
        clearTimeout(this.timeout);
        this.fPersistWriteMode = false;
        this.banner.hide();
    }

    apply() {
        for (let [tag, pendingValue] of this.pendingWrites) {
            let value = Array.from(pendingValue.values()).pop(); // Just get the last item in the map. Don't issue multiple writes to a single tag for now
            tag.setValue(value);
        }
        this.clearPending();
    }

    popPending(refNode: Node, refWidget: NodeSubscriber) {
        if (this.pendingWrites.has(refNode)) {
            let write = this.pendingWrites.get(refNode)!;
            write.delete(refWidget);;
            if (write.size === 0)
                this.pendingWrites.delete(refNode);
            refNode._updateSubscribers();
        }
        this.onWriteCallback(this.pendingWrites);
    }

    clearPending() {
        this.pendingWrites.clear();
        this.onWriteCallback(this.pendingWrites);
    }
}