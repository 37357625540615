//Converts an html element into a text input element and attaches it to a LiveData node:

import owner from '../owner';
import assert from "./debug";
import { Widget } from './widget';
import { createElement } from "./elements";
import { NodeQuality } from "./node";

// TextField inherits Widget:
export default class TextField extends Widget {
    constructor(element, properties) {
        super();
        assert(element, 'element is a required property');
        assert(element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element = element;
        this.registerAsWidget(element);		// register this element as a widget
        this.redXMask = 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.copy(properties);	// Copy optional properties object
    }

    initialize() {
        assert(this.node, 'Spinner requires a node.');
        assert(this.node.isWriteable, 'Spinner nodes should be writable');

        this.input = createElement('input', 'spinner', this.element);	// Create the input element
        this.input.disabled = !this.node.couldBeWritten();	// If they can't ever write this node, make it obviously disabled

        // Set up the value change callbacks
        this.input.addEventListener('blur',		this.onBlur.bind(this));		// So we can clear the modified flag and reset the value
        this.input.addEventListener('keydown',	this.onKeyPress.bind(this));	// So we can submit the new data value when the user hits enter
        this.input.addEventListener('input',	this.onInput.bind(this));		// So we can set the modified flag

        this.node.subscribe(this, this.element, this.redXMask);	// Connect node, and pass in the top element to receive the red x.
    };

    update(node) {	// node value/quality changed:
        assert (this.node === node);					// Only one node should be calling us back
        if (node.quality == NodeQuality.NQ_GOOD)
            this.input.value = node.getValue(this.fPending);			// set the value
    };

    destroy(node) {	// Undo pretty much everything we did in initialize() (Though we do leave some random members lying around):
        this.element.removeChildren();  // Remove the input element that we added
        this.node.unsubscribe(this);
        this.unregisterAsWidget();
    };


    onBlur(event) {	// Focus shifted away from the spinner
        if (owner.settingsManager)
            if (this.node.getValue(true) != this.input.value)
                this.onInput();
    };

    onKeyPress(evt) {		// User pressed a key:
        if (evt.keyCode == 13 || evt.keyCode == 9) {		// The enter key or the tab key
            if (this.node.hasWritePermission())	{			// If the node is currently writable
                this.node.setValue(this.input.value);
                this.fWritten = true;		// Set a flag the says not to update on the blur
            }
            evt.preventDefault();	// Prevent the enter key from giving us the onInput call back
            this.input.blur();		// Lose focus after submitting the value
        }
    };

    onInput(evt) {	// The value in the spinner has just changed
        if (owner.settingsManager) {
			owner.settingsManager.pushPending(this.node, this.input.value, this);
		}
		else if (this.node.hasWritePermission()){
			this.node.setValue(result/this.conversion);
		} else
			this.update(this.node);	// Update the node

    };

}
