import owner from "../../../owner";
import { createElement } from "../../elements";
import { GenericGraph, HDRequest } from "../../graph";
import { Node } from "../../node";
import EditorPage from "../../pages/editorpage";
import { Gizmo, DataRange, LiveDataGizmo } from './gizmo';
import {Chart, ChartData, registerables} from 'chart.js';
import 'chartjs-adapter-moment';
import { Device } from "../../device";
import { LoggedFilter, UnitsFilter } from '../../tagfilter';
import { BoxPlotChart } from '@sgratzl/chartjs-chart-boxplot';
import { StyleCategories } from '../../views/dashboardstyleview';
import Cruncher, { DataInterval, HistoricalData } from "../../cruncher";
import ChartIcon            from "../../images/icons/chart.svg";
import TagSocket from "../tagsocket";
import assert from "../../debug";
import { RateMap, UnitsMap } from "../../widgets/lib/tagunits";

Chart.register(...registerables);

export class HistoricalDataGizmo extends LiveDataGizmo {
    graph: GenericGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    socket: TagSocket;
    graphID: number;
    fNeedData: boolean = true;
    cruncher: Cruncher;
    public connectedCallback(): void {
        super.connectedCallback();
        this.graphID            = owner.ldc.registerGraph(this);
        this.fNeedData          = true;
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.wrapper            = createElement('div', );
        this.cruncher           = new Cruncher();
    }

    requestData(start: Date, end: Date, interval: DataInterval, requestedTags: Set<Node>) {
        this.cruncher.getTrimmedData(start, end, Array.from(requestedTags), interval, (data: HistoricalData) => this.onDataResponse(data))
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
    }

    onDataResponse(data: HistoricalData) {}

    calculateInterval(seconds: number): DataInterval {
        //assert(start < end, "Bad interval passed in!");
        let intervals = GenericGraph.intervals as DataInterval[];
        for (let index = 0; index < intervals.length; index++) {
            let points = seconds / intervals[index];					// Number of data points displayed should we choose this interval
            if (points < 20000)											// Select the first interval that will load up fewer than x000 data points
                return intervals[index];
        }
        return intervals[intervals.length -1];
    };

    getValue(time: number, i: number, data: HistoricalData, nullValue: number | null, column: number = 3) {
        var setTimes	= data[1];		// Get the time column
        var setData		= data[column];		// Get the average column
        while(setTimes[i + 1] < time && i < setTimes.length - 2)	// Keep looking through the rows until we find the correct period
            ++i;
        if (setData.length < 2 || setData[i] === null || setData[i + 1] === null)	// If either side is a null, this guy is a null
            return nullValue;
        else								// Else interpolate between the values on either side
            return setData[i] as number + (setData[i + 1] as number - (setData[i] as number)) * (time - setTimes[i]) / (setTimes[i+1] - setTimes[i]);
    };

    rebuild() {
        let end = new Date();
        let start = new Date(new Date().setDate(end.getDate() - 1));
        this.requestData(start, end, 60, this.socket.tags);
    }
}

export class DailyProfilesGizmo extends HistoricalDataGizmo {
    graph: GenericGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    socket: TagSocket;
    interval: DataInterval;       // what resolution do we want
    endTime: number;
    startTime: number;
    device: Device;
    start: Date;
    end: Date;
    data: HistoricalData;
    graphWrapper;
    public connectedCallback(): void {
        super.connectedCallback();
        this.graphWrapper       = createElement('div', 'chart-gizmo__wrapper', this);
        this.socket = new TagSocket(owner.ldc, 'Live Data Tags', this, [new LoggedFilter(true, false)], [], true);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
        if (!this.recipe.settings['Ranges']) {
            this.recipe.settings['Ranges'] = [{
                name: 'Default',
                value: 100,
                color: '#f3523f'
            }]
        }
        if (!this.recipe.settings['Minimum'])
            this.recipe.settings['Minimum'] = 0;
        if (!this.recipe.settings['Period'])
            this.recipe.settings['Period'] = 86400;
        if (!this.recipe.settings['Periods'])
            this.recipe.settings['Periods'] = 7;

        this.controlRow = createElement('div', 'graph-widget__controls', this.graphWrapper)
        this.graphRow   = createElement('div', 'graph-widget__graph', this.graphWrapper);
        this.legendRow  = createElement('div', 'graph-widget__legend', this.graphWrapper);
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.fNeedData  = true;
    }

    onDataResponse(data: HistoricalData) {	// We got historical data back
        this.data = data;
        this.fNeedData = false;
        this.hideWarning();
        this.createProfileGraph(this.data);
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createRangeSettings(editor.toolTabs.getSectionByName('Settings'));
    }

    rebuild() {

        if (this.socket.tags.size < 1) {
            return
        }
        let period  = this.recipe.settings['Period'] as number;
        let periods = this.recipe.settings['Periods'] as number;

        this.interval   = this.calculateInterval(period * periods)
        this.end        = new Date(Math.floor((new Date().getTime() + new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start      = new Date((this.end.getTime() / 1000 - periods * period) * 1000);
        if (this.fNeedData)
            this.requestData(this.start, this.end, this.interval, this.socket.tags);
        else if (this.data)
            this.createProfileGraph(this.data);
    }

    createProfileGraph(data: HistoricalData) {
        let ranges = this.recipe.settings['Ranges'] as [DataRange];
        let period  = this.recipe.settings['Period'] as number;
        let periods = this.recipe.settings['Periods'] as number;
        var fluxSets: any = [];
        for (let i = 0; i < periods; ++i) {
            let cumulative  = new Array(ranges.length).fill(0);
            let index       = 0; // keep track of this for speediness
            let dateColumn  = data[1];
            let startTime = this.start.getTime() + i * period * 1000;
            for (let time = startTime; time < startTime + period * 1000; time += this.interval * 1000) {	// Go through the entire interval they requested at the interval they requested
                let value: number | null = this.getValue(time, index, data, null);
                if (value == null)
                    continue; //TODO: add null range for bad data
                for (let j=0;j<ranges.length;++j) {
                    if (value < ranges[j].value) {
                        cumulative[j]++;
                        break;
                    }
                }
            }
            fluxSets.push(cumulative);
        }
        let fluxColors: string[] = []
        ranges.forEach(range => {
            fluxColors.push(range.color);
        });
        this.graphRow.removeChildren();
        let element = createElement('canvas', '', this.graphRow, '', {'width':this.graphRow.clientWidth, 'height':this.graphRow.clientHeight});
        let ctx = element.getContext('2d')!;
        let dataLabels: number[] = [];
        for (let i=0;i<periods;++i)
            dataLabels.push(this.start.getTime() + i * period * 1000)
        let dataSets: any[] = [];
        for (let i=0;i<ranges.length;++i) {
            let dataSet: any = {
                label: ranges[i].name,
                backgroundColor: ranges[i].color,
                strokeColor: ranges[i].color,
                data: []
            }
            for (let j = 0; j < periods; ++j) {	// For each day we were given
                dataSet.data.push(fluxSets[j][i])
            }
            dataSets.push(dataSet)
        }

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: dataLabels,
                datasets: dataSets
            },
            options: {
                scales: {
                x: {
                    stacked: true,
                    offset: true,
                    type: 'time',
                    time: {
                    parser: 'MM/DD/YYYY',
                    tooltipFormat: 'll',
                    unit: 'day',
                    displayFormats: {
                        'day': 'MM/DD/YYYY'
                    }
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: 'Minutes'
                    },
                    stacked: true,
                    ticks: {
                        sampleSize: period / this.interval,
                    },
                    type: 'linear',
                }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                }
            }
        })
    }

    addOverlayElements(parent: HTMLElement): void {
        if (this.socket.tags.size == 0 && this.editor) {
            createElement('img', '_gizmo__overlay__icon', parent, undefined, {'src':ChartIcon});
            let addButton = createElement('button', 'se-button', parent, 'Add Tags to Chart');
            addButton.onclick = (e) => {
                e.stopPropagation();
            }
        }
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '300px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    onResize() {
        this.rebuild();
    }
}

export class DailyTotalsGizmo extends HistoricalDataGizmo {
    graph: GenericGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    socket: TagSocket;
    interval: DataInterval;       // what resolution do we want
    endTime: number;
    startTime: number;
    device: Device;
    start: Date;
    end: Date;
    data: HistoricalData;
    graphWrapper;
    public connectedCallback(): void {
        super.connectedCallback();
        this.graphWrapper       = createElement('div', 'chart-gizmo__wrapper', this);
        this.socket = new TagSocket(owner.ldc, 'Live Data Tags', this, [new LoggedFilter(true, false), new UnitsFilter(Array.from(RateMap.keys()))], [], true);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
        if (!this.recipe.settings['Minimum'])
            this.recipe.settings['Minimum'] = 0;
        if (!this.recipe.settings['Period'])
            this.recipe.settings['Period'] = 86400;
        if (!this.recipe.settings['Periods'])
            this.recipe.settings['Periods'] = 7;
        if (!this.recipe.settings['Color'])
            this.recipe.settings['Color'] = owner.colors.hex('--color-primary');

        this.controlRow = createElement('div', 'graph-widget__controls', this.graphWrapper)
        this.graphRow   = createElement('div', 'graph-widget__graph', this.graphWrapper);
        this.legendRow  = createElement('div', 'graph-widget__legend', this.graphWrapper);
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.fNeedData  = true;
    }

    onDataResponse(data: HistoricalData) {	// We got historical data back
        this.data = data;
        this.fNeedData = false;
        this.hideWarning();
        this.createProfileGraph(this.data);
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingsColorInput('Color', 'Bar Color:');
    }

    rebuild() {
        if (this.socket.tags.size < 1) {
            return
        }
        let period  = this.recipe.settings['Period'] as number;
        let periods = this.recipe.settings['Periods'] as number;

        this.interval   = this.calculateInterval(period * periods)
        this.end        = new Date(Math.floor((new Date().getTime() + new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start      = new Date((this.end.getTime() / 1000 - periods * period) * 1000);
        if (this.fNeedData)
            this.requestData(this.start, this.end, this.interval, this.socket.tags);
        else if (this.data)
            this.createProfileGraph(this.data);
    }

    createProfileGraph(data: HistoricalData) {
        assert(this.socket.tags.size == 1, 'Too many tags');
        let [tag] = this.socket.tags;
        assert(RateMap.has(tag.units), 'Tag has units that are not defined in the rate map');
        let accumulatedUnits = RateMap.get(tag.units)!.unit;
        let period  = this.recipe.settings['Period'] as number;
        let periods = this.recipe.settings['Periods'] as number;
        let dayTotals: number[] = [];
        for (let i = 0; i < periods; ++i) {
            let cumulative  = 0;
            let index       = 0; // keep track of this for speediness
            let dateColumn  = data[1];
            let startTime = this.start.getTime() + i * period * 1000;
            for (let time = startTime; time < startTime + period * 1000; time += this.interval * 1000) {	// Go through the entire interval they requested at the interval they requested
                let value: number | null = this.getValue(time, index, data, null);
                if (value == null)
                    continue; //TODO: add null range for bad data
                cumulative += value;
            }
            dayTotals.push(cumulative);
        }
        this.graphRow.removeChildren();
        let element = createElement('canvas', '', this.graphRow, '', {'width':this.graphRow.clientWidth, 'height':this.graphRow.clientHeight});
        let ctx = element.getContext('2d')!;
        let dataLabels: number[] = [];
        for (let i=0;i<periods;++i)
            dataLabels.push(this.start.getTime() + i * period * 1000)
        let dataSets: any[] = [];
        dataSets.push( {
            label: `Total (${UnitsMap.get(accumulatedUnits)?.plural})`,
            backgroundColor: this.recipe.settings['Color'],
            strokeColor: this.recipe.settings['Color'],
            data: dayTotals
        })

        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: dataLabels,
                datasets: dataSets
            },
            options: {
                scales: {
                x: {
                    stacked: true,
                    offset: true,
                    type: 'time',
                    time: {
                    parser: 'MM/DD/YYYY',
                    tooltipFormat: 'll',
                    unit: 'day',
                    displayFormats: {
                        'day': 'MM/DD/YYYY'
                    }
                    }
                },
                y: {
                    title: {
                        display: true,
                        text: UnitsMap.get(accumulatedUnits)?.plural
                    },
                    stacked: true,
                    ticks: {
                        sampleSize: period / this.interval,
                    },
                    type: 'linear',
                }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                }
            }
        })
    }

    addOverlayElements(parent: HTMLElement): void {
        if (this.socket.tags.size == 0 && this.editor) {
            createElement('img', '_gizmo__overlay__icon', parent, undefined, {'src':ChartIcon});
            let addButton = createElement('button', 'se-button', parent, 'Add Tags to Chart');
            addButton.onclick = (e) => {
                e.stopPropagation();
            }
        }
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '300px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    onResize() {
        this.rebuild();
    }
}

export class BoxPlotGizmo extends HistoricalDataGizmo {
    graph: GenericGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    socket: TagSocket;
    interval: DataInterval;       // what resolution do we want
    endTime: number;
    startTime: number;
    device: Device;
    start: Date;
    end: Date;
    data: HistoricalData;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.socket = new TagSocket(owner.ldc, 'Live Data Tags', this, [new LoggedFilter(true, false)], [], true);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
        if (this.recipe.settings['Period'] === undefined)
            this.recipe.settings['Period'] = 604800;
        this.wrapper = createElement('div', 'flex__row full__height full__width', this);
    }

    onDataResponse(data: HistoricalData) {	// We got historical data back
        this.data = data;
        this.fNeedData = false;
        this.createBoxPlot(this.data);
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingsColorInput('BorderColor', 'Border Color: ')
        this.createSettingsColorInput('BackgroundColor', 'Background Color: ')
        this.createSettingsColorInput('OutlierColor', 'Outlier Color: ')
        //this.createSizeSettings(editor.toolTabs.getSectionByName('Style'));
    }

    createBoxPlot(data: HistoricalData) {
        let element = createElement('canvas', '', this.wrapper, '', {'width':this.wrapper.clientHeight, 'height':this.wrapper.clientWidth});
        let ctx = element.getContext('2d')!;
        console.log(data)
        let [tag] = this.socket.tags;
        const boxplotData = {
            // define label tree
            labels: [''],
            datasets: [{
                label: tag.getDisplayName(),
                backgroundColor: this.recipe.settings['BackgroundColor'] ?? '#000000',
                borderColor: this.recipe.settings['BorderColor'] ?? '#000000',
                borderWidth: 2,
                outlierColor: this.recipe.settings['OutlierColor'] ?? '#000000',
                padding: 0,
                itemRadius: 0,
                data: [data[3]]
            }]
        };

        new BoxPlotChart(ctx, {
            data: boxplotData,
            options: {
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                },
                scales: {
                    y: {
                        title: {
                            display: true,
                            text: UnitsMap.get(tag.units)?.abbrev
                        }
                    }
                }
            }
        });
    }

    addOverlayElements(parent: HTMLElement): void {
        if (this.socket.tags.size == 0 && this.editor) {
            createElement('img', '_gizmo__overlay__icon', parent, undefined, {'src':ChartIcon});
            let addButton = createElement('button', 'se-button', parent, 'Add Tags to Chart');
            addButton.onclick = (e) => {
                e.stopPropagation();
            }
        }
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '300px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    rebuild() {
        this.wrapper.removeChildren();
        if (this.socket.tags.size < 1) {
            return
        }
        let period      = this.recipe.settings['Period'] as number;

        this.interval   = this.calculateInterval(period)
        this.end        = new Date();
        this.start      = new Date((this.end.getTime() / 1000 - period) * 1000);
        if (this.fNeedData)
            this.requestData(this.start, this.end, this.interval, this.socket.tags);
        else
            this.createBoxPlot(this.data);
    }
}

export class PieRangeGizmo extends HistoricalDataGizmo {
    graph: GenericGraph;
    socket: TagSocket;
    interval: DataInterval;       // what resolution do we want
    endTime: number;
    startTime: number;
    device: Device;
    start: Date;
    end: Date;
    data: HistoricalData;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        this.socket = new TagSocket(owner.ldc, 'Live Data Tags', this, [new LoggedFilter(true, false)], [], false);
        this.sockets.set(this.socket.name, this.socket);
        this.socket.refreshTags();
        if (!this.recipe.settings['Ranges']) {
            this.recipe.settings['Ranges'] = [{
                name: 'Default',
                value: 100,
                color: '#f3523f'
            }];
        }
        if (!this.recipe.settings['Minimum'])
            this.recipe.settings['Minimum'] = 0;
        if (!this.recipe.settings['Period'])
            this.recipe.settings['Period'] = 604800;
        this.wrapper = createElement('div', 'flex__row full__height full__width', this);
    }

    onDataResponse(data: HistoricalData) {	// We got historical data back
        this.data = data;
        this.fNeedData = false;
        this.createPieChart(this.data);
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createRangeSettings(editor.toolTabs.getSectionByName('Settings'));
    }

    createPieChart(data: HistoricalData) {
        this.wrapper.removeChildren();
        let ranges = this.recipe.settings['Ranges'] as [DataRange];
        let cumulative  = new Array(ranges.length).fill(0);
        let index       = 0; // keep track of this for speediness
        for (let time = this.start.getTime(); time < this.end.getTime(); time += this.interval * 1000) {	// Go through the entire interval they requested at the interval they requested
            let value: number | null = this.getValue(time, index, data, null);
            if (value == null)
                continue; //TODO: add null range for bad data
            for (let j=0;j<ranges.length;++j) {
                if (value < ranges[j].value) {
                    cumulative[j]+= this.interval / 60;
                    break;
                }
            }
        }

        let element = createElement('canvas', '', this.wrapper, '', {'width':400, 'height':400});
        let ctx = element.getContext('2d')!;
        let dataSet: ChartData = {
            labels: [],
            datasets: [{
                label: 'Chart Name',
                data: cumulative,
                backgroundColor: [],
            }]
        }
        for (let i=0;i<ranges.length;++i) {
            dataSet.labels?.push(ranges[i].name);
            //@ts-ignore
            dataSet.datasets[0]!.backgroundColor.push(ranges[i].color)
        }

        new Chart(ctx, {
            type: 'pie',
            data: dataSet,
            options: {
                animation: {
                    duration: 0
                },
                responsive: true,
                maintainAspectRatio: false,
            }
        })
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '300px');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '200px');
        }
    }

    addOverlayElements(parent: HTMLElement): void {
        if (this.socket.tags.size == 0 && this.editor) {
            createElement('img', '_gizmo__overlay__icon', parent, undefined, {'src':ChartIcon});
            let addButton = createElement('button', 'se-button', parent, 'Add Tags to Chart');
            addButton.onclick = (e) => {
                e.stopPropagation();
                //this.socket.selectTags();
            }
        }
    }

    rebuild() {
        if (this.socket.tags.size < 1) {
            return
        }
        if (this.editor)
            this.editor.overlay.refresh();
        let period  = this.recipe.settings['Period'] as number;

        this.interval   = this.calculateInterval(period)
        this.end        = new Date(Math.floor((new Date().getTime() - new Date().getTimezoneOffset()) / 1000 / period) * period * 1000);
        this.start      = new Date((this.end.getTime() / 1000 - period) * 1000);
        if (this.fNeedData)
            this.requestData(this.start, this.end, this.interval, this.socket.tags);
        else
            this.createPieChart(this.data);
    }
}