import ArrowIcon         from "../images/icons/arrow_down_filled.svg";
import AlignVTopIcon    from "../images/icons/align_vertical_top.svg";
import AlignVCenterIcon from "../images/icons/align_vertical_center.svg";
import AlignVBottomIcon from "../images/icons/align_vertical_bottom.svg";
import AlignVDIcon      from "../images/icons/align_vertical_distribute.svg";
import AlignHDIcon      from "../images/icons/align_horizontal_distribute.svg";
import AlignHCenterIcon from "../images/icons/align_horizontal_center.svg";
import AlignHRightIcon  from "../images/icons/align_horizontal_right.svg";
import AlignHLeftIcon   from "../images/icons/align_horizontal_left.svg";
import AlignSpaceIcon   from "../images/icons/align_space_around.svg";

import { createElement } from "../elements";
import View from "./view";
import Switch from "../components/switch";
import owner from "../../owner";
import { RadioButton, RadioSelector } from '../components/radio';
import { TabManager } from '../components/tabmanager';
import EditorPage from '../pages/editorpage';
import assert from "../debug";
import tippy from 'tippy.js';
import Dropdown from '../components/dropdown';

/**
 * DashboardSettingViews are used to create an interface for editing generic string-string key-value pairs.
 */
export class DashboardSettingView extends View {
    parent:     HTMLElement;
    settings:  {[key: string]: string};
    defaultSettings: {[key: string]: string};
    inputs:     SettingInput[] = [];
    editor:     EditorPage;
    sectionMap: Map<string, HTMLElement> = new Map();
    autoSettings: {[key: string]: string};

    /**
     * Sets the string-string key-value pair to be edited.
     * @param newSettings       // The key value pair that we are interested in editing
     * @param sections          // Array of names of the settings allowed for newSettings
     * @param defaultSettings   // A default string-string key value pair that will show its values, but won't be editable
     */
    setSettingsToEdit(newSettings: {[key: string]: string}, sections: string[], defaultSettings: {[key: string]: string} = {}, autoSettings: {[key: string]: string} = {}): void {
        assert(newSettings);
        assert(sections);
        this.settings = newSettings;
        this.defaultSettings = defaultSettings;
        this.autoSettings = autoSettings;
        for (let [category, section] of this.sectionMap) {
            section.classList.toggle('hide', !(sections.includes(category)))
        }
        this.updateSettings();
    }

    /**
     * Calls each input's update method
     */
    updateSettings(): void {
        for (let i=0;i<this.inputs.length;++i) {
            this.inputs[i].update();
        }
    }

    /**
     * Returns a collapsible HTMLElement section
     * @param parent
     * @param name
     * @param callback
     * @param switchState
     * @param switchColor
     * @returns
     */
    createSection(parent: HTMLElement, name: string, callback?:(state: boolean)=>void, switchState?: boolean, switchColor?: string) : HTMLElement {
        this.wrapper         = createElement('div', 'editor__settings__wrapper hide', parent);
        let titleContainer = createElement('div', 'editor__container__setting', this.wrapper);
        createElement('img', 'editor__container__setting__arrow', titleContainer, '', {'src':ArrowIcon});
        createElement('div', 'editor__container__setting__title', titleContainer, name);
        if (callback) {
            new Switch(titleContainer, switchState, switchColor && 'var(--color-primary)', (state: boolean)=>callback(state))
        }
        return this.wrapper;
    }

    /**
     * Each settings view needs its own apply method
     */
    apply() {
        assert(false, "Dashboard settings views must implement their own apply method");
    }

    hideSections() {
        for (let [category, section] of this.sectionMap) {
            section.classList.toggle('hide', true);
        }
    }
}

export enum SerializedTagCategories {
    PATH        = "PATH",
    CHART       = "CHART",
    NAME        = "NAME",
    VISIBILITY  = "VISIBILITY",
    HOA         = "HOA"
}

export class DashboardSerializedTagView extends DashboardSettingView {
    constructor(editor: EditorPage) {
        super();
        this.editor = editor;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.sectionMap = new Map([
            [SerializedTagCategories.PATH,          this.createAbsolutePathSettings()],
            [SerializedTagCategories.CHART,         this.createChartSettings()],
            [SerializedTagCategories.NAME,          this.createChartSettings()],
            [SerializedTagCategories.VISIBILITY,    this.createVisibilitySettings()],
            [SerializedTagCategories.HOA,           this.createHOASettings()]
        ]);
        this.fInitialized = true;
        return this;
    }

    createAbsolutePathSettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Tag Path');
        this.inputs.push(new ToggleInput('Make Relative to Device', pathSection, 'fRelative', this));
        return pathSection;
    }

    createChartSettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Tag Style');
        this.inputs.push(
            new ColorInput('Color', pathSection, 'color', this),
            new TextInput('Display Name', pathSection, 'name', this, 'The name to show on the legend in the chart'),
            new NumberInput('Minimum', pathSection, 'min', this, 'The minimum value to show on the y-axis'),
            new NumberInput('Max', pathSection, 'max', this, 'The maximum value to show on the y-axis'),
        );
        return pathSection;
    }

    createVisibilitySettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Visibility Settings');
        this.inputs.push(
            new NumberInput('Hide When Value Equals', pathSection, 'hideWhen', this, 'Update this'),
        );
        return pathSection;
    }

    createHOASettings(): HTMLElement {
        let hoaSection  = this.createSection(this.parent,'Visibility Settings');
        this.inputs.push(
            new TextInput('Display Name', hoaSection, 'name', this, 'Update this'),
        );
        return hoaSection;
    }

    apply() {
        this.editor.selectedGizmo?.onTagSettingChanged();
    }


}

export enum StyleCategories {
    POSITION    = "POSITION",
    DIMENSIONS  = "DIMENSIONS",
    TYPOGRAPHY  = "TYPOGRAPHY",
    BACKGROUND  = "BACKGROUND",
    BORDER      = "BORDER",
    LAYOUT      = "LAYOUT",
    CHART       = "CHART",
    SPACING     = "SPACING"
}

export class DashboardStyleView extends DashboardSettingView {
    parent: HTMLElement;
    inputs: SettingInput[] = [];
    editor: EditorPage;
    constructor(editor: EditorPage) {
        super();
        this.editor = editor;
    }
    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.sectionMap = new Map([
            [StyleCategories.POSITION,      this.createPositionSettings()],
            [StyleCategories.DIMENSIONS,    this.createSizeSettings()],
            [StyleCategories.TYPOGRAPHY,    this.createTypographySettings()],
            [StyleCategories.BACKGROUND,    this.createBackgroundSettings()],
            [StyleCategories.BORDER,        this.createBorderSettings()],
            [StyleCategories.LAYOUT,        this.createLayoutSettings()],
            [StyleCategories.SPACING,       this.createSpacingSettings()],
        ]);

        this.fInitialized = true;
        return this;
    }

    apply() {
        this.editor.selectedGizmo?.applyStyles();
    }

    updateSettings() {
        for (let i=0;i<this.inputs.length;++i) {
            this.inputs[i].update();
        }
    }

    createSpacingSettings(): HTMLElement {
        let spacingSection  = this.createSection(this.parent,'Spacing');
        let spacingCanvas   = createElement('canvas', '', spacingSection, '', {height:180, width: 348});
        let ctx = spacingCanvas.getContext('2d')!;
        ctx.rect(0,0,spacingCanvas.width, spacingCanvas.height);
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '20';
        ctx.fill()
        ctx.beginPath();
        ctx.moveTo(0,0);
        ctx.lineTo(4 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16);
        ctx.lineTo(4 * spacingCanvas.width / 9, 9 * spacingCanvas.height / 16);
        ctx.lineTo(0, spacingCanvas.height);
        ctx.lineTo(spacingCanvas.width, spacingCanvas.height);
        ctx.lineTo(5 * spacingCanvas.width / 9, 9 * spacingCanvas.height / 16);
        ctx.lineTo(5 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16);
        ctx.lineTo(spacingCanvas.width, 0);
        ctx.closePath();
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '30';
        ctx.fill();
        ctx.beginPath();
        ctx.rect(4 * spacingCanvas.width / 9, 7 * spacingCanvas.height / 16, spacingCanvas.width / 9, spacingCanvas.height / 8);
        ctx.fillStyle = owner.colors.hex('--color-onSurface') + '50';
        ctx.fill();
        ctx.beginPath();
        ctx.rect(spacingCanvas.width / 5, spacingCanvas.height / 5, 3 * spacingCanvas.width / 5, 3 * spacingCanvas.height / 5);
        ctx.lineWidth = 4;
        ctx.strokeStyle = owner.colors.hex('--color-onSurface') + '50';
        ctx.stroke();

        let marginLabel     = createElement('div', 'editor__container__setting__title', spacingSection, 'Margin');
        let paddingLabel    = createElement('div', 'editor__container__setting__title', spacingSection, 'Padding');
        marginLabel.style.position = 'absolute';
        marginLabel.style.top = '28px';
        marginLabel.style.left = '6px';
        marginLabel.style.marginLeft = 'calc(0px-50%)';
        marginLabel.style.fontSize = '0.75em';
        marginLabel.style.color = 'var(--color-onSurface)';

        paddingLabel.style.position = 'absolute';
        paddingLabel.style.top = spacingCanvas.height / 4 + 22 + 'px' ;
        paddingLabel.style.left = spacingCanvas.width / 5 + 6 + 'px';
        paddingLabel.style.fontSize = '0.75em';
        paddingLabel.style.color = 'var(--color-onSurface)';

        let mInputRight     = new SizeInput('', spacingSection, 'margin-right',  ['px','%'], this, 'Edit margin right');
        let mInputTop       = new SizeInput('', spacingSection, 'margin-top',    ['px','%'], this, 'Edit margin top');
        let mInputLeft      = new SizeInput('', spacingSection, 'margin-left',   ['px','%'], this, 'Edit margin left');
        let mInputBottom    = new SizeInput('', spacingSection, 'margin-bottom', ['px','%'], this, 'Edit margin bottom');

        this.inputs.push(mInputRight, mInputTop, mInputLeft, mInputBottom);

        let margins: SizeInput[] = [mInputLeft, mInputTop, mInputRight, mInputBottom];

        for (let i=0;i<margins.length;++i) {
            margins[i].wrapper.style.position = 'absolute';
            margins[i].wrapper.style.width = 'max-content';
            margins[i].wrapper.style.top = 24 + spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 2.5 + 'px';
            margins[i].wrapper.style.left = spacingCanvas.width / 2 - Math.cos(i * Math.PI / 2) * spacingCanvas.width / 2.5 + 'px';
            margins[i].wrapper.style.transformOrigin = 'center'
            margins[i].wrapper.style.transform = 'translate(-50%, -50%) scale(0.66)'
        }

        let pInputRight     = new SizeInput('', spacingSection, 'padding-right', ['px','%'], this, 'Edit padding right');
        let pInputTop       = new SizeInput('', spacingSection, 'padding-top',   ['px','%'], this, 'Edit padding top');
        let pInputLeft      = new SizeInput('', spacingSection, 'padding-left',  ['px','%'], this, 'Edit padding left');
        let pInputBottom    = new SizeInput('', spacingSection, 'padding-bottom',['px','%'], this, 'Edit padding bottom');

        this.inputs.push(pInputRight, pInputTop, pInputLeft, pInputBottom);

        let paddings: SizeInput[] = [pInputLeft, pInputTop, pInputRight, pInputBottom];

        for (let i=0;i<margins.length;++i) {
            paddings[i].wrapper.style.position = 'absolute';
            paddings[i].wrapper.style.width = 'max-content';
            paddings[i].wrapper.style.top = 24 + spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 6 + 'px';
            paddings[i].wrapper.style.left = spacingCanvas.width / 2 - Math.cos(i * Math.PI / 2) * spacingCanvas.width / 6 + 'px';
            paddings[i].wrapper.style.transformOrigin = 'center'
            paddings[i].wrapper.style.transform = 'translate(-50%, -50%) scale(0.66)'
        }
        return spacingSection;

    }

    createSizeSettings(): HTMLElement {
        let sizeSection     = this.createSection(this.parent,'Size');
        let sizeColumns     = createElement('div', 'flex__row full__width', sizeSection);
        let sizeColumn1     = createElement('div', 'editor__container__setting__column', sizeColumns);
        let sizeColumn2     = createElement('div', 'editor__container__setting__column', sizeColumns);
        this.inputs.push(
            new SizeInput('Height:',sizeColumn1,    'height',        ['px','%','em'], this),
            new SizeInput('Min H:',sizeColumn1,     'min-height',    ['px','%','em'], this),
            new SizeInput('Max H:',sizeColumn1,     'max-height',    ['px','%','em'], this),

            new SizeInput('Width:',sizeColumn2, 'width',     ['px','%','em'], this),
            new SizeInput('Min W:',sizeColumn2, 'min-width', ['px','%','em'], this),
            new SizeInput('Max W:',sizeColumn2, 'max-width', ['px','%','em'], this),

            new ToggleInput('Grow to Fill', sizeColumn1, 'flex-grow', this, `Grow to fill the parent's size`)
        )
        return sizeSection;
    }

    createTypographySettings(): HTMLElement {
        let typographySection   = this.createSection(this.parent, 'Typography');
        let types               = createElement('div', 'flex__column full__width', typographySection);
        let align               = createElement('div', 'editor__radio__row', typographySection);
        this.inputs.push(
            new SizeInput('Font Size:', types, 'font-size',     ['px','%','em'], this),
            new SelectInput('Font:',    types, 'font-family',   ['Source Sans', 'Arial', 'IBM Plex Mono'], ['Source Sans Pro', 'Arial', `"IBM Plex Mono", monospace`], this),
            new ColorInput('Text Color:', typographySection, 'color', this),
            new RadioInput(align, 'text-align', 'Align:', [
                {name: 'left',          displayName: '', icon: AlignHLeftIcon,   tooltip: 'Left'},
                {name: 'center',        displayName: '', icon: AlignHCenterIcon, tooltip: 'Center'},
                {name: 'right',         displayName: '', icon: AlignHRightIcon,  tooltip: 'Right'},
                {name: 'justify',       displayName: '', icon: AlignHDIcon,      tooltip: 'Justify'}
            ], this)
        );
        return typographySection;
    }

    createBackgroundSettings(): HTMLElement  {
        let backgroundSection   = this.createSection(this.parent, 'Background');
        this.inputs.push(
            new ColorInput('Background Color:', backgroundSection, 'background-color', this)
        );
        return backgroundSection;
    }


    createBorderSettings(): HTMLElement  {
        let spacingSection  = this.createSection(this.parent, 'Border');
        this.inputs.push(
            new SelectInput('Border Style',     spacingSection, 'border-style', ['None', 'Solid', 'Dashed', ], [ 'none', 'solid', 'dashed',], this),
            new SizeInput('Border Radius:',     spacingSection, 'border-radius', ['px','%'], this),
            new SizeInput('Border Thickness:',  spacingSection, 'border-width', ['px','%'], this),
            new ColorInput('Border Color:',     spacingSection, 'border-color', this),
        );
        return spacingSection;
    }

    createLayoutSettings(): HTMLElement  {
        let layoutSection   = this.createSection(this.parent, 'Layout');

        let flexWrapper2 = createElement('div', 'editor__radio__row', layoutSection);
        let flexWrapper3 = createElement('div', 'editor__radio__row', layoutSection);
        let flexWrapper6 = createElement('div', 'editor__radio__row', layoutSection);
        let flexWrapper7 = createElement('div', 'editor__radio__row', layoutSection);

        this.inputs.push(
            new RadioInput(flexWrapper2, 'align-items', 'Alignment', [
                {name: 'normal',        displayName: '', icon: AlignVTopIcon,    tooltip: 'Start'},
                {name: 'center',        displayName: '', icon: AlignVCenterIcon, tooltip: 'Center'},
                {name: 'flex-end',      displayName: '', icon: AlignVBottomIcon, tooltip: 'End'},
                {name: 'stretch',       displayName: '', icon: AlignVDIcon,      tooltip: `<strong>Stretch</strong> to fit the container`}
            ], this),
            new RadioInput(flexWrapper3, 'justify-content', 'Justify', [
                {name: 'normal',        displayName: '', icon: AlignHLeftIcon,   tooltip: 'Start'},
                {name: 'center',        displayName: '', icon: AlignHCenterIcon, tooltip: 'Center'},
                {name: 'flex-end',      displayName: '', icon: AlignHRightIcon,  tooltip: 'End'},
                {name: 'space-between', displayName: '', icon: AlignSpaceIcon,   tooltip: '<strong>Space Between</strong> — Distribute items evenly. The first item is flush with the start, the last is flush with the end'},
                {name: 'space-around',  displayName: '', icon: AlignHDIcon,      tooltip: '<strong>Space Around</strong> — Distribute items evenly. Items have equal spacing around all children'}
            ], this),
            new RadioInput(flexWrapper6, 'flex-wrap', 'Wrap Children', [
                {name: 'nowrap',    displayName: 'No Wrap', icon: '', tooltip: 'The items break into multiple lines'},
                {name: 'wrap',      displayName: 'Wrap',    icon: '', tooltip: 'The items are laid out in a single line. May cause overflow'},
            ], this),
            new RadioInput(flexWrapper7, 'overflow', 'Overflow', [
                {name: 'auto',      displayName: 'Auto',    icon: '', tooltip: 'Only scroll when content overflows'},
                {name: 'scroll',    displayName: 'Scroll',  icon: '', tooltip: 'Scroll overflowing content'},
                {name: 'hidden',    displayName: 'Hidden',  icon: '', tooltip: 'Hide overflowing content without scrolling'},
            ], this),
        );
        return layoutSection;
    }

    createPositionSettings(): HTMLElement {
        let positionSection   = this.createSection(this.parent, 'Position');

        let positionTabs = new TabInput(createElement('div', 'flex__column full__width', positionSection), 'position', 'Position', [
            {name: 'relative', icon: '', displayName: 'Relative', tooltip: 'Positioned according to the normal flow of the document'},
            {name: 'absolute', icon: '', displayName: 'Absolute', tooltip: 'Removed from the normal document flow and positioned relative to its closest positioned parent'},
        ], this, (name) => {
            switch (name) {
                case 'relative':
                    delete this.settings['left'];
                    delete this.settings['right'];
                    delete this.settings['top'];
                    delete this.settings['bottom'];
                    break;
                case 'absolute':
                    this.settings['left'] = this.settings['top'] = '0px';
                    break;
                default:
                    assert(false, "Bad position style setting");
            }
            this.apply();
            this.updateSettings();
        });

        this.inputs.push(positionTabs);

        let absoluteTab     = positionTabs.tabManager.sectionMap.get("absolute");
        let absoluteSection = createElement('div', 'full__width flex__column', absoluteTab);

        let spacingCanvas   = createElement('canvas', '', absoluteSection, '', {height:79, width: 300});
        let ctx = spacingCanvas.getContext('2d')!;

        ctx.beginPath();
        ctx.rect(spacingCanvas.width / 3, 2 * spacingCanvas.height / 5, spacingCanvas.width / 3, spacingCanvas.height / 5);
        ctx.lineWidth = 4;
        ctx.strokeStyle = '#00000080';
        ctx.stroke();

        let positionInputs  = ['right', 'top', 'left', 'bottom'];

        for (let i=0;i<positionInputs.length;++i) {
            let wrapper = createElement('div', '', absoluteSection);
            let input = new SizeInput('', wrapper, positionInputs[i], ['px','%'], this);
            wrapper.style.position         = 'absolute';
            wrapper.style.top              = spacingCanvas.height / 2 - Math.sin(i * Math.PI / 2) * spacingCanvas.height / 3 - 6 + 'px';
            wrapper.style.left             = spacingCanvas.width / 2 + Math.cos(i * Math.PI / 2) * spacingCanvas.width  / 3 - 6 + 'px';
            wrapper.style.transformOrigin  = 'center';
            wrapper.style.transform        = 'scale(0.66) translate(-50%, -50%)';
            this.inputs.push(input);
            positionTabs.registerSubSetting(input);
        }
        return positionSection;
    }


}

export class SettingInput {
    input: HTMLInputElement;
    property: string;
    view: DashboardSettingView;
    parent: HTMLElement;
    wrapper: HTMLElement;
    constructor(parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        this.parent         = parent
        this.wrapper        = createElement('div', 'setting-input__wrapper', this.parent);
        if (tooltip && tooltip.length > 0) {
            assert(tooltip != undefined, 'Tooltip string is undefined');
            assert(tooltip != '', 'Tooltip string is empty');
            tippy(this.wrapper, {
                content  : tooltip,
                delay    : [750, 0],  // duration: [show, hide]
                duration : [0, 0],    // duration: [show, hide]
                placement: 'top',
                offset   : [0, 5],
                allowHTML: true
            });
        }
        this.wrapper.addEventListener('contextmenu', (e) => {
            e.preventDefault();
            new Dropdown(e, ['Reset to Default'], (name)=> {
                switch(name) {
                    case 'Reset to Default':
                        this.resetStyle();
                    break;
                    default:
                        assert(false);
                }
            })
        });
        this.property       = property;
        this.view           = view;
    }

    apply(setting: string, fResize: boolean = false) {
        this.view.settings[this.property] = setting;
        this.view.apply();
        if (fResize) {
            this.view.editor.resize();
        }
        this.updateIndicator();
    }

    resetStyle() {
        delete this.view.settings[this.property];
        this.update();
        this.view.apply();
        this.view.editor.resize();
    }

    setEnabled(fEnabled: boolean) {}

    getSettingValue() {
        return this.view.settings[this.property] ?? this.view.defaultSettings[this.property] ?? this.view.autoSettings[this.property] ?? '';
    }

    update() {
        this.updateIndicator();
    }

    updateIndicator() {
        if (this.property in this.view.settings) {
            this.wrapper.style.border = `1px solid var(--color-blue-3)`;
        }
        else if (this.property in this.view.defaultSettings)
            this.wrapper.style.border = `1px solid var(--color-orange-3)`;
        else
            this.wrapper.style.border = '';

    }
}

class RadioInput extends SettingInput {
    radioSelector: RadioSelector;
    constructor(parent: HTMLElement, property: string, title: string, radioButtons: RadioButton[], view: DashboardSettingView) {
        super(parent, property, view);
        this.radioSelector = new RadioSelector(this.wrapper, {
            buttons: radioButtons,
            title: title,
            changeCallback: (name) => {
                this.apply(name);
            }
        });
    }

    update(): void {
        super.update();
        this.radioSelector.update(this.getSettingValue());
    }

    setEnabled(fEnabled: boolean): void {

    }
}

class TabInput extends SettingInput {
    tabManager: TabManager;
    subSettings: SettingInput[] = [];
    constructor(parent: HTMLElement, property: string, title: string, radioButtons: RadioButton[], view: DashboardSettingView, callback?: (name: string)=>void, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.tabManager = new TabManager(this.wrapper, title, radioButtons, undefined,(name)=> {
            this.apply(name);
            if (callback)
                callback(name);
        });
    }

    registerSubSetting(subSetting: SettingInput) {
        this.subSettings.push(subSetting);
    }

    update(): void {
        super.update()
        this.tabManager.update(this.getSettingValue());
    }

    resetStyle(): void {
        super.resetStyle();
        for (let setting of this.subSettings) {
            setting.resetStyle();
        }
    }
}

export class SelectInput extends SettingInput {
    element: HTMLInputElement;
    select: HTMLSelectElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, options: string[], values: string[], view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.select = createElement('select', 'editor__container__settings__select', this.wrapper);
        for (let i=0;i<options.length;++i) {
            createElement('option', 'editor__container__settings__unit__option', this.select, options[i]).value = values[i];
        }
        this.select.onchange = () => this.apply(this.select.options[this.select.selectedIndex].value);
    }

    update(): void {
        super.update()
        this.select.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.select.disabled = !fEnabled;
    }
}

export class NumberInput extends SettingInput {
    element: HTMLInputElement;
    property: string;
    setting: string;
    wrapper: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element    = createElement('input', 'spinner editor__container__settings__input', this.wrapper, '', {'type':'number'});
        this.element.onchange       = () => this.onChange();
    }

    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value);

    }
    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class TextInput extends SettingInput{
    element: HTMLInputElement;
    property: string;
    setting: string;
    wrapper: HTMLElement;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element    = createElement('input', 'editor__container__settings__input', this.wrapper, '', {'type':'text'});
        this.element.onchange       = () => this.onChange();
    }

    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value);

    }
    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class ColorInput extends SettingInput {
    element: HTMLInputElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property           = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element            = createElement('input', '',this.wrapper, '', {'type':'color'});
        this.element.style.border       = 'none'
        this.element.style.borderRadius = '4px';
        this.element.style.padding      = '0px';
        this.element.oninput            = () => {
            this.colorCallback(this.element.value)
        }
    }

    colorCallback(color: string) {
        this.apply(color);
    }

    update(): void {
        super.update()
        this.element.value = this.getSettingValue();
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
    }
}

export class SizeInput extends SettingInput {
    element: HTMLInputElement;
    unitsInput: HTMLSelectElement;
    wrapper: HTMLElement;
    property: string;
    setting: string;
    unit: string;
    constructor(name: string, parent: HTMLElement, property: string, units: string[], view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        let container = createElement('div', 'editor__container__settings__wrapper', this.wrapper);
        createElement('div', 'editor__container__settings__name', container, name);
        this.element    = createElement('input', 'editor__container__settings__input', container, '', {'type':'number', 'step':'1'});
        this.unitsInput = createElement('select', 'editor__container__settings__unit', container);
        for (let i=0;i<units.length;++i) {
            createElement('option', 'editor__container__settings__unit__option', this.unitsInput, units[i]);
        }
        this.element.onchange       = () => this.onChange();
        this.unitsInput.onchange    = () => this.onChange();
    }
    onChange() {
        this.apply(this.element.value == '' ? '' : this.element.value + this.unitsInput.options[this.unitsInput.selectedIndex].value, true);
    }

    update(): void {
        super.update()
        let setting = this.getSettingValue();
        if (setting.length > 1) {
            let number          = setting.match(/^[0-9]+/);
            if (number !== null) {
                this.element.value      = number[0].length > 0 ? number[0] : '0';
                let unit                = setting.split(number[0]);
                this.unitsInput.value   = unit[1];
                return;
            }
        }
        this.element.value = ''
        this.unitsInput.selectedIndex = 0;
    }

    setEnabled(fEnabled: boolean): void {
        this.element.disabled = !fEnabled;
        this.unitsInput.disabled = !fEnabled;
    }
}

export class ToggleInput extends SettingInput{
    element: HTMLInputElement;
    wrapper: HTMLElement;
    property: string;
    setting: string;
    constructor(name: string, parent: HTMLElement, property: string, view: DashboardSettingView, tooltip?: string) {
        super(parent, property, view, tooltip);
        this.property   = property;
        createElement('div', 'editor__container__settings__name', this.wrapper, name);
        this.element                = new Switch(this.wrapper).switchCheckbox;
        this.element.onchange       = () => this.onChange();
    }
    onChange() {
        this.apply(this.element.checked ? '1' : '');
    }

    update(): void {
        super.update();
        let value = this.getSettingValue();
        this.element.checked        = value == '1'
    }
}