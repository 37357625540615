import { Attribute } from "../../lib/attributes";
import { RegisterWidget, Widget } from "../../lib/widget";
import template from './accordion.html';

@RegisterWidget({tag: 'se-accordion', template: template, displayName: 'Accordion'})
export class Accordion extends Widget {
    isOpen: boolean = false;
    @Attribute({displayName: 'Open By Default'}) defaultOpen: boolean = false;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected connectedCallback(): void {
        this.isOpen = !this.defaultOpen; // Set to the opposite of what we want
        this.toggle();                   // Then flip it (logic is already in toggle)
    }

    open() {
        this.isOpen = true;
        this.style.setProperty('--accordion-max-height', `${this.scrollHeight}px`);
    }

    close() {
        this.isOpen = false;
        this.style.setProperty('--accordion-max-height', '0px');
    }

    toggle() {
        this.isOpen = !this.isOpen
        if (this.isOpen)
            this.open();
        else
            this.close();
    }
}
