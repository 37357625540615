import Icon from '../../../images/icons/name.svg';
import { Attribute } from '../../lib/attributes';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";
import template from './tagunits.html';
import { UnitsMap } from '../../lib/tagunits';

@RegisterWidget({tag: 'tag-units', displayName: 'Tag Units', icon: Icon, section: 'Tag Information', template: template})
export class TagUnits extends Widget {
    unitsElement: HTMLElement;
    @TagAttribute({displayName: 'Named Tag'}) unitsTag: TagDefinition;
    @Attribute({displayName: 'Display Type'}) displayType: string;

    protected connectedCallback(): void {
        this.unitsElement = this.shadowRoot?.getElementById('units')!;
    }

    protected enliven(): void {
        this.unitsElement.innerHTML = UnitsMap.get(this.unitsTag.tag.units)?.abbrev ?? '';
    }
}