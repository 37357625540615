import {createElement} from './elements';
import {Node} from './node'
import {Widget}         from './widget';
import assert           from './debug';
import { Pump } from './pump';
import {BooleanFill}    from './graph';
import NodeManager      from './nodemanager';
import Localization     from './localization'
import CurveIcon        from './images/icons/pumpcurve.svg';
import SettingsIcon     from './images/icons/settings.svg';
import PrivateIcon      from './images/icons/private.svg';

import { RadioButton, RadioSelector } from './components/radio';
import './pumpbank.css';
import { TagQuality } from './widgets/lib/tag';
import { Role } from './role';
import { ColorAttribute, ColorString } from './widgets/lib/attributes';

interface PumpBankOptions {
    showButtons: boolean;
    showCurveNames: boolean;
    clickCallback: (pump: Node) => void;
}
// ldc, device, parent, pumpSystem, dpo, fAssetManagement
export class PumpBank {
    pumpSystemNode: Node;
    dpoFolder: Node | undefined;
    startColorIndex: number;
    parent: HTMLElement;
    wrapper: HTMLElement;
    maxShutoffHead: number = 0;
    maxZeroHeadFlow: number = 0;
    pumps: Pump[] = [];
    radios: RadioSelector;
    pumpContainer: HTMLElement;
    options: PumpBankOptions;
    containerMap: Map<Node, HTMLElement> = new Map();
    hasCurves: boolean;
    constructor(pumpSystemNode: Node, parent: HTMLElement, dpoFolder?: Node, startColorIndex: number = 0, options: Partial<PumpBankOptions> = {}) {
        // Create pump panels. Get all the pump folder nodes:
        this.pumpSystemNode     = pumpSystemNode;
        this.dpoFolder          = dpoFolder;
        this.startColorIndex    = startColorIndex
        this.parent             = parent;
        this.wrapper            = createElement('div', 'pump-bank__wrapper', this.parent);
        this.options            = {
            showButtons: true,
            showCurveNames: false,
            clickCallback: (pump: Node)=>{}
        };
        Object.assign(this.options, options);

        this.hasCurves = pumpSystemNode.findByRole(Role.ROLE_MODEL_PUMPSYSTEM).length > 0;
        let buttons: RadioButton[] = [{
            name: 'Settings',
            icon: SettingsIcon,
            displayName: 'Settings',
            selectCallback: ()=> {
                for (let i=0;i<this.pumps.length;i++)
                    this.pumps[i].showSettings();
                dispatchEvent(new Event('resize'));
            }
        },
        {
            name: 'None',
            icon: PrivateIcon,
            displayName: 'None',
            selectCallback: ()=> {
                for (let i=0;i<this.pumps.length;i++)
                    this.pumps[i].showNone();
                dispatchEvent(new Event('resize'));
            }
        },];
        if (this.hasCurves)
            buttons.unshift({
                name: 'Curves',
                icon: CurveIcon,
                displayName: 'Curves',
                selectCallback: ()=> {
                    for (let i=0;i<this.pumps.length;i++)
                        this.pumps[i].showCurves();
                    dispatchEvent(new Event('resize'));
                }
            })
        if (this.options.showButtons) {
            this.radios = new RadioSelector(this.wrapper, {
                buttons: buttons,
                defaultSelection: buttons[0].name
            });
        }

        this.pumpContainer = createElement('div', 'pump-bank__pump-container', this.wrapper);
        this.pumpSystemNode.tree.device.requestPumpTwins(this);
        return this;
    };

    highlightPump(pumpNode: Node) {
        this.containerMap.get(pumpNode)?.setAttribute('highlighted', 'true')
    }

    unhighlightPumps() {
        this.containerMap.forEach((container, node) => container.setAttribute('highlighted', ''))
    }

    onPumpTwinsComplete(pumpTwins) {
        var pumpNodes		        = this.pumpSystemNode.findByRole(Role.ROLE_PUMP);
        var modelFolder             = this.pumpSystemNode.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM);
        let modelNodes: Node[]      = [];
        let targetSpeeds: Node[]    = [];
        let idealSpeeds: Node[]     = [];
        let advisoryError: Node | null = null;
        let displayAdvisory: Node | null = null;
        if (modelFolder)
            modelNodes		    = modelFolder.findByRole(Role.ROLE_MODEL_PUMP);
        if (this.dpoFolder) {
            var fAssetOnly		= this.pumpSystemNode.tree.device.fAssetOnly;
            displayAdvisory	= this.dpoFolder.findChildByRole(Role.ROLE_TLC_DISPLAY_ADVISORY);
            advisoryError	= this.dpoFolder.findChild('AdvisorySpeedError');
            targetSpeeds	= this.pumpSystemNode.tree.findNodesByRole(Role.ROLE_TLC_TARGET_SPEED);
            idealSpeeds		= this.pumpSystemNode.tree.findNodesByRole(Role.ROLE_TLC_IDEAL_SPEED);
            assert(pumpNodes.length == idealSpeeds.length, "Should have found a speed nodes for every pump!");
        }

        if (pumpNodes.length == 0) // no pumps to create:
            return;
        let maxHoas         = 0;
        let maxSpeeds       = 0;
        let maxFaults       = 0;

        for (var i=0, pumpNode; pumpNode=pumpNodes[i]; ++i){	// Create pump panel for each pump:
            let twin = pumpTwins.getTwin(pumpNode);
            if (!twin)
                continue;
            this.maxShutoffHead     = Math.max(this.maxShutoffHead, twin.shutoffHead);
            this.maxZeroHeadFlow    = Math.max(this.maxZeroHeadFlow, twin.zeroHeadFlow);
        }

        //var maxHOAs			= 0;								// Find the biggest number of HOAs that a pump has
        for (let i=0, pumpNode: Node; pumpNode=pumpNodes[i]; ++i){	// Create pump panel for each pump:
            //createElement('pump-card', '',this.pumpContainer, '', {pumpFolder: {tag: pumpNode}, modelFolder: {tag: modelNodes[i]}, color: new ColorAttribute((this.startColorIndex === undefined ? BooleanFill.colorStrings[i] : BooleanFill.colorStrings[this.startColorIndex + i]) as unknown as ColorString)}).setAttribute('work-dir', pumpNode.absolutePath);

            // Create the pump panel:
            let pumpWrapper = createElement('div', 'pumpWrapper', this.pumpContainer);
            pumpWrapper.onclick = () => this.options.clickCallback(pumpNode);
            let modelPump = modelNodes[i] && modelNodes[i]?.name == pumpNode.name ? modelNodes[i] : undefined;
            let color = this.startColorIndex === undefined ? BooleanFill.colorStrings[i] : BooleanFill.colorStrings[this.startColorIndex + i];
            let pump = new Pump (pumpNode, pumpWrapper, color, i, modelPump, this, this.options.showCurveNames ? pumpNode.tree.device.pumpTwins.getTwin(pumpNode)?.name ?? '' : '');

            if (this.dpoFolder) {
                let pumpBadgeDiv = createElement('div', 'pump-bank__badge');
			    pumpWrapper.insertChildAt(pumpBadgeDiv, 0);
			    new PumpSyncBadge(pumpBadgeDiv, targetSpeeds[i], idealSpeeds[i], displayAdvisory, pumpNodes[i], advisoryError);
            }
            maxHoas     = Math.max(maxHoas, pump.maxHoas);	// Determine the max number of HOAs by any one pump
            maxSpeeds   = Math.max(maxSpeeds, pump.maxSpeeds);
            maxFaults   = Math.max(maxFaults, pump.maxFaults);
            this.pumps.push(pump);	// Add the pump object to the pumps vector:
            this.containerMap.set(pumpNode, pumpWrapper);
        }

        if (!this.hasCurves) {
            for (let i=0;i<this.pumps.length;i++)
                this.pumps[i].showSettings();
            this.resize();
        }

        /*
        for (let i=0;i<this.pumps.length;i++) {
            this.pumps[i].hoaDiv.style.height   = (maxHoas * 28 + 14) + 'px';
            this.pumps[i].speedDiv.style.height = (maxSpeeds * 28 + 14) + 'px';
            this.pumps[i].faultDiv.style.height = (maxFaults * 28 + 14) + 'px';
        }
        if (!this.hasCurves)
            for (let i=0;i<this.pumps.length;i++)
                this.pumps[i].showSettings();
        this.resize();
        */
    }

    resize() {
        let fSmall = window.innerWidth < 620 || window.innerHeight < 420;
        for (let i=0; i<this.pumps.length; i++) {
            this.pumps[i].resize(fSmall);
        }
    }

    destroy() {
        this.wrapper.destroyWidgets(true);
        this.parent.removeChild(this.wrapper)
    }
};

// This is a little helper widget to fill out the badge on each pump
export class PumpSyncBadge extends Widget {
    badge: HTMLElement;
    nodeManager: NodeManager;
    targetSpeed: Node;
    idealSpeed: Node;
    displayAdvisory: Node;
    advisoryError: Node;
    overallHOA: Node;
    fSpeedControlled: Node;
    fFixedSpeed: boolean;
    constructor(badge: HTMLElement, targetSpeed, idealSpeed, displayAdvisory, pumpNode, advisoryError) {
        super();
        this.badge			= badge;		// Save the reference to the badge (which should have been created above)
        this.registerAsWidget(badge);		// Register with the element like a good little widget
        this.badge.style.opacity = '0';     // Hide the badge by default

        this.nodeManager		= new NodeManager(this);
        this.targetSpeed		= this.nodeManager.addNode(targetSpeed);	// Save the nodes we need
        this.idealSpeed			= this.nodeManager.addNode(idealSpeed);
        this.displayAdvisory	= this.nodeManager.addNode(displayAdvisory);
        this.advisoryError		= advisoryError ? this.nodeManager.addNode(advisoryError) : null;
        this.overallHOA			= this.nodeManager.addNodeByRole(pumpNode, Role.ROLE_PUMP_OVERALL_HOA);
        this.fSpeedControlled	= this.nodeManager.addNodeByRole(pumpNode, Role.ROLE_AUTO_SPEED_CONTROLLABLE);
        this.fFixedSpeed		= pumpNode.findChildByRole(Role.ROLE_ACT_SPEED) === null;
        this.nodeManager.subscribe();
    };

    update(node) {
        if (this.idealSpeed.quality != TagQuality.TQ_GOOD || this.targetSpeed.quality != TagQuality.TQ_GOOD ||
            node.quality != TagQuality.TQ_GOOD || this.fFixedSpeed === undefined)		// Bad quality on this node
            return;

        var idealSpeed	= this.idealSpeed.getValue();	// Get the ideal speed the 100% DPO mode would run at
        var targetSpeed = this.targetSpeed.getValue();
        var speedError	= this.advisoryError ? this.advisoryError.getValue() || 0.05 : 0.05;
        if (!this.displayAdvisory.getValue())
            this.badge.style.opacity = '0';
        else if (Math.abs(targetSpeed - idealSpeed) < speedError) {	// We have an actual speed and the speed is close to the ideal speed
            // this.badge.classList.add('hide');		// Hide the badge, the pump is in the correct spot
            this.badge.style.opacity = '0';
            //this.badge.style.backgroundColor = 'var(--color-primary)';
            //this.badge.innerHTML = '✓';
        } else {
            this.badge.style.opacity = '1';
            // this.badge.classList.remove('hide');	// Show the badge, the pump is wrong
            this.badge.style.backgroundColor = 'var(--color-red-8)';
            this.badge.innerHTML = idealSpeed == 0 ? Localization.toLocal('Stop') : this.fFixedSpeed ? Localization.toLocal('Run') : idealSpeed.toFixed(1) + " " + this.idealSpeed.getUnitsText();

            var fStartControlled = (this.overallHOA.getValue() == 3) && ((idealSpeed > 0) != (targetSpeed > 0));
            var fSpeedControlled = this.fSpeedControlled.getValue() && ((idealSpeed > 0) == (targetSpeed > 0));
            this.badge.setAttribute('controlled', (fStartControlled || fSpeedControlled).toString());
        }
    };

    destroy = function() {
        this.nodeManager.destroy();
        this.unregisterAsWidget();	// Unregister like a good little widget
    };
};
