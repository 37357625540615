import { GenericGraph } from "../graph";
import { RegisterWidget, Widget } from "./lib/widget";
import { Attribute } from "./lib/attributes";
import * as echarts from 'echarts';
import { createElement } from "../elements";
import Owner from "../../owner";
import { TagSetAttribute } from "./lib/tag";
import LineChartIcon from '../images/icons/line-chart.svg';
import type { Tag, TagDefinition } from "./lib/tag";

const template = /*html*/`
<style>
:host {
    display: block;
    height: 400px;
    width: 400px;
}
.wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.control {
    padding: 2px 5px;
    display: flex;
    justify-content: space-between;
}
.graph {
    flex: 1;
    width: 100%;
    overflow: hidden;
}
.legend {
    display: flex;
    flex-wrap: wrap;
    overflow-x: auto;
}
.legendEntry{
    /*border: 1px #A2A4A4 solid;*/
    height: 22px;
    margin-right: 2px;
    margin-bottom: 4px;
    display:flex;
    align-items: center;
    white-space: nowrap;
    border-radius: 11px;
    padding: 2px 6px 2px 6px;
    color: var(--color-inverseOnSurface);
    font-weight: 500;
    font-size: 14px;
}
.legendEntry[hasnode=true] {
    cursor: pointer !important;
}
.legendEntry[gray=true]{
    color: var(--color-inverseOnSurface-2);
}
.dygraph-legend {
    position:		absolute;
    height:			14px;
    text-align:		center;
    pointer-events: none;
    line-height:	14px;
    font-size:		14px;
    border-radius:	3px;
    background:		var(--color-surfaceContainerLowest);
    color:          var(--color-onSurfaceContainer);
    z-index:		15;
    box-sizing: 	content-box;
}
.radio {
    height: 24px;
    font-size: 0.75em;
}
</style>
<div class="wrapper">
    <div class="control"></div>
    <div class="graph"></div>
    <div class="legend"></div>
</div>
`.trim()

@RegisterWidget({tag: 'line-graph', displayName: 'Line Graph', template: template, icon: LineChartIcon, section: 'Charts'})
export class LineGraph extends Widget {
    @Attribute({displayName: 'Make Interactive'}) isInteractive: boolean = true;
    @Attribute({displayName: 'Date Selection'}) dateSelection: boolean = true;
    @Attribute({displayName: 'Highlight on Hover'}) highlightHover: boolean = true;
    @Attribute({
        displayName: 'Default Range',
        typeModifier: 'select',
        typeConfig: {
            displayNames: ['Hour','Day','Week','Year'],
            values: ['3600000','86400000','604800000','31540000000']
        }
    }) defaultRange: number = 86400000;
    @Attribute({displayName: 'Show Legend'}) showLegend: boolean = true;

    @TagSetAttribute({displayName: 'Live Data Tags', requiresHistorical: true, isOptional: true, attributes: [
        {
            section: 'Display',
            id: 'color',
            type: 'String',
            displayName: 'Color',
            typeModifier: 'color'
        }
    ]}) liveValueTags: TagDefinition[] = []

    isLive: boolean = true;
    endDate: Date = new Date();
    deltaTime: 864000;
    startDate: Date
    graphWrapper: HTMLElement;
    graph: GenericGraph;
    graphRow: HTMLElement;
    controlRow: HTMLElement;
    legendRow: HTMLElement;
    chart: echarts.ECharts;

    protected connectedCallback(): void {
        this.graphRow = this.shadowRoot!.querySelector('.graph') as HTMLElement;
        this.controlRow = this.shadowRoot!.querySelector('.control') as HTMLElement;
        this.legendRow = this.shadowRoot!.querySelector('.legend') as HTMLElement;
    }

    protected enliven() {
        if (this.dateSelection) {
            let radio = createElement('radio-button', 'radio', this.controlRow, '', {valueMap: {60000:'Mi',3600000:'H',86400000:'D',604800000:'W',2628000000:'Mo',31540000000:'Yr',1000000000000:'Custom'}, value: this.defaultRange});

            radio.onchange = () => {
                if (this.graph.interval != null) {					// For the custom case
                    let dates = this.graph.graph?.xAxisRange();	// Get how much the window has selected
                    dates[0] = dates[1] - radio.value;	// Start at the same end point, but graph the selected interval

                    let fUpdating = this.graph.fUpdating;		// Store whether or not the graph was live
                    this.graph.updateWindow(dates[0], dates[1]);

                    this.graph.fUpdating = fUpdating;			    // Reset this variable (that was unset in _onDraw)
                    if (fUpdating)									// If we were updating @ts-ignore
                        this.graph._startTimer(this.graph.interval);	// Start the timer again to call us again to keep updating
                }
                if (radio.value != 1000000000000 && !this.graph.live)            // if we click our custom button
                    this.graph.makeInteractive(true)             // make sure this graph is interactive
            }
        }
        if (this.isInteractive) {
            let radio = createElement('radio-button', 'radio', this.controlRow, '', {valueMap: {0:'Live',1:'Static'}, value: 0});
            radio.onchange = () => {
                if (radio.value == 0)
                    this.graph.makeLive();
                else
                    this.graph.makeStatic();
            }
        }

        let end = new Date();
        let start: Date = new Date(new Date().getTime() - this.defaultRange);

        this.graph = new GenericGraph(Owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight, start, end, true, undefined, undefined, {
            stophighlighting: !this.highlightHover
        });

        if (this.highlightHover)
            this.graph.createLegend();

        if (this.isInteractive)
            this.graph.makeInteractive(true);	// Let them pan and zoom and such

        if (this.highlightHover)
            this.graph.createLegend();		// Make a legend happen

        this.graph.axesCanChange();			// Axis can change between attached nodes

        this.addTags();
        this.graph.requestDataForAllDevices(start.getTime(), end.getTime());
        this.onResize();
    }

    addTags() {
        for (let tagDef of this.liveValueTags) {
            this.addTag(tagDef.tag, tagDef.attributes, true);
        }
    }

    addTag(tag: Tag, attributes: {[key: string] : string} = {}, fDelayRequest: boolean = false) {
        let end = new Date();
        let start: Date = new Date(new Date().getTime() - this.defaultRange);
        //@ts-ignore TODO: Remove this ignore once graphs use tags instead of nodes
        this.graph.addNode(tag, true, tag.name, attributes['color'], undefined, undefined, undefined);
        if (!fDelayRequest)
            this.graph.requestDataForAllDevices(start.getTime(), end.getTime());
    }

    onResize() {
        requestAnimationFrame(() => {
            if (this.graph && this.graph) {
                this.graph.resize(this.graphRow.clientWidth, this.graphRow.clientHeight);
            }
        })
    }

    protected onDisconnect(): void {
        if (this.graph)
            this.graph.destroy();
    }
}