////////////////////////////////////////////////////////////////////////////////
//
//                            DATASTRUCTURE HELPERS
//

//////////////////////////////
//          ARRAY

//Extends an array ("into") in-place with items from another ("from").
// and returns it.
export function extend(into,from) {
  for (const item of from) into.push(item);
  return into;
}

//Returns a new array whose elements are those of a followed by those of b.
export function concat(a,b) {
  return extend( extend([], a), b );
}

//////////////////////////////
//           SET
export var Set = {};

Set.union = function(a,b) {
  const result = new Set(a);
  b.forEach( (item) => result.add(item) );
  return result;
}

Set.intersection = function(a,b) {
  const result = new Set();
  a.forEach( (item) => {
    if (b.has(item))
      result.add(item)
  });
  return result;
}

Set.extend = function(into,from) {
  from.forEach( (item) => into.add(item) );
  return into;
}


//Delayed unshift queue class: because array.shift/unshift is O(n)
// Works in amortized constant time.
// based on code by Stephen Morley (CC0 license)
// (rewritten to use ES6 features)
export class Queue {
  constructor() {
    this.queue = [];
    this.head = 0;
  }

  get length() {
    return this.queue.length - this.head;
  }

  get empty()  {
    return this.length === 0;
  }

  enqueue(item) {
    this.queue.push(item);
  }

  dequeue() {
    if (this.queue.length === 0)
      return undefined;

    //the item to be returned
    const result = this.queue[this.head];
    this.head += 1; //advance head

    //If the array is more than 50% junk space,
    // remove the junk items.
    if (this.head * 2 > this.queue.length) {
      this.queue.splice(0,this.head);
      this.head = 0;
    }

    return result;
  }

  //peeks [depth] into the queue and returns an item
  //without removing it. Default depth: 0, which returns
  //the item that will be dequeued next.
  peek(depth) {
    depth = depth !== undefined
              ? depth
              : 0;

    const index = depth + this.head;
    return (this.queue.length > index)
              ? this.queue[index]
              : undefined;
  }


  //changes the most-recently-pushed item
  swap(newValue) {
    this.queue[this.queue.length-1] = newValue;
  }
}



////////////////////////////////////////////////////////////////////////////////
//
//                            CALLBACK MECHANISMS
//


//Fires every callback in a callback map.
export function fire(callbackMap, ...args) {
  //Cool note: we iterate over entries instead of the map itself so that the
  //map can be added to during callbacks without triggering infinite loops.
  const array = Array.from(callbackMap.entries()); //makes a copy
  for (const pair of array) {
    const [listener,callback] = pair;
    callback.apply(listener,args);
  }
}

//A counter with callback support. Used heavily by the navigation system.
export class Counter {
  constructor(initial) {
    this._count = initial
      ? initial
      : 0;

    this.onNone = new Map(); //callbacks for when we go 1 -> 0
    this.onSome = new Map(); //callbacks for when we go 0 -> 1
  }

  ping(listener) {
    if (listener){
        const noneCallback = this.onNone.get(listener);
        if (noneCallback && this.none)
          noneCallback.call(listener);

        const someCallback = this.onSome.get(listener);
        if (someCallback && this.some)
          someCallback.call(listener);
      } else {
        if (this.none) SE.fire(this.onNone);
        else if (this.some) SE.fire(this.onSome);
      }
  }

  get count() {
    return this._count;
  }

  get some() {
    return this._count > 0;
  }

  get none() {
    return this._count === 0;
  }

  //Probably best to not implement this method at all.
  // set count(value) {
  //   assert("Use Counter.inc() and Counter.dec()!");
  // }

  inc(...callbackArgs) {
    this._count += 1;
    if (this._count === 1)
      SE.fire(this.onSome, ...callbackArgs);
  }

  dec(...callbackArgs) {
    this._count -= 1;
    if (this._count === 0)
      SE.fire(this.onNone, ...callbackArgs);

    if (this._count < 0)
      this._count = 0;
  }

  valueOf() {
    return this._count;
  }

  toString() {
    return this._count.toString();
  }
}

export class WatchedMapWindow {
  constructor(wmap) {
    this.watchedKeys = new Map(); // maps key -> Map() from listener to callback
    this.watchers = new Map();    // maps listener -> Set() of keys
    this.wmap = null;
    if (wmap)
      this.attach(wmap);
  }

  get(key) {
    if (this.wmap)
      return this.wmap.get(key);
    else
      return undefined;
  }

  attach(wmap) {
    this.wmap = wmap;
    wmap.windows.add(this);
  }

  detach(wmap) {
    this.wmap = null;
    wmap.windows.remove(this);
  }

  move(wmap) {
    if (this.wmap)
      this.detach(this.wmap);
    this.attach(wmap);
  }

  //registers a listener and callback to be fired when any of the rest-arguments
  //are set or cleared as keys in this map.
  watch(listener,callback,...keys) {
    const oldListenerKeys = this.watchers.get(listener);

    //add to this.watchers
    if (oldListenerKeys) {
      SE.Set.extend(oldListenerKeys, keys);
    } else {
      this.watchers.set(listener, new Set(keys));
    }

    //add to this.watchedKeys
    for (const key of this.keys) {
      const onValueChange = this.watchedKeys.has(key)
                          ? this.watchedKeys.get(key)
                          : this.watchedKeys.set(key, new Map());
      onValueChange.set(listener,callback);
    }
  }

  //clears the given listener so that it will no longer be called back
  avert(listener) {
    const keys = this.watchers.get(listener);
    if (!keys)
      return;

    for (const key of keys) {
      this.watchedKeys.get(key).delete(listener);
    }

    this.watchers.delete(listener);
  }

  fire(key) {
    const map = this.watchedKeys.get(key);
    if (map) {
      SE.fire(map,key);
    }
  }
}

//wraps map with the ability to fire callbacks
export class WatchedMap extends Map {
  constructor() {
    super();
    this.windows = new Set();
    this.canonicalWindow = new SE.WatchedMapWindow(this);
  }

  //Watch and avert simply call watch/avert on our canonical window.
  watch(listener,callback,...keys) {

  }
  avert() {

  }

  fire(key) {
  }

  //Removes all keys and informs listeners that this has happened.
  clear() {

    super.clear();
  }
}
