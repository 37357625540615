import { Widget }       from "./widget";
import {createElement}  from './elements';
import Localization     from './localization';
import { NodeFlags, NodeQuality }         from './node';
import assert           from './debug';
import owner            from '../owner';
import './pushbutton.css'

// Creates a widget that is disabled when false and is a reset when the node is true.
export default class PushButton extends Widget {
    constructor(element, opts) {
        super();
        assert (element, 'element is a required property');
        this.element = element;
        this.registerAsWidget(element);			// Register this element as a widget
        this.button = createElement('input', 'se-button', element);
        this.button.type = 'button';
        this.button.value = Localization.toLocal('Reset');
        
        this.node = opts.node;
        if (opts.name)
            this.button.value = opts.name;
        if (opts.class)
            this.button.classList.add(opts.class);
        if (this.node.couldBeWritten()) { 			// Not a read-only node and they have the permission to write it one day:
            this.button.onclick = this.onClick.bind(this);
        }
        else    
            this.button.disabled = true;
        this.fFlip = opts.fFlip ?? false;
        this.node.subscribe(this, this.element);
    };

    onClick() {
        if (owner.settingsManager) {
            owner.settingsManager.pushPending(this.node, this.fFlip, this);
            this.button.classList.add('radio__depressed');	// Add class for formatting, updating disabled flag based on user write permission changes
        }
        else if(this.node.hasWritePermission()) 
            this.node.setValue(this.fFlip); // ?? false
    };

    update(node) {
        assert(node == this.node, 'Only one node should call PushButton.update()');            
        this.button.classList.remove('radio__depressed');	// Add class for formatting, updating disabled flag based on user write permission changes
        this.button.disabled = !(node.couldBeWritten() && node.quality == NodeQuality.NQ_GOOD && (this.fFlip ? !node.getValue(this.fPending) : node.getValue(this.fPending)));
    };

    destroy = function() {
        this.node.unsubscribe(this);
        this.element.removeChildren();
        this.unregisterAsWidget();
    };
};
