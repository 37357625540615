import { RegisterWidget, Widget } from "./lib/widget";
import { TagAttribute } from "./lib/tag";
import { Attribute } from "./lib/attributes";
import NumericIcon from '../images/icons/value.svg';
import TextIcon from '../images/icons/settings.svg'
import type { Tag, TagDefinition } from "./lib/tag";

const ValueID = 'val';
const UnitsID = 'units';

const template = /*html*/`
    <style>
    :host {
        display: flex;
        text-align: right;
        align-items: center;
        line-height: 1em;
    }
    #${ValueID}:empty {
        min-width:48px;
    }
    #${UnitsID} {
        display: flex;
        font-size: 0.75em;
        opacity: 0.7;
        white-space: pre;
    }
    #${UnitsID}:not(:empty)::before {
        white-space: pre;
        content: " ";
    }
    </style>
    <div id=${ValueID}>⠀</div>
    <div id=${UnitsID}></div>
`

@RegisterWidget({tag: 'digital-gauge', displayName: 'Digital Gauge'})
export class DigitalGauge extends Widget {
    valueElement: HTMLElement;
    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: string]: string};

    @TagAttribute({
        displayName: 'Value Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric','boolean', 'string']
    }) valueTag: TagDefinition

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        this.valueElement = this.shadowRoot!.getElementById(ValueID)!;
    }

    update(tag: Tag) {
        let value = tag.getValue(this);
        this.valueElement.textContent = (this.valueOverride && this.valueOverride[value]) ?? tag.getFormattedText(false) ?? '';
    }
}

@RegisterWidget({tag: 'numeric-gauge', displayName: 'Numeric Gauge', icon: NumericIcon, section: 'Status'})
export class NumericGauge extends DigitalGauge {
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;
    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string};

    @TagAttribute({
        displayName: 'Numeric Tag',
        shouldSubscribe: true,
        supportedTypes: ['numeric']
    }) valueTag: TagDefinition

    protected enliven(): void {
        this.valueElement = this.shadowRoot!.getElementById(ValueID)!;
        if (this.showUnits)
        {
            let unitsElement = document.createElement('div');
            unitsElement.id = UnitsID;
            unitsElement.textContent = ` ${this.valueTag.tag.unitsText}` ?? '';
            this.shadowRoot?.appendChild(unitsElement);
        }
    }

    update(tag: Tag) {
        let numericValue = tag.getValue(this);
        this.valueElement.textContent = (this.valueOverride && this.valueOverride[numericValue]) ?? tag.getFormattedText(false) ?? '';
    }
}


//@RegisterWidget({tag: 'kubota-mbr-sub-state', displayName: 'MBR State', roles: ['Kubota_MBR_SubState'], icon: TextIcon, section: 'Kubota'})
//export class KubotaMBRSubState extends NumericGauge {
//    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string} = {
//        0: 'Offline',
//        10: 'Low Flow',
//        11: 'Medium Flow',
//        12: 'High Flow',
//        13: 'Relax',
//        14: 'DifCln',
//        15: 'Relax - Waiting for UV',
//        20: 'Sleep',
//        21: 'Nap',
//        22: 'Alarm Nap',
//        30: 'CIP',
//        99: 'Waiting for Power'
//    };
//}

@RegisterWidget({tag: 'kubota-pa-mode', displayName: 'PA State', roles: ['Kubota_PA_Mode'], icon: TextIcon, section: 'Kubota'})
export class KubotaPAMode extends NumericGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string} = {
        0: 'Offline',
        1: 'ConstSpd',
        2: 'Pulse',
        3: 'Auto'
    };
}


@RegisterWidget({tag: 'kubota-level-state', displayName: 'Level State', roles: ['Kubota_Level_State'], icon: TextIcon, section: 'Kubota'})
export class KubotaLevelState extends NumericGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string} = {
        0: 'Stop',
        1: 'Low Flow',
        2: 'Med Flow',
        3: 'High Flow'
    };
}

@RegisterWidget({tag: 'open-close-value', displayName: 'Open Close Value', roles: ['OpenClose'], icon: TextIcon, section: 'Values'})
export class OpenClose extends DigitalGauge {
    @Attribute({displayName: 'Value Override'}) valueOverride: {[value: number]: string} = {
        0: 'Closed',
        1: 'Open'
    };

    update(tag: Tag) {
        let numericValue = tag.getValue(this);
        this.valueElement.textContent = (this.valueOverride && this.valueOverride[numericValue]) ?? tag.getFormattedText(false) ?? '';
    }
}
