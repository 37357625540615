import owner                            from '../../../owner';
import { Alarm, AlarmSet, AlarmTypes }  from '../../alarm';
import assert                           from '../../debug';
import { Device }                       from '../../device';
import { createElement }                from '../../elements';
import EditorPage                       from '../../pages/editorpage';
import { Gizmo }                        from './gizmo';
import { Chart, registerables }         from 'chart.js';
import { StyleCategories }              from '../../views/dashboardstyleview';
import 'chartjs-adapter-moment';

Chart.register(...registerables);

class HistoricalAlarmGizmo extends Gizmo {
    device:         Device;
    alarmSet:       AlarmSet;
    startTime:      number;
    endTime:        number;
    trendTime:      number;
    alarms:         Alarm[] = [];
    trendAlarms:    Alarm[] = [];
    countElement:   HTMLElement;
    wrapper:        HTMLElement;
    latestTime:     number;
    public connectedCallback(): void {
        super.connectedCallback();
        if (!this.recipe.settings['Period'])
            this.recipe.settings['Period'] = '604800';
        if (!this.recipe.settings['Device'])
            return;
        this.device         = owner.ldc.devices.getByKey(this.recipe.settings['Device'])!;
        this.wrapper        = createElement('div', 'analytics-widget__wrapper', this)
        this.countElement   = createElement('div', '', this.wrapper)
        assert(this.device);
        this.alarmSet = this.device.alarms;
        this.requestAlarms();
    }

    requestAlarms() {
        let end         = new Date();
        this.endTime    = end.getTime() * 1000;
        this.startTime  = this.endTime - (1000 * 1000 * this.recipe.settings['Period']);
        this.trendTime  = this.endTime - (this.recipe.settings['Trend'] ? 2 : 1) * (1000 * 1000 * this.recipe.settings['Period'])
        this.alarmSet.registerCallback(this);						// Get a callback on new alarms and events now that we've set up everything
        this.alarmSet.requestHistorical(this.endTime, true, 100);
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        //this.createSizeSettings(editor.toolTabs.getSectionByName('Style'));
        this.createDeviceSettings(editor.toolTabs.getSectionByName('Settings'));
        this.createSettingSelectInput('Period', 'Period', ['Day', 'Week', 'Month'], ['86400', '604800', '2592000'], '86400');
    }

    createDeviceSettings(parent: HTMLElement) {
        let names: string[]   = [];
        let keys: string[]    = [];
        for (let i=0;i<owner.sortedDevices.length;++i) {
            let device = owner.sortedDevices[i];
            names.push(device.siteName);
            keys.push(device.key)
        }
        this.createSettingSelectInput('Device', 'Device', names, keys, keys[0]);
    }

    onAlarmAdded() {}
    onAlarmChanged() {}
    onAlarmRemoved() {}
    endAlarmCommand() {}

    onHistorical(ordered: any[], latestTime: number, fCurrent: boolean, fBefore: boolean, fUnprompted: boolean) {	// Got historical alarms back
        if (latestTime == this.latestTime) // Already have this data. Probably someone else asking for it
            return;
        this.latestTime = latestTime;
        if ('Trend' in this.recipe.settings) {
            let trendAlarms = ordered.filter(event=>{return (((event.type == AlarmTypes.ACTIVATED) || (event.type == AlarmTypes.DEACTIVATED)) && event.time >= this.trendTime && event.time < this.startTime)});
            this.trendAlarms.push(...trendAlarms)
        }
        let alarms = ordered.filter(event=>{return (((event.type == AlarmTypes.ACTIVATED) || (event.type == AlarmTypes.DEACTIVATED)) && event.time >= this.startTime)});

        this.alarms.push(...alarms);
        if (ordered.length == 0 || ordered[ordered.length - 1].time < ('Trend' in this.recipe.settings ? this.trendTime : this.startTime)) {
            this.onHistoricalAlarmsFinished()
        }
        else {
            this.alarmSet.requestHistorical(ordered[ordered.length - 1].time, true, 100);
        }
    }

    onDeviceChanged(key: string) {
        this.alarms = [];
        if (this.alarmSet)
            this.alarmSet.removeCallback(this)
        this.device = owner.ldc.devices.getByKey(this.recipe.settings['Device'])!;
        this.alarmSet = this.device.alarms;
        assert(this.device);
        this.recipe.settings.set('Device',this.recipe.settings['Device']);
        this.requestAlarms();
    }

    onHistoricalAlarmsFinished() {
        this.alarmSet.removeCallback(this)
    }

    rebuild() {
        this.alarms = [];
        if (this.alarmSet)
            this.alarmSet.removeCallback(this)
        this.device = owner.ldc.devices.getByKey(this.recipe.settings['Device'])!;
        this.alarmSet = this.device.alarms;
        assert(this.device);
        this.requestAlarms();
    }
}

export class AlarmCountGizmo extends HistoricalAlarmGizmo {
    countElement: HTMLElement;
    trendWrapper: HTMLElement;
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS, StyleCategories.POSITION, StyleCategories.TYPOGRAPHY];
        if (!('Trend' in this.recipe.settings))
            this.recipe.settings['Trend']  = true;
    }

    createTrend(parent: HTMLElement, value: number) {
        let icon            = createElement('div', 'analytics-widget__trend-wrapper__icon', this.trendWrapper, '▲');
        let text            = createElement('div', 'analytics-widget__trend-wrapper__text', this.trendWrapper, value.toFixed(2) + '%');
        this.trendWrapper.style.color = value > 0 ? 'green' : 'red'
        icon.style.transform = value > 0 ? 'rotate(180deg)' : '';
        //this.refreshStyles();
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
        this.createSettingNumberInput('sev-min', 'Minimum Severity', 1);
        this.createSettingNumberInput('sev-max', 'Maximum Severity', 1);
    }

    onHistoricalAlarmsFinished() {
        super.onHistoricalAlarmsFinished();
        if (!this.device.configuredAlarms.isInitialized)
            setTimeout(()=>this.onHistoricalAlarmsFinished(), 1000)
        else
            this.buildAlarms()
    }

    buildAlarms() {
        this.wrapper.removeChildren();
        this.trendWrapper   = createElement('div', 'analytics-widget__trend-wrapper', this.wrapper);
        this.countElement = createElement('div', '', this.wrapper);
        let activated = this.alarms.filter(event=>{return event.type == AlarmTypes.ACTIVATED});
        let trendActivated = this.trendAlarms.filter(event=>{return event.type == AlarmTypes.ACTIVATED});
        if ('sev-max' in this.recipe.settings || 'sev-min' in this.recipe.settings)
        {
            let minSetting  = this.recipe.settings['sev-min']
            let maxSetting  = this.recipe.settings['sev-max']
            let minSeverity = minSetting == '' ? 0 : parseInt(minSetting ?? '0');
            let maxSeverity = maxSetting == '' ? 1000 : parseInt(maxSetting ?? '1000');
            activated = activated.filter(alarm => {
                let configured = this.alarmSet.findConfigured(alarm);
                if (configured)
                    return configured.severity >= minSeverity && configured.severity <= maxSeverity;
                //else
                //    throw(new Error('Have an Alarm with no ConfiguredAlarm'))
            })
            trendActivated = trendActivated.filter(alarm => {
                let configured = this.alarmSet.findConfigured(alarm);
                if (configured)
                    return configured.severity >= minSeverity && configured.severity <= maxSeverity;
            })
        }
        let alarmCount = activated.length;
        let trendCount = trendActivated.length;
        this.countElement.textContent = alarmCount.toString();
        if (this.recipe.settings['Trend'])
            this.createTrend(this.trendWrapper, (alarmCount - trendCount) / trendCount)
    }
}

export class AlarmTimelineGizmo extends HistoricalAlarmGizmo {
    public connectedCallback(): void {
        super.connectedCallback();
        this.allowableStyles    = [StyleCategories.POSITION, StyleCategories.BACKGROUND, StyleCategories.BORDER, StyleCategories.DIMENSIONS];
    }

    applyDefaults(): void {
        if (this.getStyleSetting('width', 0) === undefined) {
            this.modifyStyleSetting('width', 0, '100%');
        }
        if (this.getStyleSetting('height', 0) === undefined) {
            this.modifyStyleSetting('height', 0, '120px');
        }
    }

    populateSettings(editor: EditorPage): void {
        super.populateSettings(editor);
    }

    onHistoricalAlarmsFinished() {
        super.onHistoricalAlarmsFinished();
        this.wrapper.removeChildren();
        let alarmActivations    = this.alarms.filter(event=>{return event.type == AlarmTypes.ACTIVATED});
        let alarmDeactivations  = this.alarms.filter(event=>{return event.type == AlarmTypes.DEACTIVATED});
        let alarmMap: Map<string, any[]> = new Map();
        for (let i=0; i< alarmActivations.length;++i) {
            let activationTime      = alarmActivations[i].time!
            let deactivationTime    = this.endTime
            let deactivationAlarm   = alarmDeactivations.find(element=>element.activated == activationTime);
            if (deactivationAlarm)
                deactivationTime = deactivationAlarm.time!;

            let activationTimes = {
                activated: Math.round(activationTime / 1000),
                deactivated: Math.round(deactivationTime / 1000)
            }
            //@ts-ignore
            if (alarmMap.has(alarmActivations[i].message)) {
                //@ts-ignore
                alarmMap.get(alarmActivations[i].message).push(activationTimes)
            }
            else {
                //@ts-ignore
                alarmMap.set(alarmActivations[i].message, [activationTimes])
            }
        }
        let element = createElement('canvas', '', this.wrapper);
        let ctx = element.getContext('2d')!;
        let dataLabels: any[] = [];
        let dataSets: any[] = [];

        let alarmNames = Array.from(alarmMap.keys());
        for (let i=0;i<alarmNames.length;++i) {
            let timestamps = alarmMap.get(alarmNames[i])!;
            dataSets.push({
                label: '',
                backgroundColor: 'rgba(0,0,0,0)',
                strokeColor: 'rgba(0,0,0,0)',
                data: [{
                    x: this.startTime / 1000,
                    y: dataSets.length,
                    tooltip: false,
                }],
                fill: true,
                stack: alarmNames[i],
            })
            let lastTime = this.startTime / 1000;
            for (let j=0;j<timestamps.length;++j) {
                let timestamp = timestamps[j];
                dataSets.push({
                    label: '',
                    backgroundColor: 'rgba(0,0,0,0)',
                    strokeColor: 'rgba(1,1,1,1)',
                    data: [{
                        x: timestamp.activated - lastTime,
                        y: dataSets.length,
                        tooltip: false,
                    }],
                    fill: true,
                    stack: alarmNames[i],
                },
                {
                    label: alarmNames[i],
                    backgroundColor: owner.colors.hex(`--color-graph-${i + 1}`),
                    strokeColor: 'rgba(0,0,0,0)',
                    data: [{
                        x: timestamp.deactivated - timestamp.activated,
                        y: dataSets.length,
                        tooltip: true,
                        activationTime: timestamp.activated
                    }],
                    fill: true,
                    stack: alarmNames[i],
                })
                lastTime = timestamp.deactivated
            };
        }
        dataLabels.push(this.endTime / 1000)
        new Chart(ctx, {
            type: 'bar',
            data: {
                labels: dataLabels,
                datasets: dataSets
            },
            options: {

                indexAxis: 'y',
                elements: {
                    point:{
                        radius: 0
                    }
                },
                scales: {
                    x: {
                        min: this.startTime / 1000,
                        max: this.endTime / 1000,
                        stacked: true,
                        type: 'time',
                        time: {
                            parser: 'MM/DD/YYYY',
                            tooltipFormat: '',
                            unit: 'day',
                            displayFormats: {
                                'day': 'MM/DD/YYYY'
                            }
                        }
                    },
                    y: {
                        labels: [''],
                        stacked: true
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: (tooltipItem) => {
                                //@ts-ignore
                                if (tooltipItem.dataset.data[tooltipItem.dataIndex].tooltip) {
                                    //@ts-ignore
                                    return tooltipItem.dataset.label + ' ' + new Date(tooltipItem.dataset.data[tooltipItem.dataIndex].activationTime).toLocaleString();
                                }
                                return ''
                            },
                            title: ()=>''
                        }
                    },
                    legend: {
                        labels: {
                            filter: (item, chart) => {
                                let entries = chart.datasets.map(e => e.label);
                                return entries.indexOf(item.text) === item.datasetIndex && item.text.length > 0;
                            }
                        }
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0
                }
            }
        })
    }
}