import {Widget} from "./widget";
import {createElement} from './elements';
import MouseCapture from './mousecapture';
import {NodeQuality} from './node';
import assert from './debug';
import './toggle.css';
import owner from "../owner";

// Converts an html element into an iphone-style toggle switch and attaches it to a LiveData node:

// Element: This is the DOM element that will be converted to a toggle switch

// Properties:
// node:			(required)			Logical node
// trueText:		(default = 'ON') 	Text to display when switch is true
// falseText:		(default = 'OFF')	Text to display when switch is false
// redXMask:		(default: 0)		node quality flags for which to suppress the red x

// Toggle inherits Widget:
export default class Toggle extends Widget  {
    constructor(element, properties) {
        super();
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element		= element;

        this.registerAsWidget(element);	// register this element as a widget

        // First step: Establish default properties:
        this.redXMask		= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)
        this.trueText		= 'ON';
        this.falseText		= 'OFF';
        this.fDisabled		= false;

        // Second step: copy properties over to the bar graph object:
        this.copy(properties);

        //assert (this.node, 'node is a required property for Toggle');
        //assert (this.node.isWriteable, "If we can't write the node, it shouldn't be a toggle button!");

        // Third step: create internal properties:
        this.fInitialized	= false;
    };

    initialize() {
        this.element.classList.add('toggle-wrapper')
        if (this.width)
            this.element.style.width = this.width + 'px';
        // Create the toggle elements: 'toggle-window' is the element that contains the four following elements.
        // It has the 'overflow:hidden' property so we only see the separator and one side of the slide at a time.
        this.wrapper     = createElement('div', 'toggle-window',    this.element)
        this.wrapper.setAttribute('disabled', !this.node.couldBeWritten());

        var slide		= createElement('div', 'toggle-slide',		this.wrapper);			// slide holds the three next items:
        var on			= createElement('div', 'toggle-on',			slide, this.trueText);	// 'on' part of the toggle slide
        var off			= createElement('div', 'toggle-off',		slide, this.falseText);	// 'off' portion of the toggle slide
        var separator	= createElement('div', 'toggle-separator',	slide);					// separator button between on and off part of slide

        this.slideOffset = on.clientWidth - on.clientHeight / 2;// Compute css pixel offset to slide the button to toggle off/on:

        // Position the separator half way between the buttons:
        separator.style.left = this.slideOffset + 'px';

        // Create variable that represents current toggle position:
        this.fChecked	 = true;	// toggle starts out in the 'on' position

        // Attach 'mousedown' handler to the toggle:
        this.wrapper.onmousedown = this.processMouseEvent;
        this.wrapper._Toggle = this;	// Store the Toggle reference for the mouse event handler
        this.wrapper.setAttribute('slide', false);

        this.fInitialized = true;	// Must set before calling node.subscribe()

        // Connect node, and pass in the top element to receive the red x.
        this.node.subscribe(this, this.element, this.redXMask);
    };

    processMouseEvent(evt) {	// 'this' is the DOM element, this._Toggle is the toggle object
        var toggle = this._Toggle;
        switch (evt.type) {
            case 'mousedown':
                if (evt.button == 0 && toggle.node.couldBeWritten() && !toggle.fDisabled) {	// left mouse button:
                    if (owner.settingsManager || toggle.node.hasWritePermission()) {
                        toggle.capture = new MouseCapture(this, evt);
                        toggle._fMoved = false;	// true if slider moves > 5 pixels
                    }
                }
            break;

            case 'mousemove':
                // Allow for 5 pixels of deadband, so once user moves mouse more than 5 pixels in
                // the right direction (to change the toggle value), then 'clicked' will no longer
                // be valid. Instead, test closest toggle position on mouseup:
                var delta = ((toggle.fChecked ? -1 : 1) * toggle.capture.deltaX);	// absolute value of pixels that slider should moved

                toggle._fMoved = toggle._fMoved || (delta >= 5);	// once click is cancelled, slider will slide to nearest position upon release

                if (toggle._fMoved) { // Track the mouse with the slide:

                    // Set delta to be 0 to slideOffset:
                    if (delta < 0)							// user has tugged slide in wrong direction
                        delta = 0;
                    else if (delta > toggle.slideOffset)	// user has tugged the slide too far in the right direction
                        delta = toggle.slideOffset;

                    // Slide the slider to in-between position:
                    this.firstChild.style.left = (toggle.fChecked ? -delta : delta - toggle.slideOffset) + 'px';
                }
            break;

            case 'mouseup':
                if (toggle._fMoved) {	// Snap toggle to closest position:
                    var delta	= ((toggle.fChecked ? -1 : 1) * toggle.capture.deltaX);	// abs value of position change
                    var changed	= delta > (toggle.slideOffset / 2);				// user slid toggle more than halfway to new position

                    // Set toggle to final position. If user moved the toggle enough to change its value,
                    // then move the toggle all the way to the new position and write out its value:
                    toggle.toggle.call(toggle, changed ? !toggle.fChecked : toggle.fChecked, changed, true);

                } else { // User did not move at least 5 pixels, so just toggle the button:
                    if (toggle.capture.clicked)	// change position and write out new value:
                        toggle.toggle.call(toggle, !toggle.fChecked, true, true);
                }

                delete toggle.capture;
            break;
        }
    };

    update(node) {	// node value/quality changed:
        assert(node = this.node);
        assert (this.fInitialized);
        if (node.quality == NodeQuality.NQ_GOOD) {
            // This sets slide true on the SECOND call, so we will not slide on the first call. We have to
            // enable slide on the second call so we get two separate reflows of the HTML page.
            this.wrapper.setAttribute('slide', this.fCalled);	// On first call, 'slide' will be undefined. We use that in the CSS with classes for true and false.
            this.fCalled = true;								// Every call after this, 'slide' will be true.
            var value = node.getValue(this.fPending);
            if (!this.capture)		{		// user is not moving the toggle:
                this.toggle(value, false);	// Set the toggle switch to match the node value
            }
            else 	{						// user is moving toggle, so synch up our display value with update:
                this.fChecked = !!value;
            }
        }
    };

    setValue(value) {
        this.toggle(value, false);
    }

    destroy(node) {	// Undo pretty much everything we did in initialize() (Though we do leave some random members lying around):
        assert(this.fInitialized);

        delete this.capture;	// Just in case the element goes away while mouse is captured

        this.node.unsubscribe(this);

        this.element.removeChildren();

        this.unregisterAsWidget();
    };

    toggle(fChecked, fWrite, fSet) {	// Set switch to new position:
        this.fChecked = !!fChecked;		// convert checked to boolean value

        if (fSet) {
            this.wrapper.setAttribute('slide', true);
            this.fCalled = true;
        }

        // Update slide position:
        this.wrapper.firstChild.style.left = (this.fChecked ? '0' : -(this.slideOffset+1)) + 'px';

        if (fWrite) 	// Write the value to the LiveData node:
            if (owner.settingsManager) {
                owner.settingsManager.pushPending(this.node, this.fChecked? 1 : 0, this);
            }
            else if (this.node.hasWritePermission())
                this.node.setValue(this.fChecked);
            else
                assert(false, 'trying to set a value without permissions')
    };

    setDisabled(fDisabled) {	// Force disabled status regardless of the Node's state
        this.fDisabled = fDisabled;
        this.wrapper.setAttribute('disabled', !this.node.couldBeWritten() || fDisabled);
    };
};
