import View from "./view";
import { createElement, getHumanReadableTime } from '../elements';
import { CredentialMetadata } from "../passkeys";
import owner from "../../owner";
import './passkeyview.css';
import PassKeyIcon from '../images/icons/passkeys-logo.svg'
import AddIcon from '../images/icons/add.svg'
import DeleteIcon from '../images/icons/delete.svg'
import EditIcon from '../images/icons/edit.svg'
import Dialog from "../dialog";
import { base64UrlEncodeBuffer } from "../base64"
import assert from '../debug';

export default class PasskeyView extends View {
    wrapper: HTMLDivElement;
    scrollContainer: HTMLDivElement;
    userName: string;
    owner: typeof owner;
    passkeyTiles: PasskeyTile[];
    promptNicknameChangeOnRecentlyAdded: boolean = false;

    constructor(userName: string, promptNicknameChangeOnRecentlyAdded: boolean = false) {
        super();
        this.owner = owner;
        this.userName = userName;
        this.promptNicknameChangeOnRecentlyAdded = promptNicknameChangeOnRecentlyAdded;
    }

    initialize(parent: HTMLElement) {
        super.initialize(parent);
        this.wrapper = createElement('div', 'passkeyview__wrapper', this.parent);
        this.scrollContainer = createElement('div', 'passkeyview__scroll-container', this.wrapper);
        this.refreshPasskeys();

        return this;
    }

    async refreshPasskeys() {
        let credentials = await this.owner.ldc.user.passKeys.getCredentials(this.userName);
        this.scrollContainer.removeChildren(); // Remove all existing info
        this.passkeyTiles = [];
        // Display the new information
        for (let credentialMetadata of credentials)
            this.passkeyTiles.push(new PasskeyTile(this.scrollContainer, credentialMetadata, this.userName));

        if (this.userName == this.owner.ldc.user.username) // You can only add passkeys on behalf of yourself
        {
            let wrapper = createElement('div', 'passkeyview__add-tile', this.scrollContainer);
            createElement('img', 'passkey__icon', wrapper, undefined, { 'src': AddIcon });
            createElement('div', 'passkey__tile-text-container', wrapper, "Create a passkey");

            wrapper.onclick = async () => {
                let success = await this.owner.ldc.user.passKeys.register(true);

                // If we succeeded - reload all passkey metadata
                if (success) {
                    await this.refreshPasskeys();
                    this.passkeyTiles[this.passkeyTiles.length - 1].displayNickNameChanger(); // Give them a chance to set a nickname for the newly created credential
                }
                else
                    new Dialog(document.body, {
                        title: 'Error',
                        body: 'Failed to create passkey.'
                    });
            }

            if (this.promptNicknameChangeOnRecentlyAdded) {
                this.promptNicknameChangeOnRecentlyAdded = false; // Only do this once!
                this.passkeyTiles[this.passkeyTiles.length - 1].displayNickNameChanger(); // Give them a chance to set a nickname for the newly created credential
            }
        }
        else if (credentials.length == 0)
            createElement('p', 'passkeyview_no-keys-text', this.scrollContainer, `No active passkeys. Passkeys can only be created by the user of the account. Once the user has set up passkeys, you, as an administrator, will be able to view and revoke their passkeys here.`);
    }

    destroy() {
        this.parent.removeChild(this.wrapper);
    }
}

class PasskeyTile {
    credentialMetadata: CredentialMetadata;
    parent: HTMLDivElement;
    wrapper: HTMLDivElement;
    textContainer: HTMLDivElement;
    nickNameElement: HTMLSpanElement;
    createdElement: HTMLSpanElement;
    lastUsedElement: HTMLSpanElement;
    owner: typeof owner;
    hasFidoStatusReport: boolean;
    fidoIcon: string;
    hasBase64Nickname: boolean = false; // Whether the displayed nickname is using a base64encoded credentialID

    constructor(parent: HTMLDivElement, credentialMetadata: CredentialMetadata, userName: string) {
        this.credentialMetadata = credentialMetadata;
        this.parent = parent;
        this.owner = owner;
        this.hasFidoStatusReport = this.credentialMetadata.fidoData.statusReports != undefined;
        if (this.hasFidoStatusReport)
            this.fidoIcon = this.credentialMetadata.fidoData.metadataStatement?.icon;

        this.wrapper = createElement('div', 'passkeyview__tile', this.parent);
        createElement('img', 'passkey__icon', this.wrapper, undefined, { 'src': (this.fidoIcon) ? this.fidoIcon : PassKeyIcon });

        this.textContainer = createElement('div', 'passkey__tile-text-container', this.wrapper);
        let titleContainer = createElement('div', '', this.textContainer);
        this.populateCredentialNickName(); // If we don't have a nickname - it will be populated by something sane
        this.nickNameElement = createElement('span', 'passkey__tile-text-bold', titleContainer, this.credentialMetadata.nickName);

        // Display the FIDO status report if we have one
        if (this.hasFidoStatusReport && this.credentialMetadata.fidoData.statusReports[0] && this.credentialMetadata.fidoData.statusReports[0].status)
            createElement("span", this.getFIDOCertificationStatusClass(this.credentialMetadata.fidoData.statusReports[0].status), titleContainer, `${this.credentialMetadata.fidoData.statusReports[0].status}`);

        this.createdElement = createElement('span', 'passkey__tile-text', this.textContainer, `${getHumanReadableTime(this.credentialMetadata.created, "Created ", " ago")}`);
        createElement('span', 'passkey__tile-text-small', this.textContainer, `${new Date(this.credentialMetadata.created / 1000).toTimeString()}`)
        this.lastUsedElement = createElement('span', 'passkey__tile-text', this.textContainer, this.credentialMetadata.lastUsed == 0 ? "Never used" : `${getHumanReadableTime(this.credentialMetadata.lastUsed, "Used ", " ago")}`);
        createElement('span', 'passkey__tile-text-small', this.textContainer, `${new Date(this.credentialMetadata.lastUsed / 1000).toTimeString()}`)

        let modifyButtonContainer = createElement('div', 'passkey__modify-button-container', this.wrapper);

        if (userName == this.owner.ldc.user.username)
            createElement('img', 'passkey__edit-icon', modifyButtonContainer, undefined, {
                'src': EditIcon, onclick: () => { this.displayNickNameChanger(); }
            });

        createElement('img', 'passkey__edit-icon', modifyButtonContainer, undefined, {
            'src': DeleteIcon, onclick: () => {

                new Dialog(document.body, {
                    title: 'Revoke passkey',
                    body: 'Are you sure you want to revoke this passkey? This passkey will never be able to be used again.',
                    titleBackground: 'var(--color-primary)',
                    titleColor: 'var(--color-inverseOnSurface)',
                    fButtons: true,
                    fImportant: true,
                    buttons: [
                        {
                            'title': 'Revoke passkey',
                            color: 'var(--color-green-8)',
                            callback: () => {
                                owner.ldc.user.passKeys.revokeCredential(this.credentialMetadata.userId, this.credentialMetadata.credentialId).then((success) => {
                                    new Dialog(document.body, {
                                        title: success ? 'Success' : 'Error',
                                        body: success ? 'Passkey successfully revoked.' : 'Failed to revoke passkey.'
                                    });

                                    // Remove this tile from the list if it was successfully revoked
                                    if (success)
                                        this.parent.removeChild(this.wrapper);
                                });
                            }
                        },
                        {
                            'title': 'Cancel',
                            color: 'var(--color-red-8)',
                        }
                    ]
                });

            }
        });
    }

    displayNickNameChanger(isInitialNickname: boolean = false) {
        let nickNameDialog = new Dialog(document.body, {
            title: 'Change passkey nickname',
            body: 'Choose a nickname for this passkey that is recognizable to you.',
            titleBackground: 'var(--color-primary)',
            titleColor: 'var(--color-inverseOnSurface)',
            fButtons: true,
            fImportant: true,
            fText: true,
            buttons: [
                {
                    'title': 'Set nickname',
                    color: 'var(--color-green-8)',
                    callback: (nickName) => {
                        this.owner.ldc.user.passKeys.setCredentialNickName(this.credentialMetadata.credentialId, nickName).then((success) => {
                            new Dialog(document.body, {
                                title: success ? 'Success' : 'Error',
                                body: success ? 'Passkey nickname updated.' : 'Failed to update passkey nickname.'
                            });

                            if (success) // If it worked, change the display name
                            {
                                this.credentialMetadata.nickName = nickName;
                                this.populateCredentialNickName(); // If the nickname was blank - it will go back to the default
                                this.nickNameElement.textContent = this.credentialMetadata.nickName;
                            }
                        });
                    }
                },
                {
                    'title': 'Cancel',
                    color: 'var(--color-red-8)',
                }
            ]
        });
        if (!this.hasBase64Nickname) // Blank for base64 nicknames - otherwise we'll populate it with existing nickname
            nickNameDialog.textField.value = this.credentialMetadata.nickName;
    }

    populateCredentialNickName() {
        // If we don't have a nickname and this is a FIDO certified device with a description - set the description as the nickname or the base64 encoded cred id
        this.hasBase64Nickname = false;
        if (this.credentialMetadata.nickName == "") {
            if (this.credentialMetadata.fidoData.statusReports && this.credentialMetadata.fidoData.statusReports[0].certificationDescriptor)
                this.credentialMetadata.nickName = this.credentialMetadata.fidoData.statusReports[0]?.certificationDescriptor;
            else {
                this.credentialMetadata.nickName = base64UrlEncodeBuffer(this.credentialMetadata.credentialId);
                this.hasBase64Nickname = true;
            }
        }
    }

    getFIDOCertificationStatusClass(status: string): string {
        switch (status) {
            case "NOT_FIDO_CERTIFIED":
            case "USER_VERIFICATION_BYPASS":
            case "ATTESTATION_KEY_COMPROMISE":
            case "USER_KEY_REMOTE_COMPROMISE":
            case "USER_KEY_PHYSICAL_COMPROMISE":
            case "REVOKED":
                return "passkeyview__fido-danger";

            case "UPDATE_AVAILABLE":
            case "SELF_ASSERTION_SUBMITTED":
                return "passkeyview__fido-warn";

            case "FIDO_CERTIFIED":
            case "FIDO_CERTIFIED_L1":
            case "FIDO_CERTIFIED_L1plus":
            case "FIDO_CERTIFIED_L2":
            case "FIDO_CERTIFIED_L2plus":
            case "FIDO_CERTIFIED_L3":
            case "FIDO_CERTIFIED_L3plus":
                return "passkeyview__fido-ok";
            default:
                assert(false, `Unknown FIDO status ${status}`);
        }
        return ""; // We'll never get here but needed to make TS happy
    }
}
