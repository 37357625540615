import View from "./view";
import { createElement, createUniqueId } from '../elements';
import Localization from '../localization';
import owner from '../../owner';
import assert from '../debug'
import Loader from "../loader";
import RefreshIcon from '../images/icons/refresh.svg';
import ConnectedIcon from '../images/icons/connected.svg';
import DisconnectedIcon from '../images/icons/disconnected-wifi.svg';
import RemoveIcon from '../images/icons/remove.svg';
import SettingsIcon from '../images/icons/settings.svg';
import AddIcon from '../images/icons/add.svg';
import Dialog, { WritesEnabler } from "../dialog";
import ViewModal from "../viewmodal";
import CarouselView from "./carouselview";
import './devicesettingsview_classic.css'
import SiteAccessView from "./siteaccessview";
import DeviceTokenView from "./devicetokenview";
import LiveDataClient from "../livedataclient";

export default class DeviceSettingsViewClassic extends View {
	constructor(ldc) {
		super();
		this.ldc = ldc;
	}

	initialize(parent) {
		super.initialize(parent);
		this.id = this.ldc.registerGraph(this);
		this.wrapper = createElement('div', 'device-classic__page-wrapper', this.parent);
		var deviceListContainer = createElement('div', 'device-classic__list-container', this.wrapper);
		var deviceListOptions = createElement('div', 'device-classic__list-container__options', deviceListContainer);

		this.pending = [];	// To hold changes while we enable writes
		this.changes = [];	// Changes we've actually submitted

		this.messageContainer = createElement('div', 'device-classic__list-container__message-container', deviceListOptions);
		this.loader = new Loader(deviceListContainer);
		this.container = createElement('div', 'device-classic__list-container__list-row', deviceListContainer);			// To hold all the devices
		this.message = createElement('span', 'UserMessage', this.messageContainer);
		this.requestDevices();

		var buttonRow = createElement('div', 'device-classic__list-container__button-row');
		deviceListOptions.prepend(buttonRow)
		var pairedContainer = createElement('div', undefined, buttonRow);
		this.paired = createElement('input', 'radio-buttons__input', pairedContainer, undefined, { 'type': 'radio', 'name': 'DeviceSelector', 'id': 'DevicePairedInput' });
		var pairedLabel = createElement('label', 'radioButtonLabel', pairedContainer, 'Assigned');
		this.unpaired = createElement('input', 'radio-buttons__input', pairedContainer, undefined, { 'type': 'radio', 'name': 'DeviceSelector', 'id': 'DeviceUnpairedInput' });
		var unpairedLabel = createElement('label', 'radioButtonLabel', pairedContainer, 'Unassigned');
		this.paired.checked = true;
		pairedLabel.setAttribute('for', this.paired.id);
		unpairedLabel.setAttribute('for', this.unpaired.id);
		this.paired.onchange = this.unpaired.onchange = this.onRadioChange.bind(this);	// Change displayed guys when the radio changes


		let buttonContainer = createElement('div', 'device-classic__button-container', buttonRow)
		this.refreshButton = createElement('button', 'se-button device-classic__refresh-button', buttonContainer);
		this.refreshButton.type = "submit";
		this.refreshIcon = createElement('img', 'device-classic__refresh-icon', this.refreshButton, undefined, { 'src': RefreshIcon });
		this.refreshButton.onclick = this.requestDevices.bind(this);
		/*
		this.addButton					= createElement('button', 'se-button device-classic__refresh-button', buttonContainer);
		this.addButton.type 			= "submit";
		this.addIcon					= createElement('img', 'device-classic__refresh-icon', this.addButton, undefined, {'src':AddIcon});
		this.addButton.onclick			= () => new ViewModal(AddDeviceView);
		*/
		if (this.key)
			this.onKeyChange(this.key)

		this.options = [];

		this.fInitialized = true;
		return this;
	}

	onViewShown() {
		this.requestDevices();
	}

	submitSiteChange() {
		this.modalError.textContent = '';
		if (!this.selectedRow.fAssigned && this.modalSelect.selectedIndex == 0)
			return this.modalError.textContent = 'You must select a site.';
		var change = this.pending.shift();
		this.changes.push(change);
		new WritesEnabler(() => {
			this.ldc.assignSiteKey(this.id, change.tag, change.key);
		});
		this.pending.push({ tag: this.selectedRow.tag, key: this.selectedRow.fAssigned ? '' : this.modalSelect.selectedOptions[0].device.key, row: this.selectedRow });
	}

	destroy() {
		this.parent.removeChildren();	// Delete any DOM elements left over
		if (this.modalWrapper)
			this.modalWrapper.parentNode.removeChild(this.modalWrapper);
		this.fInit = false;			// No longer initialized
	}

	onKeyAssigned(result) {	// Feedback from one of our device management changes
		if (result) {
			this.message.textContent = 'Device reassignment successful.';
			this.requestDevices();
		}
		else
			this.message.textContent = 'Device reassignment failed.'
	}

	onKeyChange(key) {
		new ViewModal(new AddDeviceView(key));
	}

	onSearch() {
		var text = this.siteSearch.value;
		var regex = new RegExp(text.replace(/\s/g, ""), 'i');
		for (var i = 0; i < this.tags.length; ++i) {
			var row = this.container.children[i];
			var fShow = text.length == 0 || this.tags[i].search(regex) != -1 || row.name.search(regex) != -1;
			this.container.children[i].style.display = fShow ? 'flex' : 'none';
			//this.container.children[i].classList.toggle('collapse', !fShow);
		}
	}

	requestDevices() {
		this.message.textContent = ' ';
		this.loader.show();
		this.container.classList.add('hide');
		this.ldc.getServiceTags(this.id, owner.menuPanel.getCompanyKey());						// Get company service tags
		this.message.textContent = Localization.toLocal('Searching for devices...');										// Tell them we are service tags
	}

	onCompanySelectorChanged(companyKey) {
		this.requestDevices();		// Request devices for the selected company
	}

	onServiceTagsResponse(tags, keys, status) {
		this.container.removeChildren();	// Make sure we don't have any devices leftover
		this.options = [];
		this.loader.hide();					// Hide the loader
		this.container.classList.remove('hide');
		if (tags.length < 1 || keys.length < 1) {
			this.messageContainer.classList.remove('hide');		// Show the loader
			this.loader.hide();
			this.message.textContent = Localization.toLocal('No devices found')
			return;
		}
		for (let j = 0; j < owner.sortedDevices.length; ++j) {	// For each site
			let device = owner.sortedDevices[j];				// Convenience reference
			if (owner.menuPanel.company && device.companyKey != owner.menuPanel.company.selectedOptions[0].value)	// If we are SE and not looking at our company, skip some sites
				continue;
			if (keys.indexOf(device.key) != -1)	// If this site is currently assigned
				continue;								// Keep going
			let option = createElement('option', 'UserSiteRow', undefined, device.siteName);		// Create an option
			option.device = device;
			this.options.push(option);
		}

		this.sortedKeys = [keys[0]];					// List of all service tags sorted alphabetically
		this.sortedTags = [tags[0]];
		this.sortedStatus = [status[0]];

		for (let i = 1; i < keys.length; ++i) {				// For each device
			for (let j = 0; j <= this.sortedKeys.length; ++j) {
				if (j == this.sortedKeys.length) {
					this.sortedKeys.push(keys[i]);
					this.sortedTags.push(tags[i]);
					this.sortedStatus.push(status[i])
					break;
				}
				else if (keys[i] < this.sortedKeys[j]) {
					this.sortedKeys.splice(j, 0, keys[i]);
					this.sortedTags.splice(j, 0, tags[i]);
					this.sortedStatus.splice(j, 0, status[i])
					break;
				}
			}
		}

		for (let i = 0; i < this.sortedTags.length; ++i) {		// For each tag we got back
			if (this.sortedKeys[i] && !this.ldc.devices.getByKey(this.sortedKeys[i])) // sometimes we ship an assigned device before the site has a commissioned device key
				continue;
			var item = createElement('div', 'device-classic__item', this.container);
			item.iconDiv = createElement('img', 'device-classic__item__icon', item, undefined, { 'src': DisconnectedIcon });
			if (this.sortedStatus[i]) {
				item.iconDiv.classList.add('device-classic__item__connected');
				item.iconDiv.src = ConnectedIcon;
			}
			var siteWrapper = createElement('div', 'deviceContent', item);

			var siteName = 'None';
			if (this.sortedKeys[i] != "")
				siteName 	= this.ldc.devices.getByKey(this.sortedKeys[i]).siteName;

			item.nameDiv = createElement('div', 'deviceName', siteWrapper, siteName);
			item.tagDiv = createElement('div', 'deviceTag', siteWrapper, this.sortedTags[i]);
			item.gearDiv = createElement('img', 'se-button device-classic__item__unassign', item, undefined, { 'src': SettingsIcon });
			item.gearDiv.onclick = () => new ViewModal(new DeviceConfigurationView(this.sortedKeys[i], this.sortedTags[i], this.options, owner.menuPanel.getCompanyKey(), this.ldc), {
				maxWidth: '800px',
				title: 'Device Settings',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				closeCallback: () => this.requestDevices()
			})
			let dialogOptions = {
				title: 'Unassign Device',
				body: 'WARNING: You are about to unassign this device from this site. This device must be unassigned from this site in order to assign a different device.',
				callback: () => new WritesEnabler(() => { this.ldc.assignSiteKey(this.id, this.sortedTags[i], '') })
			}

			let selectOptions = {
				fSelect: true,
				title: 'Assign Device',
				body: 'Select a site to assign this device to.',
				options: this.options,
				callback: (option) => new WritesEnabler(() => { this.ldc.assignSiteKey(this.id, this.sortedTags[i], option.device.key) })
			}
			/*
			if (keys[i]) // if we are already paired to a site
				item.gearDiv.onclick = () => new Dialog(document.body, dialogOptions);
			else if (this.options.length > 0) // else if we have unassigned sites
				item.gearDiv.onclick = () => new Dialog(document.body, selectOptions);
			else	// if we aren't paird and we don't have any unassigned sites, just tell the user
				item.gearDiv.onclick = () => new Dialog(document.body, {title:'No Available Sites', body:'Please unassign a device from a site or create a new one.'});
			*/
			item.tag = this.sortedTags[i];
			item.key = this.sortedKeys[i];
			item.name = siteName;
			item.fAssigned = item.key.length > 0;
			item.index = i;
		}

		this.message.textContent = Localization.toLocal('Found ') + this.sortedTags.length + Localization.toLocal(' service tags.');
		this.onRadioChange();	// Show/hide by radio button
	}

	onRadioChange() {
		for (var i = 0; i < this.container.children.length; ++i)
			this.container.children[i].classList.toggle('hide', (this.sortedKeys[i].length == 0) != this.unpaired.checked);
	}
};

class AddDeviceView extends CarouselView {
	constructor(key) {
		super();
		this.key = key;

	}
	initialize(parent) {
		super.initialize(parent);
		this.wrapper = createElement('div', 'view__wrapper', this.parent);
		if (this.key) {
			createElement('div', '', this.wrapper, this.key)
		}
	}
}

class DeviceConfigurationView extends View {
	constructor(key, tag, unassignedSites, companyKey, ldc) {
		super()
		this.key 			= key;
		this.tag 			= tag;
		this.unassignedSites= unassignedSites;
		this.companyKey 	= companyKey;
		this.ldc 			= ldc;
		this.id 			= this.ldc.registerGraph(this);
	}

	initialize(parent) {
		super.initialize(parent);
		this.wrapper = createElement('div', 'dev-config-view__wrapper', this.parent);
		let deviceSettings = createElement('div', 'dev-config-view__settings', this.wrapper)
		createElement('div', 'dev-config-view__settings__title', deviceSettings, 'Device Information');
		createElement('div', 'dev-config-view__settings__info', deviceSettings, 'Service Tag: ' + this.tag);
		this.siteSettings = createElement('div', 'dev-config-view__settings', this.wrapper)
		createElement('div', 'dev-config-view__settings__title', this.siteSettings, 'Site Settings');
		if (this.key) {
			let siteName = this.ldc.devices.getByKey(this.key).siteName;
			createElement('div', 'dev-config-view__settings__info', this.siteSettings, 'Assigned Site: ' + siteName);
			let unassignButton = createElement('button', 'se-button', this.siteSettings, 'Reset Device')
			let dialogOptions = {
				title: 'Reset Device',
				titleBackground: 'var(--color-error)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'WARNING: You are about to reset this device (Service Tag: ' + this.tag + ') to factory settings. We will save a backup of ' + siteName + ' that can be restored in the future.',
				callback: () => {
					new WritesEnabler(() => { this.ldc.assignSiteKey(this.id, this.tag, '') });
				}
			}
			unassignButton.onclick = () => new Dialog(document.body, dialogOptions);

			let getTokenButton = createElement('button', 'se-button', this.siteSettings, 'Manage Local Authentication')
			getTokenButton.onclick = () => new ViewModal(new DeviceTokenView(this.siteSettings, this.tag), {
				maxWidth: 				'600px',
				maxHeight: 				'600px',
				title: 					'Device Tokens',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)'
			});

			this.groupSettings = createElement('div', 'dev-config-view__settings__groups', this.siteSettings);
			this.ldc.getGroups(this.id, this.companyKey)
		}
		else {
			let assignButton = createElement('button', 'se-button', this.siteSettings, 'Restore from Backup')
			let selectOptions = {
				fSelect: true,
				title: 'Restore from Backup',
				titleBackground: 'var(--color-primary-1)',
				titleColor: 'var(--color-inverseOnSurface)',
				body: 'Select backup to assign to this device.',
				options: this.unassignedSites,
				fButtons: true,
				callback: (option) => new WritesEnabler(() => { this.ldc.assignSiteKey(this.id, this.tag, option.device.key); })
			}
			assignButton.onclick = () => new Dialog(document.body, selectOptions);
		}
	}

	onGetGroupsResponse(groups) {
		this.groupSettings.removeChildren();
		createElement('div', 'dev-config-view__settings__info', this.groupSettings, 'Groups: ');
		let deviceGroups = groups.filter((group) => {
			return group.devices.some((key) => key == this.key)
		})
		for (let i = 0; i < deviceGroups.length; i++) {
			if (i > 0)
				createElement('div', 'dev-config-view__settings__groups__name', this.groupSettings, ', ');
			createElement('div', 'dev-config-view__settings__groups__name', this.groupSettings, ' ' + deviceGroups[i].name);
		}
		if (!this.groupsButton) {
			this.groupsButton = createElement('button', 'se-button', this.siteSettings, 'Edit Groups')
			this.groupsButton.onclick = () => new ViewModal(new SiteAccessView(this.ldc, undefined, this.companyKey, this.key, this.id), {
				maxWidth: '400px',
				maxHeight: '500px',
				title: 'Device Groups',
				titleBackgroundColor: 'var(--color-primary)',
				titleTextColor: 'var(--color-inverseOnSurface)'
			});
			if (this.ldc.isPowerUser()) {
				let seGroupsButton = createElement('button', 'se-button', this.siteSettings, 'Edit SE Groups')
				seGroupsButton.onclick = () => new ViewModal(new SiteAccessView(this.ldc, undefined, '', this.key, this.id), {
					maxWidth: '400px',
					maxHeight: '500px',
					title: 'Device Groups',
					titleBackgroundColor: 'var(--color-primary)',
					titleTextColor: 'var(--color-inverseOnSurface)'
				});
			}
		};
	};

	onAccountsManaged(command, result) {
		if (result)
			this.ldc.getGroups(this.id, this.companyKey)
	}

	onKeyAssigned(result) {	// Feedback from one of our device management changes
		if (result)
			new Dialog(document.body, {
				title: 'Success',
				body: this.key ? 'Device has been reset to factory settings.' : 'Device successfully restored from backup.',
				titleBackground: 'var(--color-primary-1)',
				titleColor: 'var(--color-inverseOnSurface)'
			});
		else
			new Dialog(document.body, {
				title:'Error',
				body: this.key? this.ldc.devices.getByKey(this.key).siteName + ' could not be restored from backup. Please try again.' : 'An error occurred while trying to reset this device. Please try again.',
				titleBackground:'var(--color-primary-2)',
				titleColor:'var(--color-inverseOnSurface)'
			});
		this.modal.destroy();
	}
};

class GroupsView extends View {
	constructor(groups, key, callbackID, companyKey, ldc) {
		super();
		this.groups = groups;
		this.key = key;
		this.callbackID = callbackID;
		this.companyKey = companyKey;
		this.ldc 		= ldc;
		this.id 		= this.ldc.registerGraph(this);
	}

	initialize() {
		this.wrapper = createElement('div', 'user-view__wrapper', this.parent)
		let container = createElement('div', 'user-view__container', this.wrapper);
		for (let i = 0; i < this.groups.length; i++) {
			let sites = this.groups[i].devices;
			let row = createElement('div', 'user-view__row', container)
			let id = createUniqueId();
			let checkwrapper = createElement('div', 'se-checkbox', container)
			let checkbox = createElement('input', null, checkwrapper, null, { 'type': 'checkbox', 'id': id });
			createElement('label', null, checkwrapper, this.groups[i].name, { 'htmlFor': id });
			console.log(this.groups[i].devices)
			checkbox.checked = sites.some((key) => { return key == this.key });
			checkbox.onchange = () => {
				if (checkbox.checked) {
					sites.push(this.key);
					this.ldc.modifyGroupDevices(this.id, this.groups[i].name, this.companyKey, sites);
				}
				else {
					this.groups[i].device = this.groups[i].devices.filter((key) => { return key != this.key });
					this.ldc.modifyGroupDevices(this.id, this.groups[i].name, this.companyKey, sites);
				}
			}
		}
	}

	onAccountsManaged(command, result) {
		if (result) {
			this.ldc.getGroups(this.callbackID, this.companyKey);
		}
	}

}
