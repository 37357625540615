import assert from './debug';
import LiveData from './livedata';
import Localization from './localization';
import createSVGElement from './svgelements';
import owner from '../owner';
import type FrameParser from './frameparser';
import { Alarm, ConfiguredAlarm } from './alarm';
import { Device } from './device';
import { GenericTreeable, Treeable, TreeableType } from './views/treeview';
import { LiveDataJobNode } from './livedatajob';
import { type Tag, type TagMetadata, type TagPropertyOptions, TagQuality, type TagTypeOptions, type TagUnitOptions } from './widgets/lib/tag';
import { TagQuantityMap, TagUnit, TagUnitQuantity, UnitsMap, convert } from './widgets/lib/tagunits';
import { Role } from './role';
import { getDependentWidgets } from './widgets/lib/widget';

export enum VType {
	VT_UNKNOWN = 0,	// unknown type
	VT_BOOL = 1,	// one-byte value (0 or 1)
	VT_U8 = 2,	// unsigned integers
	VT_U16 = 3,
	VT_U32 = 4,
	VT_U64 = 5,
	VT_S8 = 6,	// signed integers
	VT_S16 = 7,
	VT_S32 = 8,
	VT_S64 = 9,
	VT_F32 = 10,	// floating point
	VT_F64 = 11,
	VT_STRING = 12,	// data points to an std::string or array of std::strings
	VT_BLOCK = 13,	// block of data. Not yet implemented.
}

export const typeMap: Map<VType, string> = new Map([
	[VType.VT_UNKNOWN, 'Unknown'],
	[VType.VT_BOOL, 'Boolean'],
	[VType.VT_U8, 'U8 Int'],
	[VType.VT_U16, 'U16 Int'],
	[VType.VT_U32, 'U32 Int'],
	[VType.VT_U64, 'U64 Int'],
	[VType.VT_S8, 'S8 Int'],
	[VType.VT_S16, 'S16 Int'],
	[VType.VT_S32, 'S32 Int'],
	[VType.VT_S64, 'S64 Int'],
	[VType.VT_F32, '32 Float'],
	[VType.VT_F64, '64 Float'],
	[VType.VT_STRING, 'String'],
	[VType.VT_BLOCK, 'Data'],
]);

export enum NodeFlags {
	NF_READ = 0x00000010,		// data source grants read permission
	NF_WRITE = 0x00000020,		// data source grants write permission
	NF_LOG = 0x00000040,		// node wants its value to be written to the binary file by the NodeLogger
	NF_FINAL = 0x00000080,		// node value is constant throughout execution of server application. The quality of a final tag is always 0.
	NF_ROLE = 0x00000400,		// strictly-defined role (e.g. "PUMP", "HOA", "OPTIMIZER", "SOURCE_TANK_LEVEL")
	NF_RANGE = 0x00000800,		// normal range of minimum and maximum values is defined as double floats -- useful without NF_RAW_SCALING to give GUI clue as to how to represent a value as a bar graph
	NF_RESOLUTION = 0x00001000,		// node has numeric resolution as double float(in engineering units, if scaled)
	NF_SCALING = 0x00002000,		// if NF_RANGE is also defined, node will scale raw value to engineering units as double floats, otherwise, does nothing.
	// if NF_RANGE and NF_SCALING are both defined, values read to and written from Nodes are doubles and get scaled to/from eng. units to raw units in the Node's Variant
	NF_HIDDEN = 0x00004000,		// node should not be visible to mortals
	NF_ALIAS = 0x00010000,		// If set, this node is just a proxy for another Node. In C++, it shares the same data space, so writing to the alias node should overwrite the original node
	NF_DERIVED = 0x00020000,
	NF_USER = 0x00040000,
	NF_TEMPORARY = 0x00080000,
}

export enum UserNodeFlags {
	UNF_DRIVER = 0x00000001,
	UNF_LINKED = 0x00000002,
	UNF_DESCRIPTION = 0x00000004,
	UNF_TYPE = 0x00000008,
	UNF_CONFIG = 0x00000010
}

export enum NodeOperand {
	DERIVED_ADD = 0,
	DERIVED_SUBTRACT = 1,
	DERIVED_MULTIPLY = 2,
	DERIVED_DIVIDE = 3,
}

export enum NodeQuality {
	NQ_GOOD				= 0,				// just here so you can set quality = NQ_GOOD instead of 0.
	NQ_UNINIT			= 0x00000008,		// data is not yet initialized (data is garbage)
	NQ_OFFLINE			= 0x00000010,		// data is off line (data is stale)
	NQ_UNREGISTERED		= 0x00000020,		// Not registered or UnregisterLiveData() was called
	NQ_MACHINE_NOT_FOUND= 0x00000040,		// could not find host machine
	NQ_PROCESS_NOT_FOUND= 0x00000080,		// could not find process on host machine
	NQ_DATA_NOT_FOUND	= 0x00000100,		// could not find data on host process
	NQ_CLOSED			= 0x00000200,		// unconnected
	NQ_PROTOCOL_ERROR	= 0x00000400,		// error in protocol code
	NQ_PERMISSION		= 0x00000800,		// user does not have permission (may be due to LDF_READ LDF_WRITE flags)
	NQ_DISCONNECTED		= 0x00001000,		// socket became disconnected -- will retry periodically
	NQ_OUT_OF_RANGE		= 0x00002000,		// value is out of range, and therefore invalid
}

export const QualityMap: Map<NodeQuality, string> = new Map([
	[NodeQuality.NQ_GOOD,				'Good'],
	[NodeQuality.NQ_UNINIT,				'Tag data not initialized'],
	[NodeQuality.NQ_OFFLINE,			'Tag Source Offline'],
	[NodeQuality.NQ_UNREGISTERED,		'Unregistered'],
	[NodeQuality.NQ_MACHINE_NOT_FOUND,	'Machine not Found'],
	[NodeQuality.NQ_PROCESS_NOT_FOUND,	'Process not Found'],
	[NodeQuality.NQ_DATA_NOT_FOUND,		'Data not Found'],
	[NodeQuality.NQ_CLOSED,				'Closed'],
	[NodeQuality.NQ_PROTOCOL_ERROR,		'Protocol Error'],
	[NodeQuality.NQ_PERMISSION,			'Invalid Permission'],
	[NodeQuality.NQ_DISCONNECTED,		'Disconnected'],
	[NodeQuality.NQ_OUT_OF_RANGE,		'Out of Range']
]);

export interface TagID {
    location: string,
    role?: string,
    attributes?: {[key:string] : string};
}

interface NodeOperation {
	op: number;
	node: number | undefined;
	min: number | undefined;
	max: number | undefined;
	constant: number | undefined;
}

export interface NodeSubscriber {
	update: (node: Tag) => void;
	onNodeChanged: (oldNode: Tag, newNode: Tag) => void;
	onNodeRemoved: (node: Tag) => void;
	onAlarm: (node: Tag, alarm: Alarm, fAdded: boolean, fChanged: boolean, fDeleted: boolean) => void;
	onConfiguredAlarm: (node: Tag, configuredAlarm: ConfiguredAlarm, fAdded: boolean) => void;
	redXElement?: HTMLElement;
	redXMask?: number;
}

export function ParseNodeFromFrame(fp: FrameParser) {
	let nodeAttributes: Partial<NodeAttributes> = {};
	nodeAttributes.fPsuedo = false;  // Used for what-if

	fp.pop_s8();                             // read permission level -- deprecated
	fp.pop_s8();                             // write permission level -- deprecated
	nodeAttributes.id    		= fp.pop_u32();     // unique index
	nodeAttributes.name  		= fp.pop_string();	// node name of this leaf
	nodeAttributes.vtype 		= fp.pop_u32();     // variant type
	fp.pop_s32();     						// Element count -- deprecated
	nodeAttributes.cacheUnits 	= fp.pop_u16();     // Node units (TagUnit.TU_xxxxx)
	nodeAttributes.flags 		= fp.pop_u32();     // Node flags (NodeFlags.NF_xxxxx)

	// For readable tags, the initial value is included with the metadata:
	if ((nodeAttributes.vtype != VType.VT_UNKNOWN) && (nodeAttributes.flags & NodeFlags.NF_READ)) {	// readable tag:
		// assert(this.hasReadPermission(), 'nodes that come across should always be valid reads');
		var quality = fp.pop_u32();                                     // Node quality (NodeQuality.NQ_xxxx flags)
		if (quality == TagQuality.TQ_GOOD)                             // quality is good, so unscaled value follows. This portion of the protocol is deprecated
			nodeAttributes.value = Node.pop(fp, nodeAttributes.vtype);  // pop unscaled value

		if (nodeAttributes.flags & NodeFlags.NF_FINAL)  // If we're a final node, though
			nodeAttributes.quality = quality;           // Save the quality
	}

	// Read the total number of option bytes. If we don't understand all options,
	// we can skip past new options that we don't understand using this value:
	var optionBytes = fp.pop_u32();
	var endSize = fp.size() - optionBytes;  // bytes that should be left in fp after all option bytes are consumed

	// Read up the options, based on the node flags:
	if (nodeAttributes.flags & NodeFlags.NF_ROLE)
		nodeAttributes.roles = parseRolesFromCSV(fp.pop_string());

	if (nodeAttributes.flags & NodeFlags.NF_RANGE) {
		nodeAttributes.engMin = fp.pop_f64();
		nodeAttributes.engMax = fp.pop_f64();
	}

	if (nodeAttributes.flags & NodeFlags.NF_RESOLUTION) {
		nodeAttributes.resolution = fp.pop_f64();
		// Compute digits to display to the right of decimal point:
		nodeAttributes.digits = Math.max(0, Math.ceil(-Math.log(nodeAttributes.resolution) / Math.LN10));
	} else {  // default:
		nodeAttributes.resolution = 1;
		nodeAttributes.digits = 0;
	}

	if (nodeAttributes.flags & NodeFlags.NF_SCALING) {
		nodeAttributes.rawMin = fp.pop_f64();
		nodeAttributes.rawMax = fp.pop_f64();
	}

	if (nodeAttributes.flags & NodeFlags.NF_ALIAS || nodeAttributes.flags & NodeFlags.NF_TEMPORARY)
		nodeAttributes.sourceID = fp.pop_u32();

	if (nodeAttributes.flags & NodeFlags.NF_DERIVED) {
		nodeAttributes.operations = [];
		var count: number = fp.pop_u8();
		for (var i = 0; i < count; ++i) {
			var operation: NodeOperation = {
				op: fp.pop_u8(),
				node: undefined,
				min: undefined,
				max: undefined,
				constant: undefined
			};
			if (fp.pop_u8()) {
				operation.node = fp.pop_u32();
				operation.min = fp.pop_f64();
				operation.max = fp.pop_f64();
			} else
				operation.constant = fp.pop_f64();
			nodeAttributes.operations?.push(operation);
		}
	}

	if (nodeAttributes.flags & NodeFlags.NF_USER) {
		nodeAttributes.userFlags = fp.pop_u32();
		if (nodeAttributes.userFlags & UserNodeFlags.UNF_DRIVER) {
			nodeAttributes.driver = fp.pop_string();
			nodeAttributes.registerName = fp.pop_string();
		}
		if (nodeAttributes.userFlags & UserNodeFlags.UNF_TYPE)
			nodeAttributes.dataType = fp.pop_string();
		if (nodeAttributes.userFlags & UserNodeFlags.UNF_LINKED)
			nodeAttributes.remotePath = fp.pop_string();
		if (nodeAttributes.userFlags & UserNodeFlags.UNF_DESCRIPTION)
			nodeAttributes.description = fp.pop_string();
	}

	// Skip any option bytes that we don't yet know how to read (for forward-compatibility):
	assert(endSize == fp.size(), 'We didn\'t consume all the option bytes. Why?');
	fp.skip(fp.size() - endSize);

	return nodeAttributes as NodeAttributes;
}

export function parseRolesFromCSV(csvString: string): Set<string> {
	let roles = new Set<string>;
	var splitted = csvString.split(',');
	for (let potentialRole of splitted) {
		let trimmed = potentialRole.trim(); // trim whitespace i.e. "role, someOtherRole, something"
		if (trimmed != "")
			roles.add(trimmed);
	}
	return roles;
}

export function serializeRolesToCSV(roles: Set<string>): string {
	var csvString = "";
	for(let role of roles)
		csvString += (role + ",");
	csvString = csvString.substring(0, csvString.length -1); // Remove the last comma
	return csvString;
}

export interface NodeAttributes {
	name: string;
	id: number;
	vtype: VType;
	cacheUnits: TagUnit;
	units?: TagUnit;
	flags: NodeFlags;
	quality: TagQuality;
	roles: Set<string>;
	digits: number;
	fPsuedo: boolean;
	userFlags?: UserNodeFlags;
	sourceID?: number;
	operations?: NodeOperation[];
	engMin?: number;
	engMax?: number;
	resolution?: number;
	value?: any;
	rawMin?: number;
	rawMax?: number;
	driver?: string;		// the name of the driver that created this node
	registerName?: string;	// the source name in the pds (F40001, NewTagName, etc..)
	dataType?: string;
	remotePath?: string;
	description?: string;
}

// Node constructor function:
export class Node implements Tag, Treeable, NodeAttributes {
	name: string;
	id: number;
	children: Node[] = [];
	parent: Node;
	tree: NodeTree;
	vtype: VType;
	units: TagUnit;
	flags: NodeFlags;
	subscribers: Set<NodeSubscriber> = new Set();
	alarms: Alarm[] = [];
	configured: ConfiguredAlarm[] = [];
	filterPass: boolean;
	_value: any;
	quality: TagQuality = TagQuality.TQ_OFFLINE;
	unitConversion: number;
	roles: Set<string> = new Set();
	engMin: number;
	engMax: number;
	resolution: number;
	digits: number;
	rawResolution: number;
	sourceID: number;
	operations: NodeOperation[] = [];
	_unitConversion: number | null = null;
	timerID: NodeJS.Timeout | undefined;
	value: any;
	rawMin: number;
	rawMax: number;
	driver: string;		// the name of the driver that created this node
	registerName: string;	// the source name in the pds (F40001, NewTagName, etc..)
	dataType: string;
	fValueChanged: boolean;
	fQualityChanged: boolean;
	fDiscrete: boolean;
	cacheUnits: number;
	cachedEngMin: number;
	cachedEngMax: number;
	cachedResolution: number;
	baseNodes: Node[];
	derivativeOf: Node[];
	ldNode: LiveDataJobNode;
	lastValue: number;
	remotePath: string;
	description: string;
	userFlags: number;
	fPsuedo: boolean;  //
	absolutePath: string;
	dashboards: GenericTreeable[] = [];
	treeableType: TreeableType = 'tag';
	private subscribedCallbackMap: Map<NodeSubscriber, (tag: Node) => void> = new Map();
	private _deviceRelativePath: string;
	private pendingWrites: Map<NodeSubscriber, any> = new Map();
	constructor(parent?: Node, nodeAttributes?: NodeAttributes) {
		if (typeof parent === 'undefined') {	// constructing the root node:
			assert (typeof nodeAttributes == 'undefined', "Root node should not be given a FrameParser in the constructor");
			this.name		= '/';
			this.id			= 0;
			this.vtype		= VType.VT_UNKNOWN;
		} else if (nodeAttributes) {
			Object.assign(this, nodeAttributes);
			this.parent     	= parent;
			this.tree       	= parent.tree;     // get NodeTree reference from parent
			this.absolutePath 	= `${this.tree.device.key}:${this.deviceRelativePath}`;

			this.units 			= this.units ?? this.cacheUnits + 0;
			this.filterPass 	= false;

			// For readable tags, the initial value is included with the metadata:
			if (this.flags & NodeFlags.NF_READ) {	// readable tag:
				assert(this.hasReadPermission(), 'nodes that come across should always be valid reads');
				if (this.flags & NodeFlags.NF_FINAL || this.fPsuedo)  // If we're a final node, though
					this._value = this.value;
			}

			this.cachedEngMin = this.engMin + 0;
			this.cachedEngMax = this.engMax + 0;
			this.cachedResolution = this.resolution + 0;

			if (!this.roles.has(Role.ROLE_MAX_SPEED_HZ))
				this.updateUnits(this.tree.unitsMap);

			if (this.tree.device.oldTree) {
				let deviceRelativePath = parent === this.tree.nodes[0] ? parent.deviceRelativePath + this.name : parent.deviceRelativePath + '/' + this.name;
				let oldNode = this.tree.device.oldTree.findNode(deviceRelativePath);
				if (oldNode && oldNode.flags === this.flags) {
					let stateKeys = ["configured", "alarms", "subscribers", "children", "subscribedCallbackMap"] // These are important to keep around for the tree transition
					oldNode._value = undefined;
					for (let key in this) {
						if (!stateKeys.includes(key)) {
							//@ts-ignore
							oldNode[key] = this[key];       // Update the old node with all the new metadata
						}
					}
					if (parent.children.indexOf(oldNode) === -1) { // If we aren't already in our parent's children
						parent.children.push(oldNode);
					}
					return oldNode;
				}
			}
			parent.children.push(this);
			this.fValueChanged = true;		// Only valid during an update(node) call, and only for the passed node
			this.fQualityChanged = true;	// Only valid during an update(node) call, and only for the passed node
			return this;
		}
	};

	destroy() {
		if (this.timerID !== undefined)
			clearTimeout(this.timerID);

		for (var key in this)
			delete this[key];	// Remove all of our Node member variables
	}

	get isLogged() { return (this.flags & NodeFlags.NF_LOG) != 0 };
	isReadable() { return (this.flags & NodeFlags.NF_READ) != 0 };
	get isWriteable() { return (this.flags & NodeFlags.NF_WRITE) != 0 }
	couldBeWritten() { return (this.isWriteable && this.tree.device.isWriteable()) }
	hasReadPermission() { return this.isReadable(); }	// user has read permission
	hasWritePermission() { return (this.couldBeWritten() && this.tree.device.ldc.user.writesEnabled()); }// user has write permission

	getValue(widget?: NodeSubscriber) {	// Preferred method for getting node value. You should not directly access _value.
		if (!this.fPsuedo) {
			// C++ Should only give us readable nodes that we have the permission level to read. Make sure we are still doing that
			assert(this.isReadable(), `getValue should only be called if the node is readable, but ${owner.ldc.user.username} tried to read ${this.deviceRelativePath}`);
		}

		/*
		*	Initially made this return if quality was less than adequate,
		*	lead to amazing results from many pump related nodes being NQ_OFFLINE on the summary page
		*	during initial load and subsequent failure of those particular summary panels to be added to the page. Only a few summary panels loaded at all.
		*	Also several nodes were NQ_UNINIT from Temple Dane, returning then would occasionally cause TD Site Overview page to fail to load properly.
		*/
		if (this.quality !== TagQuality.TQ_GOOD) {
			//			console.log("getValue on a node without NQ_GOOD quality, name: " + this.tree.device.key + this.getDeviceRelativePath());
			//			console.trace();
			return NaN;
		}

		if (owner.settingsManager && widget)
			return owner.settingsManager.pendingWrites.get(this)?.get(widget) ?? ((this.vtype == VType.VT_STRING || this.vtype == VType.VT_BOOL) ? this._value : this.convertFromCacheToDisplay(this._value));
		else
			return this.vtype == VType.VT_STRING || this.vtype == VType.VT_BOOL ? this._value : this.convertFromCacheToDisplay(this._value);
	}

	updateUnits(unitsMap: Map<TagUnitQuantity, TagUnit>) {
		this.rawResolution = this.resolution;
		let unitConversion = 1;
		let displayUnits = unitsMap.get(this.units & 0xFF00);	// Find if we should portray ourselves in different units
		if (displayUnits && displayUnits != this.units) {
			let maxLineFreq: number | undefined = undefined;
			if ((displayUnits == TagUnit.TU_HZ && this.units == TagUnit.TU_PERCENT) || (displayUnits == TagUnit.TU_PERCENT && this.units == TagUnit.TU_HZ))
				maxLineFreq = this.tree.device.timeZone.includes('America') ? 60 : 50;
			if ((this.units & 0xFF00) != TagUnitQuantity.TUQ_TEMPERATURE)
				unitConversion = this._unitConversion = convert(1, this.units, displayUnits, maxLineFreq);
			this.engMax *= unitConversion!;
			this.engMin *= unitConversion!;
			this.resolution *= unitConversion!;	// FIXME: make this more intelligent
			this.digits = Math.min(3, Math.max(0, Math.ceil(-Math.log(this.resolution) / Math.LN10)));
			this.units = displayUnits;
		}
		else {
			this.units = this.cacheUnits;
			this._unitConversion = 1;
			this.engMax = this.cachedEngMax;
			this.engMin = this.cachedEngMin;
			this.resolution = this.cachedResolution;
			this.digits = Math.min(3, Math.max(0, Math.ceil(-Math.log(this.resolution) / Math.LN10)));
		}
		for (let widget of getDependentWidgets(this)) {
			widget.onNodeChanged(this);
		}
	}

	updateNodeChanged() {
		for (let widget of getDependentWidgets(this)) {
			widget.onNodeChanged(this);
		}
	}

	convertValue(desiredUnits: TagUnit, widget?: NodeSubscriber) {
		return convert(this.getValue(widget), this.units, desiredUnits);
	}

	get unitsText(): string {
		return UnitsMap.get(this.units)?.abbrev ?? '';
	}

	get device(): Device {
		return this.tree.device;
	}

	get treeChildren(): Treeable[]  {
		return [...this.dashboards, ...this.children]
	}

	setValue(value: any) {	// scaled value == standard case:
		// Ignore writes if quality is bad or if the user does not have the appropriate permissions.
		assert(this.isWriteable, 'node is not writeable');
		if (this.fPsuedo || ((this.quality == TagQuality.TQ_GOOD) && this.hasWritePermission())) {
			if (this.vtype != VType.VT_STRING)
				value = this.convertFromDisplayToCache(value);
		}
		if (this.fPsuedo) {
			this.value = value;
			this._value = this.value;
			return;
		}
		else
			this.tree.device.job.sendWriteCommand(this, value);	// Write the command out immediately

		// Regardless of whether we issued the write or not, clear any old timer and start a new one
		if (this.timerID !== undefined)
			clearTimeout(this.timerID);
		this.timerID = setTimeout(this.onTimerExpired.bind(this), 2000);	// TODO: One day, we can add in an optional time that will set how long to timeout
	}

	setPendingValue(value: any, caller: NodeSubscriber) {
		if (this.fPsuedo) {
			this.setValue(value);
			for (let sub of this.subscribers) {
				if (sub !== caller) {
					this._sendUpdate(sub)
				}
			}
			this.tree.onTagValueWritten(this, value, caller);
		}
		else
			owner.settingsManager.pushPending(this, value, caller)
	}

	onTimerExpired() {
		assert(this.timerID !== undefined, "Node got called back when it didn't have a valid timer!");
		delete this.timerID;		// Remove our timer reference
		this._updateSubscribers();	// Call update in case the write failed
	}

	getDeviceRelativePath(fDisplayPath: boolean = false): string {
		// Return the device-relative path to the node, e.g., "/Folder",or "/Folder/Tag".
		// Examples of paths:
		// Absolute:		//us_tx_gtwn_wtp1_rwp/Pumps/RWP1.RefSpeed
		// Device Relative:						 /Pumps/RWP1.RefSpeed
		// Relative:							  Pumps/RWP1.RefSpeed
		// Relative:									RWP1.RefSpeed
		// Relative:										 RefSpeed
		// Examples of node names: Pumps, RWP1, RefSpeed
		//
		// Note that we can distinguish absolute, device-relative, and relative based on the first two characters.
		// We can recognize node names as strings without any separators.
		if (this.parent) {
			let parentPath = this.parent.getDeviceRelativePath(fDisplayPath);
			return (parentPath === '/' ? parentPath :  parentPath + '/') + (fDisplayPath ? this.getDisplayName(false) : this.name);
		}
		else // no parent:
			assert(this.name == '/');	// only root node has no parent
		return "/";
	}

	get deviceRelativePath(): string {
		if (this._deviceRelativePath)
			return this._deviceRelativePath
		else {
			this._deviceRelativePath = this.getDeviceRelativePath();
			return this._deviceRelativePath
		}
	}

	get deviceName(): string {
		return this.tree.device.siteName;
	}

	get deviceKey(): string {
		return this.tree.device.key
	}

	get valueText() {
		return this.getFormattedText(false);
	}

	static pop(fp: FrameParser, vtype: VType) {	// read value from frame:
		switch (vtype) {
			case VType.VT_BOOL: return fp.pop_bool();
			case VType.VT_U8: return fp.pop_u8();
			case VType.VT_U16: return fp.pop_u16();
			case VType.VT_U32: return fp.pop_u32();
			case VType.VT_U64: return fp.pop_u64();
			case VType.VT_S8: return fp.pop_s8();
			case VType.VT_S16: return fp.pop_s16();
			case VType.VT_S32: return fp.pop_s32();
			case VType.VT_S64: return fp.pop_u64();
			case VType.VT_F32: return fp.pop_f32();
			case VType.VT_F64: return fp.pop_f64();
			case VType.VT_STRING: return fp.pop_string();
			default:
				assert(false, 'no support');
				break;
		}
	}

	findChildByRole(role: string): Node | null {	// Find a singular descendant of this node by role. This method is not recursive
		if (this.children) {			// roles are defined in Node.js as NodeRole.ROLE_xxxxx;
			for (var i = 0, node: Node; node = this.children[i]; ++i) {	// Check all children
				if (node.roles && node.roles.has(role)) 	// If we find the role
					return node;		// Return that node
			}
		}
		return null;					// Didn't find the node, return a null
	}

	findChildrenByRole(role: string) : Node[] {
		let nodes: Node[] = [];
		if (this.children) {			// roles are defined in Node.js as NodeRole.ROLE_xxxxx;
			for (var i = 0, node: Node; node = this.children[i]; ++i) {	// Check all children
				if (node.roles.has(role)) 	// If we find the role
					nodes.push(node);	// Add it to our array
			}
		}
		return nodes;
	}

	findByRole(role: string): Node[] {	// Find all descendant nodes that have matching role and return an array:
		var result: Node[] = [];	// 'role' is a string and acceptable roles are defined in Node.js as NodeRole.ROLE_xxxxx;
		// Nodes are returned in order discovered, using depth-first tree search.
		this._findByRoleHelper(role, result);
		return result;
	}

	private _findByRoleHelper(role: string, result: Node[]) {	// internal helper method for findByRole()
		if (this.children) {
			for (var i = 0, node: Node; node = this.children[i]; ++i) {
				if (node.roles && node.roles.has(role))
					result.push(node);
				node._findByRoleHelper(role, result);
			}
		}
	}

	findChild(name: string): Node | null { // Return child with matching name:
		// Tags, PCL variables are case-sensitive, just like C, Java, etc.
		// Return 'undefined' if not found. (non-recursive)
		if (this.children) {
			for (var i = 0, node: Node; node = this.children[i]; ++i) {
				if (name == node.name)
					return node;
			}
		}
		return null;
	}

	findChildrenWithProperties(props: TagMetadata) {
		let nodes = Node.filterNodes([...this.children], props);
        return nodes;
	}

	_findNodeHelper(path: string): Node | undefined {	// private method used by findNode()
		// If the pathBuffer is a simple name without separator, then return the named child node if it exists, else undefined.
		// Otherwise, pull out the name before the separator and recursively call findNodeHelper() on the named child block or folder, if it exists.
		// If the named child block or folder is not found as a child of this node, return NULL.

		var slashIndex	= path.indexOf('/');
		var fFolder		= (slashIndex != -1);

		// Strip the child node name out from front of path:
		var childName	= fFolder ? path.substr(0, slashIndex) : path;
		var child		= this.findChild(childName);

		if (child) {
			if (fFolder)
				return child._findNodeHelper(path.substring(slashIndex + 1));
			else
				return child;
		}
		else
			return undefined;
	}

	subscribeWidget(object: NodeSubscriber, callback: (tag: Node)=>void) {
		this.subscribedCallbackMap.set(object, callback);
		this.subscribe(object);
	}

	subscribe(object: NodeSubscriber, redXElement?: HTMLElement, redXMask?: number) {	// Subscribe object to node:
		assert(object.update && (typeof object.update == 'function'), 'Object must have update() method.');
		assert(this.subscribers, 'Attempting to subscribe to a node without a subscribers map');
		assert(!this.subscribers.has(object), 'Object is already connected to this node.');
		assert(!redXElement || (redXElement instanceof Element), 'redXElement, if specified, must be a DOM element');
		assert(!redXMask || (redXMask && redXElement), 'Cannot specify red x mask without corresponding element');
		object.redXElement = redXElement;
		object.redXMask = redXMask ?? 0;
		this.subscribers.add(object);
		//this.subscribers.set(object,{element:redXElement, mask:mask});
		// Add the node to the job, on behalf of this object (job uses lock counting for nodes):
		if (!this.fPsuedo)
			this.tree.device.job.add(this);

		// If we have a value for this node TODO: Temporary hack, these map lookups should go away when all widgets are Widgets
		if (this.subscribedCallbackMap.has(object)) {
			this.subscribedCallbackMap.get(object)!(this);
		}
		else
			object.update(this);	// Call update so the object gets the value

		if (object.onConfiguredAlarm)
			for (let configuredAlarm of this.configured)
				configuredAlarm && object.onConfiguredAlarm(this, configuredAlarm, true);
		if (object.onAlarm)
			for (var i = 0; i < this.alarms.length; ++i)
				object.onAlarm(this, this.alarms[i], true, true, false);
		if (object.redXElement)
			this._updateRedX(object.redXElement, object.redXMask);
	}

	unsubscribe(object: any) {	// Disconnect object from node:
		if (!this.subscribers || !this.subscribers.has(object)) return;
		// Remove node from job:
		this.subscribers.delete(object);
		if (!this.fPsuedo && this.subscribers.size === 0)
			this.tree.device.job.remove(this);
	}

	getUnitsText() {
		return UnitsMap.get(this.units)?.abbrev;
	}

	/*
	 * Convert node value to formatted text
	 * @param {boolean}		append text units if available
	 */
	getFormattedText(fUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string, widget?: NodeSubscriber) {
		if (this.quality != TagQuality.TQ_GOOD)
			return '';

		switch (this.vtype) {
			case VType.VT_BOOL:
				return this.getValue(widget) ? 'ON' : 'OFF';

			case VType.VT_STRING:
				return this.getValue(widget);
		}
		assert(this.vtype >= VType.VT_U8 && this.vtype <= VType.VT_F64, 'numeric node expected');

		// If we got to here, format the value according to resolution:
		var text, resolution, digits;
		if (newUnits === undefined) {	// If the didn't pass in units
			newUnits = this.units;	// use our current units for simplicity
			resolution = this.resolution;
			digits = newDigits !== undefined ? newDigits : this.digits;
		} else {						// The passed in stuff
			digits = newDigits;	// For now, just give back an integer. FIXME: Calculate what it should be from our current stuff
			resolution = 1;
		}
		if (this.units == TagUnit.TU_TIME)
			return new Date(this.getValue(widget) / 1000).format(newFormat || "%yyyy.%MM.%dd %HH:%mm:%ss");
		else if ((this.units & 0xFF00) == TagUnitQuantity.TUQ_CURRENCY)
			return this.getValue(widget).toCurrency(this.getUnitsText());
		else if (resolution > 1) {
			let value = Math.round(this.convertValue(newUnits, widget) / resolution) * resolution;
			text = isNaN(value) ? '' : value.toFixed(digits);
		}
		else if (this.roles && (this.roles.has(Role.ROLE_PUMP_AUTO_HOA_SWITCH) || this.roles.has(Role.ROLE_PUMP_SOFTWARE_HOA) || this.roles.has(Role.ROLE_PUMP_OVERALL_HOA) || this.roles.has("softwareHOA"))) {
			let list = ['Hand', 'Off', 'Auto'];
			text = list[this.getValue(widget) - 1];
		}
		else {
			let value = this.convertValue(newUnits, widget);
			text = isNaN(value) ? '' : value.toFixed(digits);	// format with 'this.digits' to the right of the decimal point
		}

		// Concatenate units if requested by caller:
		if (fUnits && (newUnits != TagUnit.TU_UNDEFINED))
			text += (' ' + UnitsMap.get(newUnits)?.abbrev ?? '');

		return text;
	}

	/*
	 * Convert given value to formatted text
	 * @param {boolean}		append text units if available
	 */
	getFormattedTextFromValue(value: any, fUnits: boolean, newUnits?: TagUnit, newDigits?: number, newFormat?: string) {
		if (this.quality != TagQuality.TQ_GOOD)
			return '';

		switch (this.vtype) {
			case VType.VT_BOOL:
				return value ? 'ON' : 'OFF';

			case VType.VT_STRING:
				return value;
		}
		assert(this.vtype >= VType.VT_U8 && this.vtype <= VType.VT_F64, 'numeric node expected');

		// If we got to here, format the value according to resolution:
		var text, resolution, digits;
		if (newUnits === undefined) {	// If the didn't pass in units
			newUnits = this.units;	// use our current units for simplicity
			resolution = this.resolution;
			digits = newDigits !== undefined ? newDigits : this.digits;
		} else {						// The passed in stuff
			digits = newDigits;	// For now, just give back an integer. FIXME: Calculate what it should be from our current stuff
			resolution = 1;
		}

		if (this.units == TagUnit.TU_TIME)
			return new Date(value / 1000).format(newFormat || "%yyyy.%MM.%dd %HH:%mm:%ss");
		else if ((this.units & 0xFF00) == TagUnitQuantity.TUQ_CURRENCY)
			return this._value.toCurrency(this.getUnitsText());
		else if (resolution > 1) {
			text = new String(Math.round(this.convertValue(newUnits) / resolution) * resolution);
		}
		else if (this.roles.has(Role.ROLE_PUMP_AUTO_HOA_SWITCH) || this.roles.has(Role.ROLE_PUMP_SOFTWARE_HOA) || this.roles.has(Role.ROLE_PUMP_OVERALL_HOA) || this.roles.has("softwareHOA")) {
			let list = ['Hand', 'Off', 'Auto'];
			text = list[value - 1];
		}
		else {
			text = value.toFixed(digits);	// format with 'this.digits' to the right of the decimal point
		}

		// Concatenate units if requested by caller:
		if (fUnits && (newUnits != TagUnit.TU_UNDEFINED))
			text += (' ' + UnitsMap.get(newUnits)?.abbrev);

		return text;
	}



	updatePsuedoValue(value: any) {
		assert(this.fPsuedo, "Node is not psuedo");
		this.value = value;
		this._value = this.value;
		this._updateSubscribers();
	}

	/**
	 * For now, all we need to do to refresh the Node is to check if we need to update the units.
	 */
	refresh() {
		this.units = this.cacheUnits + 0;
		this.setUnits();
		this._updateSubscribers();
	}

	_updateSubscribers(fFirstCall: boolean = false) {	// Update objects attached to this node (node value/quality presumably changed):
		//		assert (this.fQualityChanged || this.fValueChanged);	// Not always the case -- when there is a second subscriber to a node, this gets called.

		// Instead of checking if there is a timer active, we check if there is a pending write. If we try to check if the timer
		// exists, any widgets that subscribe to the written node will not be updated until the timer expires. While that is fine
		// for the widget that issued the write, it is awkward for any widgets that are merely reacting to the write.
		// It is safe to rely upon this.written because of the state machine of the LiveDataJob. If we have a written member,
		// there is an outstanding LiveDataJob that has just returned and we have not submitted the write. If we don't have a
		// written member, we have either not updated or this is the LBB's response to our write command. Whether or not this
		// forced a change on the LBB's node is inconsequential; the process of submitting the write is complete.
		/*
		if (this.written !== undefined)	// A write timer is active for this node
			return;			// Do not update the nodes
			*/

		if (this.subscribers) {
			this.subscribers.forEach( sub => {
				this._sendUpdate(sub)
			});
		}
	}

	_sendUpdate(sub: any) {
		if (this.subscribedCallbackMap.has(sub))
			this.subscribedCallbackMap.get(sub)!(this);
		else
			sub.update(this);	// Call update for value or quality change
		if (sub.redXElement) {	// quality changed, and dom element was specified:
			this._updateRedX(sub.redXElement, sub.redXMask!);
		}
	}

	_updateAlarmSubscribers(alarm: Alarm, fAdded: boolean, fChanged: boolean, fDeleted: boolean) {	// Update objects attached to this node who care about alarms
		if (this.subscribers)
			this.subscribers.forEach(
				(sub, object) => {
					if (object.onAlarm)
						object.onAlarm(this, alarm, fAdded, fChanged, fDeleted);
				}
			);
	}

	_updateConfiguredAlarmSubscribers(configuredAlarm: ConfiguredAlarm, fAdded: boolean) {	// Update objects attached to this node who care about configured alarms
		this.subscribers?.forEach((sub, object) => object.onConfiguredAlarm && object.onConfiguredAlarm(this, configuredAlarm, fAdded));
	}

	getDisplayName(fUnits?: boolean, fPretty: boolean = true) {// Return node name with CamelCase words separated by spaces:
		let name = this.name;
		if (this === this.tree.nodes[0]) // If we are the root node
			return this.tree.device.siteName;
		if (fPretty) {
			let firstFix = this.name.replace(/_+/g, ' ');					// Replace all underscores with spaces
			name = Localization.getLocalText(firstFix.replace(/([a-z0-9])([A-Z])/g, '$1 $2'));		// Word can end with lower-case letter or digit.
		}
		// Word always starts with an upper-case letter.
		return fUnits && this.units !== TagUnit.TU_UNDEFINED ? name + ' (' + UnitsMap.get(this.units)?.abbrev + ')' : name;
	}

	_updateRedX(element: HTMLElement, mask: number) {	// private method to show/hide the red x over an element for nodes with bad quality:r
		// Element must either be any HTML element, or it must be an SVG canvas element (SVGSVGElement).
		// If an HTML element, then you cannot directly alter the html text without wiping out the red x elements. A solution is
		// to enclose your changing text within a span element.
		assert(element instanceof Element);
		if (this.quality != TagQuality.TQ_GOOD || !this.tree.device.connected) {	// Draw a red X over the DOM element:
			if (element._redX) { // Show the existing red x:
				element.appendChild(element._redX);
				element._redX.setAttribute('visibility', 'visible');
			} else {		 // Create a new red X:
				// Create an SVG viewport that takes up the full content size of the parent.
				// Add it immediately to the DOM so we can query CSS styles as necessary:

				// Get element width and height:
				// var w = element.clientWidth||element.offsetWidth;
				// var h = element.clientHeight||element.offsetHeight;
				let elBoundingRect = element.getBoundingClientRect();
				var w = elBoundingRect.width;
				var h = elBoundingRect.height;

				// Compute red x coordinates to cover 90% of the parent element:
				var x1 = w * 0.05;
				var x2 = w * 0.95;
				var y1 = h * 0.05;
				var y2 = h * 0.95;

				element._redX = createSVGElement('svg', '', element, { width: w, height: h });	// Default CSS svg size as 100% x 100%

				//element.style.position = 'relative';		// Set parent's position to relative so we can absolutely position the X over it (will this screw up some layouts??)
				element._redX.style.position = 'absolute';	// Set svg to absolute
				element._redX.style.top = '0';				// Set svg origin top left to zero
				element._redX.style.left = '0';

				// Create a red 'X' that is 50% opaque and covers 4/5 of the parent:
				createSVGElement('line', '', element._redX, {
					x1: x1,
					y1: y1,
					x2: x2,
					y2: y2,
					'stroke-width': 3,
					stroke: 'rgba(255,0,0,.5)'
				});
				createSVGElement('line', '', element._redX, {
					x1: x2,
					y1: y1,
					x2: x1,
					y2: y2,
					'stroke-width': 3,
					stroke: 'rgba(255,0,0,.5)'
				});
			}
		} else { // Hide the red x:
			//console.log('hideRedX');
			if (element._redX)
				element._redX.setAttribute('visibility', 'hidden');
		}
	}

	setCacheUnits(units: TagUnit) {
		assert(this.fPsuedo) // if we aren't a pseudo node, the one true node tree should be the only thing that can change cacheunits
		this.cacheUnits = units;
		this.units = this.cacheUnits;
		this._unitConversion = null;
		this.subscribers.forEach(sub => sub.onNodeChanged(this, this));
	}

	setResolution(resolution: number) {
		assert(this.fPsuedo) // if we aren't a pseudo node, the one true node tree should be the only thing that can change resolutions
		this.resolution = resolution;
		this.subscribers.forEach(sub => sub.onNodeChanged(this, this));
		this.digits = Math.ceil(-Math.log(this.resolution) / Math.LN10);
	}

	/**
	 * Helper method to reset all units to a new units map. This only overrides, it does not replace the defaults
	 */
	setUnits(units?: TagUnit, fNotify: boolean = true) {
		let displayUnits = this.tree.unitsMap.get(this.units & 0xFF00) ?? units;	// Find if we should portray ourselves in different units
		if (displayUnits && displayUnits != this.units) {
			this.units = displayUnits;
			this.engMax = this.convertFromCacheToDisplay(this.cachedEngMax);
			this.engMin = this.convertFromCacheToDisplay(this.cachedEngMin);
			this.resolution = this.convertFromCacheToDisplay(this.cachedResolution);	// FIXME: make this more intelligent
			this.digits = Math.min(3, Math.max(0, Math.ceil(-Math.log(this.resolution) / Math.LN10)));
			if (fNotify)
				for (let widget of getDependentWidgets(this)) {
					widget.onNodeChanged(this);
				}
		}
	}

	insertAlphabetically(node: Node) {
		let index = this.children.length - 1;
		while (index >= 0) {
			let child = this.children[index];	// Convenience reference
			if(node.name > child.name)
				break;
			index--;
		}
		this.children.splice(index + 1, 0, node);
	}

	convertFromCacheToDisplay(value: any) {
		if (this._unitConversion != null)
			return value * this._unitConversion
		else if ((this.units & 0xFF00) != TagUnitQuantity.TUQ_TEMPERATURE) {
			this._unitConversion = convert(1, this.cacheUnits, this.units);
			return value * this._unitConversion!
		}
		return convert(value, this.cacheUnits, this.units);
	}

	convertFromDisplayToCache(value: any) {
		if (this._unitConversion !== null)
			return value / this._unitConversion
		if ((this.units & 0xFF00) != TagUnitQuantity.TUQ_TEMPERATURE) {
			this._unitConversion = convert(1, this.cacheUnits, this.units);
			return value / this._unitConversion!
		}
		return convert(value, this.units, this.cacheUnits);
	}

	static isValidName(name: string) {
		let kosher = false;
		do {
			if (name.length == 0)
				break;
			let first = name[0];
			if (!((first >= 'a' && first <= 'z') || (first >= 'A' && first <= 'Z') || first == '_'))
				break;
			for (let i = 1; i < name.length; ++i) {
				let next = name[i];
				if (!((next >= 'a' && next <= 'z') || (next >= 'A' && next <= 'Z') || (next == '_') || (next == '#') || (next >= '0' && next <= '9')))
					return false;
			}
			kosher = true;
		} while (false);
		return kosher;
	}

	static filterNodes(nodes: Node[], metaData: TagMetadata) : Node[]
    {
		return nodes.filter(node => Node.filterNode(node, metaData));
	}

	static isFolder(tag: Node) : boolean {     // helper method to check if our tag is a folder\
        return tag.children.length > 0 || tag.vtype == VType.VT_UNKNOWN;
        // TODO: Do we want to check vtype here? Or have an option to do so?
    }

	static filterNode(node: Node, metaData: TagMetadata): boolean {
		if (metaData.supportedRoles && !metaData.supportedRoles.some(role => node.roles.has(role)))
			return false;
		if (metaData.requiredProperties && !metaData.requiredProperties?.every(prop => Node.filterByProperty(prop, node)))
			return false;
		if (metaData.supportedUnits && !metaData.supportedUnits?.some(unit => Node.filterByUnit(unit, node)))
			return false;
		if (metaData.supportedTypes && !metaData.supportedTypes?.some(type => Node.filterByType(type, node)))
			return false;
		return true;
	}

    static filterByProperty(prop: TagPropertyOptions, node: Node): boolean
    {
        switch(prop)
        {
            case "logged":
                return (node.flags & NodeFlags.NF_LOG) != 0;
            case "writeable":
                return node.isWriteable;
            case "scaled":
                return node.engMin !== undefined && node.engMax !== undefined
            case "subscribeable":
                return node.vtype > 0 && node.vtype < 13;
            default:
                throw new Error(`Unexpected tag property specified: '${prop}'`);
        }
    }

    static filterByType(type: TagTypeOptions, node: Node): boolean
    {
        if (node.vtype == VType.VT_UNKNOWN)
            return false;
        switch(type)
        {
            case 'boolean':
                return node.vtype == VType.VT_BOOL;
            case 'folder':
                return node.vtype == VType.VT_BLOCK;
            case 'numeric':
                return node.vtype != VType.VT_BLOCK && node.vtype != VType.VT_BOOL && node.vtype != VType.VT_STRING;
            case 'string':
                return node.vtype == VType.VT_STRING;
            default:
                throw new Error(`Unexpected tag type specified: '${type}'`);
        }
    }

    static filterByUnit(unit: TagUnitOptions, node: Node): boolean
    {
        if(TagQuantityMap.has(unit))
            return (node.units & TagQuantityMap.get(unit)!) != 1;
        else
            throw(new Error('Unsupported unit provided for tagProperties'))
    }

	static calculateDataInterval(seconds: number) {
        let intervals = Node.intervals;
        for (let index = 0; index < intervals.length; index++) {
            let points = seconds / intervals[index];					// Number of data points displayed should we choose this interval
            if (points < 20000)											// Select the first interval that will load up fewer than x000 data points
                return intervals[index];
        }
        return intervals[intervals.length -1];
    };

	static intervals = [1, 10, 60, 600, 3600, 21600, 86400];	// Possible data intervals we will request for

};

// NodeTree -- holds root node and node index array:
export class NodeTree {
	device: Device;
	unitsMap: Map<TagUnitQuantity, TagUnit> = new Map();
	nodes: (Node | undefined)[] = [];
	requests: number;
	uninitialized: boolean;
	logged: Node[];
	onTagValueWritten: (tag: Tag, value: any, caller: NodeSubscriber) => void = (tag, value, caller) => {};
	constructor(device: Device) {
		this.device = device;
		this.nodes[0] = new Node();		// root of the node tree
		this.nodes[0].tree = this;				// store tree reference in each node
		this.requests = 0;				// total resolve node children requests outstanding
		this.uninitialized = true;				// node tree has not started requesting node children from whoville
		this.logged = [];
		this.unitsMap = this.device.cachedUnitsMap
	}

	getNode(nodeID: number): Node | undefined {
		return this.nodes[nodeID];
	}

	findNode(deviceRelativeName: string) {
		// Pull the given device-relative path name apart to find the referenced node in the tree.
		// Return undefined if no matching node is found.
		// The path is expected to be of the form
		//		DRPath	:: / [ <RelPath> ]
		//		RelPath	:: <folderName> [ / <RelPath> ]
		//		RelPath	:: <blockName>  [ . <RelPath> ]
		// Though we may want to constrain the language of paths to not allow folders as children of blocks.

		// Check for empty strings and for a missing leading slash avoid unnecessary allocations and
		// set up precondition for helper function.
		if (deviceRelativeName.length == 0 || deviceRelativeName.charAt(0) != '/')
			return undefined;

		// Special case: "/" names the root node.
		if (deviceRelativeName.length == 1)
			return this.nodes[0];

		// Use a helper function to recurse down the tree to find the node (strip leading slash from string):
		return this.nodes[0]?._findNodeHelper(deviceRelativeName.substring(1));
	}

	findNodesByRole(role: string) {	// Find node by role, starting at root node:
		return this.nodes[0]!.findByRole(role);
	}

	setDisconnected() {
		for (var i = 1; i < this.nodes.length; ++i)		// 0 is the root node and not a tag, do not set bad quality on it
			if (this.nodes[i] && this.nodes[i]?.vtype != VType.VT_UNKNOWN) {
				this.nodes[i]!.quality = TagQuality.TQ_OFFLINE;
				this.nodes[i]!._updateSubscribers(true);
			}
	}

	clear() { // Clear out the entire node tree, leaving the root
		for (var i = 1; i < this.nodes.length; ++i) {
			if (this.nodes[i]) {
				this.nodes[i]?.destroy();
			}
		}

		// added a check if first node is undefined, SKUN-23 Uncaught TypeError: Cannot read property 'children' of undefined (Node.js)
		if (this.nodes[0]) { this.nodes[0].children.length = 0; }	// delete all nodes except the root node
		this.nodes.length = 1;				// wipe out the node index, except for the root node
		this.requests = 0;					// wipe out any outstanding requests
		this.uninitialized = true;			// set to uninitialized
	}

	isComplete() { // Returns whether tree is completely formed from the server:
		return (!this.uninitialized && (this.requests == 0));
	}

	populate() { // Start asking server for branches of the node tree:
		this.uninitialized = false;
		//if (this.uninitialized) {
		//	this.uninitialized = false;
			this.populateChildren(0);	// get the branch starting at root (root node id==0)
		//}
	}

	populateChildren(id: number) {	// Ask server for the node tree branch with parent node id:
		//		console.log ('populateChildren(' + this.nodes[id].name + ')');

		var ldc = this.device.ldc;
		//if (this.getNode(id)!.children.length > 0)
		//	debugger;

		// Send a LiveData frame to the server to request Whoville:
		ldc.fm.buildFrame(LiveData.LDC_GET_NODE_CHILDREN, this.device.id);
		ldc.fm.push_u32(id);	// parent node id
		ldc.send();

		++this.requests;	// Once total requests outstanding go to zero, the node tree is complete.
	}

	getLoggedNodes(folder: Node, nodeArray: Node[]) { // Get an array of all logged nodes within the parent folder
		let root = folder ? folder : this.nodes[0]!;
		for (let i=0;i<root.children.length;i++) {
			let tag = root.children[i];
			if (tag.flags & NodeFlags.NF_LOG || tag.flags & NodeFlags.NF_DERIVED)
				nodeArray.push(tag)
			if (Node.isFolder(tag))
				this.getLoggedNodes(tag, nodeArray)
		}
	}

	onGetNodeChildrenResponse(fp: FrameParser) {
		var parent = this.nodes[fp.pop_u32()];
		// SKUN-130, reference to parent.children causes uncaught error if parent undefined or in an otherwise strange state and somehow gets past assert(parent, ...)
		// So lets be a little more explicit with our checks.
		if (typeof parent === 'undefined' || parent === null) {
			console.log("onGetNodeChildrenResponse(): Trying to get children of a parent node that is undefined or null");
			return;
		}
		assert(!this.uninitialized, 'device node tree should be initializing');
		assert(parent, 'parent should have been found');

		// Get number of children:
		var children = fp.pop_u16();

		// Deserialize children:
		for (var i = 0; i < children; ++i) {
			var legacyType	= fp.pop_u16();	// legacy node type. 1 = Folder, 3 = Tag
			var node = new Node (parent, ParseNodeFromFrame(fp));
			this.nodes[node.id] = node;
			node.parent = parent;
			if (legacyType == 1)  // legacy type is one (meaing it has children)
				this.populateChildren (node.id);	// Populate children
		}

		if (--this.requests == 0) {	// We are finished retrieving the node tree:
			if (!this.device.connected)
				this.setDisconnected();

			if (owner.ldc.user.preferences.units)
				for (let [quantity, newQuantity] of Object.entries(owner.ldc.user.preferences.units)) { // for each of our key value pairs mapped from our custom units
					this.unitsMap.set(parseInt(quantity), parseInt(newQuantity));      // set the units to the new setting
				}
			this.nodes.forEach(node => {
				if (node && node.subscribers.size > 0) {
					this.device.job.add(node);
				}
			})
			this.device.onNodeTreeComplete();
		}
	}

	getLoggedCount(): number {
		let logged: Node[] = [];
		this.getLoggedNodes(this.nodes[0]!, logged);
		return logged.length
	}

	refresh() {
		this.uninitialize();
		this.populateChildren(0);
	}

	uninitialize() {
		this.uninitialized = true;
		this.requests = 0;
	}
};

