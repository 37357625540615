import Icon from '../../../images/icons/name.svg';
import { Attribute } from '../../lib/attributes';
import { RegisterWidget, Widget } from '../../lib/widget';
import template from './tagbadge.html';
import { type DigitalGauge } from '../../digitalgauge';
import { type TagUnits } from '../tagunits/tagunits';
import { type TagName } from '../tagname/tagname';
import { CreateWidget } from '../../lib/createwidget';
import { TagAttribute, type TagDefinition } from '../../lib/tag';

@RegisterWidget({tag: 'tag-badge', displayName: 'Banner', template: template, icon: Icon, section: 'Tag Information'})
export class TagBadge extends Widget {
    // Elements to enliven
    protected nameWidget: TagName
    protected unitsElement: TagUnits
    protected valueElement: DigitalGauge
    protected topRow: HTMLElement;
    protected bottomRow: HTMLElement;

    // Attributes to let the end user fiddle
    @Attribute({displayName: 'Background Color', typeModifier: 'color'}) backgroundColor: string = 'var(--color-orange-8)';
    @Attribute({displayName: 'Text Color', typeModifier: 'color'}) textColor: string = 'var(--color-inverseOnSurface)';
    @Attribute({displayName: 'Pulse Animation'}) isAnimated : boolean = false;
    @Attribute({displayName: 'Show Units'}) showUnits: boolean = false;
    @Attribute({displayName: 'Override Name'}) overrideName: string;

    @TagAttribute({displayName: 'Tag'}) statusTag: TagDefinition;

    protected connectedCallback(): void {
        this.style.setProperty('--background-color', this.backgroundColor);
        this.style.setProperty('--text-color', this.textColor);
        if (this.isAnimated)
            this.style.setProperty('--animated', 'color');
        this.topRow = this.shadowRoot?.getElementById('top-row') as HTMLElement;
        this.bottomRow = this.shadowRoot?.getElementById('bottom-row') as HTMLElement;
    }

    protected enliven(): void {

        let name: HTMLElement = document.createElement('div');
        name.textContent = this.overrideName;
        if (typeof this.overrideName === 'undefined') {
            let nameWidget = document.createElement('tag-name') as TagName;
            nameWidget.nameTag = this.statusTag
            nameWidget.classList.add('name');
            nameWidget.formatName = true;
            nameWidget.showUnits = this.showUnits;
            name = nameWidget;
        }
        this.topRow.appendChild(name);
        if (this.showUnits) {
            let unitsWidget = document.createElement('tag-units') as TagUnits;
            unitsWidget.unitsTag = this.statusTag;
            this.topRow.appendChild(unitsWidget);
        }

        let widgetElement = document.createElement('div');
        widgetElement.classList.add('value');
        widgetElement.appendChild(CreateWidget(this.statusTag.tag));
        this.bottomRow.appendChild(widgetElement);
    }
}