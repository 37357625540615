import { Widget } from './widget';
import { VType, NodeQuality } from './node';
import { createElement, createUniqueId } from './elements';
import assert from './debug';
import owner from '../owner';
import './checkbox.css';

export default class Checkbox extends Widget  {
    constructor(element, properties) {
        super();
        this.copy(properties);	// Copy any passed in properties to this object

        assert (element, 'element is a required property');
        assert(this.node, 'Checkbox needs a node!');
        assert(this.node.isWriteable, "Checkbox needs a writable node!");
        assert(this.node.vtype == VType.VT_BOOL, 'Checkbox needs a Boolean node!');
        this.id             = createUniqueId();
        this.checkbox       = createElement('div', 'se-checkbox', element);
        this.box            = createElement('input', null, this.checkbox, null, {type:'checkbox', id:this.id});			// Create our input element
        this.label          = createElement('label', null, this.checkbox, this.name? this.name : '', {'htmlFor':this.id});
        this.box.onchange   = () => this.onChange();					// Bind a callback whenever the checkbox changes
        this.registerAsWidget(this.checkbox);								// Register as a widget

        if(!this.node.couldBeWritten())				// If the user can't ever write this node
            this.box.setAttribute('disabled', '');		// Disable the checkbox

        this.node.subscribe(this, this.box);		// Subscribe to the node we were passed in
    };

    update(node) {	// Node value/quality changed:
        assert(node === this.node);					// Only one node should call us back
        if (node.quality == NodeQuality.NQ_GOOD)		// If the node has good quality
            this.box.checked = node.getValue(this.fPending);		// Check the box if the node is true
    };

    destroy() {
        this.node.unsubscribe(this);	// Unsubscribe to the node we subscribed to
        this.checkbox.parentNode.removeChildren();
        this.unregisterAsWidget();		// Unregister like a good little widget
    };

    onChange(e) {	// This is called when the checkbox is changed by a user
        if (owner.settingsManager) {
            owner.settingsManager.pushPending(this.node, this.box.checked? 1 : 0, this);
        }
        else if (this.node.hasWritePermission())
            this.node.setValue(this.box.checked);
        else
            assert(false, 'trying to set a value without permissions')
    };
};
