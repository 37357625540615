import { Node as Tag } from "../../../node";
import { RegisterWidget, Widget } from "../../lib/widget";
import { Attribute } from "../../lib/attributes";
import SwitchIcon from '../../../images/icons/switch.svg';
import { TagAttribute } from "../../lib/tag";
import type { TagDefinition } from "../../lib/tag";
import template from './checkbox.html';

@RegisterWidget({tag: 'se-checkbox', displayName: 'Checkbox', icon: SwitchIcon, section: 'Input'})
export class Checkbox extends Widget {
    protected valueElement: HTMLDivElement;
    private _checked: boolean;
    onchange: ()=>void = ()=>{};

    @Attribute({displayName: 'On Color', typeModifier: 'color'}) onColor: string = '#2196F3';
    @Attribute({displayName: 'Off Color', typeModifier: 'color'}) offColor: string = '#ffffff';
    @Attribute({displayName: 'Disabled'}) disabled : boolean = false;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let checkbox = this.shadowRoot!.querySelector('.checkbox') as HTMLDivElement;
        let check = this.shadowRoot!.getElementById('check') as HTMLInputElement;

        if (this.disabled)
            checkbox.classList.add('disabled')
        else
            check.disabled = false;

        checkbox.style.setProperty('--off-color', this.offColor);
        checkbox.style.setProperty('--on-color', this.onColor);
        check.checked = this.checked;
        check.onchange = () => {
            this._checked = check.checked;
            this.onchange();
        }
    }


    get checked() {
        return this._checked;
    }

    set checked(v: boolean) {
        this._checked = v;
        let check = this.shadowRoot?.getElementById('check') as HTMLInputElement;
        if (check) {
            check.checked = this._checked;
        }
    }
}



@RegisterWidget({tag: 'se-tag-checkbox', displayName: 'Toggle Switch', icon: SwitchIcon, section: 'Input'})
export class TagCheckbox extends Checkbox {
    @TagAttribute({
        displayName: 'Switch Tag',
        shouldSubscribe: true,
        requiredProperties: ['writeable'],
        supportedTypes: ['boolean']
    }) toggleTag: TagDefinition;

    protected enliven(): void {
        let container = this.shadowRoot!.querySelector('.checkbox') as HTMLDivElement;
        let check = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        if (!this.toggleTag.tag.isWriteable)
            container.classList.add('disabled')
        else
            check.disabled = false;

        container.style.setProperty('--off-color', this.offColor);
        container.style.setProperty('--on-color', this.onColor);

        check.onchange = () => this.toggleTag.tag.setPendingValue(check.checked ? 1 : 0, this);
        let state = this.toggleTag.tag.getValue() != 0;
        check.checked = state;
    }

    update(tag: Tag): void {
        let check = this.shadowRoot!.getElementById('check') as HTMLInputElement;
        let state = tag.getValue(this) != 0;
        check.checked = state;
    }
}

