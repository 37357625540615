import Icon from '../../../images/icons/name.svg';
import { TagAttribute } from '../../lib/tag';
import { RegisterWidget, Widget } from '../../lib/widget';
import type { TagDefinition } from "../../lib/tag";

const template = /*html*/`
<style>
    :host{
        display: inline-block;
        height: max-content;
    }#text{
        display: inline-block;
        align-items:center;
        overflow:hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        pointer-events: none;
    }
</style>
<div id=text></div>`

@RegisterWidget({tag: 'tag-description', displayName: 'Tag Description', icon: Icon, section: 'Tag Information'})
export class TagDescription extends Widget {
    @TagAttribute({displayName: 'Tag'}) descriptionTag: TagDefinition;

    render(): Node | null {
        let templateElement = document.createElement('template');
        templateElement.innerHTML = template;
        return templateElement.content.cloneNode(true);
    }

    protected enliven(): void {
        let text = this.shadowRoot?.getElementById('text')!;
        text.textContent = this.descriptionTag.tag.description;
    }
}