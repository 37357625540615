import assert from './debug';
import { NodeTree } from './node';
import LiveDataJob from './livedatajob';
import {AlarmSet, ConfiguredAlarms} from './alarm';
import {fire} from './utilities';
import {PumpTwins} from './curves';
import owner from '../owner';
import LiveDataClient from './livedataclient';
import FrameParser from './frameparser';
import { GroupInfo } from './livedataclient';
import LiveData from './livedata';
import { TagUnit, TagUnitQuantity } from './widgets/lib/tagunits';
import { Role } from './role';
import { Tag } from './widgets/lib/tag';

export interface PDSComponent {
    type: PDSType;
    pattern: string;
    help: string;
    min?: number;
    max?: number;
    value: any;
	values?: string[];
}

export enum PDSType {
    PDST_TextInput      = 0,
    PDST_NumericInput   = 1,
    PDST_Text           = 2,
	PDST_Select 		= 3,
	PDST_Node 			= 4
}

export interface DashboardDetails {
	version: number;
	id: number;
}

export class Driver {
	name: string;
	fRequiresDataType: boolean = false;
    sources: Map<string, PotentialDataSource> = new Map();
	type: string;
	constructor(fp: FrameParser, device: Device) {
		this.name 				= fp.pop_string();
		this.fRequiresDataType 	= fp.pop_u8() == 1;
		//this.type  				= fp.pop_string();
		let size        		= fp.pop_u32(); // how many sources does this driver have?
		for (let j=0;j<size;++j) {      // For each source
			let source = new PotentialDataSource(fp, device);
			this.sources.set(source.name, source);
		}
	}

	getSourceFromRegisterName(name: string) : string | undefined {
		assert(!this.fRequiresDataType);
		for (let [sourceName, pds] of this.sources) {
			if (pds.validateRegisterName(name))
				return sourceName;
		}
		assert(false);
	}
}

export class PotentialDataSource {
    name: string;
    hint: string;
    help: string;
    fDiscrete: boolean;
    fWriteable: boolean;
    components: PDSComponent[];
	defaultRegister: string;
	device: Device;
	constructor(fp: FrameParser, device: Device) {
		this.device 			= device;
		this.name               = fp.pop_string(),
		this.fDiscrete          = fp.pop_u8() == 1,
		this.fWriteable         = fp.pop_u8() == 1,
		this.hint               = fp.pop_string(),
		this.help               = fp.pop_string(),
		this.components         = [],
		this.defaultRegister	= ""
		let componentSize = fp.pop_u8();
		for (let k=0;k<componentSize;++k) {
			let component: PDSComponent = {
				type: fp.pop_u8(),
				help: fp.pop_string(),
				pattern: '',
				value: undefined
			}
			switch (component.type) {
				case PDSType.PDST_TextInput:
					component.value     = fp.pop_string();
					component.pattern   = fp.pop_string();
				break;
				case PDSType.PDST_NumericInput:
					component.min 		= fp.pop_u32();
					component.max 		= fp.pop_u32();
					component.value 	= component.min;
					component.pattern 	= "^[0-9]+";
				break;
				case PDSType.PDST_Text:
					let value = fp.pop_string()
					component.value = value;
					component.pattern = "^" + value;
				break;
				case PDSType.PDST_Select:
					let count = fp.pop_u16();
					let values: string[] = [];
					for (let i=0;i<count;++i)
						values.push(fp.pop_string());
					component.values 	= values;
					component.value 	= values[0] ?? '';
					if (component.values?.length > 0)
					{
						component.pattern 	= "(";
						component.values.forEach(value => {
							component.pattern += `${value}|`
						});
						component.pattern = `${component.pattern.slice(0,-1)})`;
					}
					else
						component.pattern = '';
				break;
				case PDSType.PDST_Node:
					component.pattern 	= "^[A-Z]+(\.[A-Z0-9]+)*:\/([A-Za-z0-9]+(\/[A-Za-z0-9]+)*)?$";
					component.value 	= this.device.key + ':/';
				break;
				default:
					assert(false, "Received a bad PDS_Type");
				break;
			}
			this.defaultRegister += component.value;
			this.components.push(component);
		}
	}

	validateRegisterName(registerName: string): boolean {
		let copy = registerName.slice();
		for (let i=0;i<this.components.length;++i) {
			let component 	= this.components[i];
			let match: RegExpMatchArray | null = null;
			switch (component.type) {
				case PDSType.PDST_Text:
				case PDSType.PDST_TextInput:
				case PDSType.PDST_Node:
					match       = copy.match(component.pattern); 	// get the first string that matches the component pattern
				break;
				case PDSType.PDST_NumericInput:
					match 			= copy.match(component.pattern);
					if (!match || match.length < 1)
						return false;
					let matchString = copy.substring(0, match[0].length)
					let parsedNumber = parseFloat(matchString);
					if (parsedNumber < component.min! || parsedNumber > component.max!)
						return false;
				break;
				case PDSType.PDST_Select:
					if (!component.values)
						return false;
					for (let j=0;j<component.values.length;++j) {
						match = copy.match("^"+component.values[j]);
						if (match)
						 	break;
					}
			}
			if (!match || match.length < 1)
				return false;
			copy = copy.substring(match[0].length);     // lop off the portion of the string that matched so we can move to the next component
		};
		return copy.length == 0;
	}
}

export interface DeviceAttributes {
	id: number;
	cachedTree: boolean;
	connected: boolean;
	key: string;
	owner: string; // Company name
	siteName: string;
	timeZone: string;
	latitude: number;
	longitude: number;
	groups: GroupInfo[];
	cachedUnitsMap: Map<TagUnitQuantity, TagUnit>;
	customFiles: string[];
	companyKey: string;
}

export function parseDeviceFromFrame(fp: FrameParser): DeviceAttributes {
	let deviceAttributes: Partial<DeviceAttributes> = {};

	deviceAttributes.id         = fp.pop_u32();      // Device id
	deviceAttributes.connected  = fp.pop_u8() != 0;  // True if the device is connected
	deviceAttributes.cachedTree = fp.pop_u8() != 0;  // True if the device's NodeTree is on whoville
	deviceAttributes.key        = fp.pop_string();   // device key
	deviceAttributes.owner      = fp.pop_string();   // owner name
	deviceAttributes.siteName   = fp.pop_string();   // site name
	deviceAttributes.timeZone   = fp.pop_string();   // Olson time zone
	deviceAttributes.latitude   = fp.pop_f64();
	deviceAttributes.longitude  = fp.pop_f64();
	deviceAttributes.groups     = [];                // Empty array. Will be filled out by the LDC

	deviceAttributes.cachedUnitsMap = new Map();
	var unitCount 		= fp.pop_u8();			// Number of quantity/unit pairs appended
	for (var i = 0; i < unitCount; ++i) {		// For each pair
		var quantity	= fp.pop_u16();			// Get the quantity
		let newQuantity = fp.pop_u16();
		deviceAttributes.cachedUnitsMap.set(quantity, newQuantity);	// Get the units and save in the map on the tree
	}

	deviceAttributes.customFiles = [];
	var fileCount				 = fp.pop_u16();						// Number of custom file names appended
	for (var i = 0; i < fileCount; ++i)
		deviceAttributes.customFiles.push(fp.pop_string());					// Add the name of each custom file to the array
	if (fp.command == LiveData.WVCR_GET_DEVICE_INFO_V2)
		deviceAttributes.companyKey = fp.pop_string();

	return deviceAttributes as DeviceAttributes;
}

export interface PumpTwinResponder {
	onPumpTwinsComplete: (pumpTwins: PumpTwins) => void;
}

// This module defines the Device and DeviceList objects:
export class Device implements DeviceAttributes {
	onConnect: 						Map<any, any> = new Map();
	onDisconnect: 					Map<any, any> = new Map();
	ldc: 							LiveDataClient;
	id: 							number;
	cachedTree: 					boolean;
	_connected: 					boolean;
	key: 							string;
	owner: 							string;
	siteName: 						string;
	timeZone: 						string;
	latitude: 						number;
	longitude: 						number;
	groups: 						GroupInfo[];
	tree: 							NodeTree;
	customFiles: 					string[];
	job: 							LiveDataJob;
	alarms: 						AlarmSet;
	configuredAlarms: 				ConfiguredAlarms;
	nodeTreeRequests: 				(()=>void)[] = [];
	fOutstandingTreeRequest: 		boolean = false;
	pumpTwinRequests: 				PumpTwinResponder[] = [];
	driverRequests: 				((drivers: Driver[])=>void)[] = [];
	fOutstandingPumpTwinRequest: 	boolean = false;
	graphId: 						number;
	dashboards: 					Map<string, DashboardDetails> = new Map();
	pumpTwins: 						PumpTwins;
	index: 							number;
	drivers: 						Driver[] = [];
	fAssetOnly: 					boolean;
	tagger:							boolean = false;
	name: 							string;
	cachedUnitsMap: 			   	Map<TagUnitQuantity, TagUnit> = new Map();
	_isWriteable: 					boolean | null = null;
	companyKey: 					string;
	oldTree: 						NodeTree | null = null
	private isInitialized:			boolean = false;
    constructor(ldc: LiveDataClient, deviceAttributes: DeviceAttributes) {
		this.onConnect = new Map();
		this.onDisconnect = new Map();

		this._connected = false;

		this.ldc = ldc;  // LiveDataClient

		Object.assign(this, deviceAttributes);
	}

	initialize() {
		this.tree				= new NodeTree(this);						// empty node tree
        this.job				= new LiveDataJob(this);					// Create the Live Data Job
		this.pumpTwins			= new PumpTwins(this);						// empty curves class
        this.alarms				= new AlarmSet(this, owner.ldc);			// set of all active and unacknowledged alarms
		this.configuredAlarms 	= new ConfiguredAlarms(owner.ldc, this);	// Create a set of configured alarms
		this.graphId 			= this.ldc.registerGraph(this);
		this.alarms.subscribe();									// go ahead and get the alarms
		this.isInitialized = true;
	}

	get connected() {
		return this._connected; // Note: this is an integer and will crash whoville if it ever isn't.
	}

	set connected(status) {
		if (status === this._connected)
			return; //don't do anything if nothing has changed
		this._connected = status;
		if (status) {
			// If we already initialized, we need to build up all our nodes again
			if (this.isInitialized) {
				if (this.tree.isComplete()) // Have an existing tree, cache it
					this.oldTree = this.tree;
				this.job = new LiveDataJob(this);
				this.tree = new NodeTree(this);
				this.tree.populate();
			}
		} else {
			fire(this.onDisconnect,this);
			this.fOutstandingTreeRequest 		= false;
			this.fOutstandingPumpTwinRequest 	= false;
		}
	}

	isTreeComplete() {	// Device has a complete, fully-formed node tree
		return this.tree.isComplete();
	}

	requestNodeTree(callback: () => void) {
		if (this.tree.isComplete()) { //we've already got it, give it back
			callback();
		} else {	//we should wait for it
			this.nodeTreeRequests.push(callback);

			if (!this.fOutstandingTreeRequest) {
				this.fOutstandingTreeRequest = true;
				if (this.connected || this.cachedTree) {
					this.tree.populate();
				}
			}
		}
	}

	isIncludedIn(group: GroupInfo): boolean {
		if (group.devices.includes(this.key))
			return true;
		else if (group.children.length > 0) {
			return group.children.some((childGroup) => this.isIncludedIn(childGroup))
		}
		else return false;
	}

	onNodeTreeComplete() {
		this.fOutstandingTreeRequest = false;
		this.tagger = this.tree.findNodesByRole(Role.ROLE_VERSION).length > 0;
		if (this.configuredAlarms && !this.configuredAlarms.isInitialized)
			this.configuredAlarms.request();	// Request all configured alarms for the site. TODO: Should this come across with the node metadata?

		this.oldTree = null; // No need to keep the old tree around any longer

		for (const callback of this.nodeTreeRequests) {
			callback();
		};
		this.nodeTreeRequests = []; //clear callbacks
		this.job && this.job.refresh();
	}

	requestPumpTwins(listener: PumpTwinResponder) {
		if (this.pumpTwins && this.pumpTwins.isComplete) {
			listener.onPumpTwinsComplete(this.pumpTwins);
		}
		else if (!this.tree.isComplete()) {
			this.requestNodeTree(() => this.requestPumpTwins(listener))
			return
		}
		else {
			this.pumpTwinRequests.push(listener)
			if (!this.fOutstandingPumpTwinRequest) {
				this.fOutstandingPumpTwinRequest = true;
				this.pumpTwins.populate();
			}
		}
	}

	onPumpTwinsComplete() {
		for (const listener of this.pumpTwinRequests) {
			listener.onPumpTwinsComplete(this.pumpTwins);
		};
		this.fOutstandingPumpTwinRequest 	= false; 				// are we waiting for curves?
		this.pumpTwinRequests = [];
	}

	getDisplayName(fUnits?: boolean) {
		return this.siteName;
	}

	requestDrivers(callback: (drivers: Driver[])=>void) {
		assert(this.ldc.isLoggedIn());
		this.driverRequests.push(callback)
		owner.ldc.getDrivers(this.graphId, this.id);
	}

	/**
     * We just received information about our device's drivers
     * We build up a map of driver names -> a map of the driver's sources
     * @param {FrameParser} fp
     */
	 onGetDriversResponse(fp: FrameParser) {
		this.drivers = [];
		let count = fp.pop_u16();
        for (let i=0; i<count; ++i)
            this.drivers.push(new Driver(fp, this));

		for (let callback of this.driverRequests) {
			callback(this.drivers);
		}

		this.driverRequests = [];
	}

	isWriteable() {
		return this._isWriteable ?? this.findPermissions();
	}

	findPermissions() {
		this._isWriteable = owner.ldc.user.canWrite(this) ?? false;
		return this._isWriteable;
	}
};

// DeviceList -- holds all visible devices, both connected and disconnected:
export class DeviceList {
	array: Device[];
    constructor() {
    	this.array = [];	// indexed array of nodes (same nodes as in root, but in flattened array)
    }

    //Prototype values for all DeviceList objects to inherit upon construction:
	push(device: Device) {
		device.index = this.array.length;	// Store the DeviceList index in device
		this.array.push(device);			// And store device at end of array
	}

	size() {
		return this.array.length;
	}

	clear() {
		this.array.length = 0;	// idiomatic way to clear JavaScript arrays
	}

	at(index: number) {
		return this.array[index];
	}

	get(id: number): Device | undefined { // get device by id O(n):
		for (var i = 0, device; device = this.array[i]; ++i) {
			if (device.id == id)
				return device;
		}
		return undefined;
	}

    getByKey(key: string) : Device | undefined { // get device by key O(n):
        for (var i = 0, device; device = this.array[i]; ++i) {
			if (device.key === key)
				return device;
		}
		return undefined;
    }
};
