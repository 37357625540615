import owner from "../../owner";
import View from "./view";
import AddIcon from '../images/icons/add.svg';
import { createElement, createUniqueId } from '../elements';
import { Alarm, ConfiguredAlarm, AlarmSet, AlarmConditions, AlarmTypes, AlarmTypeText, IConfiguredResponder, AlarmSettings } from '../alarm';
import './eventfeedview.css';
import TreeView, { TreeViewTypes } from "./treeview";
import ViewModal, { ViewModalProperties } from "../viewmodal";
import { Node, VType, NodeTree } from '../node';
import LiveDataClient from '../livedataclient';
import { Device } from '../device';
import { Treeable, TreeViewProperties, InfoColumn } from './treeview';
import EditIcon from '../images/icons/edit.svg';
import { WritesEnabler } from "../dialog";
import { create } from "domain";
import Switch from "../components/switch";
import { ValueFilter } from "../tagfilter";
import assert from "../debug";


export default class EventFeedView extends View  implements IConfiguredResponder{
	ldc: LiveDataClient;
	device: Device;
	alarms: AlarmSet;
	tree: NodeTree;
	siteName: string;
	displayCount: number = 40;
	dateFormat: string = '%yy/%MM/%dd %HH:%mm:%ss';
	alarmSet: ConfiguredAlarm[] = [];
	treeContainer: HTMLElement;
	treeView: TreeView;
	fSmall: boolean;
    constructor(device: Device, ldc: LiveDataClient) {
        super();
        this.ldc            = ldc;
        this.device         = device;
        this.tree           = this.device.tree;
        this.siteName       = this.device.siteName;
    }

    initialize(parent: HTMLElement): View {
		super.initialize(parent);
        this.wrapper    = createElement('div', 'event-feed', this.parent)
		this.treeContainer	= createElement('div', 'event-feed__alarms', this.wrapper);

		this.device.configuredAlarms.registerCallback(this);

		let addAlarmButton = createElement('button', 'se-button event-feed__add', this.wrapper);
		createElement('img', 'event-feed__add__icon', addAlarmButton, undefined, {'src':AddIcon});
		createElement('div', 'event-feed__add__text', addAlarmButton, 'Add Alarm');
		let addProperties = {
			type: 		'select',
			folder: 	this.device.tree.nodes[0],
			fPseudo: 	true,
		}
		addAlarmButton.onclick = () => {
			new ViewModal(new AlarmConfigView(this.device, undefined, true),
			{
				title: 'Add Alarm',
				titleBackgroundColor: 'var(--color-alarm-1)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				maxWidth:'800px',
				closeCallback:this.buildAlarmList.bind(this),
			});
		};
		this.fInitialized = true;
		return this;
	}

	selectCallback(alarm: Treeable) {
		if (alarm instanceof ConfiguredAlarm) {
			let configModal = new ViewModal(new AlarmConfigView(this.device, alarm), {
				title: alarm.name,
				titleBackgroundColor: 'var(--color-alarm-1)',
				titleTextColor: 'var(--color-inverseOnSurface)',
				maxWidth:'800px',
				closeCallback:this.buildAlarmList.bind(this),
				buttons: [
					{
						title: 'Accept',
						callback: () => {
							configModal.view.submitAlarm();
							return false;
						},
						borderColor: 'var(--color-primary)'
					},
					{
						title: 'Cancel',
					}
				]
			});
		}
		//this.device.configuredAlarms.modifyAlarmConfiguration(alarm.node, alarm.type, )
	}

	onAlarmConfigured(alarm: ConfiguredAlarm, fNew: boolean) {
		if (fNew)
			this.buildAlarmList();
		else
			this.treeView.findTagCard(alarm)?.then(tagCard => tagCard?.refresh())
	}

	onAlarmsConfigured(alarms: ConfiguredAlarm[]) {
		this.alarmSet.push(...alarms)
		this.buildAlarmList();
	}

	buildAlarmList() {
		if (this.treeView)
			this.treeView.destroy();
		this.treeContainer.removeChildren();
		let categories: {[key: string]:ConfiguredAlarm[]} = {};
		let alarmFolder: Treeable = {
			treeChildren: [],
			name: 'Alarms',
			getDisplayName: () => {return 'Alarms'}
		}
		this.device.configuredAlarms.configured.forEach(alarm => { // check for each alarm's category and populate an array of categories
			if (!categories[alarm.category]) { 		// if we haven't already added this category
				categories[alarm.category] = [];	// add it!
			}
			categories[alarm.category].push(alarm) 	// add the alarm to its respective category folder
		});

		for (let [category, alarms] of Object.entries(categories))  { // for each category, add a row
			let categoryFolder: Treeable = {
				treeChildren:[],
				parent: alarmFolder,
				name: category + ' Alarms',
				getDisplayName:()=>{return category + ' Alarms'},
			};
			categoryFolder.treeChildren.push(...alarms); // add all alarms to each category
			alarmFolder.treeChildren.push(categoryFolder); // add the category to the alarms folder
		}

		let infoColumns: InfoColumn[] = [
			{
				header: 'Enabled',
				info: (tag) => {
					if (tag instanceof ConfiguredAlarm) {
						let checkWrapper        = createElement('div', 'se-checkbox');
						let id                  = createUniqueId()
						let checkBox           	= createElement('input', undefined, checkWrapper, undefined, {type:'checkbox', checked:!tag.fDisabled, 'id':id})
						createElement('label', '', checkWrapper, '', {'htmlFor':id});
						checkBox.onchange = () => {
							this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, tag.setting, tag.severity, tag.category, tag.message, tag.onDelay, tag.offDelay, !checkBox.checked, ()=>{checkBox.checked = !checkBox.checked})
						};
						return checkWrapper;
					}
					else {
						return createElement('div');
					}
				},
				width: 'minmax(90px, 1fr)'
			},
			{
				header: 'Setting',
				info: tag => {
					if (tag instanceof ConfiguredAlarm && tag.node?.vtype != VType.VT_STRING && tag.node?.vtype != VType.VT_BOOL) {
						let container = createElement('div', 'alarm-config__setting-row__setpoint');
						let input = createElement('input', 'spinner', container, undefined, {type: 'number', value: tag.node?.convertFromCacheToDisplay(tag.setting.toString())});
						createElement('div', 'alarm-config__setting-row__units', container, tag.node?.getUnitsText());
						input.onchange = () => {
							let value = tag.node?.convertFromDisplayToCache(input.valueAsNumber);
							this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, value, tag.severity, tag.category, tag.message, tag.onDelay, tag.offDelay, tag.fDisabled, ()=>{value = tag.setting})
						}
						return container;
					}
					else
						return createElement('div');
				},
				width: 'minmax(180px, 1fr)'
			},
			{
				header: 'On Delay (s)',
				info: tag => {
					if (!(tag instanceof ConfiguredAlarm))
							return createElement('div');
					let onDelay = createElement('input', 'spinner', undefined, undefined, {type: 'number', value: tag.onDelay.toString()});
					onDelay.onchange = () => {
						this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, tag.setting, tag.severity, tag.category, tag.message, onDelay.valueAsNumber, tag.offDelay, tag.fDisabled, ()=>{onDelay.valueAsNumber = tag.onDelay});
					};
					return onDelay;
				},
				width: 'minmax(120px, 1fr)'
			},
			{header: 'Off Delay (s)', info: tag => {
				if (!(tag instanceof ConfiguredAlarm))
						return createElement('div');
				let offDelay = createElement('input', 'spinner', undefined, undefined, {type: 'number', value: tag.offDelay.toString()});
				offDelay.onchange = () => {
					this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, tag.setting, tag.severity, tag.category, tag.message, tag.onDelay, offDelay.valueAsNumber, tag.fDisabled, ()=>{offDelay.valueAsNumber = tag.offDelay});
				};
				return offDelay;
			}, width: 'minmax(120px, 1fr)'},
			{header: 'Severity', info: tag => {
				if (tag instanceof ConfiguredAlarm) {
					let severity = createElement('input', 'spinner', undefined, undefined, {type: 'number', value: tag.severity.toString()});
					severity.onchange = () => {
						this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, tag.setting, severity.valueAsNumber, tag.category, tag.message, tag.onDelay, tag.offDelay, tag.fDisabled, ()=>{severity.valueAsNumber = tag.severity})
					}
					severity.style.width = '90%';
					return severity;
				}
				else
					return createElement('div');
			}, width: 'minmax(120px, 1fr)'},
			{header: 'Message', info: tag => {
				if (tag instanceof ConfiguredAlarm && tag.node?.vtype != VType.VT_STRING) {
					let message = createElement('input', 'spinner', undefined, undefined, {type: 'text', value: tag.message.toString()});
					message.style.textAlign = 'left';
					message.onchange = () => {
						this.device.configuredAlarms.modifyAlarmConfiguration(tag.node!, tag.type!, tag.setting, tag.severity, tag.category, message.value, tag.onDelay, tag.offDelay, tag.fDisabled, ()=>{message.value = tag.message})
					}
					message.style.width = '90%';
					return message;
				}
				else
					return createElement('div');
			}, width: 'minmax(240px, 1fr)'},
			{header: '', info: tag => {
				if (!(tag instanceof ConfiguredAlarm))
						return createElement('div');
				let editButton = createElement('button', 'se-button');
				editButton.style.minWidth = '48px';
				editButton.style.margin = '0';
				createElement('img', '', editButton, '', {src: EditIcon});
				editButton.onclick = () =>	{
					let configModal = new ViewModal(new AlarmConfigView(this.device, tag),
					{
						title: `${tag.getDisplayName()}`,
						titleBackgroundColor: 'var(--color-alarm-1)',
						titleTextColor: 'var(--color-inverseOnSurface)',
						maxWidth: '800px',
						buttons: [
							{
								title: 'Accept',
								callback: () => {
									configModal.view.submitAlarm();
									return false;
								},
								borderColor: 'var(--color-primary)'
							},
							{
								title: 'Cancel',
							}
						]
					});
				}
				return editButton;
			}, width: 'minmax(72px, 1fr)'},
		];

		this.fSmall = innerWidth < 1200

		let alarmViewProperties: TreeViewProperties = {
			type:TreeViewTypes.TVT_FINDER,
			// hideNav: true,
			clickCallback: this.fSmall ? this.selectCallback.bind(this) : undefined,
			folder: alarmFolder,
			recursiveOpen: !this.fSmall,
			infoColumns: this.fSmall ? undefined : infoColumns
		}

		this.treeView = new TreeView(alarmViewProperties).initialize(this.treeContainer);
	}

	resize() {
		if (this.fSmall != innerWidth < 1200)
			this.buildAlarmList();
	}

    destroy() {
        this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
    }
}

export class AlarmConfigView extends View {
	alarm: ConfiguredAlarm | undefined;
	submit: HTMLButtonElement;
	alarmTextContainer: HTMLElement;
	alarmModal: ViewModal<TreeView>;
	fNew: boolean;
	owner: EventFeedView;
	modifiedSettings: AlarmSettings;
	device: Device;
	tag: Node | undefined;
	constructor(device: Device, alarm?: ConfiguredAlarm,  fNew: boolean = false) {
		super();
		this.alarm 	= alarm;
		this.device = device;
		this.fNew = fNew;
		if(this.alarm)	// We are modifying an existing alarm
			this.modifiedSettings = AlarmSettings.cloneSettings(alarm);	// Clone the current alarm settings so that we can keep track of pending alarm changes (that the user may or may not successfully submit)
	}

	initialize(parent: HTMLElement) {
		super.initialize(parent);
		this.wrapper = createElement('div', 'alarm-config__wrapper');
		this.parent.prepend(this.wrapper)

		if (!this.alarm) {
			this.fNew = true;
			let addOptions: TreeViewProperties = {
				folder: this.device?.tree.nodes[0],
				type: TreeViewTypes.TVT_SELECT_ACCEPT,
				selectFilters: {
					andFilters: [new ValueFilter(true)],
					orFilters: []
				},
				acceptCallback: (tag: Node[]) => {
					this.alarm = new ConfiguredAlarm(tag[0]);
					this.modifiedSettings = AlarmSettings.cloneSettings(this.alarm); // Keep track of any modified settings
					this.parent.removeChild(this.wrapper);
					this.initialize(parent);
				},
			}
			this.alarmModal = new ViewModal(new TreeView(addOptions), {
				maxWidth: '800px',
				titleBackgroundColor: 'var(--color-primary)',
				title: 'Choose a Tag',
				titleTextColor: 'var(--color-inverseOnSurface)',
				closeCallback: () => {
					if (!this.alarm)
						this.modal && this.modal.destroy();
					return true;
				}
			});
		}

		else {
			if(this.fNew)
				this.modifiedSettings.imbueSettings(this.alarm); // If we're in the process of creating an alarm, make sure alarm settings and modified settings are synced on each init call
			if (!this.submit && this.fNew) {
				let bottomContainer = createElement('div', 'alarm-config__bottom-container', this.parent);
				this.submit = createElement('button', 'se-button alarm-config__submit alarm-config__disabled', bottomContainer, 'Save Alarm');
			}
			//let container = createElement('div', 'alarm-config__delete',  this.wrapper);
			//createElement('img', 'alarm-config__delete__icon', container, undefined, {'src':DeleteIcon});

			let path 	= createElement('div', 'alarm-config__section-title', this.wrapper, this.alarm.node?.getDeviceRelativePath());

			let enabledContainer  = this.createAlarmSection(this.wrapper, 'Enable Alarm', 'Whether or not this alarm is enabled and will be activated and deactivated');
			new Switch(enabledContainer, !this.alarm.fDisabled, undefined, (state) => {
				this.modifiedSettings.fDisabled = !state;
				this.alarmSettingsChanged(this.wrapper, this.modifiedSettings);
			})
			let setpointContainer = this.createAlarmSection(this.wrapper, 'Alarm Type', 'The alarm type defines the condition that will activate an alarm. For example, a high alarm will be activated when the tag\'s value is higher than a defined setpoint while a true alarm will be activated when the alarm\'s value is true. The set of available alarm types is determined by the underlying tag\'s type.')
			let typeSelect = createElement('select', 'alarm-config__select', setpointContainer);

			let numericOptions = this.device.alarms.fAlarmsUpdated? [this.createOption(1), this.createOption(2), this.createOption(3), this.createOption(4), this.createOption(9), this.createOption(10)] : [this.createOption(1), this.createOption(2), this.createOption(3), this.createOption(4)];	// Drop down options for numeric nodes
			let boolOptions = [this.createOption(5), this.createOption(6), this.createOption(7), this.createOption(8)];	// Drop down options for boolean nodes
			let stringOptions = [boolOptions[0], boolOptions[2]];	// Strings are only allowed to be True alarms and True Events
			if (this.alarm.node?.vtype == VType.VT_STRING) {		    // If the node is a string
				this.alarm.type = 5;
				createElement('option', '', typeSelect, AlarmTypeText.get(AlarmConditions.TRUE_ALARM), {'value':AlarmConditions.TRUE_ALARM.toString()});
			}
			else		// If the node is a boolean
				this.fillOutOptions((this.alarm.node?.vtype == VType.VT_BOOL)? boolOptions : numericOptions, this.alarm, typeSelect);		// Load up the appropriate drop down options for a boolean


			let defaultOption = createElement('option', 'select-menu__option-default', typeSelect, 'Select Type', {disabled: true});
			typeSelect.prepend(defaultOption)
			typeSelect.value = this.modifiedSettings.type?.toString() ?? '0';
			typeSelect.onchange = () => {
				this.modifiedSettings.type = parseInt(typeSelect.options[typeSelect.selectedIndex].value);
				this.parent.removeChild(this.wrapper);
				this.initialize(parent);
				return;
			}

			if (this.alarm.type) {
				if (this.alarm.node?.vtype != VType.VT_STRING && this.alarm.node?.vtype != VType.VT_BOOL) {
					var alarmCategory = ['', 'Is lower than', 'Is lower than', 'Is higher than', 'Is higher than', 'Is true', 'Is false', 'Is true', 'Is false', 'Is equal to', 'Is not equal to'];
					let setpointContainer = this.createAlarmSection(this.wrapper, 'Alarm Setpoint', 'The alarm setpoint determines the relative value or logical condition that will activate the alarm.')
					createElement('div', 'tag-viewer__item__title', setpointContainer, alarmCategory[this.alarm.type]);
					let settingSpinner = createElement('input', 'spinner', setpointContainer, '', { 'type': 'number' });
					createElement('div', 'alarm-config__setting-row__units', setpointContainer, this.alarm.node?.getUnitsText())
					if (this.alarm.setting !== undefined)
						settingSpinner.value = this.alarm.node?.convertFromCacheToDisplay(this.alarm.setting).toString();
					settingSpinner.onchange = () => {
						this.modifiedSettings.setting = this.alarm!.node?.convertFromDisplayToCache(settingSpinner.valueAsNumber);
						this.alarmSettingsChanged(this.wrapper, this.modifiedSettings);
					}
				}

				let setpointContainer = this.createAlarmSection(this.wrapper, 'Alarm Severity', 'Each alarm has a severity level that defines the importance of the alarm. Alarm severity values range from 0 to 1000 with higher values indicating higher importance.')
				let severitySpinner = createElement('input', 'spinner', setpointContainer, '', { 'type': 'number', 'min':'0', 'max':'1000' });
				severitySpinner.onchange = () => {
					this.modifiedSettings.severity = parseInt(severitySpinner.value);
					this.alarmSettingsChanged(this.wrapper, this.modifiedSettings);
				}
				severitySpinner.value = this.alarm.severity?.toString();

				setpointContainer = this.createAlarmSection(this.wrapper, 'On Delay', 'The number of seconds to wait to activate this alarm after the tag value enters the alarm state. Setting longer activation times can help reduce nuisance alarms.')
				let activationSpinner = createElement('input', 'spinner', setpointContainer, '', { 'type': 'number', 'min':'0' });
				createElement('div', 'alarm-config__setting-row__units', setpointContainer, 'seconds');
				if (!this.alarm.onDelay)
					this.alarm.onDelay = 0;
				activationSpinner.valueAsNumber = this.alarm.onDelay;
				activationSpinner.onchange = () => {
					this.modifiedSettings.onDelay = activationSpinner.valueAsNumber;
					this.alarmSettingsChanged(this.wrapper, this.modifiedSettings);
				}


				createElement('div', 'alarm-config__section-title', this.wrapper, 'Off Delay');
				let deactivationContainer = createElement('div', 'alarm-config__setting-row', this.wrapper);
				createElement('div', 'alarm-config__setting-row__description', deactivationContainer, 'The number of seconds to sustain this alarm after the tag value is no longer in the alarm state.');
				setpointContainer = createElement('div', 'alarm-config__setting-row__setpoint', deactivationContainer)
				let deactivationSpinner = createElement('input', 'spinner', setpointContainer, '', { 'type': 'number', 'min':'0' });
				createElement('div', 'alarm-config__setting-row__units', setpointContainer, 'seconds');
				if (!this.alarm.offDelay)
					this.alarm.offDelay = 0;
				deactivationSpinner.value = this.alarm.offDelay.toString();
				deactivationSpinner.onchange = () => {
					this.modifiedSettings.offDelay = deactivationSpinner.valueAsNumber;
					this.alarmSettingsChanged(this.wrapper, this.modifiedSettings);
				}

				if (this.alarm.node?.vtype != VType.VT_STRING) {
					createElement('div', 'alarm-config__section-title', this.wrapper, 'Alarm Message');
					let messageContainer = createElement('div', 'tag-viewer__text-item', this.wrapper);
					var messageText = createElement('textarea', 'tag-viewer__text-item__text', messageContainer);
					if (this.alarm.message) messageText.value = this.alarm.message;
					else this.alarm.message = messageText.value;
					messageText.onchange = () => this.modifiedSettings.message = messageText.value;
				}
				this.alarmSettingsChanged(this.wrapper, this.modifiedSettings)
			}
		}
		this.fInitialized = true;
		return this;
	}

	createAlarmSection(parent: HTMLElement, title: string, description: string): HTMLElement {
		createElement('div', 'alarm-config__section-title', parent, title);
		let container = createElement('div', 'alarm-config__setting-row', parent);
		createElement('div', 'alarm-config__setting-row__description', container, description);
		let setpointContainer = createElement('div', 'alarm-config__setting-row__setpoint', container);
		return setpointContainer;
	}

	alarmSettingsChanged(parent: HTMLElement, tag: AlarmSettings) { // some alarm setting was just changed
        if (parent.contains(this.alarmTextContainer)) parent.removeChild(this.alarmTextContainer);          // remove any current description text
        if ((tag.node?.vtype != VType.VT_STRING && tag.node?.vtype != VType.VT_BOOL) && typeof tag.setting == 'undefined')
			return;   // check that we can handle this type of alarm
        var alarmText = 'This '
        if (tag.type == 7 || tag.type == 8) alarmText += 'event ';                                          // check if this is an event or an alarm
        else alarmText += 'alarm ';
        alarmText += 'will be '
        if (tag.onDelay == 0) alarmText += 'immediately '
        alarmText += 'activated when ' + this.getTitle(tag) + ' ';
        var alarmCategory = ['', 'is lower than', 'is lower than', 'is higher than', 'is higher than', 'is true', 'is false', 'is true', 'is false', 'is equal to', 'is not equal to'];
		alarmText += alarmCategory[tag.type!];
		if (tag.node?.vtype != VType.VT_STRING && tag.node?.vtype != VType.VT_BOOL && typeof tag.setting != 'undefined')
			alarmText += ' ' + tag.node?.convertFromCacheToDisplay(tag.setting).toString() + ' ' + tag.node?.getUnitsText();
        if (tag.onDelay > 0) alarmText += ' for more than ' + tag.onDelay + ' seconds';
		alarmText += ' and then deactivated ' + ((tag.offDelay == 0)? 'immediately when it ' : 'when it ');
		var oppositeCategory = ['', 'is higher than', 'is higher than', 'is lower than', 'is lower than', 'is false', 'is true', 'is false', 'is true', 'is  not equal to', 'is equal to'];
		alarmText += oppositeCategory[tag.type!];
		if (tag.node!.vtype != VType.VT_STRING && tag.node!.vtype != VType.VT_BOOL)
			alarmText += ' ' + tag.node?.convertFromCacheToDisplay(tag.setting).toString() + ' ' + tag.node?.getUnitsText();
		alarmText += (tag.offDelay == 0)? '.' : ' for ' + tag.offDelay + ' seconds.';

        this.alarmTextContainer = createElement('div', 'tag-viewer__text-item__alarm-text', parent);
        createElement('div', 'tag-viewer__text-item__alarm-text__text', this.alarmTextContainer, 'Description:')
        createElement('div', 'tag-viewer__text-item__alarm-text__text', this.alarmTextContainer, ' ')
		createElement('div', 'tag-viewer__text-item__alarm-text__text', this.alarmTextContainer, alarmText);
		if (this.modifiedSettings.type && this.fNew) {
			this.submit.classList.remove('alarm-config__disabled');
			this.submit.onclick = (e) => {
				e.preventDefault();
				this.submitAlarm();
			}
		}
	}

	submitAlarm() {
		assert(this.alarm, 'Tried to submit an undefined alarm')
		new WritesEnabler(()=> {
			this.modifiedSettings.imbueSettings(this.alarm); // Persist the settings to the alarm so that they're reflected in the UI and will be sent to the server via modify or create below
			this.alarm!.category = this.alarm!.category? this.alarm!.category : this.alarm!.node!.parent.name;
			if (this.fNew)	// Modify vs add if we have a new alarm
				this.device!.configuredAlarms.addAlarmConfiguration(this.alarm!.node!, this.alarm!.type!, this.alarm!.setting, this.alarm!.severity, this.alarm!.category, this.alarm!.message, this.alarm!.onDelay, this.alarm!.offDelay);
			else
				this.device!.configuredAlarms.modifyAlarmConfiguration(this.alarm!.node!, this.alarm!.type!, this.alarm!.setting, this.alarm!.severity, this.alarm!.category, this.alarm!.message, this.alarm!.onDelay, this.alarm!.offDelay, this.alarm!.fDisabled);
			this.owner.buildAlarmList();
			this.modal && this.modal.destroy();
		});
	}

	fillOutOptions(options, tag, selectedType) {
        selectedType.removeChildren();	// Remove any previous options

        //var existingTypes = tag.node.configured;	// Find all existing alarm types on this node
        for (var i = 0; i < options.length; i++)					// Iterate over the types
            //if (existingTypes[options[i].alarmType] === undefined)	// If the type isn't in the array of options we want to add
            selectedType.appendChild(options[i]);			// Add the option to the selector
        //selectedType.disabled = selectedType.children.length == 0;	// The selector is disabled if there are no children
	}

	createOption(value: number) : HTMLOptionElement{			// Create an option for the drop down selector, but don't attach it yet
		var option = createElement('option', 'select-menu__option', undefined, AlarmTypeText.get(value), {value: value.toString()});	// Create the element with no parent
		return option;						// Return the option
	}

	getTitle(alarm: AlarmSettings) : string {
		let alarmPath   	= alarm.node!.getDeviceRelativePath();    // full path to our alarm node
		let category    	= alarm.category;                        // alarm category
		let alarmComponents = alarmPath.split(category);            // create an array to remove the category from the path
		let alarmString   	= alarmComponents[alarmComponents.length - 1].replace(/\//g, ' ').replace(/([a-z]+|[0-9]+)([A-Z]+)/g, "$1 $2"); // make our path human readable by removing /'s and inserting spaces between camel case
		return alarmString;
    }
}
