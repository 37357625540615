import ArrowIcon        from "../images/icons/arrow_down_filled.svg";
import DesktopIcon      from "../images/icons/desktop.svg";
import SmartPhoneIcon   from "../images/icons/smartphone.svg";
import SmartPhoneIconLandscape from "../images/icons/smartphone_landscape.svg";
import ReportIcon       from "../images/icons/report.svg";
import AddIcon          from "../images/icons/add.svg";
import StyleIcon        from "../images/icons/style.svg";
import TagIcon          from "../images/icons/tags.svg";
import SettingsIcon     from "../images/icons/settings.svg";
import SaveIcon         from "../images/icons/save.svg";
import ShareIcon        from "../images/icons/public.svg";
import DeviceIcon       from "../images/icons/device.svg";
import MoveIcon         from "../images/icons/drag.svg";
import ResizeIcon       from "../images/icons/aspect.svg";
import ArrowBackIcon    from "../images/icons/double_arrow_left.svg";
import MoreIcon         from "../images/icons/more.svg";
import DeleteIcon       from "../images/icons/delete.svg";
import Page             from "./page";
import owner, { Routes }from "../../owner";
import { createElement }from "../elements";
import ViewModal        from "../viewmodal";
import LiveData         from '../livedata';
import Dialog           from "../dialog";
import './editorpage.css';
import FrameParser      from "../frameparser";
import { TabManager }   from "../components/tabmanager";
import { Gizmo, Recipe, GizmoMap, GizmoType, GizmoCategory, LiveDataGizmo } from '../dashboard/gizmos/gizmo';
import { RadioSelector, RadioButton } from '../components/radio';
import DashboardView, { MediaQueries } from '../views/dashboardview';
import { ContainerGizmo } from '../dashboard/gizmos/containergizmos';
import { DashboardStyleView, StyleCategories } from '../views/dashboardstyleview';
import Dropdown from "../components/dropdown";
import html2canvas from 'html2canvas';
import ReportScheduleView from "../views/reportscheduleview";
import SharingSettingsView from '../views/sharingsettingsview';
import { PageMap, ReportPageSize } from '../views/reportscheduleview';
import { DevicesSettingsView } from "../views/deviceselectview";
import { TagSocketView } from "../dashboard/tagsocket";
import { toJpeg } from "html-to-image";
import tippy from 'tippy.js';
import { getHash } from "../router/router";

interface EditorPageProps {
    id?: number;
}

interface DashboardHistory {
    text:       string;
    trace:      string;
}

export interface Dashboard {
    id                  : number;
    creator             : string;
    name                : string;
    companyKey          : string;
    fPrivate            : boolean;
    fWrites             : boolean;
    recipes             : Recipe[];
    sharedUsers         : Map<string, ShareSettings>;
    reportUsers         : Map<string, boolean>;
    devices             : string[];                 // list of device keys for device specific or templated dashboards
    assets              : Map<string, string>;
    thumb               : string;
    fReports            : boolean,
    reportFrequency     : number;
    reportFrequencyMod  : number;
    reportHourOffset    : number;
    reportSize          : number;
    reportTimezone      : string;
    version             : number;
}
/**
 *The Page that allows us to create and modify dashboards
 *
 * @export
 * @class EditorPage
 * @extends {Page}
 */
export default class EditorPage extends Page {
    props: EditorPageProps;
    wrapper: HTMLElement;
    nav:  HTMLElement;
    preview: HTMLElement;
    addPanel: HTMLElement;
    addButton: HTMLImageElement;
    toolPanel: HTMLElement;
    viewWrapper: HTMLElement;
    root: Recipe;
    tree: ContainerTree;
    settingsTabs: HTMLElement[] = [];
    styleEditor: HTMLElement;
    sectionMap: Map<string, HTMLElement> = new Map();
    styleView: DashboardStyleView;
    addOptionsMap: Map<HTMLElement, number> = new Map();
    gizmoMap: Map<HTMLElement, Gizmo> = new Map();
    toolTabs: TabManager;
    aspectButtons: RadioSelector;
    graphID: number;
    history: DashboardHistory[] = [];
    traceIndex: number = 1;
    selectedGizmo: Gizmo | null;
    view: DashboardView;
    overlay: GizmoOverlay;
    saveButton: HTMLElement;
    aspect: number = 0;
    keyListener(e: KeyboardEvent) {};
    dragListener() {};
    cancelListener() {};
    upListener() {};
    anyUpListener: ()=>void;
    anyClickListener: ()=>void;
    resizeListener: ()=>void;
    _observer: MutationObserver;
    settingsWrapper: HTMLElement;
    querySelector: RadioSelector;
    socketView: TagSocketView;
    constructor(parent: HTMLElement, props?: EditorPageProps) {
        super(parent);
        owner.navBar.title.textContent = 'HMI Editor'
        this.parent     = parent;
        this.props      = { ...props };
        this.wrapper    = createElement('div', 'editor__wrapper', this.parent);     // wrapper element for all page elements
        this.nav        = createElement('div', 'editor__nav', this.wrapper);
        let content     = createElement('div', 'editor__content', this.wrapper);
        this.addPanel   = createElement('div', 'editor__add', content);
        this.addPanel.setAttribute('open', 'true');
        this.preview    = createElement('div', 'editor__preview', content);
        this.toolPanel  = createElement('div', 'editor__tool', content);
        this.overlay    = new GizmoOverlay(this);
        this.graphID    = owner.ldc.registerGraph(this);

        this.viewWrapper     = createElement('div', 'editor__preview__window', this.preview);

        let tree                = createElement('div', 'editor__tree', this.toolPanel);
        this.settingsWrapper    = createElement('div', 'editor__settings hide', this.toolPanel);
        this.addButton          = createElement('img', 'editor__nav__button', this.nav, '', {'src':ArrowBackIcon});
        tippy(this.addButton, {
            content  : 'Toggle Components Panel',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        this.viewWrapper.onmouseleave   = () => this.hoverContainer()
        this.anyClickListener = () => this.pushHistory();
        this.keyListener = (e: KeyboardEvent) => this.onKeyDown(e);

        let categories          = Object.values(GizmoCategory);
        categories.forEach(category => this.sectionMap.set(category, this.createSection(category)));

        for (let [gizmoType, gizmoInfo] of GizmoMap) {
            this.createRecipeButton(gizmoType);
        }

        this.toolTabs = new TabManager(this.settingsWrapper, '', [
            {name: 'Tags', icon: TagIcon, displayName: 'Tags'},
            {name: 'Styles', icon: StyleIcon, displayName: 'Style'},
            {name: 'Settings', icon: SettingsIcon, displayName: 'Settings'},
        ]);

        this.styleView  = new DashboardStyleView(this);    // Instantiate but don't initialize yet so our radio selector is above this view
        this.socketView = new TagSocketView(owner.ldc, this).initialize(this.toolTabs.getSectionByName('Tags'));

        let queries: RadioButton[] = [];
        for (let i=0;i<MediaQueries.length;++i) {
            let query = MediaQueries[i];
            if (query.name.includes('Landscape'))
                continue;
            queries.push({
                name: query.name,
                icon: query.icon,
                displayName: '',
                id: i,
                tooltip: `<strong>${query.maxW == Infinity ? query.minW : query.maxW}px breakpoint</strong> <br>Styles set here will apply to windows sized ${query.maxW == Infinity ? query.minW : query.maxW}px and under (unless a smaller breakpoint overrides it).`
            })
        }

        this.querySelector = new RadioSelector(createElement('div', 'editor__nav__aspect', this.nav), {
            title: '',
            buttons:  queries,
            defaultSelection: queries[0].name,
            changeCallback: (name) => {
                if (!this.view || !this.view.fInitialized)
                    return;
                for (let i=0;i<MediaQueries.length;++i) {
                    let query = MediaQueries[i];
                    if (query.name == name) {
                        this.aspect = i;
                        this.resize();
                        this.refreshSelectedStyles();
                        break;
                    }
                }
            }
        });

        this.styleView.initialize(this.toolTabs.getSectionByName('Styles'));

        this.addButton.onclick = () => {
            if (this.addPanel.getAttribute('open') == 'true')
                this.closeAddPanel();
            else
                this.openAddPanel();
        }

        let shareButton = createElement('img', 'editor__nav__button', this.nav, '', {'src':ShareIcon});
        tippy(shareButton, {
            content  : 'Sharing Settings',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        shareButton.onclick = (e: MouseEvent) => {
            let pendingSharedUsers = new Map(this.view.dashboard.sharedUsers);   // make a copy of our existing sharing settings
            let fPrivate = this.view.dashboard.fPrivate ? true : false;          // make a copy of our private setting
            let fWrites  = this.view.dashboard.fWrites ? true : false;           // make a copy of our view only setting
            let companyKey = this.view.dashboard.companyKey + "";                // make a copy of our company key
            new ViewModal(new SharingSettingsView(this.view.dashboard.sharedUsers, this.view.dashboard), {
                title: 				    'Sharing Settings',
                titleTextColor:	        'var(--color-inverseOnSurface)',
                titleBackgroundColor: 	'var(--color-primary)',
                maxWidth:               '400px',
                maxHeight:              '600px',
                buttons: [
                    {   // green button that saves the sharing settings
                        title:'Save',
                        callback:()=> {
                            this.saveDashboard();
                            return true;
                        },
                        borderColor: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the sharing settings changes
                        title:'Cancel',
                        borderColor: owner.colors.hex('--color-error'),
                        callback:()=> {
                            this.view.dashboard.sharedUsers  = pendingSharedUsers;
                            this.view.dashboard.fPrivate     = fPrivate;
                            this.view.dashboard.fWrites      = fWrites;
                            this.view.dashboard.companyKey   = companyKey;
                            return true;
                        }
                    }
                ],
            });
        };
        let deviceButton    = createElement('img', 'editor__nav__button', this.nav, '', {'src':DeviceIcon});
        tippy(deviceButton, {
            content  : 'Add to Device',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        deviceButton.onclick = () => {
            let pendingDevices = [...this.view.dashboard.devices];   // make a copy of our existing sharing settings
            new ViewModal(new DevicesSettingsView(this.view.dashboard.devices, this.view.dashboard, owner.ldc), {
                title: 				    'Add To Device',
                titleTextColor:	        'var(--color-inverseOnSurface)',
                titleBackgroundColor: 	'var(--color-primary)',
                maxWidth:               '400px',
                maxHeight:              '600px',
                buttons: [
                    {   // green button that saves the new device settings
                        title:'Save',
                        callback:()=> {
                            this.saveDashboard();
                            return true;
                        },
                        borderColor: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the new device settings
                        title:'Cancel',
                        borderColor: owner.colors.hex('--color-error'),
                        callback:()=> {
                            this.view.dashboard.devices      = pendingDevices;
                            return true;
                        }
                    }
                ],
            });
        }
        //createElement('img', 'editor__nav__button', this.nav, '', {'src':UndoIcon}).onclick = () => this.undo();
        //createElement('img', 'editor__nav__button', this.nav, '', {'src':RedoIcon}).onclick = () => this.redo();
        this.saveButton = createElement('img', 'editor__nav__button', this.nav, '', {'src':SaveIcon});
        tippy(this.saveButton, {
            content  : 'Save',
            delay    : [750, 0],  // duration: [show, hide]
            duration : [0, 0],    // duration: [show, hide]
            placement: 'top',
            offset   : [0, 5],
            allowHTML: true
        });
        this.saveButton.onclick = () => this.saveDashboard();

        createElement('img', 'editor__nav__button', this.nav, '', {'src':MoreIcon}).onclick = (e) => {
            new Dropdown(e, ['Delete Dashboard', 'Rename Dashboard', 'Import Config', 'Export Config', 'Reports'], (selection: string) => {
                switch (selection) {
                    case 'Delete Dashboard':
                        this.deleteDashboard();
                    break;
                    case 'Rename Dashboard':
                        this.renameDashboard();
                    break;
                    case 'Print Dashboard':
                        this.printDashboard(850, 1100);
                    break;
                    case 'Import Config':
                        this.importConfig();
                    break;
                    case 'Export Config':
                        this.exportConfig();
                    break;
                    case 'Reports':
                        new ViewModal(new ReportScheduleView(owner.ldc, this.view.dashboard), {
                            title: 				    'Report Scheduler',
                            titleTextColor:		    'var(--color-inverseOnSurface)',
                            titleBackgroundColor: 	'var(--color-primary)',
                            maxWidth:               '1200px',
                        });
                    break;
                }
            })
        };

        if (this.props.id) { // if we are provided a dashboard id from our route, ask for it from wv
            this.view   = new DashboardView(owner.ldc, this.props.id, this, ()=>owner.navBar.setTitle(this.view.dashboard.name)).initialize(this.viewWrapper);
            this.createEventListeners();
            this.tree   = new ContainerTree(tree, this);
        }
        else { // if we are not provided a dashboard id, create a new one
            let dialogProperties = {
                title: 'New HMI',
                body:  'Please enter a name for your new HMI.',
                fText:  true,
                buttons: [
                    {   // green button that creates a new dashboard when clicked
                        title:'Create HMI',
                        callback:(name: string)=> this.createDashboard(name),
                        color: owner.colors.hex('--color-primary')
                    },
                    {   // red button that cancels the dashboard createion and returns the user to the explorer page
                        title:'Cancel',
                        callback:() => window.history.back(),
                        color: owner.colors.hex('--color-error')
                    }
                ]
            }
            new Dialog(document.body, dialogProperties);
        }

        // TODO:
        // this.view.onViewHidden = () => {
            // let dialogProperties = {
            //     title: 'You have unsaved changes',
            //     buttons: [
            //         {
            //             title: 'Exit without saving',
            //             callback: () => {},
            //             color: owner.colors.hex('--color-error')
            //         },
            //         {
            //             title: 'Cancel',
            //             color: owner.colors.hex('--color-onSurfaceVariant')
            //         }
            //     ]
            // }
            // new Dialog(document.body, dialogProperties);
        // };
    }

    refreshSelectedStyles() {
        if (!this.selectedGizmo)
            return;
        if (!this.selectedGizmo!.recipe.style[this.aspect])
            this.selectedGizmo!.recipe.style[this.aspect] = {};
        let defaultStyle: {[key: string]: string} = {};
        //let computedStyle   = getComputedStyle(this.selectedGizmo);
        let autoStyles      = {};
        //Array.from(computedStyle).forEach(key => autoStyles[key] = computedStyle.getPropertyValue(key));
        for (let j=0;j<this.aspect;++j) {
            let higherQuery = MediaQueries[j]
            if (higherQuery)
                Object.assign(defaultStyle, this.selectedGizmo!.recipe.style[j]);
        }
        this.styleView.setSettingsToEdit(this.selectedGizmo!.recipe.style[this.aspect], this.selectedGizmo.allowableStyles, defaultStyle, autoStyles);
    }

    createEventListeners() {
        addEventListener('scroll', () => this.overlay.resize(), true);
        addEventListener('keydown', this.keyListener);
        addEventListener('mouseup', this.anyClickListener);
        this.resizeListener = () => this.resize();
        addEventListener('resize', this.resizeListener);
        this.preview.onmousedown = () => { // Poor man's click listener (because resize and move overlay listeners)
            let upCallback = () => {
                this.preview.removeEventListener('mouseup', upCallback);
                this.onSelectionChanged(null);
            }
            this.preview.addEventListener('mouseup', upCallback);
        }
    }

    async onKeyDown(e: KeyboardEvent) {
        let ctrl = e.ctrlKey ? e.ctrlKey : ((e.key === 'Meta') ? true : false); // ctrl detection
        if (e.target instanceof HTMLInputElement && document.hasFocus() && e.target === document.activeElement)
            return;

        else if (e.key === 'Backspace' || e.key === 'Delete') {
            if (this.selectedGizmo)
                this.deleteGizmo(this.selectedGizmo)
            this.onSelectionChanged(null);
        }
        else if (e.key == 'Escape') {
            this.onSelectionChanged(null);
        }
        else if (ctrl) {
            if ( (e.key == 'c' || e.key == 'C')) {
                if (this.selectedGizmo) {
                    navigator.clipboard.writeText(JSON.stringify(this.selectedGizmo.recipe, this.recipeParser));
                }
            }
            else if ( (e.key == 'v' || e.key == 'V')) {
                try {
                    let recipeText = await navigator.clipboard.readText()
                    let copiedRecipe = JSON.parse(recipeText, this.recipeParser);

                    if (copiedRecipe) {
                        let parentElement: HTMLElement | null = null;
                        if (this.selectedGizmo) {
                            if (this.selectedGizmo.recipe.id == GizmoType.WT_CONTAINER_ROW || this.selectedGizmo.recipe.id == GizmoType.WT_CONTAINER_COL)    // else, if we have selected a new gizmo and it is a container
                                parentElement = this.selectedGizmo;                             // create the new gizmo inside the selected container
                            else if (this.selectedGizmo.parentRecipe)
                                parentElement = this.selectedGizmo.parentElement;
                        }
                        else
                            parentElement = this.view.gizmoContainer;
                        let parentRecipe: Recipe | null = null;
                        if (parentElement instanceof Gizmo)
                            parentRecipe = parentElement.recipe;
                        if (parentRecipe) {
                            if (!parentRecipe.children)
                                parentRecipe.children = [];
                            parentRecipe.children.push(copiedRecipe);
                        }
                        else
                            this.view.dashboard.recipes.push(copiedRecipe);
                        let newGizmo = this.view.buildGizmo(copiedRecipe, parentElement!, parentRecipe);
                        if (newGizmo.recipe.children)
                            this.view.buildDOM(newGizmo.recipe.children, newGizmo.recipe, newGizmo);
                        this.tree.refresh();
                    }
                }
                catch {
                    return;
                }
            }
            else if ( (e.key == 'z' || e.key == 'Z')) {
                e.preventDefault();
                if (e.shiftKey)
                    this.redo();
                else
                    this.undo();
            }
            else if ( (e.key == 's' || e.key == 'S')) {
                e.preventDefault();
                this.saveDashboard();
            }
        }

        else
            this.pushHistory();
    }

    deleteGizmo(gizmo: Gizmo) {
        if (gizmo.parentElement instanceof ContainerGizmo)
            gizmo.parentElement.removeChildRecipe(gizmo.recipe);
        else
            this.view.dashboard.recipes.splice(this.view.dashboard.recipes.indexOf(gizmo.recipe), 1);
        gizmo.parentElement?.removeChild(gizmo);
        for (let [clickHandler, gizmo] of this.gizmoMap) {
            if (gizmo === gizmo) {
                this.gizmoMap.delete(clickHandler);
                break;
            }
        }
        this.tree.refresh();
    }

    recipeParser(key: any, value: any): any {
        if(value instanceof Map)
            return Array.from(value);
        if (key=="children" && value.length == 0)
            return;
        else if (key=="settings" && Object.keys(value).length == 0)
            return;
        else if (value instanceof Set)
            return Array.from(value);
        else if (key == "gizmo" || key == "treeItem" || key == "assets" || key == "sharedUsers" || key == "reportUsers")   // we don't need to serialize these
            return;
        else {
            return value;
        }
    }

    onGizmoAdded(gizmo: Gizmo) {
        this.tree.addItem(gizmo);
        gizmo.clickHandler  = createElement('div', '_gizmo__overlay', gizmo);
        this.gizmoMap.set(gizmo.clickHandler, gizmo);
        gizmo.clickHandler.onclick = (e) => {
            e.stopPropagation();
            this.onSelectionChanged(gizmo);
        }
    }

    onGizmoRemoved(gizmo: Gizmo) {

    }

    createRecipeButton(gizmoType: GizmoType) {
        let button      = createElement('div', 'editor__view-options__container');
        let gizmoInfo   = GizmoMap.get(gizmoType)!;
        createElement('img', 'editor__view-options__icon', button, '', {'src':gizmoInfo.icon});
        createElement('div', 'editor__view-options__text', button, gizmoInfo.name)
        button.setAttribute('data-tippy-content', gizmoInfo.name);
        button.addEventListener('mousedown', this.onMouseDown.bind(this));
        this.sectionMap.get(gizmoInfo.category)?.append(button);
        this.addOptionsMap.set(button, gizmoType);
    }

    createDashboard(name: string) {
        let dashboard = {
            id:                 0,
            creator:            owner.ldc.user.username,
            name:               name,
            companyKey:         owner.ldc.user.companyKey,
            fPrivate:           true,
            fWrites:            false,
            assets:             new Map(),
            sharedUsers:        new Map(),
            devices:            [],
            thumb:              '',
            fReports:           false,
            reportFrequency:    1,
            reportFrequencyMod: 0,
            reportHourOffset:   13,
            reportSize:         0,
            reportTimezone:     'America/Chicago',
            recipes:            [],
            version:            2
        }
        let data = JSON.stringify(dashboard, (key, value) => {
            if(value instanceof Map) {
                return Array.from(value);
            }
            else if (value instanceof Set) {
                return Array.from(value);
            }
            else {
                return value;
            }
        });
        owner.ldc.fm.buildFrame(LiveData.WVC_CREATE_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_string(dashboard.name);
        owner.ldc.fm.push_u8(dashboard.fPrivate? 1 : 0);
        owner.ldc.fm.push_u8(dashboard.fWrites? 1 : 0);
        owner.ldc.fm.push_u16(dashboard.version);
        owner.ldc.fm.push_string(data);
        owner.ldc.fm.push_u32(dashboard.devices.length); // number of devices (should always be zero here);
        owner.ldc.send();
    }

    scrollElementIntoView(gizmo: Gizmo) {
        let boundingElement = this.view.wrapper.getBoundingClientRect();
        let elementRect = gizmo.getBoundingClientRect();
        if (elementRect.bottom < boundingElement.top || elementRect.top > boundingElement.bottom)
            gizmo.scrollIntoView();
    }

    /**
     * Method called after whoville response to dashboard creation frame.
     * @param  {FrameParser} fp
     */
    onCreateDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;
        if (success) {
            let id      = fp.pop_u32();
            let link    = getHash(Routes.Creator, {'id':id.toString()});
            window.location.hash = link;
        }
        else {
            let dialogProperties = {
                title:  'Failed to Create Dashboard'
            }
            new Dialog(document.body, dialogProperties);
        };
    };

    async saveDashboard(fRename: boolean = false) {
        let data = JSON.stringify(this.view.dashboard, this.recipeParser);
        const stream = new Blob([data], {
            type: 'application/json',
        }).stream();
        //@ts-ignore
        const compressedReadableStream = stream.pipeThrough(new CompressionStream("gzip"));
        const arrayBuffer = await new Response(compressedReadableStream).blob().then(result => result.arrayBuffer());

        owner.ldc.fm.buildFrame(LiveData.WVC_MODIFY_DASHBOARD, undefined, this.graphID);
        owner.ldc.fm.push_u32(this.view.dashboard.id);
		owner.ldc.fm.push_string(this.view.dashboard.name);
        owner.ldc.fm.push_u16(3);
        owner.ldc.fm.push_string(this.view.dashboard.companyKey ? this.view.dashboard.companyKey : "");
        owner.ldc.fm.push_u8(this.view.dashboard.fPrivate ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.fWrites ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.fReports ? 1 : 0);
        owner.ldc.fm.push_u8(this.view.dashboard.reportFrequency);
        owner.ldc.fm.push_u16(this.view.dashboard.reportFrequencyMod);
        owner.ldc.fm.push_u8(this.view.dashboard.reportHourOffset);
        owner.ldc.fm.push_u8(this.view.dashboard.reportSize);
        owner.ldc.fm.push_string(this.view.dashboard.reportTimezone);
		owner.ldc.fm.push_u32(arrayBuffer.byteLength);
        owner.ldc.fm.push_buffer(arrayBuffer);
        owner.ldc.fm.push_u16(this.view.dashboard.sharedUsers.size);
        this.view.dashboard.sharedUsers.forEach((settings, username)=> {
            owner.ldc.fm.push_string(username);
            owner.ldc.fm.push_u8(settings.fAccess ? 1 : 0);
            owner.ldc.fm.push_u8(settings.fWrites ? 1 : 0);
            owner.ldc.fm.push_u8(this.view.dashboard.reportUsers.get(username) ? 1 : 0)
        })
        owner.ldc.fm.push_u16(this.view.dashboard.devices.length);
        for (let i=0;i<this.view.dashboard.devices.length;++i)
            owner.ldc.fm.push_string(this.view.dashboard.devices[i]);
        owner.ldc.fm.push_u16(this.view.dashboard.assets.size);
        for (let uuid of this.view.dashboard.assets.keys()) {
            owner.ldc.fm.push_string(uuid);
        }
		owner.ldc.send();								// Send the message
        if (fRename) {
            owner.navBar.setTitle(this.view.dashboard.name);
            owner.refreshDashboards();
        }
    }

    onModifyDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;
        if (success)
            this.saveButton.style.filter = 'invert(54%) sepia(79%) saturate(470%) hue-rotate(115deg) brightness(95%) contrast(88%)';
        else
            new Dialog(document.body, {
                title: 'Error',
                body: 'An error occurred while trying to save the dashboard. Please wait a moment and try again.'
            })
    }

    undo() {
        if (this.traceIndex < 1)
            return;
        let recipes = JSON.parse(this.history[this.traceIndex--].trace!);
        this.refreshFromRecipes(recipes);
    }

    redo() {
        if (this.traceIndex > this.history.length - 2)
            return;
        let recipes = JSON.parse(this.history[this.traceIndex++].trace!);
        this.refreshFromRecipes(recipes);
    }

    exportConfig() {
		let data    = JSON.stringify(this.view.dashboard.recipes, this.recipeParser);
        let config  = new Blob([data], {type: 'application/json'});
        let downloadLink = document.createElement('a');
		downloadLink.download = this.view.dashboard.name + '.json';			// File name to download as
		downloadLink.href = URL.createObjectURL(config);// Make a URL for the file
		downloadLink.click();							// Simulate clicking on the hyperlink
    }

    importConfig() {
        let srcInput        = createElement('input', '', undefined, '', {'type':'file', 'accept':'application/json'});
        srcInput.onchange   = () => {
            let reader = new FileReader();
            reader.onload = (e) => {
                try {
                    let recipes: Recipe[] = JSON.parse(reader.result as string) as Recipe[];
                    if (recipes) {
                        this.refreshFromRecipes(recipes);
                        return;
                    }
                    else
                        throw new Error('Invalid Config File');
                } catch {
                    new Dialog(document.body, {
                        title: 'Invalid Config File',
                        body: 'There was an issue loading the dashboard. Please check your config file and try again.'
                    });
                }
            };
            reader.readAsText(srcInput.files![0]);
        }
        srcInput.click();
    }

    onDashboardChanged() {

    }

    refreshFromRecipes(recipes: Recipe[]) {
        this.view.gizmoContainer.removeChildren();
        let fragment = document.createDocumentFragment();
        this.view.dashboard.recipes = recipes;
        this.view.buildDOM(recipes, null, fragment);
        this.view.gizmoContainer.append(fragment);
        this.tree.refresh();
    }

    deleteDashboard() {
        let dialogProperties = {
            title: 'Delete Dashboard?',
            titleColor: 'var(--color-inverseOnSurface)',
            titleBackground: 'var(--color-red-8)',
            body:  `Are you sure you want to permanently remove ${this.view.dashboard.name}?`,
            buttons: [
                {   // red button that deletes the dashboard
                    title:'Delete Dashboard',
                    callback: ()=>{
                        owner.ldc.fm.buildFrame(LiveData.WVC_DELETE_DASHBOARD, undefined, this.graphID);
                        owner.ldc.fm.push_u32(this.view.dashboard.id);
                        owner.ldc.send();								// Send the message
                    },
                    color: owner.colors.hex('--color-error')
                },
                {   // gray button that cancels the dashboard deletion and returns the user to the dashboard
                    title:'Cancel',
                    color: owner.colors.hex('--color-onSurfaceVariant')
                }
            ]
        }
        new Dialog(document.body, dialogProperties);
    }

    printDashboard(width: number, height: number) {
        let newWindow = window.open(`${location.origin}/${getHash(Routes.Viewer, {'id':this.view.dashboard.id.toString(),'print':'true'})}`, "", `width=${width},height=${height}`);
    }

    renameDashboard() {
        let dialogProperties = {
            title: 'Rename',
            body:  'Please enter a new name for your dashboard.',
            fText:  true,
            buttons: [
                {   // green button that sets the new name and saves the dashboard when clicked
                    title:'Rename Dashboard',
                    callback:(name: string)=> {
                        this.view.dashboard.name = name;
                        this.saveDashboard(true);
                    },
                    color: owner.colors.hex('--color-primary')
                },
                {   // red button that cancels the dashboard renaming and returns the user to the dashboard
                    title:'Cancel',
                    color: owner.colors.hex('--color-error')
                }
            ]
        }
        new Dialog(document.body, dialogProperties);
    }

    onDeleteDashboardResponse(fp: FrameParser) {
        let success = fp.pop_u8() == 1;
        owner.refreshDashboards();
        this.viewWrapper.removeChildren();
        new Dialog(document.body, {
            title  : 'Dashboard Deleted',
            titleColor: 'var(--color-inverseOnSurface)',
            titleBackground: 'var(--color-primary)',
            body   : 'We\'ve removed ' + this.view.dashboard.name + ' from your dashboards. Would you like to create another?',
            buttons: [{
                title   : 'Yes',
                callback: ()=>{new EditorPage(this.parent)},
            },
            {
                title   : 'No',
                callback: ()=>window.location.hash = getHash(Routes.Home)
            }]
        });
    };

    pushHistory() {
        if (!this.view.dashboard)
            return;
        let traceString = JSON.stringify(this.view.dashboard.recipes, this.recipeParser);
        if (this.history.length === 0 || this.history[this.history.length - 1].trace !== traceString) {
            this.history.push({
                text: '',
                trace: traceString
            });
        }
        this.traceIndex = this.history.length - 1;
    }

    resize() {
        this.preview.style.overflowY                = '';
        this.viewWrapper.style.height               = '';
        this.viewWrapper.style.overflowY            = '';
        this.view.wrapper.style.height              = '';
        this.view.gizmoContainer.style.overflow     = '';
        this.view.gizmoContainer.style.height       = '';
        this.view.gizmoContainer.style.minHeight    = '';
        this.viewWrapper.style.minHeight            = '';
        this.viewWrapper.style.width                = '';
        let query = MediaQueries[this.aspect];
        this.viewWrapper.style.height = query.maxH === Infinity ? '100%' : `${query.maxH}px`;
        this.viewWrapper.style.width  = query.maxW === Infinity ? '100%' : `${query.maxW}px`;
        this.view.resize();
        this.overlay.resize();
    }

    closeAddPanel() {
        this.addPanel.addEventListener('transitionend', ()=>this.resize(), { once: true });
        this.addPanel.setAttribute('open', 'false');
        this.addButton.src = AddIcon;
    }

    openAddPanel() {
        this.addPanel.addEventListener('transitionend', ()=>this.resize(), { once: true });
        this.addPanel.setAttribute('open', 'true');
        this.addButton.src = ArrowBackIcon;
    }

    onMouseDown(e: MouseEvent) {
        this.dragListener = this.onMouseMoveStart.bind(this, e.target as HTMLElement, e);
        document.addEventListener('mousemove', this.dragListener, {once:true});
        this.cancelListener = this.onMouseCancel.bind(this)
        document.addEventListener('mouseup', this.cancelListener, {once:true});
    }

    onMouseMoveStart(target: HTMLElement, originalEvent: MouseEvent, e: MouseEvent) {
        this.view.gizmoContainer.style.pointerEvents = 'none';
        let draggedElement = target.cloneNode(true) as HTMLElement;
        document.body.appendChild(draggedElement);
        draggedElement.style.position   = 'absolute';
        draggedElement.style.zIndex     = '1000';
        draggedElement.style.marginLeft = `-${originalEvent.pageX  - target.getBoundingClientRect().left}px`;
        draggedElement.style.marginTop  = `-${originalEvent.pageY  - target.getBoundingClientRect().top}px`;
        document.removeEventListener('mouseup', this.cancelListener);
        this.dragListener = this.onMouseMove.bind(this, draggedElement);
        document.addEventListener('mousemove', this.dragListener);
        this.upListener = this.onMouseUp.bind(this, draggedElement, this.addOptionsMap.get(target)!);
        document.addEventListener('mouseup', this.upListener, {once:true});
    }

    onMouseMove(draggedElement: HTMLElement, e: MouseEvent) {
        draggedElement.style.top    = e.pageY + 'px';
        draggedElement.style.left   = e.pageX + 'px';
        let hoverElements           = document.elementsFromPoint(e.pageX, e.pageY);

        for (let i=0;i<hoverElements.length;++i) {                      // loop through the elements under the cursor
            let element = hoverElements[i];                             // convenience reference
            let container = this.gizmoMap.get(element as HTMLElement)!; // find the first element that corresponds to a container
            if (!container)
                return;
            if (container.recipe.id == GizmoType.WT_CONTAINER) {
                for (let [el, widget] of this.gizmoMap) {
                    el.classList.remove('_se_dash_overlay_hover')
                }
                container.overlay?.classList.add('_se_dash_overlay_hover');
                break;
            };
        };
    };

    onMouseUp(draggedElement: HTMLElement, gizmoType: GizmoType, e: MouseEvent) {
        this.view.gizmoContainer.style.pointerEvents = 'auto';
        let hoverElements = document.elementsFromPoint(e.pageX, e.pageY);
        for (let i=0;i<hoverElements.length;++i) {
            let element = hoverElements[i] as HTMLElement;
            if (element instanceof ContainerGizmo) {
                let gizmo = element as Gizmo;
                let recipe: Recipe = {
                    id      : gizmoType,
                    name    : GizmoMap.get(gizmoType)!.name,   //TODO: Come up with some better naming
                    children: [],
                    tags    : [],
                    settings: {},
                    style   : {},
                    classes : [],
                    gizmo   : null
                }
                gizmo.recipe.children.push(recipe);
                let newGizmo = this.view.buildGizmo(recipe, gizmo, gizmo.recipe, true);
                this.tree.refresh();
                this.onSelectionChanged(newGizmo);
                break;
            }
            else if (element === this.view.gizmoContainer) {
                let recipe: Recipe = {
                    id      : gizmoType,
                    name    : GizmoMap.get(gizmoType)!.name,   //TODO: Come up with some better naming
                    children: [],
                    tags    : [],
                    settings: {},
                    style   : {},
                    classes : [],
                    gizmo   : null
                }
                let newGizmo = this.view.buildGizmo(recipe, this.view.gizmoContainer, null, true);
                this.view.dashboard.recipes.push(newGizmo.recipe);
                this.tree.refresh();
                this.onSelectionChanged(newGizmo)
                break;
            }
        }
        //this.unscaleElements();
        this.hoverContainer(undefined);
        document.body.removeChild(draggedElement);
        document.removeEventListener('mousemove', this.dragListener);

    }

    onMouseCancel(e: MouseEvent) {
        document.removeEventListener('mousemove', this.dragListener);
    }

    createSection(name: string) : HTMLElement {
        let titleContainer = createElement('div', 'editor__container__setting', this.addPanel);
        createElement('img', 'editor__container__setting__arrow', titleContainer, '', {'src':ArrowIcon});
        createElement('div', 'editor__container__setting__title', titleContainer, name);
        let section = createElement('div', 'editor__add__section', this.addPanel);
        return section;
    }

    hoverContainer(element?: HTMLElement) {
        for (let [el, gizmo] of this.gizmoMap) {
            el.classList.remove('dash-widget__overlay__editing__hover');
        };
        if (element)
            element.classList.add('dash-widget__overlay__editing__hover')
    }

    onSelectionChanged(selectedGizmo: Gizmo | null) {
        this.saveButton.style.filter = '';
        this.toolTabs.getSectionByName('Settings').removeChildren();
        this.selectedGizmo      = selectedGizmo;
        for (let [el, gizmo] of this.gizmoMap) {
            el.setAttribute('_selected', 'false')
            gizmo.recipe.treeItem?.element.setAttribute('_selected', 'false')
        }
        if (this.selectedGizmo) {
            this.settingsWrapper.classList.remove('hide');
            this.tree.select(this.selectedGizmo.recipe.treeItem!)
            this.selectedGizmo.populateSettings(this);
            this.refreshSelectedStyles();
            if (this.selectedGizmo instanceof LiveDataGizmo) {
                this.toolTabs.showSection('Tags');
                this.socketView.setGizmo(this.selectedGizmo);
            }
            else
                this.toolTabs.hideSection('Tags');
        }
        else {
            this.settingsWrapper.classList.add('hide');
        }
        this.overlay.gizmo = this.selectedGizmo;
    };

    setProps(newProps: {[key: string]: string}) {
        const entries = Object.entries(newProps);
        for (const [key, value] of entries) {
            if (this.props[key] != value) { // check whether each property has changed
                this.props[key] = value;
                switch(key) {
                    case 'id': // if the device key changes, create a new device page
                        new EditorPage(this.parent, newProps);
                        return;
                    default:
                        break;
                }
            }
        }
    };

    destroy() {
        removeEventListener('mouseup', this.anyClickListener);
        removeEventListener('resize', this.resizeListener);
        removeEventListener('keydown', this.keyListener);
        this.overlay.destroy()
        this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
        this.parent.removeChildren();		// Delete any DOM elements left over
    }
}

export interface ShareSettings {
    fAccess: number;
    fWrites: number;
}

export interface TreeItem {
    element  : HTMLElement;
    childList: HTMLElement;
    arrow?   : HTMLElement;
    name     : HTMLElement;
    icon     : HTMLElement;
    fOpen    : boolean;
    gizmo    : Gizmo;
}

class ContainerTree {
    parentContainer: Gizmo;
    parentElement  : HTMLElement;
    editor         : EditorPage;
    element        : HTMLElement;
    recipeMap      : Map<Recipe,      TreeItem> = new Map();
    elementMap     : Map<HTMLElement, Gizmo> = new Map();
    hoveredRecipe  : Recipe;
    hoveredElement : HTMLElement;
    topHover       : HTMLElement;
    bottomHover    : HTMLElement;
    scrollTimeout  : NodeJS.Timeout;
    moveListener   : (e: MouseEvent) => any;
    upListener     : (e: MouseEvent) => any;
    collapseMap    : Map<Recipe, boolean> = new Map();
    optionBar      : HTMLElement;
    constructor(parent: HTMLElement, editor: EditorPage) {
        this.parentElement  = parent;
        this.editor         = editor;
        this.optionBar      = createElement('div', 'editor__tree__options', this.parentElement)
        this.element        = createElement('div', 'editor__tree__container', this.parentElement);
        this.topHover       = createElement('div', 'editor__tree__hover__top', this.parentElement);
        this.bottomHover    = createElement('div', 'editor__tree__hover__bottom', this.parentElement);

        let collapseButton = createElement('div', 'editor__tree__button', this.optionBar);
        createElement('img', 'editor__tree__button__icon', collapseButton, '', {'src':ArrowIcon}).style.transform = 'rotate(180deg)';
        collapseButton.onclick = () => this.collapseAll();

        let expandButton = createElement('div', 'editor__tree__button', this.optionBar);
        createElement('img', 'editor__tree__button__icon', expandButton, '', {'src':ArrowIcon});
        expandButton.onclick = () => this.expandAll();
    }

    refresh() {
        this.topHover.style.opacity = '0';
        this.bottomHover.style.opacity = '0';
        this.element.removeChildren();
        for (let recipe of this.editor.view.dashboard.recipes)
            this.createTree(recipe.gizmo!);
        this.editor.onSelectionChanged(this.editor.selectedGizmo)
    };

    select(treeItem: TreeItem) {
        let currentItem = treeItem;
        while(currentItem.gizmo.parentRecipe) {
            let parentItem = this.recipeMap.get(currentItem.gizmo.parentRecipe)!
            if (!parentItem.fOpen) {
                this.expand(parentItem);
            }
            currentItem = parentItem;
        }
        treeItem.element.setAttribute('_selected', 'true');
        let boundingElement = this.element.getBoundingClientRect();
        let treeItemRect = treeItem.element.getBoundingClientRect();
        if (treeItemRect.bottom < boundingElement.top || treeItemRect.top > boundingElement.bottom)
            treeItem.element.scrollIntoView();
    }

    addItem(gizmo: Gizmo) {
        let parent      = (gizmo.parentRecipe && gizmo.parentRecipe.treeItem)? gizmo.parentRecipe.treeItem.childList : this.element;
        let treeElement = createElement('div', 'editor__tree__item', parent)
        let treeItem: TreeItem = {
            element  : treeElement,
            childList: createElement('div', 'editor__tree__item__list', parent),
            arrow    : gizmo instanceof ContainerGizmo ? createElement('img', 'editor__tree__item__arrow', treeElement, '', {'src':ArrowIcon}): undefined,
            icon     : createElement('img', 'editor__tree__item__icon', treeElement, '', {'src':GizmoMap.get(gizmo.recipe.id)?.icon}),
            name     : createElement('div', 'editor__tree__item__name', treeElement, gizmo.recipe.name),
            fOpen    : !this.collapseMap.get(gizmo.recipe) ?? true,
            gizmo    : gizmo
        }
        treeItem.element.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onDragStart.bind(this, gizmo.recipe, e);
            this.upListener = this.onDragCancelled.bind(this, gizmo.recipe);
            document.addEventListener('mousemove', this.moveListener);
            document.addEventListener('mouseup', this.upListener, {once: true});
        }

        treeItem.element.ondblclick = () => {
            this.createTextInput(gizmo.recipe);
        }

        if (treeItem.arrow) {
            treeItem.arrow.onmousedown = (e) => {
                e.stopImmediatePropagation()
                if (treeItem.fOpen)
                    this.collapse(treeItem);
                else
                    this.expand(treeItem);
            }
        }

        if (treeItem.arrow) {
            treeItem.childList.style.height = treeItem.fOpen ? '' : '0px'
            treeItem.arrow!.style.transform  = treeItem.fOpen ? '' : 'rotate(-90deg)'
        }

        this.recipeMap.set(gizmo.recipe, treeItem);
        this.elementMap.set(treeItem.element, gizmo);
        gizmo.recipe.treeItem = treeItem;
    }

    collapseAll() {
        for (let treeItem of this.recipeMap.values()) {
            this.collapse(treeItem);
        }
    }

    expandAll() {
        for (let treeItem of this.recipeMap.values()) {
            this.expand(treeItem);
        }
    }

    collapse(treeItem: TreeItem) {
        if (!treeItem.arrow || !treeItem.fOpen)
            return;
        treeItem.childList.style.height = '0px';
        treeItem.arrow!.style.transform  = 'rotate(-90deg)';
        treeItem.fOpen = false;
        this.collapseMap.set(treeItem.gizmo.recipe, !treeItem.fOpen);
    }

    expand(treeItem: TreeItem) {
        if (!treeItem.arrow || treeItem.fOpen)
            return;
        treeItem.childList.style.height = ''
        treeItem.arrow!.style.transform  = ''
        treeItem.fOpen = true;
        this.collapseMap.set(treeItem.gizmo.recipe, !treeItem.fOpen);
    }

    createTree(gizmo: Gizmo) {
        this.addItem(gizmo);
        if (gizmo.recipe.children)
            gizmo.recipe.children.forEach((childRecipe) => this.createTree(childRecipe.gizmo!));
    };

    onDragStart(recipe: Recipe, ogEvent: MouseEvent, e: MouseEvent) {
        if (Math.abs(ogEvent.pageX - e.pageX) > 5 || Math.abs(ogEvent.pageY - e.pageY) > 5) {   // make sure we realllly want to drag this guy
            this.recipeMap.get(recipe)!.childList.style.pointerEvents = 'none';
            document.removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            document.removeEventListener('mouseup', this.upListener);       // get rid of the old up listener

            this.moveListener = this.onDrag.bind(this, recipe);             // make sure we remember our listener so we can clean it up later
            document.addEventListener('mousemove', this.moveListener);
            this.upListener = this.onDragFinished.bind(this, recipe);       // make sure we remember our listener so we can clean it up later
            document.addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onDrag(draggedRecipe: Recipe, e: MouseEvent) {
        clearInterval(this.scrollTimeout);
        let element         = e.target as HTMLElement;
        if (element === this.topHover) {
            element = this.findFirstHovered(e) ?? element;
            this.topHover.style.opacity = '1'
            this.element.scrollTop -= 5;
            this.scrollTimeout = setTimeout(() => {
                this.onDrag(draggedRecipe, e);
            }, 10);
        }
        else if (element === this.bottomHover) {
            element = this.findFirstHovered(e) ?? element;
            this.bottomHover.style.opacity = '1'
            this.element.scrollTop += 5;
            this.scrollTimeout = setTimeout(() => {
                this.onDrag(draggedRecipe, e);
            }, 10);
            return;
        }
        else {
            this.topHover.style.opacity = '0';
            this.bottomHover.style.opacity = '0';
        }

        let hoveredGizmo    = this.elementMap.get(element);
        for (let [el, rec] of this.elementMap) {
            el.style.backgroundColor = '';                      // set all the element style properties back to defaults
            el.style.border = '';
            el.style.outline = '';
            el.style.boxShadow = '';
        }
        if (!hoveredGizmo || hoveredGizmo.recipe === draggedRecipe)    // if we aren't hovering over a tree item, or if we are hovering over the element we are dragging
            return;
        if (!this.hoveredElement)                               // if we weren't previously hovering over a tree item
            this.hoveredElement = element;                      // make sure we remember that we are now
        else if (this.hoveredElement != element) {              // else, if we are now hovering over a different element
            this.hoveredElement = element;                      // and remember which element we are hovering over
        }
        let rect = element.getBoundingClientRect();
        let height = rect.bottom - rect.top;
        if (hoveredGizmo instanceof ContainerGizmo) {
            if (e.pageY < rect.top + height / 4) {
                element.style.borderTop = '1px solid var(--color-onSurface)';
                element.style.boxShadow = '0 -1px 0 var(--color-onSurface)';
            }
            else if (e.pageY > rect.top +  3 * height / 4) {
                element.style.borderBottom = '1px solid var(--color-onSurface)';
                element.style.boxShadow = '0 1px 0 var(--color-onSurface)'
            }
            else
                element.style.outline = '2px solid var(--color-blue-4)';
        }
        else if (e.pageY < rect.top + height / 2) {
            element.style.borderTop = '1px solid var(--color-onSurface)';
            element.style.boxShadow = '0 -1px 0 var(--color-onSurface)'

        }
        else  {
            element.style.borderBottom = '1px solid var(--color-onSurface)';
            element.style.boxShadow = '0 1px 0 var(--color-onSurface)'
        }
    };

    // We just ended our drag with a mouseup event
    onDragFinished(recipe: Recipe, e: MouseEvent) {
        clearInterval(this.scrollTimeout);
        document.removeEventListener('mousemove', this.moveListener);
        this.recipeMap.get(recipe)!.childList.style.pointerEvents = 'all';
        if (!this.hoveredElement)
            return;

        let hoveredGizmo    = this.elementMap.get(this.hoveredElement)!;
        let copiedRecipe    = JSON.parse(JSON.stringify(recipe, this.editor.recipeParser)) as Recipe;
        if (hoveredGizmo.recipe === recipe)    // if we aren't hovering over a tree item, or if we are hovering over the element we are dragging
            return;
        if (this.collapseMap.has(recipe)) {
            this.collapseMap.set(copiedRecipe, this.collapseMap.get(recipe)!);
            this.collapseMap.delete(recipe);
        }

        let rect = this.hoveredElement.getBoundingClientRect();
        let height = rect.bottom - rect.top;
        if (hoveredGizmo instanceof ContainerGizmo) {
            if (e.pageY < rect.top + height / 4) {
                let newParentChildArray     = hoveredGizmo.parentRecipe ? hoveredGizmo.parentRecipe.children :  this.editor.view.dashboard.recipes;
                let oldParentChildArray     = recipe.gizmo!.parentRecipe ? recipe.gizmo!.parentRecipe.children : this.editor.view.dashboard.recipes;
                oldParentChildArray.splice(oldParentChildArray.indexOf(recipe), 1);                             // delete this recipe from the old parent
                newParentChildArray.splice(newParentChildArray.indexOf(hoveredGizmo.recipe), 0, copiedRecipe);  // add the copied recipe to the new parent
                this.editor.view.buildGizmo(copiedRecipe, hoveredGizmo.parentElement!, hoveredGizmo.parentRecipe, false, Array.from(hoveredGizmo.parentElement?.children!).indexOf(hoveredGizmo));
                recipe.gizmo?.parentElement?.removeChild(recipe.gizmo);                                         // remove the old gizmo
            }
            else if (e.pageY > rect.top +  3 * height / 4) {
                let newParentChildArray     = hoveredGizmo.parentRecipe ? hoveredGizmo.parentRecipe.children :  this.editor.view.dashboard.recipes;
                let oldParentChildArray     = recipe.gizmo!.parentRecipe ? recipe.gizmo!.parentRecipe.children : this.editor.view.dashboard.recipes;
                oldParentChildArray.splice(oldParentChildArray.indexOf(recipe), 1);                                 // delete this recipe from the old parent
                newParentChildArray.splice(newParentChildArray.indexOf(hoveredGizmo.recipe) + 1, 0, copiedRecipe);   // add the copied recipe to the new parent
                this.editor.view.buildGizmo(copiedRecipe, hoveredGizmo.parentElement!, hoveredGizmo.parentRecipe, false, Array.from(hoveredGizmo.parentElement?.children!).indexOf(hoveredGizmo) + 1);
                recipe.gizmo?.parentElement?.removeChild(recipe.gizmo);                                             // remove the old gizmo
            }
            else {
                if (!hoveredGizmo.recipe.children)
                    hoveredGizmo.recipe.children = [];
                let newParentChildArray     = hoveredGizmo.recipe.children;
                let oldParentChildArray     = recipe.gizmo!.parentRecipe ? recipe.gizmo!.parentRecipe.children : this.editor.view.dashboard.recipes;
                oldParentChildArray.splice(oldParentChildArray.indexOf(recipe), 1);                             // delete this recipe from the old parent
                newParentChildArray.splice(newParentChildArray.indexOf(hoveredGizmo.recipe), 0, copiedRecipe);  // add the copied recipe to the new parent
                this.editor.view.buildGizmo(copiedRecipe, hoveredGizmo, hoveredGizmo.recipe, false, hoveredGizmo.childElementCount);
                recipe.gizmo?.parentElement?.removeChild(recipe.gizmo);                                         // remove the old gizmo
            }
        }
        else if (e.pageY < rect.top + height / 2) { // we are hovering over the top half of the element, so we want to add this gizmo above
            let newParentChildArray     = hoveredGizmo.parentRecipe ? hoveredGizmo.parentRecipe.children :  this.editor.view.dashboard.recipes;
            let oldParentChildArray     = recipe.gizmo!.parentRecipe ? recipe.gizmo!.parentRecipe.children : this.editor.view.dashboard.recipes;
            oldParentChildArray.splice(oldParentChildArray.indexOf(recipe), 1);                             // delete this recipe from the old parent
            newParentChildArray.splice(newParentChildArray.indexOf(hoveredGizmo.recipe), 0, copiedRecipe);  // add the copied recipe to the new parent
            this.editor.view.buildGizmo(copiedRecipe, hoveredGizmo.parentElement!, hoveredGizmo.parentRecipe, false, Array.from(hoveredGizmo.parentElement?.children!).indexOf(hoveredGizmo));
            recipe.gizmo?.parentElement?.removeChild(recipe.gizmo);                                         // remove the old gizmo
        }
        else  {
            let newParentChildArray     = hoveredGizmo.parentRecipe ? hoveredGizmo.parentRecipe.children :  this.editor.view.dashboard.recipes;
            let oldParentChildArray     = recipe.gizmo!.parentRecipe ? recipe.gizmo!.parentRecipe.children : this.editor.view.dashboard.recipes;
            oldParentChildArray.splice(oldParentChildArray.indexOf(recipe), 1);                                 // delete this recipe from the old parent
            newParentChildArray.splice(newParentChildArray.indexOf(hoveredGizmo.recipe) + 1, 0, copiedRecipe);   // add the copied recipe to the new parent
            this.editor.view.buildGizmo(copiedRecipe, hoveredGizmo.parentElement!, hoveredGizmo.parentRecipe, false, (Array.from(hoveredGizmo.parentElement?.children!).indexOf(hoveredGizmo) + 1));
            recipe.gizmo?.parentElement?.removeChild(recipe.gizmo);                                             // remove the old gizmo
        }
        if (copiedRecipe.children && copiedRecipe.children.length > 0)
            this.editor.view.buildDOM(copiedRecipe.children, copiedRecipe, copiedRecipe.gizmo!)
        this.refresh();
    }

    onDragCancelled(recipe: Recipe, e: MouseEvent) {
        document.removeEventListener('mousemove', this.moveListener);
        this.editor.onSelectionChanged(recipe.gizmo!);
        this.editor.scrollElementIntoView(recipe.gizmo!);
    }

    findFirstHovered(e: MouseEvent): HTMLElement | null {
        let allElements = document.elementsFromPoint(e.pageX, e.pageY);
        for (let currentElement of allElements) {
            let hoveredGizmo = this.elementMap.get(currentElement as HTMLElement);
            if (hoveredGizmo) {
                return currentElement as HTMLElement;
            }
        }
        return null;
    }

    createTextInput(recipe: Recipe) {
        let element = this.recipeMap.get(recipe)!.name;
        let input = createElement('input', 'editor__tree__name-input', element, '', {'value':recipe.name});
        input.focus();
        input.setSelectionRange(0, input.value.length)
        input.onblur = () => {
            if (input.value != '')
                recipe.name = input.value;
            this.refresh();
        }
    }

    onClick(widget: Gizmo, e: MouseEvent) {

    }

    onSelectionChanged(selectedGizmo?: Gizmo) {
        for (let [element, gizmo] of this.elementMap) {
            element.setAttribute('_selected', 'false')
        }
        if (selectedGizmo && selectedGizmo.recipe)
            selectedGizmo.recipe.treeItem?.element.setAttribute('_selected', 'true')
    }
}


interface User {
    username : string;
    firstName: string;
    lastName : string;
}

class GizmoOverlay {
    editor      : EditorPage;
    _gizmo      : Gizmo | null = null;
    container   : HTMLElement;
    title       : HTMLElement;
    resizeButton: HTMLElement;
    moveListener: (e: MouseEvent) => any;
    upListener  : (e: MouseEvent) => any;
    resizer     : HTMLElement;
    deleter     : HTMLElement;
    wrapper     : HTMLElement;
    xOffset     : number = 0;
    yOffset     : number = 0;
    constructor(editor: EditorPage) {
        this.editor         = editor;
        this.container      = createElement('div', 'overlay__container hide', this.editor.preview);
        let infoContainer   = createElement('div', 'overlay__info-container', this.container);
        let overlay         = createElement('div', 'overlay__overlay', this.container);
        this.wrapper        = createElement('div', 'overlay__wrapper', overlay);

        // This is a fun little hack to pass scroll events to the next element below the overlay that needs to be scrolled.
        // Scroll events don't bubble up the way click events do, so since our overlay and gizmo have different parents, we
        // need this method to make sure scrolling is passed along.
        this.wrapper.onwheel = (e) => {
            let hoveredElements = Array.from(document.elementsFromPoint(e.pageX, e.pageY));
            for (let i=0;i<hoveredElements.length;) {
                if (hoveredElements[i] === this._gizmo)
                    break;
                hoveredElements.shift();
            }

            for (let element of hoveredElements) {
                element = element as HTMLElement;
                if (element.scrollWidth > element.clientWidth || element.scrollHeight > element.clientHeight) // If the element is scrollable
                {
                    element.scrollBy({
                        top: e.deltaY,
                        left: e.deltaX,
                        behavior: 'smooth'
                    });
                    break;
                }
            }
        }
        let move            = createElement('div', 'overlay__move', infoContainer);
        this.title          = createElement('div', 'overlay__title', infoContainer);
        createElement('img', 'overlay__move__icon', move, '', {'src':MoveIcon});
        this.resizer        = createElement('div', 'overlay__resize', overlay);
        createElement('img', 'overlay__move__icon', this.resizer, '', {'src':ResizeIcon});
        this.deleter        = createElement('div', 'overlay__delete', overlay);
        createElement('img', 'overlay__move__icon', this.deleter, '', {'src':DeleteIcon});

        this.deleter.onclick = () => this.editor.deleteGizmo(this._gizmo!);

        move.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onMoveStart.bind(this, e);
            this.upListener = this.onClickCancelled.bind(this);
            addEventListener('mousemove', this.moveListener);
            addEventListener('mouseup', this.upListener, {once: true});
        }

        this.resizer.onmousedown = (e: MouseEvent) => {
            e.stopPropagation();
            this.moveListener = this.onResizeStart.bind(this, e);
            this.upListener = this.onClickCancelled.bind(this);
            addEventListener('mousemove', this.moveListener);
            addEventListener('mouseup', this.upListener, {once: true});
        }

        this.wrapper.onclick = (e: MouseEvent) => {
            e.stopPropagation();
            let gizmo = this._gizmo && this._gizmo.parentRecipe && this._gizmo.parentRecipe.gizmo;
            this.editor.onSelectionChanged(gizmo);
        }
    }

    onMoveStart(clickEvent: MouseEvent, moveEvent: MouseEvent) {
        if (Math.abs(clickEvent.pageX - moveEvent.pageX) > 5 || Math.abs(clickEvent.pageY - moveEvent.pageY) > 5) {   // make sure we realllly want to drag this guy
            removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
            this._gizmo!.style.position = 'absolute';
            let rect = this._gizmo!.parentElement!.getBoundingClientRect();
            let gizRect = this._gizmo!.getBoundingClientRect();
            this.xOffset = clickEvent.clientX - gizRect.left;
            this.yOffset = clickEvent.clientY - gizRect.top;
            this.moveListener = this.onMoveDrag.bind(this, rect, this._gizmo!.clientWidth, this._gizmo!.clientHeight);             // make sure we remember our listener so we can clean it up later
            addEventListener('mousemove', this.moveListener);
            this.upListener = this.onResizeFinished.bind(this);       // make sure we remember our listener so we can clean it up later
            addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onResizeStart(clickEvent: MouseEvent, moveEvent: MouseEvent) {
        if (Math.abs(clickEvent.pageX - moveEvent.pageX) > 5 || Math.abs(clickEvent.pageY - moveEvent.pageY) > 5) {   // make sure we realllly want to drag this guy
            removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
            removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
            let rect = this.container.getBoundingClientRect();
            let gizRect = this._gizmo!.getBoundingClientRect();
            this.xOffset = clickEvent.clientX - gizRect.right;
            this.yOffset = clickEvent.clientY - gizRect.bottom;
            this.moveListener = this.onResizeDrag.bind(this, rect);             // make sure we remember our listener so we can clean it up later
            addEventListener('mousemove', this.moveListener);
            this.upListener = this.onResizeFinished.bind(this);       // make sure we remember our listener so we can clean it up later
            addEventListener('mouseup', this.upListener, {once: true});
        }
    }

    onMoveDrag(rect: DOMRect, width: number, height: number, moveEvent: MouseEvent) {
        let deltaX = moveEvent.clientX - rect.left;
        let deltaY = moveEvent.clientY - rect.top;
        this._gizmo!.recipe.style[0]['position'] = 'absolute';
        this._gizmo!.recipe.style[0]['top']    = Math.max(Math.min(deltaY - this.yOffset, rect.height - height + this.yOffset), 0) + 'px';
        this._gizmo!.recipe.style[0]['left']   = Math.max(Math.min(deltaX - this.xOffset, rect.width - width + this.xOffset), 0)  + 'px';
        this._gizmo?.applyStyles();
        this.editor.styleView.updateSettings();
        this.resize();
    }

    onResizeDrag(rect: DOMRect, moveEvent: MouseEvent) {
        let width = moveEvent.pageX - rect.left - this.xOffset;
        let height = moveEvent.pageY - rect.top + this.yOffset;
        this._gizmo!.recipe.style[0]['width']  = width + 'px';
        this._gizmo!.recipe.style[0]['height'] = height + 'px';
        this._gizmo?.applyStyles();
        this.editor.styleView.updateSettings();
        this.resize();
    }

    onResizeFinished(e: MouseEvent) {
        e.stopPropagation();
        removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
        removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
        this.editor.styleView.updateSettings();
        this._gizmo!.onResize();
        this._gizmo!.onMove();
    }

    onClickCancelled(e: MouseEvent) {
        e.stopPropagation();
        removeEventListener('mousemove', this.moveListener);   // get rid of the old move listener
        removeEventListener('mouseup', this.upListener);       // get rid of the old up listener
    }

    set gizmo(v: Gizmo | null) {
        if (!v) {
            this.hide();
        }
        else {
            this.show();
            this._gizmo = v;
            this.title.textContent = this._gizmo.recipe.name;
            this.container.onclick = (e: MouseEvent) => {
                let hoverElements = document.elementsFromPoint(e.pageX, e.pageY);
                let sub = false;
                for (let i=0;i<hoverElements.length;++i) {
                    if (hoverElements[i] == this._gizmo) {
                        sub = true;
                        continue;
                    }
                    if (!(hoverElements[i] instanceof Gizmo))
                        continue
                    else if (sub) {
                        this.editor.onSelectionChanged(hoverElements[i] as Gizmo);
                        break;
                    }
                }
            }
            this.resize();
        }
        this.resizer.classList.toggle('hide', !this._gizmo?.allowableStyles.includes(StyleCategories.DIMENSIONS))
        this.refresh();
    }

    get gizmo() {
        return this._gizmo;
    }

    hide() {
        this.container.classList.add('hide');
    }

    show() {
        this.container.classList.remove('hide');
    }

    resize() {
        if (!this._gizmo)
            return;
        let rect        = this._gizmo.getBoundingClientRect();
        let previewRect = this.editor.preview.getBoundingClientRect();
        this.container.style.left   = rect.left - previewRect.left + 'px';
        this.container.style.top    = rect.top - previewRect.top - 18 + 'px';
        this.container.style.height = rect.height + 18  + 'px';
        this.container.style.width  = rect.width + 'px';
    }

    refresh() {
        this.wrapper.removeChildren();
        this._gizmo?.addOverlayElements(this.wrapper);
    }

    destroy() {
        this.editor.preview.removeChild(this.container);
    }
}