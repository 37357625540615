import NodeManager from "../nodemanager";
import { NodeQuality } from '../node';
import Localization from '../localization';
import { createElement, createUniqueId } from '../elements';
import { RadioButtons } from "../radiobuttons";
import Toggle from "../toggle";
import ColoredHeader from "../coloredheader";
import createWidget from '../createwidget';
import { StaticGraph } from "../graph";
import BackArrow from '../images/icons/arrow_back.svg';
import DownloadIcon from '../images/icons/download.svg';
import ForwardArrow from '../images/icons/arrow_forward.svg';
import owner from '../../owner';
import assert from '../debug';
import './pumpcurveview.css';
import View from "./view";
import TreeView, { TreeViewTypes } from "./treeview";
import ViewModal from "../viewmodal";
import CarouselView from "./carouselview";
import { findBestEfficiencyFlow, findOperationRanges } from "../pump";
import { TagUnit, TagUnitQuantity, convert } from "../widgets/lib/tagunits";
import { Role } from "../role";
import { getHash, getRouteAndProperties } from "../router/router";

// The pump curve tab generates two graphs: One with all the pump curves and one with the aggregate pump curve against the system curve
export default class PumpCurveView extends View {
	constructor(device) {
		super();
		this.device = device;
	};

	initialize(parent) {
		super.initialize(parent);
		const rootNode = this.device.tree.nodes[0];
		this.pumpSystem = rootNode.findChildByRole(Role.ROLE_PUMP_BANK);	// Save a pointer to the pump system node
		this.dpo = rootNode.findChildByRole(Role.ROLE_DPO_FOLDER);   // Save a pointer to the DPO node

		const assetMode = this.dpo ? this.dpo.findChild('AssetManagementMode') : null;
		this.fAssetOnly = assetMode && assetMode.getValue() && !owner.ldc.isPowerUser();
		this.separator = (1.1).toLocaleString()[1] === ',' ? ';' : ',';
		this.options = { useGrouping: false };	// This is the option we pass to 'toLocaleString' so it doesn't use thousand place separators

		this.nodeManager = new NodeManager(this);
		this.graphID = owner.ldc.registerGraph(this);					// Register the graph so we can get data callbacks
		this.pumpNodes = this.pumpSystem.findByRole(Role.ROLE_PUMP);	// Find all the pump folder nodes
		var modelPumpNodes = this.pumpSystem.findChildByRole(Role.ROLE_MODEL_PUMPSYSTEM).findByRole(Role.ROLE_MODEL_PUMP);
		this.pumps = [];	// Pump objects we will use to store data in groups

		for (var i = 0; i < this.pumpNodes.length; ++i) {	// For each pump we found
			let pumpFolder	= this.pumpNodes[i];
			let pump 		= {			// This object holds data representing one pump
				name: 				pumpFolder.getDisplayName(),
				nodeName: 			pumpFolder.name,
				curves: 			[],
				timestampNode: 		this.nodeManager.addNodeByName(pumpFolder, 'CurveTimestamp'),	// The node that tells us when the pump model changes
				makeNode: 			pumpFolder.findChildByRole(Role.ROLE_PUMP_MAKE),
				modelNode: 			pumpFolder.findChildByRole(Role.ROLE_PUMP_MODEL),
				powerNode: 			pumpFolder.findChildByRole(Role.ROLE_PUMP_NOMINAL_POWER),
				shaftPowerNode: 	pumpFolder.findChildByRole(Role.ROLE_SHAFT_POWER),
				runTimeNode: 		pumpFolder.findChildByRole(Role.ROLE_PUMP_TOTAL_RUN_TIME),
				startsNode: 		pumpFolder.findChildByRole(Role.ROLE_PUMP_STARTS),
				healthNode: 		pumpFolder.findChildByRole(Role.ROLE_PUMP_HEALTH_METRIC),
				voltageNode: 		pumpFolder.findChildByRole(Role.ROLE_VOLTAGE),
				currentNode: 		pumpFolder.findChildByRole(Role.ROLE_CURRENT),
				faultDescNode: 		pumpFolder.findChildByRole(Role.ROLE_FAULT_DESCRIPTION),
				faultNode: 			pumpFolder.findChildByRole(Role.ROLE_BOOL_FAULTED),
				autoSpeedSwitch:	pumpFolder.findChildByRole(Role.ROLE_AUTO_SPEED_SWITCH),
				manualSpeedNode:	pumpFolder.findChildByRole(Role.ROLE_MAN_SPEED),
				flowNode: 			modelPumpNodes[i].findChildByRole(Role.ROLE_MODEL_PUMP_FLOW),
				headNode: 			modelPumpNodes[i].findChildByRole(Role.ROLE_MODEL_PUMP_HEAD),
				npshNode: 			modelPumpNodes[i].findChildByRole(Role.ROLE_MODEL_PUMP_NPSHA),
				outsideAorNode: 	modelPumpNodes[i].findChild('OutsideAOR'),
				outsidePorNode: 	modelPumpNodes[i].findChild('OutsidePOR'),
				underNpshNode: 		modelPumpNodes[i].findChild('UnderNPSHr'),
				percentBepNode: 	modelPumpNodes[i].findChild('PercentBEP'),
				minBepRatioNode: 	this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_MIN_BEP_RATIO),
				maxBepRatioNode: 	this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_MAX_BEP_RATIO),
				minAORNode: 		this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_MIN_AOR),
				maxAORNode: 		this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_MAX_AOR),
				fUseAOR: 			this.nodeManager.addNodeByName(pumpFolder, "UseAOR"),
				powerLimitNode:		pumpFolder.findChild('ShaftPowerLimit'),
				npshFactorNode: 	pumpFolder.findChild('NPSH_SafetyFactor'),
				testNode: 			this.dpo === null ? null : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_TEST),
				actSpeedNode: 		pumpFolder.findChildByRole(Role.ROLE_ACT_SPEED),
				minSpeedNode: 		pumpFolder.findChildByRole(Role.ROLE_MIN_SPEED),
				maxSpeedNode: 		pumpFolder.findChildByRole(Role.ROLE_MAX_SPEED),
				offlineNode: 		pumpFolder.findChildByRole(Role.ROLE_PUMP_OFFLINE),
				testIntervalNode: 	pumpFolder.findChild('MinTestInterval'),	// The node that tells us how often they should test
				startControlNode: 	this.dpo === null ? null : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_OVERALL_HOA),
				speedControlNode: 	this.dpo === null ? null : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_AUTO_SPEED_CONTROLLABLE),
				fStartNode: 		this.dpo === null ? null : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_START_CONTROLLED),
				fSpeedNode: 		this.dpo === null ? null : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_PUMP_SPEED_CONTROLLED),
				runningNode: 		this.dpo === null ? pumpFolder.findChildByRole(Role.ROLE_BOOL_RUNNING) : this.nodeManager.addNodeByRole(pumpFolder, Role.ROLE_BOOL_RUNNING),
				minScopeNode: 		pumpFolder.findChild('MinRunScope'),
				minTimeNode: 		pumpFolder.findChild('MinRunTime'),
				startFailNode: 		pumpFolder.findChild('StartFailTime'),
				stopFailNode: 		pumpFolder.findChild('StopFailTime'),
				fStartFailNode: 	pumpFolder.findChild('StartFail'),
				fStopFailNode: 		pumpFolder.findChild('StopFail'),
				startDelayNode: 	pumpFolder.findChild('StartDelay'),
				stopDelayNode: 		pumpFolder.findChild('StopDelay'),
				snapshotTimes:		[],
				snapshotFlows: 		[],
				snapshotHeads: 		[],
				snapshotPowers: 	[],
				snapshotEffs: 		[],
				snapshotSpeeds: 	[],
				snapshotAlone: 		[],
				outsidePOR: 		0,
			};
			pump.speedConversion =	pump.actSpeedNode ? pump.actSpeedNode.convertFromCacheToDisplay(1) : 1

			let hoas = [];	// Start off with an empty array
			// In this external HOA folder, the order of children is important. First child has precedence.
			var hoaFolder = pumpFolder.findChildByRole(Role.ROLE_PUMP_EXTERNAL_HOAS);	// Find the external HOA node folder

			if (hoaFolder) {	// This folder doesn't have to exist, but if it does, it will have an external HOA in it
				//assert(hoaFolder.type == NodeType.NT_FOLDER, "External HOA found was not a folder!");
				//assert(hoaFolder.children, "External HOA folder didn't have any children!");
				for (let i = 0; i < hoaFolder.children.length; ++i) {
					hoas.push(hoaFolder.children[i]);	// Add on an HOA control
				}
			}

			// Check for a local, software HOA, which is submissive to all the other HOA controls.
			let softwareHOA = pumpFolder.findChildByRole(Role.ROLE_PUMP_SOFTWARE_HOA);	// Find our locally created HOA
			if (softwareHOA)	// If we have a software HOA (meaning we control start/stop of the pump)
				hoas.push(softwareHOA);	// Add on an HOA control
			pump.hoas = hoas


			this.pumps.push(pump);	// Add the object for each pump
			if (pump.minSpeedNode && pump.maxSpeedNode) {
				this.nodeManager.addNode(pump.minSpeedNode);
				this.nodeManager.addNode(pump.maxSpeedNode);
			}
		}

		var totalFlowNode = this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW);
		var pumpHeadNode = this.pumpSystem.findChildByRole(Role.ROLE_PUMP_HEAD);
		this.flowConversion = convert(1.0, TagUnit.TU_GPM, totalFlowNode.units);
		this.headConversion	= convert(1.0, TagUnit.TU_FEET, pumpHeadNode.units);
		this.effConversion	= 1.8866337e-2 / this.flowConversion / this.headConversion;
		var maxSpeedNode	= this.pumpSystem.findChildByRole(Role.ROLE_MAX_SPEED_HZ);
        let maxSpeedValue 	= maxSpeedNode.getValue();
        this.maxSpeed		= convert(maxSpeedValue, maxSpeedNode.units, maxSpeedNode.tree.unitsMap.get(TagUnitQuantity.TUQ_FREQUENCY) ?? TagUnit.TU_HZ, maxSpeedValue);

		var end 	= new Date().getTime() * 1000;		// Most recent pump curve requested
		var start 	= end - 100 * 86400 * 365 * 1000 * 1000;	// Oldest pump curve requested
		owner.ldc.getPumpCurves(this.graphID, this.pumpSystem.tree.device.id, start, end, (1 << this.pumpNodes.length) - 1);	// Get the last century of curves for all pumps

		this.selectedIndex 	= owner.currentPage.props.index || 0;	// Start off showing the first pump
		var effLineOptions 	= { strokeWidth: 0.75 };	// Make all the efficiency lines a bit thinner
		var snapshotOptions = { connectSeparatedPoints: false, drawPoints: true };	// Don't draw lines for the snapshots, but draw points
		var dottedOptions 	= { strokeWidth: 1, dotLines: [5], roundPoints: true };
		this.graphOptions 	= {						// An object to hold graph options
			colors: 				['gray', 'gray', 'red', 'gold', 'green', 'green', '#555', '#555', '#555', '#555', 'black', 'gray', 'gray', 'blue', 'dodgerblue', 'darkturquoise', 'red', 'orange', 'darkred', 'red', 'orange', 'darkred', 'blue', 'red', 'red', 'darkturquoise', 'darkred', 'darkred', 'black', 'indigo', 'purple', 'purple'],
			customAxis:				[false, false, false, false, false, false, false, true,  true, true, false, true, true, false, false, false, true,  true,  true,  true, true,  true, false, true,  true, false, true,  true, false, false, false, false],       // Plot all the efficiencies on the right axis
			visibility: 			[false, false, true,  true,  true,  true,  true,  false, true, true, true,  true, true, true,  false, true,  false, false, false, true, false, true, true,  false, true, true,  false, true, true,  false, false, false],
			connectSeparatedPoints:	true,				// By default, draw lines between points
			drawPoints: 			false,				// Don't put an indicator at each point
			pointSize: 				2,					// How big to make the points (for the snapshots only)
			xAxisAsNumber: 			true, 				// Interpret the x-axis as numbers, not time stamps
			xlabel: 				Localization.toLocal("Pump Flow (" + this.pumpSystem.findChildByRole(Role.ROLE_TOTAL_FLOW).getUnitsText() + ")"),		// X-axis title
			ylabel: 				Localization.toLocal("Pump Head (" + this.pumpSystem.findChildByRole(Role.ROLE_PUMP_HEAD).getUnitsText() + ")"),		// Y-axis title
			y2label: 				Localization.toLocal('Efficiency (%)'),	// Right y-axis title
			strokeWidth: 			1.5,				// How wide to make the lines
			secondAxisRange: 		[0, 100],			// Efficiencies are scaled 0-100
			HeadPoints: 			snapshotOptions,
			EffPoints: 				snapshotOptions,
			PowerPoints: 			snapshotOptions,
			TestPoints: 			snapshotOptions,
			yAxisLineColor: 		'blue',
			ylabelcolor: 			'blue',
			y2AxisLineColor: 		'red',
			y2labelcolor: 			'red',
			yAxisLabelWidth: 		55,
			independentTicks: 		true,
			drawY2Grid: 			true,
			axes: 					{ y2: { pixelsPerLabel: 40 } },
			fPOR: 					{ connectSeparatedPoints: false },
			POR: 					{ connectSeparatedPoints: false },
			AOR: 					{ connectSeparatedPoints: false },
			notPOR: 				{ connectSeparatedPoints: false },
			CurrentPoint: {
				pointSize: 			3,					// Make the dot a bit bigger
				drawPoints: 		true
			},
			CurrentPower: {
				pointSize: 			3,					// Make the dot a bit bigger
				drawPoints: 		true
			},
			gridLineColor: 			'rgba(0, 204, 0, 0.5)',
			HeadLine: 				dottedOptions,
			PowerLine: 				dottedOptions,
			EffLine: 				dottedOptions,
			Vert: 					dottedOptions,
			SpeedPow: 				effLineOptions,
			PowCurve: 				effLineOptions,
			FactPow: 				effLineOptions,
			SpeedEff: 				effLineOptions,
			EffCurve: 				effLineOptions,
			FactEff: 				effLineOptions,
			CurrentEff: {
									pointSize: 3,					// Make the dot a bit bigger
									drawPoints: true
			},
			NPSHa: {
									pointSize: 3,					// Make the dot a bit bigger
									drawPoints: true
			},
			fillAlpha: 				0.4,
			Hist: 					{ connectSeparatedPoints: false, drawPoints: true, xMarksTheSpot: true, alpha: 0.2 },
			HistPow: 				{ connectSeparatedPoints: false, drawPoints: true, xMarksTheSpot: true, alpha: 0.2 },
			HistEff: 				{ connectSeparatedPoints: false, drawPoints: true, xMarksTheSpot: true, alpha: 0.2 },
		};
		this.phiOptions = {
			colors: ['black', 'red'],
			connectSeparatedPoints: true,		// By default, draw lines between points
			drawPoints: false,					// Don't put an indicator at each point
			pointSize: 2,						// How big to make the points (for the snapshots only)
			ylabel: Localization.toLocal('PHI'),// Y-axis title
			strokeWidth: 1.5,					// How wide to make the lines
			valueRange: [50, 110],
			clickCallback: this.onPhiMove.bind(this),
			mouseMoveCallback: this.onPhiMove.bind(this),
			rightGap: 61,			// How far left to put the right axis
			drawY2Line: true,
			drawCallback: null
		};
		this.wrapper 		= createElement('div', 'pump-curve__wrapper', this.parent)
		this.selectorBar 	= createElement('div', 'pump-curve__selector-bar', this.wrapper);
		this.pageWrapper 	= createElement('div', 'pump-curve__page-wrapper', this.wrapper);
		this.prev 			= createElement('img', 'pump-curve__arrow', this.selectorBar, null, { 'src': BackArrow });
		this.prev.onclick 	= () => this.lowerSelectedIndex();
		this.nameDiv 		= createElement('div', 'pump-curve__selector-bar__name', this.selectorBar);
		this.next 			= createElement('img', 'pump-curve__arrow', this.selectorBar, null, { 'src': ForwardArrow });
		this.next.onclick 	= () => this.raiseSelectedIndex();

		var graphWrapper 	= createElement('div', 'pump-curve__graph-wrapper', this.pageWrapper);	// A wrapper for the pump curve graph

		this.graphContainer = createElement('div', 'pump-curve__graph-wrapper__graph-container', graphWrapper);
		this.makeModelDiv 	= createElement('div', 'pumpNameWrapper pumpTableTitleWidth', this.graphContainer);

		this.graphDiv 		= createElement('div', 'pump-curve__graph-wrapper__graph-container__graph', this.graphContainer);
		this.pumpCurveDiv 	= createElement('div', 'pump-curve__graph-wrapper__graph-container__pump-curve', this.graphDiv);		// The division we will put the pump curve graph in

		this.flowWrapper 	= createElement('div', 'pumpCurveFlow', this.graphDiv);
		createElement('div', 'pumpCurveFlowArrow', this.flowWrapper);
		this.flowLabel 		= createElement('div', 'pumpCurveValue', this.flowWrapper);

		this.headWrapper 	= createElement('div', 'pumpCurveHead', this.graphDiv);
		createElement('div', 'pumpCurveHeadArrow', this.headWrapper);
		this.headLabel 		= createElement('div', 'pumpCurveValue', this.headWrapper);

		this.powerWrapper 	= createElement('div', 'pumpCurveEff', this.graphDiv);
		createElement('div', 'pumpCurveEffArrow', this.powerWrapper);
		this.powerLabel 	= createElement('div', 'pumpCurveValue', this.powerWrapper);

		this.effWrapper 	= createElement('div', 'pumpCurveEff', this.graphDiv);
		createElement('div', 'pumpCurveEffArrow', this.effWrapper);
		this.effLabel 		= createElement('div', 'pumpCurveValue', this.effWrapper);

		var dataWrapper 		= createElement('div', 'pump-curve__data-wrapper', this.pageWrapper);	// This holds all the pump data and settings
		this.dataContainer 		= createElement('div', 'pump-curve__data-wrapper__data-container', dataWrapper);
		//let testPumpButton 		= createElement('button', '', dataWrapper, 'Test');
		//testPumpButton.onclick = () => {
		//	let pumpTest = createElement('pump-test', '', undefined, '', {
		//		pumpFolder: 		{tag: this.pumpNodes[this.selectedIndex]},
		//		curveTimestampTag: 	{tag: this.pumps[this.selectedIndex].timestampNode},
		//		manualTestTag:		{tag: this.dpo.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_MODE)},
		//		manualCollectTag: 	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA)},
		//		manualEndTestTag:	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_MANUAL_TEST_END_TEST)},
		//		testStartTimeTag:	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_TEST_START_TIME)},
		//		testStepTimeTag:	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_TEST_STEP_TIME)},
		//		testFinTimeTag:		{tag: this.dpo.findChildByRole(Role.ROLE_TLC_TEST_COMPLETE_TIME)},
		//		testStateTag:		{tag: this.dpo.findChildByRole(Role.ROLE_TLC_TEST_STATE)},
		//		preCheckDoneTag:	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_PRE_CHECKLIST_DONE)},
		//		postCheckDoneTag:	{tag: this.dpo.findChildByRole(Role.ROLE_TLC_POST_CHECKLIST_DONE)},
		//		systemStateTag: 	{tag: this.pumpSystem.findChildByRole(Role.ROLE_SYSTEM_CURVE_STATE)},
		//	});
//
//
//
		//	let modal = createElement('hmi-modal', '', document.body, '', {
		//		titleText: 'Pump Test',
		//		preferredHeight: '900px',
		//		preferredWidth: '1000px',
		//		titleBackgroundColor: owner.colors.hex('--color-primary')
		//	})
		//	modal.appendChild(pumpTest);
		//}
		this.tagViewContainer 	= createElement('div', 'pump-curve__data-wrapper__data-container__tag-view', this.dataContainer);
		this.pumpTestButton 	= createElement('div', 'se-button hide', this.dataContainer, 'Run Pump Test');
		this.tagViewButton 		= createElement('div', 'se-button pump-curve__data-wrapper__data-container__tag-view-button', this.dataContainer, 'Pump Information')

		//this.dataDiv			= this.createHidingSection(this.dataContainer, 'Pump Data', false);	// Pump data (displayed initially)
		//this.settingsDiv		= this.createHidingSection(this.dataContainer, 'Pump Settings', true);	// Pump settings (hidden initially)
		//var testDiv				= this.createHidingSection(this.dataContainer, 'Pump Test', true);		// Pump test (hidden initially)
		//this.testMessage		= createElement('div', 'pumpCurveLogMessage', testDiv);

		this.keyPressFunction = this.onKeyPress.bind(this);			// We want to listen for the left and right arrow keys
		window.addEventListener('keydown', this.keyPressFunction);

		var legend = createElement('div', 'pumpCurveLegendWrapper', this.graphDiv);
		this.legendItems = [];
		this.createLegendItem(legend, 'Tested Pump Head', 'pumpCurveLegend blueText', [2, 3, 4, 5, 13, 22]);
		this.createLegendItem(legend, 'Tested Efficiency', 'pumpCurveLegend redText', [16, 19, 23, 24]);
		this.createLegendItem(legend, 'Factory Pump Head', 'pumpCurveLegend dodgerBlueText', [0, 1, 14], true);
		this.createLegendItem(legend, 'Factory Efficiency', 'pumpCurveLegend orangeText', [17, 20], true);
		this.createLegendItem(legend, 'Current Pump Head', 'pumpCurveLegend darkTurquoiseText', [6, 15, 25]);
		this.createLegendItem(legend, 'Current Efficiency', 'pumpCurveLegend darkRedText', [7, 8, 18, 21, 26, 27]);
		this.createLegendItem(legend, 'Historical Operation', 'pumpCurveLegend', [10, 11, 12]);
		this.createLegendItem(legend, 'Full Speed NPSHr', 'pumpCurveLegend indigoText', [29], true);
		this.createLegendItem(legend, 'Current NPSH', 'pumpCurveLegend purpleText', [30, 31], true);

		var selectedWrapper = createElement('div', 'pumpCurvePeriodWrapper', this.graphDiv);
		createElement('label', null, selectedWrapper, 'History from ');
		this.startTime = createElement('input', 'savingsInput', selectedWrapper);
		createElement('label', null, selectedWrapper, ' through ');
		this.endTime = createElement('input', 'savingsInput', selectedWrapper);
		this.startTime.type = this.endTime.type = 'date';		// Both are a date, meaning no time selection is possible
		this.startTime.required = this.endTime.required = true;	// Both are required to get rid of HTML's weird x's

		var date = new Date();
		this.endTime.value = date.format('%yyyy-%MM-%dd');
		date.setDate(date.getDate() - 1);
		this.startTime.value = date.format('%yyyy-%MM-%dd');
		this.startTime.onblur = this.endTime.onblur = this.onDateChange.bind(this);

		createElement('label', null, selectedWrapper, '. Time running outside POR: ');
		this.outsidePorTime = createElement('label', null, selectedWrapper);

		var secondWrapper = createElement('div', 'pumpCurveOptionsWrapper', this.graphDiv);
		let singlePumpId = createUniqueId();
		let singleCheckWrapper = createElement('div', 'se-checkbox', secondWrapper);
		this.fSinglePump = createElement('input', 'se-checkbox pumpCurveOption', singleCheckWrapper, null, { 'type': 'checkbox', 'id': singlePumpId });
		let label1 = createElement('label', '', singleCheckWrapper, 'This pump only', { 'htmlFor': singlePumpId });

		let affinityId = createUniqueId();
		let affinityCheckWrapper = createElement('div', 'se-checkbox', secondWrapper);
		this.fAffinity = createElement('input', 'se-checkbox pumpCurveOption', affinityCheckWrapper, null, { 'type': 'checkbox', 'id': affinityId });
		let label2 = createElement('label', '', affinityCheckWrapper, 'Apply affinity laws', { 'htmlFor': affinityId });
		this.fAffinity.onchange = this.fSinglePump.onchange = this.onGraphChecked.bind(this);

		var download = createElement('div', 'pump-curve__export-button', secondWrapper, 'Export');	// Create the input
		var exportIcon = createElement('img', 'overview__settings-icon', download, undefined, { 'src': DownloadIcon });
		download.onclick = this.downloadData.bind(this);				// Attach the call back

		var wrapper 		= createElement('div', 'treeButton', secondWrapper);
		this.effButton 		= createElement('input', 'radio-buttons__input', wrapper);					// Create a button for only plotting efficiencies
		this.effButton.checked = true;														// Start off with efficiencies checked
		var effLabel 		= createElement('label', 'radioButtonLabel', wrapper, 'Efficiency');	// Label for the button
		this.spButton 		= createElement('input', 'radio-buttons__input', wrapper);					// Create a button for the complex (year summary) solve
		var spLabel 		= createElement('label', 'radioButtonLabel', wrapper, 'Shaft Power');	// Label for the button
		this.effButton.type = this.spButton.type = 'radio';									// Type of both
		this.effButton.name = this.spButton.name = 'EffOrPower';							// Bind them together with the same name
		effLabel.setAttribute('for', this.effButton.name);									// Bind the labels
		spLabel.setAttribute('for', this.effButton.name);
		effLabel.button 	= this.effButton;													// Give the labels references to their selected buttons
		spLabel.button 		= this.spButton;
		effLabel.onclick 	= spLabel.onclick = this.onPowerRadioClicked.bind(this);

		this.phiGraphDiv = createElement('div', 'pumpCurveGraph pumpCurvePhiGraph', this.graphDiv);
		this.phiLabel = createElement('div', 'pumpCurveGraph pumpCurvePhiLabel', this.graphContainer);

		this.flows = [];	// Will hold the flows for the full speed head
		this.heads = [];	// Will hold the current full speed heads for the current pump
		this.powers = [];	// These are not used for graphing, just for convenience storage
		this.effs = [];	// Will hold the efficiencies for the current pump
		this.factHeads = [];	// Will hold the factory heads for the current pump
		this.factPowers = [];
		this.factEffs = [];
		this.speedFlows = [];	// Will hold the flows for the current speed head
		this.speedHeads = [];	// Will hold the current actual speed heads for the current pump
		this.speedPowers = [];	// Will hold the current actual speed powers for the current pump
		this.speedEffs = [];	// Will hold the current actual speed efficiencies for the current pump

		this.facPorFlows = [];	// These hold the data for the preferred operation range for the factory curve
		this.facPorHeads = [];
		this.facPorMaxes = [];

		this.badFlows = [];	// These hold the data data outside the AOR
		this.badHeads = [];
		this.badMaxes = [];

		this.midFlows = [];	// These hold the data data outside the POR
		this.midHeads = [];
		this.midMaxes = [];

		this.porFlows = [];	// These hold the data for the preferred operation range (min Flow Ratio - max Flow Ratio)
		this.porHeads = [];
		this.porMaxes = [];

		this.snapshotTimes = [];
		this.snapshotFlows = [];	// These hold the snapshot data for test flow and head points
		this.snapshotHeads = [];
		this.snapshotPowers = [];
		this.snapshotEffs = [];

		this.testFlows = [];
		this.testHeads = [];

		this.bepFlows = [];
		this.bepHeads = [];
		this.fBepFlows = [];
		this.fBepHeads = [];

		this.histTimes = [];
		this.histFlows = [];
		this.histHeads = [];
		this.histPowers = [];
		this.histPowers = [];
		this.histEffs = [];
		this.histSpeeds = [];

		this.headLineFlow = [0, 0];
		this.headLineHead = [0, 0];
		this.powerLineFlow = [0, 0];
		this.powerLineHead = [0, 0];
		this.effLineFlow = [0, 0];
		this.effLineHead = [0, 0];
		this.verFlows = [0, 0];
		this.verHeads = [0, 100];

		this.currentFlow = [0];	// These will hold the current operation point for the pump at its actual speed (if it has an actual speed)
		this.currentHead = [0];
		this.currentPower = [0];
		this.currentEff = [0];
		this.blobs = [];

		this.npshHeads = [], this.speedNPSH = [], this.npsha = [0];
		this.data 				= [['fPOR', 'fBEP', 'notPOR', 'AOR', 'POR', 'BEP', 'HeadLine', 'PowerLine', 'EffLine', 'Vert', 'Hist', 'HistPow', 'HistEff', 'HeadCurve', 'FactoryCurve', 'SpeedCurve', 'PowCurve', 'FactPow', 'SpeedPow', 'EffCurve', 'FactEff', 'SpeedEff', 'HeadPoints', 'PowerPoints', 'EffPoints', 'CurrentPoint', 'CurrentPower', 'CurrentEff', 'TestPoints', 'FsNPSHr', 'NPSHr', 'NPSHa'],
									this.facPorFlows, 	null, 	this.facPorHeads, 	this.facPorMaxes,	// Por for factory curve
									this.fBepFlows, 	null, 	this.fBepHeads, 	null,				// Bep Line for factory curve
									this.badFlows, 		null, 	this.badHeads, 		this.badMaxes,		// Outside Acceptable Operating Range
									this.midFlows, 		null, 	this.midHeads, 		this.midMaxes,		// Acceptable Operating Range
									this.porFlows, 		null, 	this.porHeads, 		this.porMaxes,		// Preferred Operating Range
									this.bepFlows, 		null, 	this.bepHeads, 		null,				// Bep Line
									this.headLineFlow, 	null, 	this.headLineHead, 	null,				// Dotted line at current head
									this.powerLineFlow, null, 	this.powerLineHead, null,				// Dotted line at current power
									this.effLineFlow, 	null, 	this.effLineHead, 	null,				// Dotted line at current efficency
									this.verFlows, 		null, 	this.verHeads, 		null,				// Dotted line at current flows
									this.histFlows, 	null, 	this.histHeads, 	null,				// Historical operation heads
									this.histFlows, 	null, 	this.histPowers, 	null,				// Historical operation efficienies
									this.histFlows, 	null, 	this.histEffs, 		null,				// Historical operation efficienies
									this.flows, 		null, 	this.heads, 		null,				// Current full speed Head curve
									this.flows, 		null, 	this.factHeads, 	null,				// Factory full speed Head curve
									this.speedFlows, 	null, 	this.speedHeads, 	null,				// Current actual speed Head curve
									this.flows, 		null, 	this.powers, 		null,				// Current full speed Power Curve
									this.flows, 		null, 	this.factPowers, 	null,				// Factory full speed Power Curve
									this.speedFlows, 	null, 	this.speedPowers, 	null,				// Current actual speed Power Curve
									this.flows, 		null, 	this.effs, 			null,				// Current full speed Efficiency Curve
									this.flows, 		null, 	this.factEffs, 		null,				// Factory full speed Efficiency Curve
									this.speedFlows, 	null, 	this.speedEffs, 	null,				// Current actual speed Efficiency Curve
									this.snapshotFlows, null, 	this.snapshotHeads, null,				// Test heads from current curve
									this.snapshotFlows, null, 	this.snapshotPowers,null,				// Test powers from current curve
									this.snapshotFlows, null, 	this.snapshotEffs, 	null,				// Test efficiencies from current curve
									this.currentFlow, 	null, 	this.currentHead, 	null,				// Current operating point for the selected pump
									this.currentFlow, 	null, 	this.currentPower, 	null,				// Current operating power for the pump
									this.currentFlow, 	null, 	this.currentEff, 	null,				// Current operating efficiency for the pump
									this.testFlows, 	null, 	this.testHeads, 	null,				// Points for test being conducted
									this.flows, 		null, 	this.npshHeads, 	null,				// NPSH full speed curves
									this.speedFlows, 	null, 	this.speedNPSH, 	null,				// NPSH scaled curve
									this.currentFlow, 	null, 	this.npsha, 		null];				// Current NPSH

		this.phiTimes = [], this.phis = [], this.highlightTimes = [], this.highlightPhis = [];
		this.phiData 			= [['Phi', 'Highlight'],
									this.phiTimes, null, this.phis, null,
									this.highlightTimes, null, this.highlightPhis, null];

		// This is the only place we calculate how many points to draw in the pump curve graph
		this.flows.length = this.heads.length = this.factHeads.length = this.effs.length = this.factPowers.length = this.factEffs.length = this.powers.length = this.npshHeads.length = Math.ceil(this.pumpCurveDiv.clientWidth / 3);

		if (this.dpo) {
			this.manualTestNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_MODE);
			this.manualCollectNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA);
			this.manualEndTestNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_END_TEST);
			this.testStartTimeNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_START_TIME);
			this.testStepTimeNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_STEP_TIME);
			this.testFinTimeNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_COMPLETE_TIME);
			this.testStateNode 		= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_STATE);
			this.preCheckDoneNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_PRE_CHECKLIST_DONE);
			this.postCheckDoneNode 	= this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_POST_CHECKLIST_DONE);
			this.systemStateNode 	= this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SYSTEM_CURVE_STATE);

			//this.testConfig				= this.createTestSection(testDiv, 'Test Configuration',		'pumpCurveData');
			//this.preChecklist			= this.createTestSection(testDiv, 'Pre-Test Checklist',		'pumpCurveChecklist');
			//this.testControls			= this.createTestSection(testDiv, 'Test Controls',			'pumpCurveData');
			//this.testResults			= this.createTestSection(testDiv, 'Test Status',			'pumpCurveData');
			//this.postChecklist			= this.createTestSection(testDiv, 'Post-Test Checklist',	'pumpCurveChecklist');
			//this.preChecklist.boxes		= [];
			//this.postChecklist.boxes	= [];

			// Create a radio button for our operator state
			//this.manTestButton			= new RadioButtons(this.createIndicatorRow('Test Mode:', this.testConfig), {node: this.manualTestNode, list: 'Automated,Operator-Guided'});
			//this.testButtonDiv			= this.createIndicatorRow('Test Pump:', this.testConfig);
			//
			//this.abortButton			= createElement('input', null, this.createIndicatorRow('Abort Test:', this.testControls));
			//this.abortButton.type		= 'button';
			//this.abortButton.value		= Localization.toLocal('Abort');
			//this.abortButton.onclick	= this.onAbort.bind(this);

			//this.manCollectButton		= new Toggle(this.createIndicatorRow('Collect Point:', this.testControls), {node: this.manualCollectNode});
			//this.manEndTestButton		= new Toggle(this.createIndicatorRow('End Test:', this.testControls), {node: this.manualEndTestNode});
			//this.manCollectButton.initialize();
			//this.manEndTestButton.initialize();
			//
			//var testPercentNode			= this.dpo.findChildByRole(Role.ROLE_TLC_TEST_PERCENT);
			//var wrapper					= this.createIndicatorRow('Test Progress:', this.testResults);

			//new BarGraph(createElement('span', null, wrapper), {node: testPercentNode, width: 220, height: 12, fVertical: false}).initialize();
			//new ValueDisplay(createElement('span', null, wrapper), {node: testPercentNode, fShowUnits: true}).initialize();
			//
			//this.timeRemainingDiv		= createElement('span', null, this.createIndicatorRow('Estimated Time Remaining:', this.testResults));
			//this.timerID				= setInterval(this.onTimerExpired.bind(this), 10000);	// Update every ten seconds
			//
			//owner.ldc.getTestChecklists(this.graphID, this.pumpSystem.tree.device.id);	// Get the checklist information for the pumps
		}
		this.powerData 	= [7, 11, 16, 17, 18, 23, 26];
		this.effData 	= [8, 12, 19, 20, 21, 24, 27];

		owner.ldc.getPumpCurves(this.graphID, this.pumpSystem.tree.device.id, 0, 0, (1 << this.pumpNodes.length) - 1);	// Get the factory curves for all pumps

		this.nodeManager.subscribe();
		this.onDateTimeout();

		this.fInitialized = true;
		return this; // always return the view after initialization
	}

	onViewShown() {
		if (this.fInitialized)
			this.selectPump(this.pumps[this.selectedIndex]);
	}

	fixAllVisibilities() {
		for (var i = 0; i < this.legendItems.length; ++i)	// Check all legend items
			this.fixVisibility(this.legendItems[i], false);	// Don't toggle, just update everything
	}

	fixVisibility(legendItem, fToggle) {
		var fShowing = legendItem.getAttribute("visible") === 'true';	// True if the item is currently showing
		var toHide = legendItem.toHide;		// Lift of lines to hide
		if (fToggle)						// If we want to toggle this set
			fShowing = !fShowing;			// Change the showing status
		for (var j = 0; j < toHide.length; ++j) {		// For each line
			if (this.powerData.includes(toHide[j]))		// If it's power data
				this.graphOptions.visibility[toHide[j]] = this.spButton.checked && fShowing;	// Only show it if power data is showing
			else if (this.effData.includes(toHide[j]))	// If it is efficiency data
				this.graphOptions.visibility[toHide[j]] = this.effButton.checked && fShowing;	// Only show it if eff data is showing
			else										// Head or NPSH or POR
				this.graphOptions.visibility[toHide[j]] = fShowing;	// Just match the legend item status
		}
		return fShowing;					// Return the status
	}

	onPowerRadioClicked(e) {
		if (!e.target.button.checked) {		// If this button isn't checked
			e.target.button.checked = true;	// Check it
			if (this.effButton.checked) {	// Showing efficiency
				this.graphOptions.y2label 			= Localization.toLocal('Efficiency (%)');	// Update Y2 label
				this.graphOptions.secondAxisRange 	= [0, 100];								// Update scale
				this.legendItems[1].textContent 	= 'Tested Efficiency';			// Update legend
				this.legendItems[3].textContent 	= 'Factory Efficiency';
				this.legendItems[5].textContent 	= 'Current Efficiency';
			} else {
				this.graphOptions.y2label 			= Localization.toLocal('Shaft Power (kW)');				// Update Y2 label
				this.graphOptions.secondAxisRange 	= [0, Math.max.apply(null, this.powers) * 1.05];	// Update scale
				this.legendItems[1].textContent 	= 'Tested Shaft Power';							// Update legend
				this.legendItems[3].textContent 	= 'Factory Shaft Power';
				this.legendItems[5].textContent 	= 'Current Shaft Power';
			}
			this.verHeads[1] = this.graphOptions.secondAxisRange[1];	// Update dotted lines

			this.fixAllVisibilities();	// Check graph visibilities
			this.onGraphChecked();		// Replot the graph
		}
	}

	resize() {
		this.drawGraph();
		this.drawPhiGraph();
		if (this.speedNode)
			this.calculateCurrentCurves(this.speedNode)
	}

	onGraphChecked() {
		this.addHistoricalSnapshots();	// Recompute pointss
		this.drawGraph();				// Redraw the graph
	}

	downloadData() {
		var pump = this.pumps[this.selectedIndex];	// Get the selected pump
		var csv = 'Timestamp' + this.separator;
		csv += pump.name + this.graphOptions.xlabel + this.separator;	 	// Build up the header. Pump1Flow(gpm)
		csv += pump.name + this.graphOptions.ylabel + this.separator;		// Pump1Head(ft)
		csv += pump.name + this.graphOptions.y2label + this.separator;		// Pump1Efficiency(%)
		csv += pump.name + 'Speed (Hz)' + this.separator;					// Pump1Speed(hz)
		csv += '\n';
		for (var i = 0; i < this.histFlows.length; ++i) {	// For each point we have graphed
			csv += `${new Date(this.histTimes[i] * 1000).format('%yyyy/%MM/%dd %HH:%mm:%ss %zz')}${this.separator}`;	// Timestamp
			csv += this.histFlows[i].toLocaleString(undefined, this.options) + this.separator;	// Flow
			csv += this.histHeads[i].toLocaleString(undefined, this.options) + this.separator;	// Head
			if (this.effButton.checked)
				csv += this.histEffs[i].toLocaleString(undefined, this.options) + this.separator;	// Eff
			else
				csv += this.histPowers[i].toLocaleString(undefined, this.options) + this.separator;	// Power
			csv += this.histSpeeds[i].toLocaleString(undefined, this.options) + this.separator;	// Speed
			csv += '\n';
		}

		// Create an href element that will allow the user to download the data as a CSV
		var downloadLink = document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
		downloadLink.download = 'pump.csv';				// File name to download as
		downloadLink.href = URL.createObjectURL(new Blob([csv], { type: 'text/plain' }));	// Make a blob text file URL for the CSV
		downloadLink.click();							// Simulate clicking on the hyperlink
	}

	onDateInput(e) {	// Whenever either of the date has a key pressed on them
		if (e.keyCode == 38 || e.keyCode == 40) {	// Up arrow is 38, down arrow is 40.
			this.oldValue = this.value;				// Save the old value
			this.oldDate = this.valueAsNumber;		// Save the old date timestamp
			this.sign = e.keyCode == 38 ? 1 : -1;	// Remember which direction they pressed
		}
	}

	fixTime(element) {	// Called whenever the date input changes
		if (element.oldValue) {		// If they hit up or down, fix how the damn arrow keys work
			// The goal here is to make the arrow keys iterate like you'd expect. Instead of this:
			//		2017-02-28 ==> UP ARROW KEY on the day input ==> 2017-02-29
			// make it work like this:
			//		2017-02-28 ==> UP ARROW KEY on the day input ==> 2017-03-01
			if (isNaN(element.valueAsNumber)) {	// If NaN, then an invalid date is displayed
				element.valueAsNumber = element.oldDate;					// Go back to the valid date
				element.sign == 1 ? element.stepDown() : element.stepUp();	// Go the OPPOSITE direction we can tell what changed
			}
			var offset = new Date().getTimezoneOffset() * 60;		// Get the time zone offset
			var date = new Date(element.oldDate + offset * 1000);	// Go to the old date
			if (element.oldValue.substr(0, 4) !== element.value.substr(0, 4))	// If they changed the year. Both are of the form 2015-12-31
				date.setFullYear(date.getFullYear() + element.sign);			// Increment/decrement the year
			else if (element.oldValue.substr(5, 2) !== element.value.substr(5, 2)) 	// If they changed the month
				date.setMonth(date.getMonth() + element.sign);				// Increment/decrement the month
			else 															// Else they changed the day
				date.setDate(date.getDate() + element.sign);				// Increment/decrement the day
			element.valueAsNumber = date.getTime() - offset * 1000;			// Set the new date on the input
			delete element.oldValue;										// Delete the saved value so other keys don't correct the date
		}
	}

	onDateChange(e) {	// Whenever either of the date objects change
		this.fixTime(e.target);	// See if we need to fix up the time
		this.endTime.valueAsNumber = Math.min(this.endTime.valueAsNumber, new Date().setHours(0, 0, 0, 0));	// Clamp end date to now
		this.startTime.valueAsNumber = Math.min(this.startTime.valueAsNumber, this.endTime.valueAsNumber);	// Clamp start date to end date
		// If they get too wide, start clamping. Move start or end to match at a maximum of one month away.
		if (e.target === this.endTime && 														// If we are changing the end time
			this.endTime.valueAsNumber > 0 && 													// and our time makes sense
			!Number.isNaN(this.endTime.valueAsNumber) && 										// and our time is a valid number
			this.endTime.valueAsNumber - this.startTime.valueAsNumber > 30 * 86400 * 1000) {	// and our end time is greater than 30 days from our start time
				this.startTime.valueAsNumber = this.endTime.valueAsNumber - 30 * 86400 * 1000;	// set the start time to 30 days before our end time
		}
		else if (this.startTime.valueAsNumber > 0 && 												// If our time makes sense
				!Number.isNaN(this.startTime.valueAsNumber) && 										// and our time is a valid number
				this.endTime.valueAsNumber - this.startTime.valueAsNumber > 30 * 86400 * 1000) {	// and our start time is greater than 30 days from our end time
					this.endTime.valueAsNumber = this.startTime.valueAsNumber + 30 * 86400 * 1000;
		}
		if (this.dateID !== undefined)	// If we are waiting on a hitch
			clearTimeout(this.dateID);	// Clear the old hitch
		this.dateID = setTimeout(this.onDateTimeout.bind(this), 500);	// Wait half a second before requesting data
	}

	onDateTimeout() {
		// We have this hitch so if they scroll through a bunch of data, we don't make a ton of requests all at once
		delete this.dateID;		// We have hitched! Delete the timestamp
		var d = new Date(this.endTime.valueAsNumber);	// Get the date
		d.setDate(d.getDate() + 1);	// Advance to the end of the day (the label says through whatever day)
		this.reqEndTime = owner.timeZone.toLocal(d) * 1000 * 1000;									// Go to UTC from their time zone
		this.reqStartTime = owner.timeZone.toLocal(new Date(this.startTime.valueAsNumber)) * 1000 * 1000;	// Go to UTC from their time zone
		owner.ldc.getSnapshotData(this.graphID, this.pumpSystem.tree.device.id, this.reqStartTime, this.reqEndTime);
	}

	createHidingSection(dataWrapper, titleText, fHidden) {
		var dataSection = createElement('div', 'pumpCurveDataSection', dataWrapper);
		var dataTitle = createElement('div', 'pumpCurveDataTitle pumpsTabTitle', dataSection);
		var dataArrow = createElement('text', 'pumpCurveDropDownArrow', dataTitle);
		dataArrow.innerHTML = fHidden ? '&#9654' : '&#9660';
		createElement('text', null, dataTitle, titleText);
		var dataDiv = createElement('div', 'pumpCurveData', dataSection);
		dataDiv.setAttribute('hidden', fHidden);
		dataDiv.dataArrow = dataArrow;
		dataDiv.dataTitle = dataTitle;
		dataTitle.setAttribute('unselected', fHidden);
		dataTitle.onclick = this.onHidingSectionClick.bind(this, dataDiv);

		this.dataSections = this.dataSections || [];
		this.dataSections.push(dataDiv);

		return dataDiv;
	}

	onHidingSectionClick(dataDiv) {
		for (var i = 0; i < this.dataSections.length; ++i) {
			var fHidden = dataDiv !== this.dataSections[i];	// One section (the one just clicked) is shown. Hide the rest
			this.dataSections[i].setAttribute('hidden', fHidden);	// Hide or show
			this.dataSections[i].dataTitle.setAttribute('unselected', fHidden);
			this.dataSections[i].dataArrow.innerHTML = fHidden ? '&#9654' : '&#9660';	// Arrow down or up as appropriate
		}
	}

	hideModal(e) {
		this.modalBox.setAttribute('show', false);	// Hide the modal box
		this.modalBox.box.destroyWidgets(true);		// Clear the tank widget we created
		this.modalBox.box.removeChildren();			// Remove all the elements created in the box
	}

	onAbort() {
		this.pumps[this.selectedIndex].testNode.setValue(false);	// They have hit the abort button, on which we just unset the test pump node
	}

	onTimerExpired() {
		var testFinishTime = this.testFinTimeNode.getValue();
		if (testFinishTime == 0)						// Invalid test finish time
			this.timeRemainingDiv.textContent = '';		// Just put a place holder in
		else {											// We have a valid end time
			var timeLeft = Math.max(0, Math.ceil((testFinishTime / 1000 - (new Date().getTime())) / 60000));		// Estimated time left in the test in minutes, clamped to zero
			this.timeRemainingDiv.textContent = timeLeft <= 1 ? 'less than 1 minute' : timeLeft + " minutes";	// Give minutes resolution on our estimate
		}
	}

	onLegendClick(legendItem) {
		var fVisible = this.fixVisibility(legendItem, true);	// Change the visibilities of the lines we were told to hide
		var vis = this.graphOptions.visibility;
		vis[9] = vis[6] || vis[7] || vis[8];					// Show the vertical line if anything is showing
		this.flowWrapper.classList.toggle('hide', !vis[9]);		// Show the flow wrapper if the flow is showing
		this.headWrapper.classList.toggle('hide', !vis[6]);		// Hide the hide indicator if the head is showing
		this.drawGraph();								// Redraw the graph
		legendItem.setAttribute("visible", fVisible);	// Make the legend entry fade a bit for hidden lines
	}

	createLegendItem(parent, text, className, toHide, fHidden) {
		var e = createElement('label', className, parent, text);	// Create the entry
		e.toHide = toHide;								// Remember the array of lines to hide
		e.setAttribute("visible", !fHidden);			// Show it or not
		e.onclick = this.onLegendClick.bind(this, e);	// Set a click callback
		this.legendItems.push(e);						// Add it to the list
	}

	createTestSection(testDiv, text, className) {
		var title = createElement('div', 'pumpCurveChecklistTitle', testDiv);
		var arrow = createElement('text', 'pumpCurveDropDownArrow', title);
		arrow.innerHTML = '&#9654';
		createElement('text', null, title, text);
		var section = createElement('div', className + ' hide', testDiv);
		title.onclick = function () { arrow.innerHTML = section.classList.toggle('hide') ? '&#9654' : '&#9660'; };
		return section;
	}

	updateEfficiency() {
		this.currentEff[0] 		= this.currentFlow[0] * this.currentHead[0] / this.currentPower[0] * this.effConversion;
		if (this.efficiencyDiv)
			this.efficiencyDiv.textContent = isNaN(this.currentEff[0]) ? '' : this.currentEff[0].toFixed(1) + ' %';
		this.effLabel.textContent 	= this.currentEff[0].toFixed(1);
		this.effLineHead[0] 	= this.effLineHead[1] = this.currentEff[0];
	}

	update(node) {
		if (!this.fShown) return;
		var pump = this.pumps[this.selectedIndex];	// Get the selected pump
		if (!pump || node.quality !== NodeQuality.NQ_GOOD)
			return;

		switch (node) {	// Switch uses the '===' operator, so this should be pretty cheap
			case this.speedNode:		// Need to update our current speed curves
				this.calculateCurrentCurves(node);
				return;

			case this.flowNode:			// Update the current operating point for the pump
				if (!this.flowNode || !this.headNode) return;
				this.currentFlow[0] 	= this.flowNode.getValue();
				this.currentHead[0] 	= this.headNode.getValue();
				this.npsha[0] 			= pump.npshNode.getValue();
				this.headLineHead[0] 	= this.headLineHead[1] = this.currentHead[0];
				this.verFlows[0] 		= this.verFlows[1] = this.headLineFlow[1] = this.powerLineFlow[0] = this.effLineFlow[0] = this.currentFlow[0];
				this.flowLabel.textContent 	= this.currentFlow[0].toFixed(Math.min(this.flowNode.digits, 1));
				this.headLabel.textContent 	= this.currentHead[0].toFixed(Math.min(this.headNode.digits, 1));
				this.updateEfficiency();
				this.drawGraph();
				return;
			case this.powerNode:
				this.currentPower[0] 	= this.powerNode.convertValue(TagUnit.TU_KW);
				this.powerLineHead[0] 	= this.powerLineHead[1] = this.currentPower[0];
				this.powerLabel.textContent = this.currentPower[0].toFixed(1);
				this.updateEfficiency();
				this.drawGraph();
				return;
			case this.headNode:			// These nodes are updated with the flow node
				return;

			case pump.minBepRatioNode:	// If this is one of the POR nodes for the pump
			case pump.maxBepRatioNode:
			case pump.minAORNode:
			case pump.maxAORNode:
			case pump.minSpeedNode:
			case pump.maxSpeedNode:
			case pump.fUseAOR:
				this.updateOperationRanges(pump);	// Update the graph
				this.drawGraph();					// Redraw the graph
				return;
		}

		for (var i = 0; i < this.pumps.length; ++i) {	// Check if any pump curves have changed, not just the selected pump
			if (node === this.pumps[i].timestampNode) {
				// If the pump already has gotten its curve once (at start up)
				if (this.pumps[i].timestamp !== undefined && this.pumps[i].timestamp != this.pumps[i].timestampNode.getValue()) {
					var now = new Date().getTime() * 1000;
					owner.ldc.getPumpCurves(this.graphID, node.tree.device.id, now, now, 1 << i);	// Requery the curve
				}
				return;		// Found our pump. No need to look through the rest
			}
		}
	}

	onPumpCurvesResponse(fp) {
		var start = fp.pop_u64();	// The start time we asked for
		var end = fp.pop_u64();	// The last time we asked for
		var config = fp.pop_u64();	// The pumps we asked data of

		for (var i = 0; i < this.pumps.length; ++i) {
			if (!(config & (1 << i)))	// We didn't ask for this pump
				continue;				// Skip it

			var pump = this.pumps[i];	// Convenience reference to the pump we are modifying
			var curveCount = fp.pop_u8();
			for (var j = 0; j < curveCount; ++j) {
				var curve = { timestamp: fp.pop_u64() };

				curve.headCurve = [];	// Extract the head curve
				var headCurveTerms = fp.pop_u8();
				for (var k = 0; k < headCurveTerms; ++k)
					curve.headCurve.push(fp.pop_f64() * this.headConversion / Math.pow(this.flowConversion, k));

				curve.powerCurve = [];	// Extract the shaft power curve
				var powerCurveTerms = fp.pop_u8();
				for (var k = 0; k < powerCurveTerms; ++k)
					curve.powerCurve.push(fp.pop_f64() / Math.pow(this.flowConversion, k));

				if (start === 0 && end === 0) {
					pump.npshrCurve = [];
					var npshrTerms = fp.pop_u8();
					for (var k = 0; k < npshrTerms; ++k)
						pump.npshrCurve.push(fp.pop_f64() * this.headConversion / Math.pow(this.flowConversion, k));
				}

				var snapshotCount = fp.pop_u16();
				curve.flows = [], curve.heads = [], curve.powers = [], curve.effs = [];
				for (var k = 0; k < snapshotCount; ++k) {
					var flow 	= fp.pop_f32();	// Pump flow in GPM
					var head 	= fp.pop_f32();	// Pump head in ft
					var power 	= fp.pop_f32();	// Shaft power in kW
					var speed 	= fp.pop_f32();	// Pump speed in Hz
					var ratio 	= speed == 0 ? 1 : this.maxSpeed / speed;

					curve.flows.push(flow * this.flowConversion * ratio);				// Pro rate everything up to full speed
					curve.heads.push(head * this.headConversion * ratio * ratio);
					curve.powers.push(power * ratio * ratio * ratio);
					curve.effs.push(curve.flows[k] * curve.heads[k] * this.effConversion / curve.powers[k]);
				}

				pump.curves.binsert(curve, this.sortCurves);	// Keep the curves in order
			}

			if (start == 0) {	// This is the factory curve
				var firstCurve = pump.curves[0];
				pump.factoryHeadCurve = firstCurve.headCurve;	// Save these curves as factory curves
				pump.factoryPowerCurve = firstCurve.powerCurve;
				pump.shutOffHead = Math.max(pump.headCurve[0], pump.factoryHeadCurve[0]);
				pump.fBepFlow = findBestEfficiencyFlow(pump.zeroHeadFlow, pump.factoryHeadCurve, pump.factoryPowerCurve);
			} else {											// This is the current pump curve
				var lastCurve = pump.curves[pump.curves.length - 1];
				this.pumpNodes[i].pumpCurve = lastCurve;
				pump.headCurve = lastCurve.headCurve;		// Save these curves as current curves
				pump.powerCurve = lastCurve.powerCurve;
				pump.timestamp = lastCurve.timestamp;
				pump.curve = lastCurve;
				var flowStep = 100 * this.flowConversion;		// How much we jump to check and see if the head is negative
				var flow = flowStep;						// Start off with a small flow and keep jumping up until head gets negative
				while (pump.zeroHeadFlow === undefined) {	// Find the head at zero flow
					if (pump.headCurve.evaluatePolynomial(flow) <= 0)	// Found a head less than 0!
						pump.zeroHeadFlow = pump.headCurve.solvePolynomial(flow - flowStep, flow, 0, 0.5 * this.flowConversion);	// Get a little more accurate on zero head flow
					else									// Head was positive
						flow += flowStep;					// Keep looking at a bigger flow for the zero head flow
				}
				lastCurve.zeroHeadFlow = pump.zeroHeadFlow;
				pump.bepFlow = lastCurve.bepFlow = findBestEfficiencyFlow(pump.zeroHeadFlow, pump.headCurve, pump.powerCurve);
			}
		}

		if (start == 0)		// If this was the request for factory curves, create all the pump stuff
			this.selectPump(this.pumps[this.selectedIndex]);
		else {				// We were querying the current curves
			var maxZeroHeadFlow = 0, maxShutoffHead = 0;	// Find out which pump has the biggest zero head flow
			for (var i = 0; i < this.pumpNodes.length; ++i) {
				maxZeroHeadFlow = Math.max(maxZeroHeadFlow, this.pumpNodes[i].pumpCurve.zeroHeadFlow);	// Take the max zero head flow
				maxShutoffHead = Math.max(maxShutoffHead, this.pumpNodes[i].pumpCurve.headCurve[0]);
			}
			for (var i = 0; i < this.pumpNodes.length; ++i) {
				this.pumpNodes[i].pumpCurve.maxZeroHeadFlow = maxZeroHeadFlow;							// Set the max zero head flow
				this.pumpNodes[i].pumpCurve.maxShutoffHead = maxShutoffHead;
			}
		}
	}

	sortCurves(curve1, curve2) {	// This is used in the Array.sort method to rank pump test curves by timestamp
		return curve1.timestamp - curve2.timestamp;
	}

	onSnapshotDataResponse(fp) {
		var start = fp.pop_u64();
		var end = fp.pop_u64();
		var count = fp.pop_u32();	// Snapshot count. Size arrays appropriately
		if (this.testStartTimeNode && start === this.testStartTimeNode.getValue()) {	// These are test snapshots
			var pump;										// First find the pump that is being tested. We just got new test ponts for it
			for (var j = 0; j < this.pumps.length; ++j) {
				if (this.pumps[j].testNode.getValue()) {
					pump = this.pumps[j];	// This is our guy
					break;
				}
			}
			var index = this.pumps.indexOf(pump);
			for (var i = 0; i < count; ++i) {
				fp.skip(6);		// Skip timestamp and config
				var flow = fp.pop_f32();	// Pump flow in GPM
				var head = fp.pop_f32();	// Pump head in ft
				fp.pop_f32();				// Skip the power
				var speed = fp.pop_f32();	// Pump speed in Hz
				this.testSpeed = speed;
				var factor = speed == 0 ? 1 : this.maxSpeed / speed;	// Use the affinity laws to calculate the full speed points
				this.testFlows[i] = flow * this.flowConversion * factor;
				this.testHeads[i] = head * this.headConversion * factor * factor;
			}

			if (this.selectedIndex == index)	// If this is the pump we are currently displaying
				this.selectPump(pump);		// Draw the graph with these new pump parameters
		} else if ((end === this.reqEndTime) && (start === this.reqStartTime)) {
			var outsidePORtimes = [], runTimes = [];
			for (var j = 0; j < this.pumps.length; ++j) {
				this.pumps[j].snapshotTimes.length = this.pumps[j].snapshotFlows.length = this.pumps[j].snapshotHeads.length = this.pumps[j].snapshotPowers.length = this.pumps[j].snapshotEffs.length = this.pumps[j].snapshotSpeeds.length = this.pumps[j].snapshotAlone.length = 0;
				outsidePORtimes.push(0);
				runTimes.push(0);
			}
			start /= 1000000;
			for (var i = 0; i < count; ++i) {
				var time = fp.pop_u32();
				var config = fp.pop_u16();
				var interval = time - start;
				for (var j = 0; j < this.pumps.length; ++j) {
					var mask = (1 << j);
					if (config & mask) {
						var flow = fp.pop_f32() * this.flowConversion;
						var head = fp.pop_f32() * this.headConversion;
						var power = fp.pop_f32();
						var speed = fp.pop_f32() * this.pumps[j].speedConversion;
						this.pumps[j].snapshotTimes.push(time)
						this.pumps[j].snapshotFlows.push(flow);
						this.pumps[j].snapshotHeads.push(head);
						this.pumps[j].snapshotSpeeds.push(speed);
						this.pumps[j].snapshotPowers.push(power);
						this.pumps[j].snapshotEffs.push(flow * head * this.effConversion / power);
						this.pumps[j].snapshotAlone.push(config == mask);
						runTimes[j] += interval;
						var ratio = (speed == 0 ? flow : flow * this.maxSpeed / speed) / this.pumps[j].bepFlow;
						if (ratio < this.pumps[j].minBepRatioNode.getValue() || this.pumps[j].maxBepRatioNode.getValue() < ratio)
							outsidePORtimes[j] += interval;
					}
				}
				start = time;
			}

			for (var j = 0; j < this.pumps.length; ++j)
				this.pumps[j].outsidePOR = runTimes[j] == 0 ? 0 : 100 * outsidePORtimes[j] / runTimes[j];

			this.addHistoricalSnapshots();
			this.drawGraph();	// Redraw the graph
		} else	// Not test snapshots and not a request for pump operation. Must be something we wanted, but kept going
			fp.skip(fp.size());
	}

	addHistoricalSnapshots() {
		this.histFlows.length = this.histHeads.length = this.histPowers.length = this.histEffs.length = this.histSpeeds.length = this.histTimes.length = 0;	// Reset graphed snapshots
		var pump = this.pumps[this.selectedIndex];					// Pump reference
		for (var i = 0; i < pump.snapshotFlows.length; ++i) {		// For each snapshot
			if (this.fSinglePump.checked && !pump.snapshotAlone[i])	// If the want single pumps and this isn't a single pump snapshot
				continue;											// Keep going
			var factor = this.fAffinity.checked && pump.actSpeedNode ? this.maxSpeed / pump.snapshotSpeeds[i] : 1;	// Calculate factor
			this.histTimes.push(pump.snapshotTimes[i])
			this.histFlows.push(pump.snapshotFlows[i] * factor);			// Flow
			this.histHeads.push(pump.snapshotHeads[i] * factor * factor);	// Head
			this.histPowers.push(pump.snapshotPowers[i] * factor * factor * factor);	// Power
			this.histEffs.push(pump.snapshotEffs[i]);					// Efficiency
			this.histSpeeds.push(this.fAffinity.checked || pump.actSpeedNode == null ? this.maxSpeed : pump.snapshotSpeeds[i]);	// Full speed or snapshot speed
		}
		this.outsidePorTime.textContent = pump.outsidePOR.toFixed(1) + '%.';	// Update pump outside POR label
	}

	onTestChecklists(fp) {
		this.createChecklistRow(fp, this.preChecklist, this.preCheckDoneNode);
		this.createChecklistRow(fp, this.postChecklist, this.postCheckDoneNode);
	}

	createChecklistRow(fp, checklist, node) {
		var itemCount = fp.pop_u8();
		if (itemCount == 0)
			createElement('div', 'pumpCurveChecklistItem', checklist, 'Nothing here');

		for (var i = 0; i < itemCount; ++i) {
			var div = createElement('div', 'pumpCurveChecklistItem', checklist);
			var checkbox = createElement('input', null, div);
			checkbox.type = 'checkbox';
			checkbox.onchange = this.onCheckboxChange.bind(this, checklist, checkbox, node);
			checkbox.checked = node.getValue();
			checklist.boxes[i] = checkbox;
			if (!node.couldBeWritten())
				checkbox.setAttribute('disabled', true);
			createElement('div', 'pumpCurveChecklistText', div, fp.pop_string());
		}
	}

	onCheckboxChange(checklist, checkbox, node, state) {
		if (node.getValue())									// If the node is already true
			checkbox.checked = true;							// Leave the box checked. Nothing they should do
		else if (!node.hasWritePermission())					// If we can't write the node
			checkbox.checked = false;							// Don't check the box
		else {													// We have write permission
			for (var i = 0; i < checklist.boxes.length; ++i) {	// We only write out trues here in case another client sets the box to true
				if (!checklist.boxes[i].checked)				// If a single checkbox isn't checked, don't write anything out
					return;
			}
			node.setValue(true);								// Write a new value to the node
		}
	}

	updateCheckboxes(checklist, node, fCorrectState) {	// This sets the test checklist check boxes
		var fTrue = node.getValue();
		for (var i = 0; i < checklist.boxes.length; ++i) {			// Make sure all the check boxes are cleared, just in case
			checklist.boxes[i].checked = fTrue;						// If the node is set, all of the check boxes should be checked
			if (!node.couldBeWritten() || fTrue || !fCorrectState)	// If they can't write the node, if it's already set, or if we aren't waiting on a checklist
				checklist.boxes[i].setAttribute('disabled', true);	// Disable all of the checkboxes
			else if (!fTrue)
				checklist.boxes[i].removeAttribute('disabled');
		}
	}

	onKeyPress(e) {
		if (this.pumpCurveDiv.clientHeight == 0 || 								// If we aren't on the displayed tab
			e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA')	// Or they are focused on an input element in the DOM
			return;																// Leave, keeping the page static

		if (e.keyCode == 37)			// Left arrow
			this.lowerSelectedIndex();
		else if (e.keyCode == 39)		// Right arrow
			this.raiseSelectedIndex();
	}

	onIndexChange(index) {
		this.selectedIndex = index;
		this.selectPump(this.pumps[index]);	// Select the pump we ended up on
	}

	lowerSelectedIndex() {
		if (this.selectedIndex > 0) {	// If we're not at the lowest pump
			--this.selectedIndex;
			location.hash = getHash(...getRouteAndProperties(location.hash, {'index': this.selectedIndex.toString()}));
		}
	}

	raiseSelectedIndex() {
		if (this.selectedIndex < this.pumps.length - 1) {	// If we're not at the highest pump
			++this.selectedIndex
			location.hash = getHash(...getRouteAndProperties(location.hash, {'index': this.selectedIndex.toString()}));
		}
	}

	selectPump(pump) {

		let tagViewProperties = {
			folder: this.buildFolder(pump),
			type: TreeViewTypes.TVT_SETTINGS,
		}

		let pumpTestProperties = {
			pump: pump,
			dpo: this.dpo,
			pumps: this.pumps,
			pumpSystem: this.pumpSystem,
			title: 'Pump Test'
		}

		if (this.tagView) {
			this.tagView.destroy();
		}

		this.tagViewButton.onclick = () => new ViewModal(new TreeView(tagViewProperties), {maxWidth:'500px'});
		this.pumpTestButton.onclick = () => new ViewModal(new PumpTestView(pumpTestProperties), {maxWidth:'600px', maxHeight:'500px'})
		this.tagView = new TreeView(tagViewProperties).initialize(this.tagViewContainer);

		this.pumpSystem.tree.device.pumpIndex = this.selectedIndex;	// Update the last graph the user selected
		if (!pump.headCurve || !pump.factoryHeadCurve)	// If we haven't gotten the pump curve yet
			return;				// Bail out until we do so

		this.copyCurveSnapshots(pump.curve);	// Copy over the snapshot data to the arrays passed to the Dygraph
		this.graphOptions.dateWindow = [0, pump.zeroHeadFlow];	// Update the x-axis
		this.graphOptions.valueRange = [0, pump.shutOffHead];	// Update the y-axis
		this.graphOptions.visibility[28] = pump.testNode && pump.testNode.getValue();

		// Calculate the factory and current pump curve at a bunch of evenly divided flow points
		for (var i = 0; i < this.flows.length; ++i) {
			var flow = pump.zeroHeadFlow * i / (this.flows.length - 1);	// Flow at this point

			this.flows[i] = flow;
			this.heads[i] = pump.headCurve.evaluatePolynomial(flow);			// Head at this flow
			this.powers[i] = pump.powerCurve.evaluatePolynomial(flow);			// Power at this flow for the curve speed
			this.factHeads[i] = pump.factoryHeadCurve.evaluatePolynomial(flow);	// Factory head at this flow for the curve speed
			this.npshHeads[i] = pump.npshrCurve.evaluatePolynomial(flow);
			this.factPowers[i] = pump.factoryPowerCurve.evaluatePolynomial(flow);

			if (flow > 0 && this.heads[i] > 0 && this.powers[i] > 0)	// If we are in a good place, calculate the efficiency
				this.effs[i] = flow * this.heads[i] * this.effConversion / this.powers[i];
			else
				this.effs[i] = 0;

			if (flow > 0 && this.factHeads[i] > 0 && this.factPowers[i] > 0)	// If we are in a good place, calculate the efficiency
				this.factEffs[i] = flow * this.factHeads[i] * this.effConversion / this.factPowers[i];
			else
				this.factEffs[i] = 0;
		}
		if (this.spButton.checked) {	// Showing efficiency
			this.graphOptions.secondAxisRange = [0, Math.max(...this.powers) * 1.05];	// Update scale for the second axis
		}
		this.effLineFlow[1] = this.powerLineFlow[1] = pump.zeroHeadFlow;

		if (this.graphedIndex != this.selectedIndex) {	// If we have already graphed this pump, this stuff doesn't need to update
			this.graphedIndex = this.selectedIndex;		// Update the pump we are drawing

			// If the pump has a speed node, subscribe to its data
			if (this.speedNode)
				this.speedNode.unsubscribe(this);
			this.speedFlows.length = this.speedHeads.length = this.speedPowers.length = this.speedEffs.length = this.speedNPSH.length = 0;	// Make sure we take away any old speed
			if (pump.actSpeedNode) {
				this.speedNode = pump.actSpeedNode;
				this.speedNode.subscribe(this);	// This calls update on this nodes. Since we are already subscribed to it, it will redraw the graph
			} else
				delete this.speedNode;

			if (this.flowNode) {				// We've had a pump selected before. Unsubscribe to its nodes
				this.flowNode.unsubscribe(this);
				this.headNode.unsubscribe(this);
				if (this.powerNode)
					this.powerNode.unsubscribe(this);
				this.currentFlow[0] = this.currentHead[0] = this.currentEff[0] = this.npsha[0] = 0;
			}

			this.flowNode			= pump.flowNode;
			this.headNode			= pump.headNode;
			this.powerNode			= pump.shaftPowerNode;
			this.flowNode.subscribe(this);
			this.headNode.subscribe(this);
			if (this.powerNode)
				this.powerNode.subscribe(this);

			this.nameDiv.textContent = pump.name + Localization.toLocal(' Summary');	// Update the title of the pump we are displaying
			if (this.statusWidget)
				this.statusWidget.destroy();

			// Create the colored header, which will be green if the pump is running, then red if outside por, then orange if faulted, then gray
			// if neither. We will only add the nodes we find. Also create and pass in class name for each case
			var nodeArray = [], classArray = [], vetos = [], vetoClasses = [];
			if (pump.outsideAorNode) {
				nodeArray.push(pump.outsideAorNode);
				classArray.push('outsideAOR');
				vetos.push(null);
				vetoClasses.push(null);
			}
			if(pump.outsidePorNode) {
				nodeArray.push(pump.outsidePorNode);
				classArray.push('outsidePOR');
				vetos.push(pump.fUseAOR);
				vetoClasses.push(classArray[0]);
			}
			if (pump.faultNode) {					// Found a faulted node
				nodeArray.push(pump.faultNode);		// Add the node to the array
				classArray.push('faulted');		// Add a class name for the faulted status
				vetos.push(null);
				vetoClasses.push(null);
			}
			if (pump.fStartFailNode) {					// Found a faulted node
				nodeArray.push(pump.fStartFailNode);		// Add the node to the array
				classArray.push('faulted');		// Add a class name for the faulted status
				vetos.push(null);
				vetoClasses.push(null);
			}
			if (pump.fStopFailNode) {						// Found a faulted node
				nodeArray.push(pump.fStopFailNode);		// Add the node to the array
				classArray.push('faulted');		// Add a class name for the faulted status
				vetos.push(null);
				vetoClasses.push(null);
			}

			assert(pump.runningNode, "If we don't have a running node, why is this a pump?");	// We should have this
			nodeArray.push(pump.runningNode);			// Add the node to the array
			classArray.push('running');			// Add a class name for the running status
			vetos.push(null);
			vetoClasses.push(null);

			this.statusWidget 	= new ColoredHeader(this.selectorBar, {nodes: nodeArray, trueClass: classArray, vetos: vetos, vetoClasses: vetoClasses});	// Actually create the colored header

			this.makeModelDiv.textContent = pump.makeNode.getValue() + ' ' + pump.modelNode.getValue() + ' - ' + pump.powerNode.getFormattedText(true);

			// Calculate the data for the PHI graph once for the pump
			var end = new Date().getTime();	// Most recent pump curve requested
			var previousValue = null;
			this.phiTimes.length = this.phis.length = this.highlightTimes.length = this.highlightPhis.length = 0;
			var firstTime = (end - 86400 * 365 * 1000) * 1000;
			var factoryEff = this.getEfficiency(findBestEfficiencyFlow(pump.zeroHeadFlow, pump.factoryHeadCurve, pump.factoryPowerCurve), pump.factoryHeadCurve, pump.factoryPowerCurve);
			for (var i = 0; i < pump.curves.length; ++i) {	// Skip the factory curve
				var curve = pump.curves[i];
				if (curve.timestamp == 0)	// Don't draw a current curve for a factory curve
					continue;
				firstTime = Math.min(firstTime, curve.timestamp);
				this.phiTimes.push(curve.timestamp / 1000);		// Push two points so we get clean right angles
				this.phiTimes.push(curve.timestamp / 1000);
				var currentEff = this.getEfficiency(findBestEfficiencyFlow(pump.zeroHeadFlow, curve.headCurve, curve.powerCurve), curve.headCurve, curve.powerCurve);
				var currentValue = currentEff / factoryEff * 100;	// This is the PHI calculation
				this.phis.push(previousValue);					// Added two timestamps, gotta add two PHIs
				this.phis.push(currentValue);
				previousValue = currentValue;
			}
			this.phiOptions.dateWindow = [firstTime / 1000, end];
			this.phiTimes.push(end);			// Need a last point so the current PHI line continues to the edge of the graph
			this.phis.push(previousValue);
			this.drawPhiGraph();
			this.onPhiMove(null, end, true);	// Highlight the plotted curve and create the message telling about it
			this.addHistoricalSnapshots();
		}

		this.updateOperationRanges(pump);
		this.drawGraph();	// Redraw the graph
		this.fixAllVisibilities();
	}

	createWidgetRow() {
		createElement()
	}

	createRangeSetting() {

	}



	buildFolder(pump) {
		let pumpOverview = {
			name: pump.name,
			treeChildren: [],
			getDisplayName: () => { return pump.name },
		};
		//let pumpDataName = {
		//	children: [],
		//	getDisplayName: () => {return 'Current Status'}
		//}
		let pumpData = {
			treeChildren: [],
			getDisplayName: () => { return pump.name + ' Data' },
		}
		if (pump.actSpeedNode)
			pumpData.treeChildren.push(pump.actSpeedNode);
		if (pump.flowNode)
			pumpData.treeChildren.push(pump.flowNode);
		if (pump.headNode)
			pumpData.treeChildren.push(pump.headNode);
		if (pump.shaftPowerNode)
			pumpData.treeChildren.push(pump.shaftPowerNode);
		if (pump.percentBepNode)
			pumpData.treeChildren.push(pump.percentBepNode);
		if (pump.npshNode)
			pumpData.treeChildren.push(pump.npshNode);

		let additionalData = {
			treeChildren: [],
			getDisplayName: () => { return 'Additional Data' },
		}
		if (pump.startsNode)
			additionalData.treeChildren.push(pump.startsNode);
		if (pump.runTimeNode)
			additionalData.treeChildren.push(pump.runTimeNode);
		if (pump.timestampNode)
			additionalData.treeChildren.push(pump.timestampNode);
		if (pump.healthNode)
			additionalData.treeChildren.push(pump.healthNode);
		if (pump.voltageNode)
			additionalData.treeChildren.push(pump.voltageNode);
		if (pump.currentNode)
			additionalData.treeChildren.push(pump.currentNode);

		let bepRange = {
			treeChildren:	[],
			minNode:	pump.minBepRatioNode,
			maxNode: 	pump.maxBepRatioNode,
			role:		'range',
			units:		TagUnit.TU_PERCENT,
			getDisplayName:() => {return 'Preferred Operating Range'},
		}

		let aorRange = {
			name: 'Allowable Operating Range',
			treeChildren:	[],
			minNode:	pump.minAORNode,
			maxNode: 	pump.maxAORNode,
			enableNode: pump.fUseAOR,
			fPseudo:	true,
			role:		'range',
			units:		TagUnit.TU_PERCENT,
			getDisplayName:() => {return 'Allowable Operating Range'},
		}

		let exercise = {
			treeChildren: 		[],
			minScopeNode:	pump.minScopeNode,
			minTimeNode: 	pump.minTimeNode,
			role:			'exercise',
			getDisplayName:() => {return 'Exercise'},
		}

		let speedRange = {
			treeChildren:	[],
			minNode:	pump.minSpeedNode,
			maxNode: 	pump.maxSpeedNode,
			role:		'range',
			getDisplayName:() => {return 'Allowable Operating Range'},
		}

		//let pumpSettingsName = {
		//	children: [],
		//	getDisplayName: () => {return 'Settings'}
		//}

		let pumpSettings = {
			treeChildren: [
				bepRange,
				aorRange,
			],
			parent: pumpOverview,
			getDisplayName: () => { return pump.name + ' Settings' },
		}

		if (pump.minSpeedNode && pump.maxSpeedNode)
			pumpSettings.treeChildren.push(speedRange)
		if (pump.manualSpeedNode)
			pumpSettings.treeChildren.unshift(pump.manualSpeedNode)
		if (pump.autoSpeedSwitch)
			pumpSettings.treeChildren.unshift(pump.autoSpeedSwitch)

		let additionalSettings = {
			treeChildren: [],
			parent: pumpOverview,
			getDisplayName: () => { return 'Additional Settings' },
		}

		if (pump.powerLimitNode)
			additionalSettings.treeChildren.push(pump.powerLimitNode);
		if (pump.npshFactorNode)
			additionalSettings.treeChildren.push(pump.npshFactorNode);
		if (pump.offlineNode)
			additionalSettings.treeChildren.push(pump.offlineNode);
		if (pump.startDelayNode)
			additionalSettings.treeChildren.push(pump.startDelayNode);
		if (pump.stopDelayNode)
			additionalSettings.treeChildren.push(pump.stopDelayNode);
		if (pump.startFailNode)
			additionalSettings.treeChildren.push(pump.startFailNode);
		if (pump.fStartFailNode)
			additionalSettings.treeChildren.push(pump.fStartFailNode);
		if (pump.stopFailNode)
			additionalSettings.treeChildren.push(pump.stopFailNode);
		if (pump.fStopFailNode)
			additionalSettings.treeChildren.push(pump.fStopFailNode);
		if (pump.minTimeNode)
			additionalSettings.treeChildren.push(pump.minTimeNode);
		if (pump.minScopeNode)
			additionalSettings.treeChildren.push(pump.minScopeNode);

		pumpOverview.treeChildren.push(/*pumpDataName, */...pumpData.treeChildren, additionalData, ...pump.hoas, bepRange, aorRange, exercise, additionalSettings)
		return pumpOverview;
	}

	drawPhiGraph() {
		if (this.phiGraph)		// If we have an old graph, clean house first
			this.phiGraph.destroy();
		this.phiGraph = new StaticGraph(owner.ldc, this.phiGraphDiv, this.pumpCurveDiv.clientWidth, 91, this.phiData, this.phiOptions);
	}

	onPhiMove(e, x, fForce) {
		var pump = this.pumps[this.selectedIndex];
		for (var i = pump.curves.length - 1; i >= 0; --i) {	// Find the PHI that matches the time interval

			if (x * 1000 < pump.curves[i].timestamp)			// Curve is too new, keep looking
				continue;

			// Found the first curve with a smaller timestamp than the clicked point. Plot this curve
			if (pump.curve !== pump.curves[i] || fForce) {	// Curve is different
				pump.curve = pump.curves[i];
				var headCurve = pump.curve.headCurve;
				var powerCurve = pump.curve.powerCurve;
				for (var j = 0; j < this.flows.length; ++j) {
					var flow = this.flows[j];	// Flow at this point
					this.heads[j] = headCurve.evaluatePolynomial(flow);	// Head at this flow
					this.powers[j] = powerCurve.evaluatePolynomial(flow);	// Power at this flow for the curve speed

					if (flow > 0 && this.heads[j] > 0 && this.powers[j] > 0)	// If we are in a good place, calculate the efficiency
						this.effs[j] = flow * this.heads[j] * this.effConversion / this.powers[j];
					else
						this.effs[j] = 0;
				}
				for (var j = 0; j < this.phiTimes.length; ++j) {	// Highlight part of the PHI graph
					if (x > this.phiTimes[j])
						continue;
					if (this.phis[j] === null)	// We are back at the factory curves when this happens
						return;
					this.highlightTimes[0] = j == 0 ? this.phiOptions.dateWindow[0] / 1000 : this.phiTimes[j - 1];	// Start and end times
					this.highlightTimes[1] = this.phiTimes[j];
					this.highlightPhis[0] = this.highlightPhis[1] = this.phis[j];	// Horizontal PHI valud
					break;
				}
				pump.zeroHeadFlow = undefined;
				var flowStep = 100 * this.flowConversion;		// How much we jump to check and see if the head is negative
				var flow = flowStep;						// Start off with a small flow and keep jumping up until head gets negative
				while (pump.zeroHeadFlow === undefined) {	// Find the head at zero flow
					if (headCurve.evaluatePolynomial(flow) <= 0)	// Found a head less than 0!
						pump.zeroHeadFlow = headCurve.solvePolynomial(flow - flowStep, flow, 0, 0.5 * this.flowConversion);	// Get a little more accurate on zero head flow
					else									// Head was positive
						flow += flowStep;					// Keep looking at a bigger flow for the zero head flow
				}
				pump.bepFlow = findBestEfficiencyFlow(pump.zeroHeadFlow, headCurve, powerCurve);
				this.copyCurveSnapshots(pump.curve);	// Put all the snapshots in the pump curve plot
				this.updateOperationRanges(pump);		// Reshade the POR
				this.drawGraph();						// Redraw the pump curve graph
				this.phiGraph.dygraph.updateOptions(this.phiData, this.phiOptions);	// This drops all old data. (Slower call)
				this.phiLabel.textContent = Localization.toLocal('PHI of ') + this.highlightPhis[0].toFixed(0) + Localization.toLocal(' from ') + (new Date(this.highlightTimes[0]).format('%yyyy.%MM.%dd'));
				if (this.highlightTimes[1] != this.phiTimes.back())	// If this isn't the most recent pump test
					this.phiLabel.textContent += ' to ' + (new Date(this.highlightTimes[1]).format('%yyyy.%MM.%dd'));
			}
			return;	// Found the curve we were looking for
		}
	}

	copyCurveSnapshots(curve) {
		this.snapshotTimes.length = this.snapshotFlows.length = this.snapshotHeads.length = this.snapshotPowers.length = this.snapshotEffs.length = curve.flows.length;
		for (var i = 0; i < curve.flows.length; ++i) {	// Simple copy of a curve's snapshots to the data of the pump curve graph
			this.snapshotTimes[i]	= curve.timestamp;
			this.snapshotFlows[i] 	= curve.flows[i];
			this.snapshotHeads[i] 	= curve.heads[i];
			this.snapshotPowers[i] 	= curve.powers[i];
			this.snapshotEffs[i] 	= curve.effs[i];
		}
	}

	calculateCurrentCurves(speedNode) {
		var flowFactor = speedNode.getValue() / this.maxSpeed;	// Calculate the affinity law ratios once
		var headFactor = flowFactor * flowFactor;
		var powerFactor = headFactor * flowFactor;

		var pump = this.pumps[this.selectedIndex];
		for (var i = 0; i < this.flows.length; ++i) {	// Recompute the points based on the speed factors
			this.speedFlows[i] 	= flowFactor * this.flows[i];
			this.speedHeads[i] 	= headFactor * this.heads[i];
			this.speedNPSH[i] 	= headFactor * this.npshHeads[i];
			this.speedPowers[i] = powerFactor * this.powers[i];
			if (this.speedFlows[i] > 0 && this.speedHeads[i] > 0 && this.speedPowers[i] > 0)	// If we are in a good place, calculate the efficiency
				this.speedEffs[i] = this.speedFlows[i] * this.speedHeads[i] * this.effConversion / this.speedPowers[i];
			else
				this.speedEffs[i] = 0;
		}
		this.drawGraph();	// Redraw the graph
	}

	updateOperationRanges(pump) {
		this.facPorFlows.length = this.facPorHeads.length = this.facPorMaxes.length = this.porFlows.length = this.porHeads.length = this.porMaxes.length = this.badFlows.length =
			this.badHeads.length = this.badMaxes.length = this.midFlows.length = this.midHeads.length = this.midMaxes.length = this.bepFlows.length = this.bepHeads.length =
			this.fBepFlows.length = this.fBepHeads.length = 0;
		if (!pump.curve || !pump.factoryHeadCurve)	// If we haven't gotten the snapshot data
			return;

		var minSpeed, maxSpeed;
		if (!pump.minSpeedNode || !pump.maxSpeedNode)	// If the pump doesn't have a min or max speed node
			minSpeed = maxSpeed = this.maxSpeed;
		else {	// Get the node values
			minSpeed = pump.minSpeedNode.getValue();
			maxSpeed = pump.maxSpeedNode.getValue();
		}

		if (maxSpeed - minSpeed < 1) {	// If they are too close, give a small range
			minSpeed = maxSpeed - 1;
			maxSpeed = maxSpeed + 1;
		}

		this.generateBepLine(minSpeed, maxSpeed, pump.curve.headCurve, pump.bepFlow, this.bepFlows, this.bepHeads);
		this.generateBepLine(minSpeed, maxSpeed, pump.factoryHeadCurve, pump.fBepFlow, this.fBepFlows, this.fBepHeads);

		var minPOR = pump.minBepRatioNode.getValue();
		var maxPOR = pump.maxBepRatioNode.getValue();
		var fAOR = pump.fUseAOR && pump.fUseAOR.getValue();
		var minAOR = fAOR ? Math.min(pump.minAORNode.getValue(), minPOR) : minPOR;
		var maxAOR = fAOR ? Math.max(pump.maxAORNode.getValue(), maxPOR) : maxPOR;

		findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, pump.curve.headCurve, pump.curve.powerCurve, pump.zeroHeadFlow, 0, minAOR, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
		findOperationRanges(this.midFlows, this.midHeads, this.midMaxes, pump.curve.headCurve, pump.curve.powerCurve, pump.zeroHeadFlow, minAOR, minPOR, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
		findOperationRanges(this.porFlows, this.porHeads, this.porMaxes, pump.curve.headCurve, pump.curve.powerCurve, pump.zeroHeadFlow, minPOR, maxPOR, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
		this.midFlows.push(null);
		this.midHeads.push(null);
		this.midMaxes.push(null);
		findOperationRanges(this.midFlows, this.midHeads, this.midMaxes, pump.curve.headCurve, pump.curve.powerCurve, pump.zeroHeadFlow, maxPOR, maxAOR, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
		this.badFlows.push(null);
		this.badHeads.push(null);
		this.badMaxes.push(null);
		findOperationRanges(this.badFlows, this.badHeads, this.badMaxes, pump.curve.headCurve, pump.curve.powerCurve, pump.zeroHeadFlow, maxAOR, pump.zeroHeadFlow / pump.bepFlow, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
		findOperationRanges(this.facPorFlows, this.facPorHeads, this.facPorMaxes, pump.factoryHeadCurve, pump.factoryPowerCurve, pump.zeroHeadFlow, minPOR, maxPOR, minSpeed / this.maxSpeed, maxSpeed / this.maxSpeed);
	}

	generateBepLine(minSpeed, maxSpeed, curve, bepFlow, flows, heads) {
		var bepHead = curve.evaluatePolynomial(bepFlow);
		for (var speed = minSpeed; speed <= maxSpeed; speed += 0.1) {
			var factor = speed / this.maxSpeed;
			flows.push(factor * bepFlow);
			heads.push(factor * factor * bepHead);
		};
	}

	createIndicatorRow(label, wrapper, index) {
		var div = createElement('div', 'pumpCurveDataRow');
		wrapper.insertChildAt(div, index);
		createElement('div', 'pumpCurveDataLabel', div, label);
		return createElement('div', 'pumpCurveDataValue', div);
	}

	addNodeIndicator(node, label, wrapper, options) {
		if (!node)	// if node doesn't exist, bail out
			return;

		var opts = { fShowUnits: true };	// We want to show units for these guys
		for (var i in options)			// Any other units they want
			opts[i] = options[i];		// Add on to the object

		createWidget(this.createIndicatorRow(label, wrapper ? wrapper : this.dataDiv), node, opts);
	}

	addTwoNodeIndicator(node1, node2, label, text1, text2, wrapper, units1, units2) {
		if (node1 == null || node2 == null)
			return;

		var div = createElement('div', 'pumpCurveDataRow', wrapper);
		createElement('div', 'pumpCurveDataLabel', div, label);
		var toDiv = createElement('div', 'pumpCurveDataValue', div);
		createElement('div', 'pumpCurveDataValue', div, text2);
		var fromDiv = createElement('div', 'pumpCurveDataValue', div);
		createElement('div', 'pumpCurveDataValue', div, text1);

		createWidget(toDiv, node2, { fShowUnits: true, displayUnits: units2 });
		createWidget(fromDiv, node1, { fShowUnits: true, displayUnits: units1 });
	}

	getEfficiency(flow, headCurve, powerCurve) {	// Method to easily calculate the efficiecny (just don't trust the units)
		return flow * headCurve.evaluatePolynomial(flow) / powerCurve.evaluatePolynomial(flow);
	}

	drawGraph() {									// Create a new graph to plot our points
		if (this.graph)								// If we've drawn a graph before
			this.graph.destroy();					// Delete it first
		this.graph = new StaticGraph(owner.ldc, this.pumpCurveDiv, this.pumpCurveDiv.clientWidth, this.pumpCurveDiv.clientHeight, this.data, this.graphOptions);
		this.flowWrapper.style.left = this.graph.dygraph.toDomXCoord(this.currentFlow[0]) - 18 + 'px';
		this.flowWrapper.style.top = (this.graph.dygraph.toDomYCoord(0)) + 2 + 'px';
		this.headWrapper.style.top = (this.graph.dygraph.toDomYCoord(this.currentHead[0])) - 10 + 'px';
		this.powerWrapper.style.top = (this.graph.dygraph.toDomYCoord(this.currentPower[0], 1) - 4) + 'px';
		this.powerWrapper.style.marginLeft = this.pumpCurveDiv.clientWidth - 749 + 'px';
		this.powerWrapper.classList.toggle('hide', this.effButton.checked);
		if (!isNaN(this.currentEff[0]))
			this.effWrapper.style.top = (this.graph.dygraph.toDomYCoord(this.currentEff[0], 1)) - 10 + 'px';
		this.effWrapper.style.left = this.graph.dygraph.toDomXCoord(this.graph.dygraph.xAxisRange()[1]) + 'px'
		this.effWrapper.classList.toggle('hide', isNaN(this.currentEff[0]) || !this.graphOptions.visibility[8]);
	}

	destroy() {
		window.removeEventListener('keydown', this.keyPressFunction);
		if (this.graphID !== undefined)
			owner.ldc.unregisterGraph(this.graphID);	// We no longer want any data from the LDC
		if (this.nodeManager)
			this.nodeManager.destroy();
		if (this.speedNode)
			this.speedNode.unsubscribe(this);
		if (this.flowNode) {
			this.flowNode.unsubscribe(this);
			this.headNode.unsubscribe(this);
			if (this.powerNode)
				this.powerNode.unsubscribe(this);
		}

		this.parent.destroyWidgets(true);	// Don't need to destroy our graph specifically
		this.parent.removeChildren();		// Delete any DOM elements left over
	}
};

class PumpTestView extends CarouselView {
	constructor(properties) {
		super();
		this.graphID = owner.ldc.registerGraph(this);					// Register the graph so we can get data callbacks
		this.nodeManager = new NodeManager(this);
		this.copy(properties);
		assert(this.pump, 'pump test view must have a pump property');
	}
	initialize(parent) {
		super.initialize(parent);
		if (this.dpo) {
			this.manualTestNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_MODE);
			this.manualCollectNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_COLLECT_DATA);
			this.manualEndTestNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_MANUAL_TEST_END_TEST);
			this.testStartTimeNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_START_TIME);
			this.testStepTimeNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_STEP_TIME);
			this.testFinTimeNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_COMPLETE_TIME);
			this.testStateNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_TEST_STATE);
			this.preCheckDoneNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_PRE_CHECKLIST_DONE);
			this.postCheckDoneNode = this.nodeManager.addNodeByRole(this.dpo, Role.ROLE_TLC_POST_CHECKLIST_DONE);
			this.systemStateNode = this.nodeManager.addNodeByRole(this.pumpSystem, Role.ROLE_SYSTEM_CURVE_STATE);
		}
		this.timestamp = this.pump.timestampNode.getFormattedText();
		let page1 = () => {
			this.backButton.style.visibility = 'hidden';
			let page = createElement('div', 'pump-test__wrapper', undefined);
			let page1Container = createElement('div', 'pump-test__container', page);
			let page1Title = createElement('div', 'pump-test__title', page1Container, '');
			let page1Body = createElement('div', 'pump-test__body', page1Container,
				`This dialog will walk you through performing a test on ` + this.pump.name + `. ` + this.pump.name + ` was last tested on ` + this.timestamp + `. In this test, the DPO will vary this pump's speed to adjust flow and automatically collect data points.
			Please select either an automated or operator-guided test, and then click the next button below.`)
			let options = createElement('div', 'pump-test__body__options', page1Body);
			let radioSelect = createElement('div', 'pump-test__radio', options);
			this.testMessage = createElement('div', null, page1Container)

			let autoButton = new RadioButtons(radioSelect, { node: this.manualTestNode, list: 'Automated,Operator-Guided', fPending: true });
			for (var i = 0; i < autoButton._buttons.length; ++i) {
				if (!this.fChangeType || !this.manualTestNode.couldBeWritten())
					autoButton._buttons[i].setAttribute('disabled', true);
				else
					autoButton._buttons[i].removeAttribute('disabled');
			}
			if (this.dpo)
				this.updateTestMessage(this.pump);	// Update the pump test message
			return page;
		}

		let page2 = () => {
			this.backButton.style.visibility = 'visible';
			this.nextButton.setAttribute('disabled', 'true')

			let page = createElement('div', 'pump-test__wrapper', undefined);
			let page2Container = createElement('div', 'pump-test__container', page);
			let page1Title = createElement('div', 'pump-test__title', page2Container, 'Pre-Test Checklist');
			page2Container.boxes = [];
			this.onTestChecklists = (fp) => this.createChecklistRow(fp, page2Container, this.preCheckDoneNode);
			owner.ldc.getTestChecklists(this.graphID, this.pumpSystem.tree.device.id);	// Get the checklist information for the pumps
			return page;
		}

		let page3 = () => {
			let page = createElement('div', 'pump-test__wrapper', undefined);
			this.postChecklist = createElement('div', 'pump-test__container', page);
			this.postChecklist.boxes = [];
			let page1Body = createElement('div', 'pump-test__body', this.postChecklist, 'This dialog will walk you through performing a test on ' + this.pump.name + '. In this test, the DPO will vary this pump\'s speed to adjust flow and automatically collect data points.')
			let radioSelect = createElement('div', 'pump-test__radio', this.postChecklist);
			let manTestButton = new RadioButtons(radioSelect, { node: this.manualTestNode, list: 'Automated,Operator-Guided' });
			// Enable controls if the user can write the node and we are ready for them

			return page;
		}

		let page4 = () => {
			let page 			= createElement('div', 'pump-test__wrapper', undefined);
			let page1Container 	= createElement('div', 'pump-test__container', page);
			let page1Title 		= createElement('div', 'pump-test__title', page1Container, 'Pump Test');
			let page1Body 		= createElement('div', 'pump-test__body', page1Container, 'This dialog will walk you through performing a test on ' + this.pump.name + '. In this test, the DPO will vary this pump\'s speed to adjust flow and automatically collect data points.')
			let radioSelect 	= createElement('div', 'pump-test__radio', page1Container);
			let testButton 		= new Toggle(createElement('div', null, page1Container, {node:this.manualCollectNode}));
			let abortButton 	= new Toggle(createElement('div', null, page1Container, {node:this.manualEndTestNode}));
			testButton.setDisabled(!this.fTestReady);
			if (fTesting && pump.testNode.couldBeWritten())
				abortButton.removeAttribute('disabled');
			else
				abortButton.setAttribute('disabled', true);
			testButton.setDisabled(!this.fManualTestCollect);
			return page;
		}

		//owner.ldc.getTestChecklists(this.graphID, this.pumpSystem.tree.device.id);	// Get the checklist information for the pumps

		this.pages.push(page1, page2, page3, page4);
	}

	update(node) {
		var pump = this.pump;	// Get the selected pump
		if (!pump || node.quality !== NodeQuality.NQ_GOOD)
			return;

		switch (node) {	// Switch uses the '===' operator, so this should be pretty cheap
			case this.testFinTimeNode:
				this.onTimerExpired();		// Pretend the 'setInterval' call just fired
				return;

			case this.testStartTimeNode:	// This will be used in conjunction with the testStepTimeNode
				return;

			case this.testStepTimeNode:
				// Get the snapshot data based on this new test step
				if (this.testStepTimeNode.getValue() == 0) {	// Test aborted or ended for some reason
					this.testFlows.length = 0;	// Clean out any old test values we had
					this.testHeads.length = 0;
					this.drawGraph();		// Redraw the graph without test flows/heads
				} else
					owner.ldc.getSnapshotData(this.graphID, this.pumpSystem.tree.device.id, this.testStartTimeNode.getValue(), this.testStepTimeNode.getValue());
				return;

			case this.preCheckDoneNode:		// Test parameters have changed
			case this.postCheckDoneNode:
			case this.testStateNode:
				this.updateCheckboxes(this.preChecklist, this.preCheckDoneNode, this.testStateNode.getValue() == 1);
				this.updateCheckboxes(this.postChecklist, this.postCheckDoneNode, this.testStateNode.getValue() == 4);
			// FALLTHROUGH

			case pump.testNode:
			case this.manualTestNode:
			case this.manualCollectNode:
			case this.manualEndTestNode:
			case pump.startControlNode:
			case pump.speedControlNode:
			case pump.runningNode:
			case this.systemStateNode:
				this.updateTestMessage(pump);	// Update the testing message
				return;
		}
	}

	/*
	onTestChecklists(fp) {
		this.createChecklistRow(fp, this.preChecklist, this.preCheckDoneNode);
		this.createChecklistRow(fp, this.postChecklist, this.postCheckDoneNode);
	}
	*/

	createChecklistRow(fp, checklist, node) {
		var itemCount = fp.pop_u8();
		if (itemCount == 0)
			createElement('div', 'pumpCurveChecklistItem', checklist, 'Nothing here');

		for (let i = 0; i < itemCount; ++i) {
			var div = createElement('div', 'pumpCurveChecklistItem', checklist);
			let id = createUniqueId();
			let checkwrapper = createElement('div', 'se-checkbox', div);
			let checkbox = createElement('input', null, checkwrapper, null, {'type':'checkbox', 'id':id});
			let label = createElement('label', null, checkwrapper, null, {'htmlFor':id})
			checkbox.onchange = this.onCheckboxChange.bind(this, checklist, checkbox, node);
			checkbox.checked = node.getValue();
			checklist.boxes[i] = checkbox;
			if (!node.couldBeWritten())
				checkbox.setAttribute('disabled', true);
			createElement('div', 'pumpCurveChecklistText', div, fp.pop_string());
		}
	}

	onCheckboxChange(checklist, checkbox, node, state) {
		if (node.getValue())									// If the node is already true
			checkbox.checked = true;							// Leave the box checked. Nothing they should do
		else if (!node.hasWritePermission())					// If we can't write the node
			checkbox.checked = false;							// Don't check the box
		else {													// We have write permission
			for (var i = 0; i < checklist.boxes.length; ++i) {	// We only write out trues here in case another client sets the box to true
				if (!checklist.boxes[i].checked)				// If a single checkbox isn't checked, don't write anything out
					return;
			}
			node.setValue(true);								// Write a new value to the node
		}
	}

	updateCheckboxes(checklist, node, fCorrectState) {				// This sets the test checklist check boxes
		var fTrue = node.getValue();
		for (var i = 0; i < checklist.boxes.length; ++i) {			// Make sure all the check boxes are cleared, just in case
			checklist.boxes[i].checked = fTrue;						// If the node is set, all of the check boxes should be checked
			if (!node.couldBeWritten() || fTrue || !fCorrectState)	// If they can't write the node, if it's already set, or if we aren't waiting on a checklist
				checklist.boxes[i].setAttribute('disabled', true);	// Disable all of the checkboxes
			else if (!fTrue)
				checklist.boxes[i].removeAttribute('disabled');
		}
	}

	updateTestMessage(pump) {
		this.fChangeType = false, this.fTestReady = false, this.fPreTest = false, this.fTesting = false, this.fPostTest = false, this.fManualTestCollect = false;
		switch (this.testStateNode.getValue()) {
			case 0:	// PTS_READY_TO_TEST
				if (pump.startControlNode.getValue() != 3 && !pump.runningNode.getValue()) {	// Pump isn't running and we can't start it
					if (pump.fStartNode.getValue())
						this.testMessage.innerHTML = Localization.toLocal("The DPO currently can't start the pump and the pump isn't running.") + "<br>" + Localization.toLocal("This pump can't be tested right now.");
					else
						this.testMessage.innerHTML = Localization.toLocal("The DPO doesn't have start control over this pump.") + "<br>" + Localization.toLocal("This pump can't be tested unless it's running.");
				} else if (this.manualTestNode.getValue()) {									// We are in operator-guided test mode
					var message = Localization.toLocal("Flip the 'Test Pump' switch to begin an operator-guided pump test, in which an operator will be responsible for varying this pump's flow (for instance, by clamping a discharge valve) and telling the DPO when to collect data points.");
					if (pump.speedControlNode.getValue())
						message += "<br>" + Localization.toLocal("This pump is a candidate for automatic testing.");
					else if (pump.fSpeedNode.getValue())
						message += "<br>" + Localization.toLocal("If the DPO is given control of the pump's speed, it can be automatically tested.");
					this.fTestReady = this.otherPumpsDontPreventTest(pump, message);
				} else if (!pump.speedControlNode.getValue()) {									// We can't control the speed of the pump
					if (pump.fSpeedNode.getValue())
						this.testMessage.innerHTML = Localization.toLocal("The DPO currently can't control this pump's speed.") + "<br>" + Localization.toLocal("This pump can't be tested automatically right now.");
					else
						this.testMessage.innerHTML = Localization.toLocal("The DPO can't control this pump's speed.") + "<br>" + Localization.toLocal("This pump can't be tested automatically. This pump must be tested in Operator-Guided mode.");
				} else 																			// We are in automated test mode
					this.fTestReady = this.otherPumpsDontPreventTest(pump, Localization.toLocal("Flip the 'Test Pump' switch to begin an automated pump test, in which the DPO will vary this pump's speed to adjust flow and automatically collect data points."));
				this.fChangeType = true;	// When in PTS_READY_TO_TEST, they can always flip the test type switch
				break;

			case 1:	// PTS_PRE_TEST
				if (pump.testNode.getValue()) {	// Pump is set to start testing soon (or has started already)
					this.testMessage.textContent = Localization.toLocal("The DPO will start the pump test when all the items in the pre-test checklist are acknowledged.");
					this.fPreTest = true;
				} else
					this.testMessage.textContent = Localization.toLocal("Another pump is going through the pre-test checklist. Wait until that test is complete before trying to test this pump.");
				break;

			case 2:	// PTS_STARTING_TEST
				if (pump.testNode.getValue()) {	// Pump is set to start testing soon (or has started already)
					this.testMessage.textContent = Localization.toLocal("Please wait for the " + (this.manualTestNode.getValue() ? "operator-guided" : "automated") + " test to begin...");
					fTesting = true;
				} else							// Another pump is the one testing
					this.testMessage.textContent = Localization.toLocal("Another pump is about to begin a test. Wait until that test is complete before trying to test this pump.");
				break;

			case 3:	// PTS_TESTING
				if (pump.testNode.getValue()) {	// Pump is set to start testing soon (or has started already)
					if (!this.manualTestNode.getValue()) 	// Pump is testing automatically
						this.testMessage.innerHTML = Localization.toLocal('Testing this pump in automated mode.') + '<br>' + Localization.toLocal(this.getSystemStateMessage()); // Get a message telling what the system is doing.
					else {
						this.testMessage.innerHTML = Localization.toLocal("Testing this pump in operator-guided mode.") + '<br>';
						if (this.systemStateNode.getValue() == 6) { // ST_MANUAL_TEST --> waiting on user input
							if (this.testStepTimeNode.getValue() == 0) 	// Haven't taken a first point yet
								this.testMessage.innerHTML += Localization.toLocal("Take the pump to its maximum flow.") + '<br>';
							else if (this.testFlows.length > 0) {
								if (pump.actSpeedNode)	// If this pump has an actual speed, its speed is controllable by somebody
									this.testMessage.innerHTML += Localization.toLocal("Lower the pump's speed to around ") + (this.testSpeed - 1).toFixed(1) + " Hz.<br>";
								else
									this.testMessage.innerHTML += Localization.toLocal("Lower the pump's flow to around ") + Math.max(0, Math.round(this.testFlows[this.testFlows.length - 1] - this.testFlows[0] / 10)) + " gpm.<br>";
							}
							this.testMessage.innerHTML += Localization.toLocal("Flip the 'Collect Point' switch when you are satisfied with the flow.") + '<br>';
							if (this.testFlows.length >= 6)
								this.testMessage.innerHTML += Localization.toLocal("Flip the 'End Test' switch when you are satisfied with the points you have taken. This will make the DPO fit new pump curves using all the datapoints you have collected.");
							this.fManualTestCollect = true;	// They can press the collect point button
						}
						else if (this.systemStateNode.getValue() == 7) // ST_TEST_COLLECT_STATS --> have gotten user input
							this.testMessage.innerHTML += Localization.toLocal("Collecting a test data point. The 'Collect Point' switch will reset when enough data has been collected.");
					}
					fTesting = true;
				} else
					this.testMessage.textContent = Localization.toLocal("Another pump is testing. Wait until that test is complete before trying to test this pump.");
				break;

			case 4:	// PTS_POST_TEST
				this.testMessage.textContent = Localization.toLocal("The DPO won't allow another pump test until all the items in the post-test checklist are acknowledged.");
				this.fPostTest = true;
				break;
		}

		/*
		this.testConfig.previousSibling.setAttribute('next', fChangeType);
		this.preChecklist.previousSibling.setAttribute('next', fPreTest);
		this.testControls.previousSibling.setAttribute('next', fTesting);
		this.testResults.previousSibling.setAttribute('next', fTesting);
		this.postChecklist.previousSibling.setAttribute('next', fPostTest);
		*/

		//this.manEndTestButton.setDisabled(!fTesting || !pump.testNode.couldBeWritten() || this.testFlows.length < 6);

	}

	getSystemStateMessage() {
		switch (this.systemStateNode.getValue()) {
			case 2: return "Ramping pump.";							// ST_AT_SPEED_WAIT
			case 3: return "Waiting for the system to stablize.";	// ST_QUIESCENT_WAIT
			case 4: return "Collecting a test data point.";			// ST_COLLECT_STATS
			default: return Role.ROLE_UNDEFINED;	// ST_UNINIT, ST_RESET, ST_STATS_READY, ST_MANUAL_TEST, ST_TEST_COLLECT_STATS. Wait for a new message we want to report on.
		}
	}

	otherPumpsDontPreventTest(pump, successString) {
		for (var i = 0; i < this.pumps.length; ++i) {
			if (this.pumps[i] === pump)	// This is the pump we are trying to get info about. We've already been very thorough on him.
				continue;				// Skip ahead!
			else if (this.pumps[i].testNode.getValue()) {	// Found a pump with the testing node set
				this.testMessage.textContent = Localization.toLocal("Another pump is trying to test already. Stop ") + this.pumpNodes[i].getDisplayName() + Localization.toLocal(" from requesting a test before testing this pump.");
				return false;	// No need to look any further
			} else if (this.pumps[i].runningNode.getValue() && !this.pumps[i].startControlNode.getValue()) {	// Found a pump which we can't turn off
				this.testMessage.textContent = Localization.toLocal("The DPO can't stop ") + this.pumpNodes[i].getDisplayName() + Localization.toLocal(". This pump can't be tested until it stops.");
				return false;	// No need to look any further
			}
		}
		//this.testMessage.innerHTML = successString;
		return true;	// Everything looks ship shape across the rest of the pumps
	}

}
