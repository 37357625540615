import {createElement, createUniqueId} from '../elements';
import { DragDropGraph, GraphNode } from '../graph';
import owner from '../../owner';
import View from './view';
import TreeView, { TreeViewTypes } from './treeview';
import MoreIcon from '../images/icons/more.svg';
import FullscreenIcon from '../images/icons/fullscreen.svg';
import ViewModal from '../viewmodal';
import { LoggedFilter, SearchFilter } from '../tagfilter';
import assert from '../debug';
import Loader from '../loader';
import Dropdown from '../components/dropdown';
import { Node } from '../node';
import Cruncher, { DataInterval } from '../cruncher';
import ArrowIcon from '../images/icons/double_arrow_left.svg';
import SettingIcon from '../images/icons/settings.svg';
import XIcon from '../images/icons/x.svg';
import './chartview.css';

import ExportIcon from '../images/icons/export.svg';
import ChartIcon from '../images/icons/download.svg';
import CancelIcon from '../images/icons/cancel.svg';
import Dialog from '../dialog';
import { TabManager } from '../components/tabmanager';
import { RadioButton } from '../components/radio';
import { DashboardSettingView, SettingInput, ColorInput, TextInput, NumberInput, ToggleInput } from './dashboardstyleview';
import LiveDataClient from '../livedataclient';

export interface ChartViewProperties {

}

interface ChartAction {
	name: string,
	icon: string,
	callback: (key: string)=>void;
}

export default class ChartView extends View {
	separator: string;
	formatOptions: Intl.NumberFormatOptions;
	graphNodes: GraphNode[];
	fAxis: boolean;
	fInitResp: boolean;
	controlRow: HTMLElement;
	graphRow: HTMLElement;
	legendRow: HTMLElement;
	utcOffset: number;
	graph: DragDropGraph;
	fFullScreen: boolean;
	fullScreenWrapper: HTMLElement;
	cruncher: Cruncher = new Cruncher();
	loaderContainer: HTMLElement;
	titleInput: HTMLInputElement;
	titleText: HTMLElement;
	addPanel: HTMLElement;
	selectedList: HTMLElement;
	settingsList: HTMLElement;
	startTime: HTMLInputElement;
	endTime: HTMLInputElement;
	treeView: TreeView;
	ldc: LiveDataClient;
    constructor(ldc: LiveDataClient) {
		super();
		this.ldc 			= ldc;
        this.separator 		= (1.1).toLocaleString()[1] === ',' ? ';' : ',';
		this.formatOptions 	= {useGrouping: false};	// This is the option we pass to 'toLocaleString' so it doesn't use thousand place separators
		this.graphNodes			= [];
		this.fAxis 			= true // flag for managing axis display state
		this.fInitResp 		= true;
    };

	initialize(parent: HTMLElement) {
		super.initialize(parent);
        this.wrapper			= createElement('div', 'chart-view__page-wrapper',this.parent);
		this.addPanel   		= createElement('div', 'chart-view__config', this.wrapper);
		let toggleButton 		= createElement('button', 'se-button chart-view__config__button', this.wrapper)
		toggleButton.onclick 	= () => {
			this.addPanel.addEventListener('transitionend', ()=>this.resize(), { once: true });
			if (this.wrapper.getAttribute('open') == 'true')
				this.closeAddPanel();
			else
				this.openAddPanel();
		}
		createElement('img', 'chart-view__config__button__icon', toggleButton, '', {src:ArrowIcon});
		this.wrapper.setAttribute('open', 'true');
		let addWrapper 			= createElement('div', 'chart-view__config__wrapper', this.addPanel);

		let managementPanel 	= createElement('div', 'chart-view__config__management', addWrapper);

		let managementWrapper 	= createElement('div', 'chart-view__config__management__wrapper', managementPanel);
		let tabs: RadioButton[] = [
			{
				name: 'tags',
				displayName: 'Selected Tags'
			},
			//{
			//	name: 'chart',
			//	displayName: 'Chart Settings'
			//}
		]
		let tabManager = new TabManager(managementWrapper, '', tabs, 'tags', ()=>{

		});
		this.selectedList = createElement('div', 'chart-view__config__selected__wrapper', tabManager.getSectionByName('tags'));
		this.settingsList = createElement('div', '', tabManager.getSectionByName('chart'));

		let tagPanel 			= createElement('div', 'chart-view__config__tags', addWrapper);
		this.treeView 			= new TreeView({
			type: 				TreeViewTypes.TVT_MULTISELECT,					// let the user select any number of tags
			selectedTags: 		this.graphNodes.map(graphNode=>graphNode.node),						// tell the tagView if we already have tags selected
			selectCallback: 	(tags) => this.selectCallback(tags as Node[]),
			deselectCallback: 	(tag) => this.deselectCallback(tag as Node),
            selectFilters: {
                andFilters:		[new LoggedFilter(true, true)],
                orFilters:      []
            },
			searchFilters: {
				andFilters:		[new LoggedFilter(true, true)],
                orFilters:      []
			}
		}).initialize(tagPanel);
		let graphWrapper		= createElement('div', 'chart-view__graph-wrapper', this.wrapper);
		let graphContainer		= createElement('div', 'chart-view__graph-wrapper__graph-container', graphWrapper);
		let titleRow			= createElement('div', 'chart-view__graph-wrapper__title-container', graphContainer);
		//let titleInput 			= createElement('input', 'chart-view__graph-wrapper__title-container__title__input', titleRow, '', {value: 'Untitled Graph'});
		let titleButtons 		= createElement('div', 'chart-view__graph-wrapper__title-container__title__buttons', titleRow);
		let dropDownButton 		= createElement('button', 'chart-view__graph-wrapper__title-container__title__drop', titleRow);
		createElement('img', 'chart-view__graph-wrapper__title-container__title__drop__icon', dropDownButton, '', {src:MoreIcon});
		let menuRow				= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', graphContainer);
		this.controlRow			= createElement('div', 'chart-view__graph-wrapper__graph-container__menu__control-row', menuRow);
		this.graphRow			= createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row', graphContainer);
		this.legendRow			= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', graphContainer);

		let fullButton			= createElement('img', 'chart-view__graph-wrapper__graph-container__full__icon', menuRow, undefined, {'src': FullscreenIcon});
		fullButton.onclick = () => this.fullscreenGraph();

		let actionMap: Map<string, (name: string)=>void> = new Map([
			['Export', (name) => {
				let dateArray 	= this.graph.graph!.xAxisRange();	// get our start and end date from our graph
				new ViewModal(new ExportView(new Date(dateArray[0]), new Date(dateArray[1]), true, this.downloadData.bind(this)), {
					maxWidth: 				'400px',
					maxHeight: 				'300px',
					title:					name,
					titleBackgroundColor: 	'var(--color-primary)',
					titleTextColor:			'var(--color-inverseOnSurface)',
				})
			}],
		]);

		this.createButton(titleButtons, 'Export', ExportIcon, (name) => {
			let dateArray 	= this.graph.graph!.xAxisRange();	// get our start and end date from our graph
			new ViewModal(new ExportView(new Date(dateArray[0]), new Date(dateArray[1]), true, this.downloadData.bind(this)), {
				maxWidth: 				'400px',
				maxHeight: 				'300px',
				title:					'Export Data',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)',
			})
		});
		/*
		this.createButton(titleButtons, 'Load Chart', ChartIcon, (name) => {
			new ViewModal(ExportView.bind(undefined, undefined, this.graph, this.downloadData.bind(this)), {
				maxWidth: 				'400px',
				maxHeight: 				'300px',
				title:					'Export Data',
				titleBackgroundColor: 	'var(--color-primary)',
				titleTextColor:			'var(--color-inverseOnSurface)',
			})
		});
		*/
		this.createButton(titleButtons, 'Clear', CancelIcon, (name) => {
			this.graphNodes = [];
			this.graph.clear();
			this.treeView.syncSelection(this.graphNodes.map(graphNode => graphNode.node));
			this.updateGraph();
		});

		let menuButton			= createElement('img', 'chart-view__graph-wrapper__graph-container__menu__icon hide', titleButtons, undefined, {'src': MoreIcon});
		menuButton.onclick = (e) => new Dropdown(e, ['Export Data','Add Tags','Save Chart','Load Chart'], this.dropdownSelection.bind(this));

		this.utcOffset	= new Date().getTimezoneOffset()*60;	// Calculate the time zone offset in seconds once
		//this.graph.onNodesChanged	= this.onNodesChanged.bind(this);	// When a node is removed or added //TODO: this
		//this.graph.onDateChange	= this.onGraphChange.bind(this);	// When the graph's range changes

		//this.ldc.getGraphs(this.graphID, this.device.id);

		this.resize();
		this.updateGraph();
		this.fInitialized = true;
		return this;
	}

    closeAddPanel() {
        this.wrapper.setAttribute('open', 'false');
    }

    openAddPanel() {
        this.wrapper.setAttribute('open', 'true');
    }

	createButton(parent: HTMLElement, name: string, icon: string, callback: (name: string)=>void) {
		let button = createElement('button', 'chart-view__action-button', parent);
		createElement('img', 'chart-view__action-button__icon', button, '', {src: icon});
		createElement('div', 'chart-view__action-button__title', button, name);
		button.onclick = () => callback(name);
	}

	resize() {
		if (this.fFullScreen) {
			this.fullScreenWrapper.removeChildren();
			document.body.removeChild(this.fullScreenWrapper);
			this.fFullScreen = false;
		}
		if (window.innerWidth < 620 == this.fAxis) {
			this.fAxis = !this.fAxis;
			this.resize();
			return;
		}
		else if (!this.graph) {
			let options = {
				drawYAxis:this.fAxis,
			}
			this.graphRow.removeChildren();
			let end = new Date();
			let start = new Date(new Date().getTime() - 86400000);
			this.graph = new DragDropGraph(owner.ldc, this.graphRow, this.graphRow.clientWidth, this.graphRow.clientHeight, start, end, true, true, true, this.controlRow, this.legendRow, options);
			this.graphNodes.forEach(graphNode => {
				this.graph.addNode(graphNode.node, true, undefined, undefined, true, graphNode.node.engMax, graphNode.node.engMin, graphNode.fMax,);
			});
		}
		else {
			this.graph?.graph?.resize(this.graphRow.clientWidth, this.graphRow.clientHeight);
		}
	}

	updateGraph() {
		this.refreshManagementPanel()
		let dateArray = this.graph.graph!.xAxisRange();	// get our start and end date from our graph
		//@ts-ignore
		this.graph._updateTimer();
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1]);
	}


	refreshNodes() {
		this.graph.clear();
		this.graphNodes.forEach(graphNode => {
			this.graph.addNode(graphNode.node, true, graphNode.name, graphNode.color, true, graphNode.nodeMax, graphNode.nodeMin, graphNode.fMax);
		});
		this.updateGraph();
	}

	onNodesChanged() {

	}

	fullscreenGraph() {
		/*
		this.fFullScreen = true;
		this.fullScreenWrapper 			= createElement('div', 'chart-view__modal', document.body);
		this.fullScreenWrapperContainer	= createElement('div', 'chart-view__modal__container', this.fullScreenWrapper);
		let modalMenu		= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', this.fullScreenWrapperContainer);
		this.fullScreenWrapperControl	= createElement('div', 'chart-view__graph-wrapper__graph-container__menu__control-row', modalMenu);
		let modalButton 	= createElement('button', 'chart-view__modal__button', modalMenu);
		let modalIcon		= createElement('img', 'chart-view__graph-wrapper__graph-container__menu__icon', modalButton, undefined, {'src': CloseIcon});
		this.fullScreenWrapperGraphRow	= createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row', this.fullScreenWrapperContainer);
		this.fullScreenWrapperGraph 	= createElement('div', 'chart-view__graph-wrapper__graph-container__graph-row__graph', this.fullScreenWrapperContainer);
		this.fullScreenWrapperLegend	= createElement('div', 'chart-view__graph-wrapper__graph-container__menu', this.fullScreenWrapperContainer);
		let options = {drawXAxis:true,drawYAxis:true,fRotated:true};
		this.graph.destroy();
		var end 	= new Date();
        let start 	= new Date(new Date().getTime() - 86400000);
		this.graph 	= new DragDropGraph(this.ldc, this.fullScreenWrapperGraph, this.fullScreenWrapperGraph.clientWidth, this.fullScreenWrapperGraph.clientHeight - 25, start, end, true, true, true, this.fullScreenWrapperControl, this.fullScreenWrapperLegend, options);
		this.graphNodes.forEach(node => {
			this.graph.addNode(node, true, undefined, undefined, true);
		});
		modalButton.onclick = () => {
			this.fFullScreen = false;
			this.graph.destroy();
			this.fullScreenWrapper.removeChildren();
			document.body.removeChild(this.fullScreenWrapper);
			this.resize();
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1], this.graph._calculateInterval(dateArray[0], dateArray[1]));
		*/
	}
	/*
	saveGraph(name) {
		console.log('saving')
		var nodes = this.graph.deviceNodes[0].nodes;
		owner.ldc.fm.buildFrame(LiveData.WVC_SAVE_GRAPH, this.device.id, this.graphID);
		owner.ldc.fm.push_string(name);

		var sizeOffset = this.ldc.fm.getPosition();	// Size of all the gooey data to come needs to be fixed up at the end
		this.ldc.fm.push_u32(0);					// Placeholder
		var sizeStart = this.ldc.fm.size;			// Remember the size of the frame at this point

		var newNodes = [];

		this.ldc.fm.push_u8(nodes.length);
		for (var i = 0; i < nodes.length; ++i) {
			this.ldc.fm.push_string(nodes[i].path);
			this.ldc.fm.push_string(nodes[i].color);
			this.ldc.fm.push_u8(nodes[i].flags);
			newNodes.push({path: nodes[i].path, color: nodes[i].color, flags: nodes[i].flags});
		}

		var totalSize = this.ldc.fm.getPosition();		// Get the total size
		this.ldc.fm.setPosition(sizeOffset);			// Set position back to the size of the data
		this.ldc.fm.push_u32(totalSize - sizeStart);	// Put in the correct size of the project data
		this.ldc.fm.setPosition(totalSize);				// Reset position back to the full frame
		this.ldc.send();								// Send the message
		this.ldc.getGraphs(this.graphID, this.device.id);

		for (var i = 0; i < this.oldGraphs.options.length; ++i) {
			var option = this.oldGraphs.options[i];
			if(this.saveName.value === option.graph.name)  {
				option.graph.nodes = newNodes;
				return;	// Leave without creating a new option
			}
		}

		//createElement('option', null, this.oldGraphs, this.saveName.value).graph = this.graphs.back();
	}
	*/
	/*
	onGraphResponse(fp) {
		var count = fp.pop_u32();
		this.graphs = [];
		for (var i = 0; i < count; ++i) {
			var name = fp.pop_string();
			fp.pop_u32();
			var nodeCount = fp.pop_u8();
			var nodes = [];
			for (var j = 0; j < nodeCount; ++j) {
				var path = fp.pop_string();
				var color = fp.pop_string();
				nodes.push({path: path, color: color, flags: fp.pop_u8()});
			}
			this.graphs.push({name: name, nodes: nodes});
		}
		if (this.fInitResp) {
			this.fInitResp = false;
			if (this.graphs.length > 0)
				this.loadGraph(this.graphs[this.graphs.length - 1])
		}
	}
	*/

	createDateTimeInput(wrapper, inputId) {								// Create a date/time input element and format it
		let dateLine		= createElement('input', 'treeDate datetime-local', wrapper);	// Start date of historical request
		dateLine.setAttribute('id', inputId);
		dateLine.type		= 'datetime-local';									// Date time local gives a date and time, but subtracts out from UTC, unfortunately
		dateLine.step		= '60';												// They aren't allowed to set below a minute
		dateLine.required	= true;												// No 'X' on the input that allows it to be cleared
		dateLine.onkeydown  = (e) => {
			let timeNow = new Date();
			timeNow = timeNow.format('%yyyy-%MM-%ddT%HH:%mm');

			// if keycode 38 "up" and endTime is the current time, ignore because time travel requires 88 mph
			/*
			if ((e.keyCode == 38 && timeNow.getTime() !== this.endTime.valueAsNumber) || e.keyCode == 40) {	// Up arrow is 38, down arrow is 40.
				dateLine.oldValue = dateLine.value;
				dateLine.oldDate = dateLine.valueAsNumber;
				dateLine.sign = e.keyCode == 38 ? 1 : -1;
			}
			*/
		};

		return dateLine;														// Return the date/time input
	}

	fixTime(element) {
		if (element.oldValue) {	// Fix the damn arrow keys
			// both are of the form 2017-05-15T16:08
			if (isNaN(element.valueAsNumber)) {
				element.valueAsNumber = element.oldDate + (element.sign == 1 ? -86400000 : 86400000);
			}
			var offset = new Date().getTimezoneOffset()*60;
			var date = new Date(element.oldDate + offset*1000);
			if (element.oldValue.substr(0, 4) !== element.value.substr(0, 4))	// If they changed the year
				date.setFullYear(date.getFullYear() + element.sign);
			else if (element.oldValue.substr(5, 2) !== element.value.substr(5, 2))
				date.setMonth(date.getMonth() + element.sign);
			else if (element.oldValue.substr(8, 2) !== element.value.substr(8, 2))
				date.setDate(date.getDate() + element.sign);
			else if (element.oldValue.substr(11, 2) !== element.value.substr(11, 2))
				date.setHours(date.getHours() + element.sign);
			else
				date.setMinutes(date.getMinutes() + element.sign);

			// do not fix/update the time into the future
			let updatedTime = date.getTime() - offset*1000;
			let currentTime = new Date().getTime() - offset*1000;
			if(updatedTime <= currentTime) {
				element.valueAsNumber = updatedTime;
			}
			delete element.oldValue;
		}
	}

	onDateChange(e) {	// Whenever either of the date objects change
		this.fixTime(e.target);
		if(this.isValidDates(this.startTime.valueAsNumber, this.endTime.valueAsNumber)) {
			this.graph.updateWindow(this.startTime.valueAsNumber + this.utcOffset*1000, this.endTime.valueAsNumber + this.utcOffset*1000);	// Slide the graph
		}
	}

	/* start: start time in seconds since epoch
	 * end: end time in seconds since epoch
	 * mostly avoids all the asserts buried in the underlying code, mostly
	 */
	isValidDates(start, end) {
		return 	!(isNaN(start)) && !(isNaN(end)) && start > 1000000000 && end > 0 && end < new Date() && start !== end && start < end;
	}

	dropdownSelection(selection) {
		switch (selection) {
			case 'Export Data':
				let dateArray 	= this.graph.graph!.xAxisRange();	// get our start and end date from our graph
				new ViewModal(new ExportView(new Date(dateArray[0]), new Date(dateArray[1]), true, this.downloadData.bind(this)), {
					maxWidth: 				'400px',
					maxHeight: 				'300px',
					title:					'Export Data',
					titleBackgroundColor: 	'var(--color-primary)',
					titleTextColor:			'var(--color-inverseOnSurface)',
				})
				break;
			case 'Add Tags':
				new ViewModal(new TreeView({
					folder: 			owner.selectedDevice?.tree.nodes[0] ?? undefined, 		// start at the root tag
					type: 				TreeViewTypes.TVT_MULTISELECT,			// let the user select any number of tags
					selectedTags: 		this.graphNodes.map(graphNode => graphNode.node), // tell the tagView if we already have tags selected
					selectCallback: 	this.selectCallback.bind(this),
					deselectCallback: 	this.deselectCallback.bind(this),
					selectFilters: 		{
						andFilters:			[new LoggedFilter(true, true)],
						orFilters: 			[]
					}
				}), { // ViewModal Options
					maxWidth: 				'400px',
					title:					'Export Data',
					titleBackgroundColor: 	'var(--color-primary)',
					titleTextColor:			'var(--color-inverseOnSurface)',
				});
				break;
			case 'Save Graph':
				break;
			case 'Load Graph':
				break;
			default:
				assert(false, 'Bad dropdown selection from Chart View');
		}

		/*

		let saveButton 				= {title:'Save',callback:(e) => this.saveGraph(e)}
		saveOption.onclick 			= () => {
			if (this.graphNodes.length == 0) {
				new Dialog(document.body, {title: 'No Graphed Tags', body: 'Please add at least one tag before saving a new graph.'});
				return;
			}
			new Dialog(document.body, {title:'Save Graph', body:'Please enter a name for this graph.', fText: true, buttons: [saveButton]})
		}

		loadOption.onclick			= () => {
			if (this.graphs.length == 0) {
				new Dialog(document.body, {title:'No Saved Graphs', body:'You do not have any saved graphs for this device.'});
				return;
			}
			let top = {
				children:[],
				fPseudo:true,
				getDisplayName:()=>{return 'Select a Graph'}
			}
			this.graphs.forEach(graph => {
				top.children.push({
					children:[],
					fPseudo:true,
					parent:top,
					fSelect:true,
					clickCallback:()=>{this.loadGraph(graph);this.loadModal.destroy()},
					getDisplayName:()=>{return graph.name}
				})
			})
			let loadProperties = {folder: top, type: 'select', fFilters:false};
			this.loadModal = new ViewModal(TagView.bind(undefined, undefined, loadProperties), {maxWidth: 500});
		}
		let clearOption = createElement('div', 'chart-view__dropdown__option', dropdownContainer, 'Clear Graph');
		clearOption.onclick = () => {
			this.graphNodes = [];
			this.graph._clearAll();
		}
		dropdownContainer.style.top 	= element.offsetTop + 'px' ;
		dropdownContainer.style.left 	= element.offsetLeft - dropdownContainer.clientWidth + 24 + 400 + 'px';
		*/
	}

	downloadData(start: Date, end: Date, interval: DataInterval) {
		this.loaderContainer = createElement('div', 'chart-view__export__loader__container', document.body);
		new Loader(this.loaderContainer);


		var pointCount = ((end.getTime() / 1000 - start.getTime() / 1000) / interval) * this.graph.orderedNodes!.length;
		if (start >= end || pointCount > 1E6) { 	// Limit of 1M points - will get disconnected by the server if we allow them to make this request
			new Dialog(document.body, {
                title: 'Error',
                body: 'Your request exceeded the maximum amount of data you can request (1 million data points). Please reduce the requested resolution or narrow your timespan.'
            })
			this.loaderContainer.classList.add('hide');
			return;
		}

		this.cruncher.getFormattedData(start, end, this.graphNodes.map(graphNode=>graphNode.node), interval, (data => {
			let csv = 'Time' + this.separator;		// Start out with a time column
			let names = data[0];	// Get the names out of the data
			for (let i = 0; i < names.length; ++i) {	// For each name
				csv += names[i] + this.separator;	// Add it to the first line so each column has a heading
			}
			csv += '\n';			// Add a carriage return after the header
			for (let i=0;i < data[1].length; ++i) {
				csv += `${new Date(data[1][i]).format('%yyyy/%MM/%dd %HH:%mm:%ss')},`
				for (let j=3;j<data.length;j+=4) {
					csv += `${data[j][i]},`
				}
				csv += '\n'
			}

			// Create an href element that will allow the user to download the data as a CSV
			var downloadLink 		= document.createElement('a');	// Chrome allows the link to be clicked without actually adding it to the DOM.
			downloadLink.download 	= `Chart_Export_${new Date().toLocaleDateString()}.csv`;		// File name to download as
			downloadLink.href 		= URL.createObjectURL(new Blob([csv], {type:'text/plain'}));	// Make a blob text file URL for the CSV
			downloadLink.click();							// Simulate clicking on the hyperlink
			document.body.removeChild(this.loaderContainer);
		}));
	}
	/*
	loadGraph(graph) {
		if (!this.graph)
			return
		this.graph._clearAll();
		this.graphNodes = [];
		for (let i = 0; i < graph.nodes.length; ++i) {
			let node = this.device.tree.findNode(graph.nodes[i].path);
			if (node) {
				this.graph.addNode(node, true, undefined, undefined, true);
				this.graphNodes.push(node);
			}
		}
		let dateArray 	= this.graph.graph.xAxisRange();	// get our start and end date from our graph
		this.graph.requestDataForAllDevices(dateArray[0], dateArray[1], this.graph._calculateInterval(dateArray[0], dateArray[1]));
		this.resize();
	}
	*/
	/* This is called every time the user checks a tag */
	selectCallback(tags: Node[]) {
		for (let i = 0; i < tags.length; i++) {
			let nodeArray = this.graphNodes.map(graphNode=>graphNode.node);
			if (nodeArray.includes(tags[i]))
				continue;
			let color = owner.colors.hex(`--color-graph-${this.graphNodes.length + 1}`);
			this.graphNodes.push(new GraphNode(tags[i], color, undefined));
			this.graph.addNode(tags[i], false, undefined, color, true, tags[i].engMax, tags[i].engMin);		// Actually add the node to the graph
		}
		this.updateGraph();
	}

	/* This is called every time the user un-checks a tag */
	deselectCallback(tag: Node) {
		//@ts-ignore
		this.graph._removeNode(tag);
		for(let i = 0; i < this.graphNodes.length; ++i) {
			if(this.graphNodes[i].node === tag) {
				this.graphNodes.splice(i,1);	// Found the node to remove
				break;							// No need to look further
			}
		}
		this.updateGraph();
	}

	refreshManagementPanel(): void {
		this.selectedList.removeChildren();
		if (this.graphNodes.length < 1)
			createElement('div', 'chart-view__selected-tag', this.selectedList, 'No Selected Tags');
		else
			this.graphNodes.forEach(graphNode => {
				let row 			= createElement('div', 'chart-view__selected-tag', this.selectedList);
				let fullPath 		= graphNode.node.tree.device.key + graphNode.node.getDeviceRelativePath();
				let nameWrapper 	= createElement('div', 'chart-view__selected-tag__name', row, fullPath);
				nameWrapper.title = fullPath;
				this.createSettingButton(row, SettingIcon).onclick = () => this.showSettingsModal(graphNode);
				this.createSettingButton(row, XIcon).onclick = () => {
					this.deselectCallback(graphNode.node);
					this.treeView.syncSelection(this.graphNodes.map(graphNode => graphNode.node));
				}
				nameWrapper.style.backgroundColor = graphNode.color;
			})
	}

	showSettingsModal(graphNode: GraphNode) {
		let settings = {
			'color': graphNode.color,
			'name': graphNode.name,
			'min': graphNode.nodeMin?.toString(),
			'max': graphNode.nodeMax?.toString(),
			'fMinMax': graphNode.fMax ? 'true' : 'false'
		}
		let modal = new ViewModal(new TagSettingsView(this), {
			maxWidth: 				'400px',
			maxHeight: 				'300px',
			title:					'Settings',
			titleBackgroundColor: 	'var(--color-primary)',
			titleTextColor:			'var(--color-inverseOnSurface)',
			buttons: [
				{
					title: 'Accept',
					borderColor: 'var(--color-green-8)',
					callback: () => {
						graphNode.color 	= settings.color;
						graphNode.name 		= settings.name;
						graphNode.nodeMin 	= parseFloat(settings.min);
						graphNode.nodeMax 	= parseFloat(settings.max);
						graphNode.fMax		= settings.fMinMax == '1';
						this.refreshNodes();
						this.resize();
						return true;
					}
				},
				{
					title: 'Cancel',
					borderColor: 'var(--color-red-8)'
				}
			]
		});
		modal.view.setSettingsToEdit(settings, ['CHART']);
	}

	createSettingButton(parent: HTMLElement, icon: string) : HTMLButtonElement {
		let button = createElement('button', 'se-button chart-view__setting-button', parent);
		createElement('img', 'chart-view__setting-button__icon', button, '', {src:icon});
		return button;
	}

	destroy() {
		this.parent.destroyWidgets(true);			// Don't need to destroy our graphs. That will happen here automagically
		this.parent.removeChildren();				// Delete any DOM elements left over
	}
};

export class ExportView extends View {
	graph: DragDropGraph;
	callback: (start: Date, end: Date, interval?: number)=>void;
	intervalSeconds: number[] = [1,60,3600,86400];
	defaultStart: Date;
	defaultEnd: Date;
	startDate: HTMLInputElement;
	endDate: HTMLInputElement;
	interval: HTMLSelectElement;
	fInterval: boolean;
	constructor(defaultStart: Date, defaultEnd: Date, fInterval: boolean = false, callback: (start: Date, end: Date, interval?: number)=>void) {
		super();
		this.defaultStart 		= defaultStart;
		this.defaultEnd			= defaultEnd;
		this.fInterval 			= fInterval;
		this.callback 			= callback;
	}

	initialize(parent: HTMLElement) {
		super.initialize(parent);
		this.wrapper 	= createElement('div', 'export-view__wrapper', this.parent);
		let dateWrapper = createElement('div', 'export-view__date-wrapper', this.wrapper);
		let start		= createElement('div', 'export-view__date-container', dateWrapper)
		let startId 	= createUniqueId();
		createElement('label', 'export-view__date-label', start, 'Start Date', {'htmlFor':startId})
		this.startDate	= createElement('input', '', start, '', {'type':'datetime-local', 'id':startId})
		let end			= createElement('div', 'export-view__date-container', dateWrapper)
		let endId 		= createUniqueId();
		createElement('label', 'export-view__date-label', end, 'End Date', {'htmlFor':endId})
		this.endDate	= createElement('input', '', end, '', {'type':'datetime-local', 'id':endId});
		let interval	= createElement('div', `export-view__date-container ${this.fInterval ? '' : 'hide'}`, dateWrapper);
		createElement('div',undefined, interval, 'at');
		this.interval 	= createElement('select', 'export-view__interval', interval);
		createElement('div', undefined, interval, 'resolution');
		let intervals	= ['second', 'minute', 'hour', 'day'];
		for (let i=0;i<intervals.length;i++) {
			createElement('option', '', this.interval, intervals[i])
		}
		this.startDate.value 	= this.defaultStart.toDatetimeLocal();
		this.endDate.value 		= this.defaultEnd.toDatetimeLocal();

		let resolution  = createElement('div', 'export-view__date-container');

		this.startDate.onchange = this.endDate.onchange = () => this.dateChange();

		let submit 	= createElement('div', 'se-button', this.wrapper, 'Download');
		submit.onclick = () => this.callback(new Date(this.startDate.value),new Date(this.endDate.value),this.intervalSeconds[this.interval.selectedIndex]);
		this.fInitialized = true;
		return this;
	}

	dateChange() {
		let start 					= new Date(this.startDate.value).getTime();
		let end 					= new Date(this.endDate.value).getTime();
		if (end < start) {
			let adjustedStart 		= new Date(end);
			adjustedStart.setDate(adjustedStart.getDate() - 1);
			this.startDate.value 	= adjustedStart.toDatetimeLocal();
		}
		let seconds					= (end - start) / 1000;
		let cutoff 					= 100000 // maximum number of timestamps we can ask for
		this.interval.selectedIndex = 0
		for (let i=0;i<this.interval.options.length - 1;i++) {
			this.interval.options[i].disabled = false;
			if (seconds / this.intervalSeconds[i] > cutoff) {
				this.interval.options[i].disabled = true
				this.interval.selectedIndex = i + 1;
			}
		}
	}
}

export enum TagSettingsCategories {
    APPEARANCE  = "PATH",
    DATA       	= "CHART"
}

export class TagSettingsView extends DashboardSettingView {
	parent: HTMLElement;
    inputs: SettingInput[] = [];
	chartView: ChartView;
    constructor(chartView: ChartView) {
        super();
		this.chartView = chartView;
    }

	initialize(parent: HTMLElement) {
		super.initialize(parent);
        this.sectionMap = new Map([
            [TagSettingsCategories.APPEARANCE,   this.createChartSettings()],
            [TagSettingsCategories.DATA,         this.createChartSettings()],
        ]);
        this.fInitialized = true;
        return this;
    }

	apply() {
		this.chartView.updateGraph();
	}

	createChartSettings(): HTMLElement {
        let pathSection  = this.createSection(this.parent,'Tag Style');
        this.inputs.push(
            new ColorInput('Color', pathSection, 'color', this),
            new TextInput('Display Name', pathSection, 'name', this, 'The name to show on the legend in the chart'),
            new NumberInput('Minimum', pathSection, 'min', this, 'The minimum value to show on the y-axis'),
            new NumberInput('Maximum', pathSection, 'max', this, 'The maximum value to show on the y-axis'),
			new ToggleInput('Show Min/Max', pathSection, 'fMinMax', this, 'Show the minimum and maximum values')
        );
        return pathSection;
    }
}
