import './liftstationoverview.css';
import assert from '../debug';
import { createElement } from '../elements';
import createSVGElement from '../svgelements';
import { Device } from '../device';
import DigitalGauge from '../digitalguage';
import { PumpBank } from '../pumpbank';
import { GenericGraph } from '../graph';
import NodeManager from '../nodemanager';
import { NodeQuality, Node } from '../node';
import owner from '../../owner';
import Scale from '../scale';
import { Shuttle } from '../shuttle';
import View from './view';
import { Widget } from '../widget';
import { PumpTwins } from '../curves';
import { type Tag, TagQuality } from '../widgets/lib/tag';
import { Role } from '../role';

export default class LiftStationOverview extends View {
    fLandscape     : boolean = window.innerHeight < 420;
    device         : Device | null  = owner.selectedDevice;
    liftStation    : Node;
    parameterTable : HTMLElement;
    pumpBankWrapper: HTMLElement;
    graphRow       : HTMLElement;
    controlDiv     : HTMLElement;
    graphDiv       : HTMLElement;
    legendDiv      : HTMLElement;
    pumpTable      : HTMLElement;
    wetWellDiv     : HTMLElement;
    pumpBank       : PumpBank;
    wetWell        : WetWellGraph;
    graph          : GenericGraph | undefined  = undefined;
    resizeCallback : ()=>void;
	constructor(device: Device) {
        super();
        assert(device);
        assert(device.isTreeComplete(), 'Device must already have a complete node tree');
        this.device      = device;
		const rootNode   = this.device.tree.nodes[0]!;
        this.liftStation = rootNode.findChildByRole(Role.ROLE_PUMP_BANK) ?? rootNode.findByRole(Role.ROLE_ALTERNATOR_FOLDER)[0];
    };

	/**
	 * Initialize the view elements
	 * @returns this
	 */
	initialize(parent: HTMLElement): this {
        super.initialize(parent);
		this.wrapper = createElement('div', 'overview__page-wrapper', this.parent);

		let parameterWrapper   = createElement('div', 'lift-station__parameter-wrapper', this.wrapper);
		let parameterContainer = createElement('div', 'overview__parameter-wrapper__value-container lift-station__value-container', parameterWrapper);
		this.parameterTable    = createElement('div', 'overview__parameter-table', parameterContainer);

		let overviewWrapper  = createElement('div', 'overview__overview-graph-wrapper', this.wrapper);  // center div that holds the gauges and chart
		this.pumpBankWrapper = createElement('div', 'overview__pump-bank-wrapper', overviewWrapper);

        let overviewGraphContainer = createElement('div', 'overview__overview-graph-container', overviewWrapper);  // graph flex wrapper
		this.graphRow              = createElement('div', 'overview__overview-graph-wrapper__graph-row', overviewGraphContainer);
		this.controlDiv            = createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);
		this.graphDiv              = createElement('div', 'overview__overview-graph-wrapper__graph-row__graph', this.graphRow);
		this.legendDiv             = createElement('div', 'overview__overview-graph-wrapper__graph-row__controls', this.graphRow);

		// Flow indicators
		let inflow  = this.liftStation.findChild(Role.ROLE_INFLOW);
		let outflow = this.liftStation.findChild(Role.ROLE_OUTFLOW);
		new DigitalGauge(createElement('div', 'PumpStationGauge LiftStationInflow', parameterContainer), {node: inflow}).initialize();
		new DigitalGauge(createElement('div', 'PumpStationGauge LiftStationOutflow', parameterContainer), {node: outflow}).initialize();

        this.pumpTable  = createElement('div', 'overview__parameter-table', parameterContainer)
        this.wetWellDiv = createElement('div', 'lift-station__parameter-wrapper__wet-well-container', parameterWrapper);  // bargraph for liftstation wet well
        this.resizeCallback = () => this.resize();
        this.device?.requestPumpTwins(this);
		return this;
	}

    onPumpTwinsComplete(pumpTwins: PumpTwins) {
        this.fInitialized = true;
		window.addEventListener('resize', this.resizeCallback);
		this.resize(); // graph is statically sized, so go ahead and kick off a resize
    }

	/**
	 * Sizes the elements based on the screen
	 */
	resize(): void {
        if (this.wrapper.clientHeight == 0 || !this.fInitialized)
            return;
		let options: { drawXAxis: boolean, drawYAxis: boolean, xLabelWidth?: number, yLabelWidth?: number, rightGap?: number };
		if (window.innerWidth < 620)
			options = { drawXAxis: false, drawYAxis: false, xLabelWidth: 0, yLabelWidth: 0, rightGap: 0 }
		else if (window.innerWidth >= 620)
			options = { drawXAxis: true, drawYAxis: true }

		if (!this.pumpBank || this.fLandscape != window.innerHeight < 420) {
			this.fLandscape = window.innerHeight < 420;
			this.pumpBank?.destroy();
			this.pumpBank = new PumpBank(this.liftStation, this.fLandscape? this.pumpTable : this.pumpBankWrapper);
		}
		this.pumpBank.resize();

		this.wetWellDiv.destroyWidgets(true);
        this.wetWellDiv.removeChildren();
        let element = createElement('div', 'lift-station__parameter-wrapper__tank-container__tanks', this.wetWellDiv);
		this.wetWell = new WetWellGraph(this.liftStation, element, element.clientWidth - 20, element.clientHeight - 20);
        if (this.graph && this.graph.graph)
            this.graph.resize(this.graphDiv.clientWidth, this.graphDiv.clientHeight);
        else
            this.createGraph();
	}

	/**
	 * Removes all of our widgets and DOM elements
	 */
	destroy(): void {
		this.parent.destroyWidgets(true);	// First, detach all LiveData nodes from all DOM elements
		this.parent.removeChildren();		// Second, delete all child DOM elements
	}

	/**
	 * Creates our historical graph
	 */
	createGraph(): void {
		let end   = new Date();                           // Current time
		let start = new Date(end.getTime() - 1000*3600);  // Default to the last hour

		this.graph = new GenericGraph(owner.ldc, this.graphDiv, this.graphDiv.clientWidth, this.graphDiv.clientHeight, start, end, false, this.controlDiv, this.legendDiv);	// Create the graph that takes care of the hard stuff for us
		this.graph.makeInteractive(true);  // Let them pan and zoom and such
		this.graph.createLegend();         // Make a legend happen
		this.graph.createDateSelection();  // Give them buttons that change the range of data presented
		this.graph.axesCanChange();        // Axis can change between attached nodes

		this.addNodeToGraph(Role.ROLE_DISCHARGE_PRESSURE, 'Discharge Pressure', 'purple');
		this.addNodeToGraph(Role.ROLE_SOURCE_TANK_LEVEL, 'Wet Well Level', 'black');

		let inflow  = this.liftStation.findChild(Role.ROLE_INFLOW);
		let outflow = this.liftStation.findChild(Role.ROLE_OUTFLOW);
		if(inflow || outflow) {
			// FIXME: This is an abomination to get a demo working
			let max, min;
			if(this.device!.key == "US.TX.MUSTANGSUD.GREGORY") {
				max = 500;
				min = 0;
			} else if(this.device!.key == "US.TX.MUSTANGSUD.PALOMANORTH") {
				max = 200;
				min = 0;
			}
            if (inflow)
			    this.graph.addNode(inflow, true, 'Inflow', 'blue', true, max, min);
            if (outflow)
			    this.graph.addNode(outflow, true, 'Outflow', 'maroon', true, max, min);
		}

		let currents = this.liftStation.findByRole(Role.ROLE_CURRENT);
		for (let i = 0; i < currents.length; ++i)
			this.graph.addNode(currents[i], true, undefined, undefined, true, undefined, undefined);

		let running	= this.liftStation.findByRole(Role.ROLE_BOOL_RUNNING);
		if(running.length > 0)								// If we got pump running status nodes
			this.graph.createBooleanFill(running, true);	// Create a special fill for these guys
        this.graph.requestDataForAllDevices(start.getTime(), end.getTime())
	}

	/**
	 * Adds a node to our graph
	 * @param role
	 * @param name
	 * @param color
	 */
	addNodeToGraph(role: string, name: string, color: string): void {
		let node = this.liftStation.findChildByRole(role);
		if (node)
			this.graph!.addNode(node, true, name, color, true, null, null);
	}
};

export class WetWellGraph extends Widget {
	nodeManager      : NodeManager;
	highLevel        : Node;
	visibleScale     : { minimum: number, maximum: number, resolution: number };
	levelNode        : Node;
	shuttles         : Shuttle[] = [];
	CESvg            : SVGElement;
	svgLevelFill     : SVGElement;
	lowIndicator     : Node;
	highIndicator    : Node;
	lowLevel         : Node;
	wetWellHeight    : Node;
	yAxisScale       : Scale;
	CEOverhang       : {};
	CEGraphRect      : {};
	graphWidth       : number;
	graphHeight      : number;
	wetWellHLCoords  : {};
	wetWellHeightElem: SVGElement;
	CESvgRect        : {};
	highFloat        : SVGElement;
	lowFloat         : SVGElement;

    constructor(liftStation: Node, div: HTMLElement, width: number, height: number) {
        super();
		this.registerAsWidget(div);	// Register with the element like a good little widget

        this.nodeManager = new NodeManager(this);
        this.createElements(div, liftStation, width, height);
        this.nodeManager.subscribe();
    };

	/** */
    _updateVisibleScale() {
        if (this.highLevel.quality == TagQuality.TQ_GOOD || this.highLevel.getValue() > 0)
        	this.visibleScale.maximum = Math.min(this.levelNode.engMax, Math.ceil((this.highLevel.getValue() * 1.1)));
    };

    /** Updates the well height SVG element, will fail if no element is specified. */
	_updateWellHeight(wetWellHeightElem, node, wetWellCoords, maxLevel) {
        if (wetWellHeightElem && node && wetWellCoords)
            wetWellHeightElem.updateLabel(wetWellCoords.x, wetWellCoords.y, 'Max: ' + ((maxLevel) ? maxLevel : node.getValue()).toFixed(1) + " " + node.getUnitsText());
        else
            assert(false, "Attempting to update the well height without specifying the SVG element," + " the node, and the coordinates of the text box!");
    };

	/**
	 * Creates the elements on our tank graph
	 * @param div
	 * @param liftStation
	 * @param width
	 * @param height
	 */
    createElements(div: HTMLElement, liftStation: Node, width: number, height: number): void {
        // Create our SVG element and two 'g' elements. One g is for the graph, the other is for the axis labels
        let svg             = createSVGElement('svg', '', div, { width: width, height: height });
		this.CESvg          = svg;
        let background      = createSVGElement('g', 'tankGraphBase', svg);
        let graph           = createSVGElement('g', 'tankGraphBase', background);
        let graphBackground = createSVGElement('rect', 'tankGraphBackground', graph);

        // Create our background shapes first so they'll appear behind the lines
        this.svgLevelFill = createSVGElement('rect', 'tankGraphLevelFill', graph);

        // change scale to only show interesting part of the wet well, based on high floats
        this.highIndicator = this.nodeManager.addNodeByRole(liftStation, Role.ROLE_HIGH_FLOAT_INDICATOR);
        this.highLevel     = this.nodeManager.addNodeByRole(liftStation, Role.ROLE_HIGH_FLOAT_LEVEL);
        this.lowIndicator  = this.nodeManager.addNodeByRole(liftStation, Role.ROLE_LOW_FLOAT_INDICATOR);
        this.lowLevel      = this.nodeManager.addNodeByRole(liftStation, Role.ROLE_LOW_FLOAT_LEVEL);
        this.levelNode     = this.nodeManager.addNodeByRole(liftStation, Role.ROLE_SOURCE_TANK_LEVEL);
        this.wetWellHeight = this.nodeManager.addNodeByName(liftStation, "WetWellHeight") || this.nodeManager.addNodeByName(liftStation, Role.ROLE_WET_WELL_DEPTH);

        if(!this.levelNode)
            return;

        this.visibleScale = {minimum: 0, maximum: this.levelNode.engMax, resolution: this.highLevel.resolution};
        this._updateVisibleScale();

        // Create a scale for y axis
        let yAxisScale	= new Scale(svg, this.visibleScale.minimum, this.visibleScale.maximum, this.levelNode.resolution, 'left', false, 'blackTics');
        this.yAxisScale = yAxisScale;

        // Create all the shuttles we have
        this.shuttles.push(new Shuttle(svg, this.levelNode, 'left', false, '', 'level', false, this.visibleScale));
        this.shuttles.push(new Shuttle(svg, this.highLevel, 'left', true, 'High:', 'amberShuttle', false, this.visibleScale));
        this.shuttles.push(new Shuttle(svg, this.lowLevel, 'right', true, 'Low:', 'amberShuttle', false, this.visibleScale));

        let startLevel = liftStation.parent.findByRole(Role.ROLE_START_LEVEL);
        startLevel.forEach(start => {
            this.shuttles.push(new Shuttle(svg, start, 'left', true, 'Start:', 'blueShuttle', false, this.visibleScale));
        })

        let stopLevel = liftStation.parent.findByRole(Role.ROLE_STOP_LEVEL);
        stopLevel.forEach(stop => {
            this.shuttles.push(new Shuttle(svg, stop, 'right', true, 'Stop:', 'grayShuttle', false, this.visibleScale));
        });

		// The overhang for the shuttles
        let overhang	= {
            left       : 0,
            top        : window.innerWidth < 768 ? 30: 15,   // For the y axis label (plus four pixels of padding)
            right      : 4,                                  // For the x axis label (plus four pixels of padding)
            bottom     : 0,
            strokeWidth: 1
		};
        this.CEOverhang = overhang;

        // The inner shuttle overhang is also used for the scales
        yAxisScale.getMetrics(overhang);

		// Get overhang for each shuttle
        for (let i = 0; i < this.shuttles.length; ++i)
            this.shuttles[i].getMetrics(overhang);

        let graphRect = {	// This object describes where there graph of flow versus level lives in the SVG space
            width : width - overhang.left - overhang.right - 1.5,
            height: height - overhang.top - overhang.bottom,
            left  : overhang.left,
            top   : overhang.top,
            right : width - overhang.right - 0.5,
            bottom: height - overhang.bottom
		};
        this.CEGraphRect = graphRect;

        // Store the width and height of the graph element alone
        this.graphWidth  = graphRect.width;
        this.graphHeight = graphRect.height;
        graphBackground.updateFill(0, 0, this.graphWidth, this.graphHeight);

        // Put the necessary amount of whitespace around the graph based on the shuttle overhang
        graph.translate(graphRect.left, graphRect.top);

        // Draw a border one time only
        createSVGElement('line', '', graph).updateLine(0, 0, 0, graphRect.height);
        createSVGElement('line', '', graph).updateLine(0, graphRect.height, graphRect.width, graphRect.height);
        createSVGElement('line', '', graph).updateLine(0, 0, graphRect.width, 0);
        createSVGElement('line', '', graph).updateLine(graphRect.width, 0, graphRect.width, graphRect.height);

        let maxLevel = NaN;
        if (this.wetWellHeight.quality == TagQuality.TQ_GOOD)
            maxLevel = Math.round(this.wetWellHeight.getValue());
        this.wetWellHLCoords = {x: graphRect.left + (window.innerWidth < 576 ? 4 : 0), y: graphRect.top - 4};

        // Write out the axis labels one time only
        if (!this.wetWellHeightElem)
            this.wetWellHeightElem = createSVGElement('text', 'lsGraphLabel', background);
        this._updateWellHeight(this.wetWellHeightElem, this.wetWellHeight, this.wetWellHLCoords, maxLevel);

        let svgRect	= {		  // A rectangle representing our canvas as a whole
            width : width,    // Give them the width
            height: height,   // Give them the height
            left  : 0,
            top   : 0,
            right : width,
            bottom: height
		};
        this.CESvgRect = svgRect;

        // Call render on all the scales first so that the appear behind the shuttles
        yAxisScale.render(graphRect, graphRect);

        // Call render on each shuttle in the inner circle of shuttles
        for (let i = 0; i < this.shuttles.length; ++i)					// For each shuttle
            this.shuttles[i].render(graphRect, null, svgRect);			// Make it instantiate itself

        this.highFloat = this.createFloat(graph);
        this.lowFloat  = this.createFloat(graph);
    };

	/**
	 * Create our svg float for the tank graph
	 * @param graph
	 * @returns SVG float
	 */
    createFloat = function(graph): SVGElement {
        let f = createSVGElement('g', 'floatSwitch', graph);
        createSVGElement('path', '', f, { d: 'M 0 -6 L -6 0 L -6 6 C -6 12, 6 12, 6 6 L 6 0 Z' });
        return f;
    };

    _updateScale = function() {
        // Create a scale for y axis
        this.yAxisScale.destroy();

        let yAxisScale  = new Scale(this.CESvg, this.visibleScale.minimum, this.visibleScale.maximum, this.levelNode.resolution, 'left', false, 'blackTics');
		this.yAxisScale = yAxisScale;
        let overhang    = this.CEOverhang;

        // The inner shuttle overhang is also used for the scales
        yAxisScale.getMetrics(overhang);

        let graphRect = this.CEGraphRect;

        // Call render on all the scales first so that the appear behind the shuttles
        yAxisScale.render(graphRect, graphRect);

        for (let i = 0; i < this.shuttles.length; ++i)
            this.shuttles[i].updateScale(this.visibleScale, this.CEGraphRect, null, this.CESvgRect);

        // Update the dygraph's scale
        //let WWGUpdateLevelNodeDevRelPath = this.levelNode.tree.device.key + this.levelNode.getDeviceRelativePath(),
        //    WWGUpdateVisibleScaleMin     = this.visibleScale.minimum,
        //    WWGUpdateVisibleScaleMax     = this.visibleScale.maximum;
//
        //let options = {};
        //options[WWGUpdateLevelNodeDevRelPath] = {seriesRange : [WWGUpdateVisibleScaleMin, WWGUpdateVisibleScaleMax]};
        //this.parent.graph.updateRange(this.levelNode, WWGUpdateVisibleScaleMin, WWGUpdateVisibleScaleMax);

        // Immediately adjust the svg bar graph to show the level correctly
        let y = this.graphHeight * (this.visibleScale.maximum - this.levelNode.getValue()) / this.visibleScale.maximum;
        this.svgLevelFill.updateFill(0, y, this.graphWidth, this.graphHeight - y);
    }

    update = function(node: Tag) {
        // Based on which node update, recalculate the appropriate point for our curve
        if (node.quality != TagQuality.TQ_GOOD)
            return;
        let priorMax = this.visibleScale.maximum, priorReso = this.visibleScale.resolution;
        switch(node) {	// Switches use the '===' comparator, so should be good for our use case here
        case this.levelNode:
            this._updateVisibleScale();
            if (this.visibleScale.maximum != priorMax || this.visibleScale.resolution != priorReso) {
                this._updateScale();
            }
            let y = this.graphHeight * (this.visibleScale.maximum - this.levelNode.getValue()) / this.visibleScale.maximum;
            this.svgLevelFill.updateFill(0, y, this.graphWidth, this.graphHeight - y);
            break;

        case this.highIndicator:
            this.highFloat.lastChild.setAttributeNS(null, 'transform', 'rotate(' + (this.highIndicator.getValue() ? 180:0) + ',0,0)'); // Rotate the whole gear
            this.highFloat.classList.toggle('floatAlarm', this.highIndicator.getValue());
            break;

        case this.highLevel:
            this._updateVisibleScale();
            if (this.visibleScale.maximum != priorMax || this.visibleScale.resolution != priorReso) {
                this._updateScale();
            }
            this.highFloat.translate(this.graphWidth / 2, this.graphHeight * (this.visibleScale.maximum - this.highLevel.getValue()) / this.visibleScale.maximum);
            break;

        case this.lowIndicator:
            this.lowFloat.lastChild.setAttributeNS(null, 'transform', 'rotate(' + (this.lowIndicator.getValue() ? 180:0) + ',0,0)'); // Rotate the whole gear
            this.lowFloat.classList.toggle('floatAlarm', !this.lowIndicator.getValue());
            break;

        case this.lowLevel:
            this.lowFloat.translate(this.graphWidth / 2, this.graphHeight * (this.visibleScale.maximum - this.lowLevel.getValue()) / this.visibleScale.maximum);
            break;

        case this.wetWellHeight:
            this._updateWellHeight(this.wetWellHeightElem, node, this.wetWellHLCoords);
            break;

        default:
            assert(false, "Unknown node called update on WetWellGraph");
            return;
        }
    };

    destroy = function() {
        for (let i = 0; this.shuttles && i < this.shuttles.length; ++i)
            this.shuttles[i].destroy();
        this.nodeManager.destroy();  // Unsubscribe to all the nodes we subscribed to
        this.unregisterAsWidget();	 // Unregister with the element like a good little widget
    };
};
