import { RegisterWidget } from "./lib/widget";
import { LineGraph } from "./linegraph"
import LineChartIcon from '../images/icons/line-chart.svg';
import { TagAttribute, type TagDefinition } from "./lib/tag";
import { Role } from "../role";


const FlowColor = '#0D47A1';
const SECColor = '#056839';
const DischargePressureColor = '#800080';

@RegisterWidget({tag: 'pump-system-graph', displayName: 'Pump System Graph', icon: LineChartIcon, section: 'DPO'})
export default class PumpSystemGraph extends LineGraph {
    @TagAttribute({displayName: 'Pump System Tag', supportedRoles: [Role.ROLE_PUMP_BANK]}) pumpSystemTag: TagDefinition;
    isInteractive: boolean;

    addTags() {
        for (let tagDef of this.liveValueTags) {
            this.addTag(tagDef.tag, tagDef.attributes, true);
        }

        let flow = this.pumpSystemTag.tag.findChildByRole(Role.ROLE_TOTAL_FLOW);
        let sec = this.pumpSystemTag.tag.findChildByRole(Role.ROLE_SEC);

        if (!flow)
            throw(new Error(`Pump System Tag does not have a child tag with role 'TotalFlow'`));
        if (!sec)
            throw(new Error(`Pump System Tag does not have a child tag with role 'SEC'`));
        // We pass true as the second argument to the addNode methods to block data requesting. We will manually
        // request data as a group after all nodes are added instead of making a request for each node.
        this.graph.addNode(flow, true, 'Flow', FlowColor, true, flow?.engMax, flow?.engMin);	// The last true means we can change the axis to this
        this.graph.addNode(sec!, true, 'Specific Energy', SECColor, true, sec?.engMax, sec?.engMin);
        var suctions    = this.pumpSystemTag.tag.findByRole(Role.ROLE_SUCTION_PRESSURE);
        if (suctions.length == 1)
            this.graph.addNode(suctions[0], true, 'Suction Pressure', 'black', true);
        else {
            for (var i = 0; i < suctions.length; ++i)
                this.graph.addNode(suctions[i], true, null, null, true);
        }

        var discharges = this.pumpSystemTag.tag.findByRole(Role.ROLE_DISCHARGE_PRESSURE);
        if (discharges.length == 1)
            this.graph.addNode(discharges[0], true, 'Discharge Pressure', 'purple', true);
        else {
            for (var i = 0; i < discharges.length; ++i)
                this.graph.addNode(discharges[i], true, null, null, true);
        }

        var rootNode = this.pumpSystemTag.tag.device.tree.nodes[0]!;	// Get the root node
        var tankLevels = rootNode.findByRole(Role.ROLE_TLC_TANK_LEVEL);
        tankLevels.push(...rootNode.findByRole(Role.ROLE_TARGET_TANK_LEVEL));
        for (var i = 0; i < tankLevels.length; ++i)
            this.graph.addNode(tankLevels[i], true, tankLevels[i].getDisplayName(), undefined, true);	// Plot these on the right axis, supply alternate name
        for (var i = 0; this.graph.options && this.graph.options.nodes && i < this.graph.options.nodes.length; ++i)
            this.graph.addNode(this.graph.options.nodes[i], true, this.graph.options.nodes[i].getDisplayName(), undefined, true);

        var modelPumps = this.pumpSystemTag.tag.findByRole(Role.ROLE_MODEL_PUMP);
        if (modelPumps.length > 0)						// If we got pump model flow nodes
            this.graph.createPumpFlowFill(modelPumps, flow);	// Create a special fill for these guys. True to block the call for data
    }
}