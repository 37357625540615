import { type NumericGauge, type DigitalGauge } from "../digitalgauge";
import { type InputSetpoint } from "../input/setpoint/inputsetpoint";
import { type ToggleSwitch } from "../input/toggle/toggleswitch";
import { Tag, VType } from "./tag";
import { Widget, getTagMetadata } from "./widget";

export const RoleMap: Map<string, string> = new Map();

export function CreateWidget(tag: Tag) {
    let element: Widget;
    let supportedRoles = tag.roles && Array.from(tag.roles).filter(role => RoleMap.has(role));
    if (supportedRoles && supportedRoles.length > 0) {
        let [firstRole] = supportedRoles;
        let tagName = RoleMap.get(firstRole);
        if (tagName)
            element = document.createElement(RoleMap.get(firstRole)!) as Widget;
    }
    else {
        switch (tag.vtype) {
            case VType.VT_BOOL:
                //if (tag.isWriteable)
                    element = document.createElement('se-tag-toggle-switch') as ToggleSwitch;
                //else
                //    element = document.createElement('digital-gauge') as DigitalGauge;
                break;
            case VType.VT_UNKNOWN:
                return document.createElement('div');
            default:	// numeric:
                if (tag.isWriteable)
                    element = document.createElement('input-setpoint') as InputSetpoint;
                else if (tag.vtype == VType.VT_STRING)
                    element = document.createElement('digital-gauge') as DigitalGauge;
                else
                    element = document.createElement('numeric-gauge') as NumericGauge;
        }
    }
    let metadata = element![getTagMetadata]();
    if (metadata.size > 0)
        element![metadata.values().next().value.privateKey] = { tag: tag };
    return element!;
}