import { createElement } from "../../../elements";
import { Attribute } from "../../lib/attributes";
import { TagAttribute, TagSetAttribute } from "../../lib/tag";
import { RegisterWidget, Widget } from "../../lib/widget";
import RadioIcon from '../../../images/icons/radio-button.svg';
import type { Tag, TagDefinition } from "../../lib/tag";
import template from './radio.html';
import { Role } from "../../../role";

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'radio-button', template: template, displayName: 'Radio Button'})
export class RadioButton extends Widget {
    protected wrapper: HTMLElement;
    buttonLimit: number = 12;
    isOverLimit: boolean = false;
    _value: number;
    onchange = (e: Event)=>{};
    _disabled: boolean = false;
    protected buttonMap: Map<number, HTMLInputElement> = new Map();
    protected labelMap: Map<string, number> = new Map();
    @Attribute({displayName: 'Default Value'}) defaultValue: number = 1;
    @Attribute({displayName: 'Equal Button Widths'}) equalButtonWidths: boolean = false;

    get disabled() {
        return this._disabled
    }

    set disabled(value: boolean) {
        this._disabled = value;
        this.shadowRoot?.querySelectorAll(this.isOverLimit ? 'option' : 'input').forEach(inputElement => inputElement.disabled = this._disabled);
    }

    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'One',
        2: 'Two',
        3: 'Three'
    };

    get value(): number {
        return this._value;
    }

    set value(v: number) {
        this._value = v;
        if (this.buttonMap.has(v)) {
            this.buttonMap.get(v)!.checked = true;
        }

    }

    protected connectedCallback(): void {
        this.wrapper = this.shadowRoot!.getElementById('container')!;
        if (this.equalButtonWidths)
            this.style.setProperty('--label-flex', '1');
    }

    enliven() {
        this.buttonMap.clear();
        try {
            // Set up a button for each label:
            for (let [value, label] of Object.entries(this.valueMap)) {
                let numValue = parseInt(value);
                if (this.labelMap.has(label)) {
                    this.buttonMap.set(numValue, this.buttonMap.get(this.labelMap.get(label)!)!);
                    continue;
                }
                let inputElement = createElement('input', '', this.wrapper, '', {type: 'radio', name: 'radioName', id: `radio_${value.toString()}`});
                this.buttonMap.set(numValue, inputElement);
                this.labelMap.set(label, numValue);
                let labelElement = createElement('label', '', this.wrapper, label, {htmlFor: value.toString()});	// trim leading and trailing whitespace
                inputElement.checked = parseInt(value) == this.value
                labelElement.onclick = () => {
                    inputElement.checked = true;
                    this._value = parseInt(value);
                    this.onchange(new Event('change'));;
                }
            }
        } catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
        this.value = this.defaultValue;
    }
}

/**
 * Base Radio Button class
 */
@RegisterWidget({tag: 'tag-radio-button', displayName: 'Radio Button', roles: ['Bitwise_HOA', 'SoftwareHOA', 'Bitwise_HR', 'Radio_HOA', 'Bitwise_HARR', 'OverallHOA', 'AutoHOASwitch']})
export class TagRadioButton extends RadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'Hand',
        2: 'Off',
        3: 'Auto'
    };

    @TagAttribute({
        displayName: 'Value Tag',
        supportedTypes: ['numeric'],
        shouldSubscribe: true
    }) valueTag: TagDefinition;

    enliven() {
        this.buttonMap.clear();
        try {
            // Set up a button for each label:
            let wrapper = this.shadowRoot!.getElementById('container')!;
            for (let [value, label] of Object.entries(this.valueMap)) {
                let numValue = parseInt(value);
                if (this.labelMap.has(label)) {
                    this.buttonMap.set(numValue, this.buttonMap.get(this.labelMap.get(label)!)!);
                    continue;
                }
                let inputElement = createElement('input', '', wrapper, '', {type: 'radio', name: 'radioName', id: `radio_${value.toString()}`});
                this.buttonMap.set(numValue, inputElement);
                this.labelMap.set(label, numValue);
                let labelElement = createElement('label', '', wrapper, label, {htmlFor: value.toString()});	// trim leading and trailing whitespace
                inputElement.checked = parseInt(value) == this.value
                if (this.valueTag.tag.isWriteable) { // Not a read-only node and they have the permission to write it one day:
                    labelElement.classList.add('writeable');	// Add class for formatting, updating disabled flag based on user write permission changes
                    labelElement.onclick = () => {
                        inputElement.checked = true;
                        this.valueTag.tag.setPendingValue(value, this);
                        this._value = parseInt(value);
                        this.onchange(new Event('change'));
                    }
                } else {	// node is not writeable, so do not set the 'writeable' class, and disable the button:
                    wrapper.classList.add('disabled');
                    inputElement.disabled = true;
                }
            }
        } catch(reason) {
            throw(new Error('Invalid Label Map: ' + reason));
        }
    }

    update(tag: Tag): void {
        this.value = parseFloat(tag.getValue(this));
    }
}


@RegisterWidget({tag: 'radio-button-software-hoa', displayName: 'Software HOA', roles: [Role.ROLE_PUMP_AUTO_HOA_SWITCH], icon: RadioIcon, section: 'Setpoints'})
class SoftwareHOARadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'On',
        2: 'Off',
        3: 'Auto'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-train', displayName: 'Train Select', roles: ['Kubota_Train_Select'], icon: RadioIcon, section: 'Kubota'})
class TrainSelectRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        1: 'MBR 1',
        2: 'MBR 2'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-hor', displayName: 'HOR', roles: ['HOR'], icon: RadioIcon, section: 'Kubota'})
class HORRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        2: 'REMOTE',
        0: 'OFF',
        1: 'HAND',
        3: 'REMOTE'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-mbr-state', displayName: 'MBR State', roles: ['Kubota_MBR_State'], icon: RadioIcon, section: 'Kubota'})
class MBRStateRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'Filter',
        2: 'Maintained',
        3: 'Sleep'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-cip-mode-cmd', displayName: 'CIP Mode', roles: ['Kubota_CIP_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class CIPModeCMDRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Reset',
        1: 'Start',
        2: 'Pause',
        3: 'Stop'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-mbr-mode-cmd', displayName: 'MBR Mode', roles: ['Kubota_MBR_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class MBRModeCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'Production',
        2: 'Sleep',
        3: 'CIP'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-eq-mode-cmd', displayName: 'EQ Mode', roles: ['Kubota_EQ_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class EQModeCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'ConstSpd',
        2: 'Pulse',
    };
}

@RegisterWidget({tag: 'radio-button-kubota-pa-mode-cmd', displayName: 'PA Mode', roles: ['Kubota_PA_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class PAModeCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'ConstSpd',
        2: 'Pulse',
        3: 'DO'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-naoh-mode-cmd', displayName: 'NaOH Mode', roles: ['Kubota_NaOH_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class ChemModeCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'ConstSpd',
        2: 'Pulse',
        3: 'pH'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-alum-mode-cmd', displayName: 'NaOH Mode', roles: ['Kubota_Alum_Mode_Cmd'], icon: RadioIcon, section: 'Kubota'})
class AlumModeCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Offline',
        1: 'FlowPaced',
        2: 'Pulse',
    };
}

@RegisterWidget({tag: 'radio-button-kubota-stdby-blr-cmd', displayName: 'Standby Select', roles: ['Kubota_Stdby_Blr_Select'], icon: RadioIcon, section: 'Kubota'})
class StandbyBlowerCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'None',
        1: 'MBR1',
        2: 'MBR2',
        3: 'PA1',
        4: 'PA2',
        5: 'EQ',
    };
}

@RegisterWidget({tag: 'radio-button-kubota-stdby-prmpmp-cmd', displayName: 'Standby Select', roles: ['Kubota_Stdby_PrmPmp_Select'], icon: RadioIcon, section: 'Kubota'})
class StandbyPrmPmpCmdRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'None',
        1: 'MBR1',
        2: 'MBR2'
    };
}

@RegisterWidget({tag: 'radio-button-kubota-level-state', displayName: 'MBR Level State', roles: ['Kubota_MBR_LevelState'], icon: RadioIcon, section: 'Kubota'})
class MBRLevelStateRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'Stop',
        1: 'Low Flow',
        2: 'Med Flow',
        3: 'High Flow',
    };
}

@RegisterWidget({tag: 'day-of-week-radio', displayName: 'Day of Week Picker', roles: ['DayOfWeek', 'ScheduleDayOfWeek'], icon: RadioIcon, section: 'Kubota'})
class DayOfWeekRadio extends TagRadioButton {
    @Attribute({displayName: 'Label Map'}) valueMap: {[key: number]: string} = {
        0: 'S',
        1: 'M',
        2: 'T',
        3: 'W',
        4: 'Th',
        5: 'F',
        6: 'Sa',
    };
}

