import { Widget } from "./widget";
import {createElement} from './elements';
import assert from './debug';
import {Node, NodeQuality} from './node';
import Localization from './localization';
import './digitalguage.css';

// Converts an html element into a digital gauge:
//
// A node is not required to use the digital gauge.
// Instead, the user can call setValue() to alter the gauge value.
// The label cannot be altered after creation of the gauge.
// If a node is not used, you should set label and digits.
// Of course, you can set label and digits with the node to override the node values.

// Element:
// This is the DOM element that will be converted to a digital gauge.

// Properties:
// node:		(optional)
// label:		sets a label for the digital gauge or overrides the label created from the node
// digits:		number of digits to the right of the decimal point (overrides the node value)
// redXMask:	node quality flags for which to suppress the red x

//DigitalGauge inherits Widget:
export default class DigitalGauge extends Widget  {
    constructor(element, properties) {
        super();
        // 'element' is required:
        assert (element, 'element is a required property');
        assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
        this.element = element;
        
        // 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
        // First step: Establish default properties:
        this.redXMask = NodeQuality.NQ_OUT_OF_RANGE;	// Don't show a red X on out of range errors
        
        // Second step: copy properties over to the bar graph object:
        this.copy(properties);
        
        if ((this.label === undefined) && this.node) {	// Get label from node:
            this.label = this.node.description || this.node.getDisplayName();
            var units = this.node.getUnitsText();
            if (units.length > 0)	// node has units:
                this.label += ' (' + units + ')';
        }
        
        if (this.digits===undefined) // Set digits to the right of the decimal:
            this.digits = this.node ? this.node.digits : 0;
        
        // Third step: create internal properties:
        this.fInitialized	= false;
        this.fUseUnits		= this.fUseUnits || false;
    };


    initialize() {
        this.element.classList.add ('digital-gauge');	// Add class to this element:

        if (this.label !== undefined)   // Create the label element:
            createElement('div', 'digital-label' + (this.fSmall? ' small' : ''), this.element).innerHTML = Localization.toLocal(this.label);
        
        // Create the value element:
        this.valueElement = createElement('div', 'digital-value' + (this.fSmall? ' small' : ''), this.element);
        
        this.registerAsWidget(this.element);	// Register our element as a widget element:
        this.fInitialized = true;
        // Connect node after fInitialized, since it may immediately call our update() method:
        if (this.node)
            this.node.subscribe(this, this.element, this.redXMask);
        return this; // Used for chaining
    };
        
    update(node) {	// node value/quality changed:
        assert (this.fInitialized);
        if (node.quality==NodeQuality.NQ_OUT_OF_RANGE)		// blank out the value:
            this.valueElement.textContent = ' ';
        else
            this.valueElement.textContent = node.getFormattedText(this.fUseUnits);
        // else leave the old value, which will have a red x over it
    };
        
    destroy() {
        assert (this.fInitialized);
        
        if (this.node)
            this.node.unsubscribe(this);

        this.element.removeChildren();					// Remove elements we created
        this.element.classList.remove('digital-gauge');	// Remove class we added
        
        this.unregisterAsWidget();
    };
};