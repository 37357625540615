import createWidget from "../createwidget";
import { createElement } from "../elements";
import { SettingsFilter, ValueFilter } from "../tagfilter";
import Card from "./card";
import { SocketList, TagSocket } from "./tagsocket__classic";
export default class WidgetCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {       // TODO: use copy method for overriding default properties
            props = {
                width: 300,
                height: 150,
                minWidth: 200,
                minHeight: 100,
                type: 'widget',
            }
        }
        super(parent, editor, props);
        this.element.tagMap = new Map();
        this.element.titleBox.value = this.name ? this.name : ''
        this.element.titleBox.oninput();
        this.element.widgetWrapper = createElement('div', 'card__widget-wrapper', this.element);


        this.element.socketList = new SocketList([
            new TagSocket('Widget Tags', 'Clever description here', ['name'], undefined, [new ValueFilter(true), new SettingsFilter(true)], true),
        ], this);

        if (this.tags) {
            this.element.socketList.populate(this.tags);
            this.rebuild();
        }

        else {
            this.tags = [];
            this.element.socketList.modify();
        }

        let tagOption       = createElement('div', 'card__dropdown__option', undefined, 'Edit Tags');
        tagOption.onclick   = () => {
            this.element.socketList.modify();
        }
        this.element.settingsList.push(tagOption);

        return this;
    }

    rebuild() {
        this.element.widgetWrapper.removeChildren();
        for (let i=0; i < this.element.socketList.sockets.get('Widget Tags').tags.length; i++) {
            let tag = this.resolveTag(this.element.socketList.sockets.get('Widget Tags').tags[i]);
            if (!tag)
                continue;
            let options = {
                fShowUnits: true,
                fPushButton: (tag.name == 'StartFail' || tag.name == 'StopFail'),
                fPending: true,
            }
            let widgetWrapper = createElement('div', 'card__widget-wrapper', this.element.widgetWrapper);
            this.element.tagMap.set(tag,widgetWrapper);
            createElement('div', 'card__widget-wrapper__name', widgetWrapper, this.element.socketList.sockets.get('Widget Tags').tags[i].name? this.element.socketList.sockets.get('Widget Tags').tags[i].name : tag.getDisplayName())
            createWidget(createElement('div', 'card__widget-wrapper__widget', widgetWrapper), tag, options);
        }
    }
}