import createWidget from "../createwidget";
import { createElement, getHumanReadableTime } from "../elements";
import { DragDropGraph, GenericGraph, GraphNode, HDRequest, StaticGraph } from "../graph";
import TreeView from "../views/treeview";
import ViewModal from "../viewmodal";
import Card from "./card";
import owner from "../../owner";
import Loader from "../loader";
import View from "../views/view";

export default class CumulativeCard extends Card {
    constructor(parent, editor, props) {
        if (!props) {
            props = {
                width:      800,
                height:     600,
                minWidth:   300,
                minHeight:  200,
                type:       'cumulative',
            }
        }
        super(parent, editor, props);
        this.element.loader     = new Loader(this.element);
        this.element.graphID    = owner.ldc.registerGraph(this.element);	// Register for graph data
        this.element.deviceMap  = new Map();
        this.element.onGraphDataResponse = (interval, data, id)  => {
            var devNode;
            for (let [deviceKey, tags] of this.element.deviceMap) {
                if (owner.ldc.devices.getByKey(deviceKey).id === id) {  // This is the device for this node
                    devNode = owner.ldc.devices.getByKey(deviceKey);    // Found the device node
                    break;										        // No need to look further
                }
            }
            if (devNode === undefined || devNode.request === undefined || devNode.request.interval !== interval || devNode.request.nodes.length !== data[0].length)
                return;
            for (var i = 0; i < devNode.request.nodes.length; ++i) {			// Check all of our nodes
                if (data[1+i*4].length == 0){    // If no data came back
                    if (devNode.request.start*1000 < devNode.request.nodes[i].start) {            // If we are requesting data before data we have -- then there's no data to be had
                        data[1+i*4].push(devNode.request.start*1000, devNode.request.end*1000);    // Fill the area in with nulls
                        data[2+i*4].push(null, null);
                        data[3+i*4].push(null, null);
                        data[4+i*4].push(null, null);
                    } else {                        // This is actually a manually logged node trying to get current. Just trim it out for now
                        data[0].splice(i, 1);       // Remove the name
                        data.splice(1+i*4, 4);      // Remove the data rows
                        devNode.request.nodes.splice(i, 1);    // Remove it from the request
                        --i;                        // Decrement so the loop works
                        continue;                   // Nothing else to do for this node
                    }
                }
                if (devNode.request.start * 1000 < devNode.request.nodes[i].start && data[1 + i * 4][0] > devNode.request.start * 1000) {
                    data[1 + i * 4].unshift(data[1 + i * 4][0]);
                    data[2 + i * 4].unshift(null);
                    data[3 + i * 4].unshift(null);
                    data[4 + i * 4].unshift(null);
                }
                if (!devNode.request.nodes[i].fMax)
                    data[2 + i * 4] = data[4 + i * 4] = null;

                devNode.request.nodes[i].start = Math.min(devNode.request.nodes[i].start, data[1 + i * 4].front() / 1000);
                devNode.request.nodes[i].end = Math.max(devNode.request.nodes[i].end, data[1 + i * 4].back() / 1000 + interval);

                var conversion = devNode.request.nodes[i].node.convertFromCacheToDisplay(1);	// If the node needs to be converted
                if (conversion == 1)
                    continue;
                this.convertArray(data[2 + i * 4], conversion);		// Convert all columns that exist
                this.convertArray(data[3 + i * 4], conversion);
                this.convertArray(data[4 + i * 4], conversion);
            }

            if (Object.keys(this.element.deviceMap).length > 1) {	// If we have more than one device, need to sort the data in order
                sortedData = [[]];			// Build an empty array
                for (var i = 0; i < this.orderedNodes.length; ++i) {	// For each node in order
                    sortedData[0].push(this.orderedNodes[i].name);		// Ad the name
                    var index = nodes.indexOf(this.orderedNodes[i]);	// See if we got data for it
                    if (index === -1)							// No data received
                        sortedData.push([], null, [], null);	// Just reserve space
                    else										// We have data
                        sortedData.push(data[1 + index * 4], data[2 + index * 4], data[3 + index * 4], data[4 + index * 4]);
                }
            }
            let historicalData = [[]];
            let avgData = [[],null,[],null];
            let dayStart = Math.floor(this.endTime / this.period) * this.period + (this.utcOffset * 60);
            historicalData[0].push('Cumulative');
            historicalData.push([],null,[],null);
            for (let i=0; i<this.reps + 1;i++) {
                let rangeStart  = dayStart - (this.reps - i) * this.period;
                let rangeEnd    = dayStart - (this.reps - i - 1) * this.period;
                historicalData[1].push(rangeStart * 1000);
                historicalData[3].push([])
                if (i <  this.reps) {
                    for (let j=0; j<data[1].length;j++) {
                        if (data[1][j]/1000 >= rangeStart && data[1][j]/1000 < rangeEnd) {
                            historicalData[3][i].push(data[3][j]);
                        }
                    }
                }
                else {
                    for (let j=0; j<data[1].length;j++) {
                        if (data[1][j]/1000 < rangeStart) {
                        }
                        else {
                            historicalData[3][i].push(data[3][j]);
                        }
                    }
                }
            }
            debugger;
            for (let i=0;i<historicalData[3].length;i++) {
                if (Array.isArray(historicalData[3][i]) && historicalData[3][i].length > 0)
                    historicalData[3][i] = historicalData[3][i].reduce((a,b) => (a+b))
                else historicalData[3][i] = 0;
            }

            let bargraphSize = this.element.ctx.clientWidth / (historicalData[1].length * 1.5);	// Size the bargraph based on how many days we got. width = 900px, 15% of graph is whitespace between bars
            let options = {
                strokeWidth: 1,											// Make lines this thick
                colors: [owner.colors.hex('--color-blue-6')],           // Colors previous trend lines
                ylabelcolor: owner.colors.hex('--color-blue-6'),		// Left y-axis title color
                yAxisLineColor: owner.colors.hex('--color-blue-6'),		// Color to make the left axis labels and line
                drawPoints: false,										// No need to show points
                traditionalHover: true,                                 // Don't interpolate between points
                digitsAfterDecimal: 0,									// Display everything as an integer
                legend: 'true',											// We want a legend
                yAxisLabelWidth: 50,                                    // Give the axis label a little extra room
                ylabel: this.resolveTag(this.tags[0]).getUnitsText(),	// Left y-axis title
                bargraphWidth: bargraphSize,							// Make any bar graphs this many pixels wide
                Cumulative: {											// Create an option set for a single line (a line with the name 'Volume')
                    bargraph: true, 								    // Display this line as a bar graph
                    connectSeparatedPoints: false,					    // Do not connect lines on the bar graph
                    digits: 1,
                    units: this.resolveTag(this.tags[0]).getUnitsText()
                },
            }
            this.element.chart = new StaticGraph(this.ldc, this.element.ctx, this.element.ctx.clientWidth, this.element.ctx.clientHeight, historicalData, options,  true);	// This true means to allow hover events on the graph

            this.element.loader.destroy();
        }

        this.element.ctx = createElement('div', 'card__historical__ctx', this.element);

        if (!this.tags) {
            this.tags = [];
            this.period     = 24*3600;
            this.reps       = 30;
            let treeViewProperties = {
                type: 'select',	// let the user select any number of tags
                selectCallback: this.selectCallback.bind(this),
                //deselectCallback: this.deselectCallback.bind(this),
                //filterRoles: [Role.ROLE_TLC_SOURCE_TANK, Role.ROLE_TLC_TARGET_TANK],
            }
            new ViewModal(new TreeView(treeViewProperties), {maxWidth:'500px'})
        }
        else {
            for (let i=0;i<this.tags.length;i++) {
                let tag = this.resolveTag(this.tags[i])
                let graphNode = new GraphNode(tag, undefined, tag.getDisplayName(), undefined, undefined)
                if (this.element.deviceMap[this.tags[i].deviceKey]) {
                    this.element.deviceMap.get(this.tags[i].deviceKey).push(graphNode)
                }
                else
                    this.element.deviceMap.set(this.tags[i].deviceKey, [graphNode])
            }
            let end         = new Date();								// Current time
            this.utcOffset  = end.getTimezoneOffset();
            this.endTime    = end.getTime();
            let startTime	= this.endTime - (this.period * this.reps * 1000);	// Default to the last hour
            this.interval    = this.calculateInterval(startTime, this.endTime);
            startTime       = Math.floor(startTime / 1000 / this.interval) * this.interval;
            this.endTime    = Math.floor(this.endTime / 1000 / this.interval) * this.interval;
            this.requestDataForAllTags(startTime, this.endTime, this.interval);
        }
    }

    selectCallback(tag) {
        this.tags.push({deviceKey:tag.tree.device.key, path:tag.getDeviceRelativePath()});
        let graphNode = new GraphNode(tag, undefined, tag.getDisplayName(), undefined, undefined)
        if (this.element.deviceMap[tag.tree.device.key]) {
            this.element.deviceMap.get(tag.tree.device.key).push(graphNode)
        }
        else
            this.element.deviceMap.set(tag.tree.device.key, [graphNode])
        let end         = new Date();								// Current time
        this.utcOffset  = end.getTimezoneOffset();
        this.endTime    = end.getTime();
        let startTime	= this.endTime - (this.period * this.reps * 1000);	// Default to the last hour
        this.interval   = this.calculateInterval(startTime, this.endTime);
        startTime       = Math.floor(startTime / 1000 / this.interval) * this.interval;
        this.endTime    = Math.floor(this.endTime / 1000 / this.interval) * this.interval;
        this.requestDataForAllTags(startTime, this.endTime, this.interval);
    }

    convertArray(array, conversion) {
        if (array)
            for (var i = 0; i < array.length; ++i)
                if (array[i] !== null)
                    array[i] *= conversion;
    };

    requestDataForAllTags(start, end, interval) {
        for (let [deviceKey, tags] of this.element.deviceMap) {
            let starts = new Array(tags.length);
            starts.fill(start)
            let ends = new Array(tags.length);
            ends.fill(end);
            let device = owner.ldc.devices.getByKey(deviceKey)
            owner.ldc.getGraphData(this.element.graphID, device.id, interval, starts, ends, tags, true);
            device.request = new HDRequest(start, end, interval, tags);	// Add this request to our list of requested
        }
    };

    calculateInterval(start, end) {
        //assert(start < end, "Bad interval passed in!");
        let intervals = GenericGraph.intervals;

        var seconds = (end - start) / 1000;								// Number of seconds we are currently viewing
        for (let index = 0; index < intervals.length; index++) {
            let points = seconds / intervals[index];					// Number of data points displayed should we choose this interval
            if (points < this.reps * this.period / 500)											// Select the first interval that will load up fewer than x000 data points
                return intervals[index];
        }
        return intervals[intervals.length -1];
    };

    resizeCallback() {
        if (this.element.chart)
            this.element.chart.destroy();
        let end         = new Date();								// Current time
        this.utcOffset  = end.getTimezoneOffset();
        this.endTime    = end.getTime();

        let startTime	= this.endTime - (this.period * this.reps * 1000);
        this.interval    = this.calculateInterval(startTime, this.endTime);
        startTime       = Math.floor(startTime / 1000 / this.interval) * this.interval;
        this.endTime    = Math.floor(this.endTime / 1000 / this.interval) * this.interval;
        this.requestDataForAllTags(startTime, this.endTime, this.interval);
    }
}

class HistoricalView extends View {
    constructor(parent) {
        super();
    }
}