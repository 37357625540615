import assert from './debug';

// Widgets are Javascript objects that get attached to a DOM element to give the DOM element customized behaviors.
// A widget is a base class that is intended to be inherited by a custom widget object. You should never directly
// create an SE.Widget object.
//
// Widgets add the following behaviors to derived Javascript classes:
// 
// 1. Widgets get their 'destroy()' method called whenever element.destroyWidgets(fRecursively) is called.
// 		This behavior ensures that widgets can do things like delete timers, release a captured mouse, and 
//		unsubscribe LiveData nodes. Nodes are not automatically disconnected -- you must explicitly unsubscribe.
//
// 2. Widgets have helper methods that are included in the base class.
//		widget.registerAsWidget(element)	// register as widget with 'element' so widget gets destroyed when element is destroyed
//		widget.unregisterAsWidget(element)	// unregister as widget in your custom 'destroy' method.
//		widget.copy(properties)				// copy properties to the widget

// How to be a good widget:
//
// Create a class that extends this Widget class
// Make sure to call super() in your constructor method
// Assume your class name is CustomWidget:
//
// class CustomWidget extends Widget {
//		constructor() {
//			super();
//		}	
//		additionalMethod() {}
//	}
//
// Create a constructor method for your custom widget (assume your object class name is CustomWidget):
// After the constructor definition, and before adding prototype methods to the custom widget, add the following two lines:
//
// SE.CustomWidget.prototype = new SE.Widget();				// inherit SE.Widget base class behavior
// SE.CustomWidget.prototype.constructor = SE.CustomWidget;	// point constructor method back to your constructor
//
// Call w.registerAsWidget(element) as soon as possible on the top-level element for the widget.
// Call w.unregisterAsWidget(element) to ensure that your custom 'destroy' method is not called more than once.
// These are two required method calls for widgets!!
//
// Override the default widget do-nothing methods by defining the following methods:
// initialize()		// end user should call this method on all widgets after all options have been defined (w.addHandle(), etc)
// update()			// method called by Node if you have connected this widget to a LiveData node
// destroy()		// disconnect nodes, delete active timers, release the mouse if captured, destroy children, etc.
//
// That's it.
// Note that, since you have already defined the prototype for your custom widget at this point, you can 
// add prototype methods to the existing prototype; you cannot define a new prototype object with your methods.

export class Widget {
    constructor() {
	}

	// The following three methods (initialize, update, and destroy) may be overridden by a derived class:
	initialize() {	// Initialize the widget:
		return this;			// return 'this' so initialize() call can be chained.
	}
	
	update(node) {	// Update the widget based on the changed node value:
		assert (false, 'You must provide a custom update method');
	}
	
	destroy(node) {	// Destroy the widget
	}

	setValue(value) {
		assert(false, 'You must provide a custom setValue method');
	}
	
	onAlarm(node, alarm, fAdded, fChanged, fDeleted) {	// Update the widget based on an alarm	
	}
	
	onConfiguredAlarm(node, configuredAlarm, fAdded) {	// Update the widget based on a configured alarm
		
	}
	
	// Widget registration methods (you must call these to be a good widget):
	registerAsWidget(element) {		// Register this object as a widget with the DOM element so that it gets destroy()ed.
		// Store the element so we can properly destroy the widget:
		assert(element._widget == undefined, '');
		this._widgetElement = element;
		
		// Tag element as having a widget (the magic):
		element._widget = this;
	}
	
	unregisterAsWidget() {		// Destroy stuff in the widget (must be explicitly called in custom widget destructor)
		if (!this._widgetElement)
			debugger;
		assert (this._widgetElement, 'registerAsWidget() not called');
		assert (this === this._widgetElement._widget);
		delete this._widgetElement._widget;	// Prevent destroy() from being called twice, given the recursive call and the fact that
	}   									// widgets can be the parent of other widgets (think bargraph with shuttles, handles as children)
	
	// Widget helper methods (only one so far, but more to come):
	copy(properties) {	// Copy set of properties over to this widget object
		for (var attr in properties) {
			if (properties.hasOwnProperty(attr) && typeof properties[attr] !== 'undefined')
				this[attr] = properties[attr];
		}
	}

	onNodeChanged() {};
	onNodeRemoved() {};
};


