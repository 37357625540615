import owner from '../../owner';
import { Device } from '../device';
import { Node, TagID, VType } from '../node';
import { LoggedFilter, TagFilter, VTypeFilter } from '../tagfilter';
import { SerializedTagCategories, AttributeEditorView } from '../views/attributeeditorview';
import View from '../views/view';
import EditorPage from '../pages/editorpage';
import { createElement } from '../elements';
import AddIcon              from "../images/icons/add.svg";
import MinusIcon            from "../images/icons/minus.svg";
import assert from '../debug';
import TreeView, { TreeViewProperties, TreeViewTypes } from '../views/treeview';
import ViewModal, { ViewModalProperties } from '../viewmodal';
import { Widget } from '../widgets/lib/widget';
import { tagAttributeMetadataSymbol, ExtendedTagMetadata, tagSetAttributeMetadataSymbol, ExtendedTagSetMetadata, TagDefinition, SerializedTag, Tag } from '../widgets/lib/tag';
import LiveDataClient from '../livedataclient';

// A little view that allows the user to select and change tags and their properties
export class TagSocketView extends View {
    tagList: HTMLElement;                                   // A list that allows for tag selection
    removeButton: HTMLButtonElement;                        // Button to remove tag from a socket. Changes its context based on the currently selected tag
    addButton: HTMLButtonElement;                           // Same as above but adds new tags
    propertyMap: Map<string, ExtendedTagMetadata> = new Map();
    socketSelector: HTMLSelectElement;
    settingsView: AttributeEditorView;
    tagTextEdit: HTMLInputElement;
    tagMap: Map<string, {[key: string]: string}> = new Map();
    private _element: HTMLElement | null = null;
    ldc: LiveDataClient;
    selectedTagElement: Element | null = null;
    constructor(ldc: LiveDataClient) {
        super();
        this.ldc = ldc;
    }
    initialize(parent: HTMLElement): TagSocketView {
        super.initialize(parent);
        this.wrapper        = createElement('div', 'socket__wrapper', this.parent)
        this.socketSelector = createElement('select', '', this.wrapper);
        this.socketSelector.onchange = () => {
            let value = this.socketSelector.options[this.socketSelector.selectedIndex].value;
            this.selectSocket(value);
        }
        let listWrapper    = createElement('div', 'socket__list-wrapper', this.wrapper);
        let socketRow       = createElement('div', 'socket__row', listWrapper);
        this.tagList        = createElement('div', 'socket__tags', socketRow);
        let buttonColumn    = createElement('div', 'flex__column', socketRow);
        this.addButton      = createElement('button', 'editor__nav__button', buttonColumn);
        this.removeButton   = createElement('button', 'editor__nav__button', buttonColumn);
        this.removeButton.disabled = true;
        createElement('img', '', this.addButton, '', {'src':AddIcon} );
        createElement('img', '', this.removeButton, '', {'src':MinusIcon});
        let settingsWrapper = createElement('div', 'socket__wrapper', this.parent)
        this.settingsView   = new AttributeEditorView((attributes)=>
        {
            if (!this.selectedTagElement)
                return;
            for (let [key, value] of Object.entries(attributes)) {
                this.selectedTagElement.setAttribute(key, value);
            }
        }).initialize(settingsWrapper);
        this.fInitialized = true;
        return this;
    }

    onAttributesDidChange() {

    }

    get element(): HTMLElement | null {
        return this._element;
    }
    set element(v: HTMLElement | null) {
        this._element = v;
        this.buildLists();
        this.settingsView.populateSettings();
    }

    /**
     *Clears the tags from our local maps and builds up a list of tags in the DOM for each socket
     *
     * @private
     * @param {Map<string, TagSocket>} socketMap
     * @memberof TagSocketView
     */
    private buildLists() {
        this.socketSelector.removeChildren();
        let tagAttributes = this.element!.constructor[tagAttributeMetadataSymbol] as Map<string, ExtendedTagMetadata> ?? new Map();
        for (let [tagAttrName, properties] of tagAttributes) {
            this.propertyMap.set(tagAttrName, properties);
            createElement('option', '', this.socketSelector, properties.displayName, {value: tagAttrName});
        };
        let tagSetAttributes = this.element!.constructor[tagSetAttributeMetadataSymbol] as Map<string, ExtendedTagMetadata> ?? new Map();
        for (let [tagAttrName, properties] of tagSetAttributes) {
            this.propertyMap.set(tagAttrName, properties);
            createElement('option', '', this.socketSelector, properties.displayName, {value: tagAttrName});
        };
        this.selectSocket(this.socketSelector.options[this.socketSelector.selectedIndex].value);
    }

    selectSocket(category: string) {
        let properties = this.propertyMap.get(this.socketSelector.options[this.socketSelector.selectedIndex].value)!;
        this.addButton.onclick      = () => this.selectTags(properties, category);
        this.refreshTagList();
    }

    refreshTagList()
    {
        this.tagList.removeChildren();
        this.addButton.disabled = false;
        this.removeButton.disabled = true;
        let slotName = this.socketSelector.options[this.socketSelector.selectedIndex].value;
        (this.element as Widget).getSlottedElements(slotName).forEach(tagDef => {
            this.createListItem(tagDef)
        });
    }

    createListItem(tagElement: Element)
    {
        let itemName = tagElement.getAttribute('abs-path')!; //TODO: This should be smarter. what about roles?
        let tagItem = createElement('div', 'socket__tags__tag', this.tagList, itemName);
        tagItem.onclick = (e) => {
            this.selectedTagElement = tagElement;
            e.stopPropagation();
            this.removeButton.disabled = false;
            if (this.tagTextEdit)
                this.tagTextEdit.parentElement?.removeChild(this.tagTextEdit);
            for (let child of this.tagList.children)
                child.classList.remove('socket__tags__tag__selected')
            tagItem.classList.add('socket__tags__tag__selected')
            this.removeButton.onclick = () => {
                this.element?.setAttribute(this.socketSelector.options[this.socketSelector.selectedIndex].value, '');
                this.applyTags();
                this.refreshTagList();
            }
            this.tagTextEdit         = createElement('input', 'socket__tags__tag__input', tagItem)
            this.tagTextEdit.value   = itemName;
            this.tagTextEdit.onclick = (e: MouseEvent) => e.stopPropagation()
            this.tagTextEdit.onblur  = () => {
                //TODO: this
                //let attributes = this.tagMap.get(property.location)!;
                //this.tagMap.set(this.tagTextEdit.value, attributes);
                //tagItem.removeChild(this.tagTextEdit);
                //this.applyTags();
                //this.refreshTagList();
            }
            if (this.element)
            {
                let properties = this.propertyMap.get(this.socketSelector.options[this.socketSelector.selectedIndex].value)!;
                if (properties && properties.attributes) {
                    let attributeMap = new Map();

                    let attributes: {[key: string]: string} = {};
                    let autoAttributes: {[key: string]: string} = {};

                    properties.attributes.forEach(attribute => {
                        attributeMap.set(attribute.id, attribute);
                        autoAttributes[attribute.id] = attribute.default;
                        let value = tagElement.getAttribute(attribute.id);
                        if (value !== null)
                            attributes[attribute.id] = value;
                    });
                    this.settingsView.populateSettings(attributeMap);
                    this.settingsView.setSettingsToEdit(attributes, {}, autoAttributes);
                }
            }
        }
    }

    applyTags()
    {
        //let selectedCategory = this.socketSelector.options[this.socketSelector.selectedIndex].value;
        //let tagObject: TagID = {
        //    location:
        //};
        //this.tagMap.forEach((attr, loc) => {
        //    tagObject.push({
        //        location: loc,
        //        attributes: attr
        //    });
        //})
        //this.element!.setAttribute(selectedCategory, JSON.stringify(tagObject));
    }

    selectTags(properties: ExtendedTagMetadata | ExtendedTagSetMetadata, attrName) {
        if (!(this.element && this.element instanceof Widget))
            return;
        let fSet = properties.type == 'set';
        let addProperties : TreeViewProperties  = {
            type:               properties.type == 'set' ? TreeViewTypes.TVT_MULTISELECT_ACCEPT : TreeViewTypes.TVT_SELECT_ACCEPT,
            selectedTags:       properties.type == 'set' ? [...this.element[properties.privateKey]?.map(tagDef => tagDef.tag)] : [this.element[properties.privateKey]?.tag],
            acceptCallback:     (nodes: Node[]) => {
                // Remove all the existing tag definitions for this slot
                (this.element as Widget).getSlottedElements(attrName).forEach(element => element.remove());
                if (fSet) {

                    // Then add them back plus the new ones
                    nodes.forEach(node => {
                        let tagDef = document.createElement('tag-def');
                        tagDef.slot = attrName;
                        tagDef.setAttribute('abs-path', node.absolutePath);
                        this.element?.appendChild(tagDef);
                    });
                    (this.element as Widget)
                }
                else {
                    let [node] = nodes;
                    let tagDef = document.createElement('tag-def');
                    tagDef.slot = attrName;
                    tagDef.setAttribute('abs-path', node.absolutePath);
                    this.element?.appendChild(tagDef);
                }
                //this.applyTags();
                queueMicrotask(()=>this.refreshTagList());
            },
            selectFilters:  {
                //TODO: filtering
                andFilters: this.getFilters(properties),
                orFilters: [],
            }
        }
        let modalProperties: ViewModalProperties = {
            maxWidth:               '800px',
            titleBackgroundColor:   'var(--color-primary)',
            titleTextColor:         'var(--color-inverseOnSurface)',
            title:                  properties.displayName
        }
        new ViewModal(new TreeView(addProperties), modalProperties);
    }

    getFilters(properties: ExtendedTagMetadata | ExtendedTagSetMetadata): TagFilter[]
    {
        let filters: TagFilter[] = [];
        if (properties.supportedTypes)
        {
            let types: VType[] = [];
            for (let type of properties.supportedTypes)
            {
                switch(type)
                {
                    case 'boolean':
                        types.push(VType.VT_BOOL);
                        break;
                    case 'folder':
                        types.push(VType.VT_BLOCK);
                        break;
                    case 'numeric':
                        types.push(...[VType.VT_F32, VType.VT_F64, VType.VT_S32, VType.VT_S16, VType.VT_S64, VType.VT_S8, VType.VT_U16, VType.VT_U32, VType.VT_U64, VType.VT_U8]);
                        break;
                    case 'string':
                        types.push(VType.VT_STRING);
                        break;
                    default:
                        assert(false);
                }
            }
            filters.push(new VTypeFilter(types, true));
        }
        if (properties.requiresHistorical)
            filters.push(new LoggedFilter(true, false));
        return filters
    }

    //getNodesFromWidget(widget: Widget, category: string)
    //{
    //    return widget.tags.get(category)!;
    //}
}