import { createElement } from '../elements';
import View from './view';
import TreeView, { TreeViewTypes } from './treeview';
import { TagFilter } from '../tagfilter'
import { Node, NodeFlags, serializeRolesToCSV } from '../node';
import './chartview.css';
import { TreeViewProperties, InfoColumn } from './treeview';
import { Device } from '../device';
import LiveDataClient from '../livedataclient';
import { UnitsMap } from '../widgets/lib/tagunits';


export default class TagFinderView extends View {
	treeView: TreeView;
    device: Device;
    ldc: LiveDataClient;
    constructor(ldc: LiveDataClient, device: Device) {
		super();
        this.ldc            = ldc;
        this.device         = device
    };

	initialize(parent: HTMLElement): View {
        super.initialize(parent);
        this.wrapper			= createElement('div', 'chart-view__page-wrapper',this.parent);
        this.buildTree();
        this.fInitialized       = true;
        return this;
    }

    buildTree() {
        let infoColumns: InfoColumn[] = [
            {
                header: 'Units',
                info: (tag) => {
                    if (!(tag instanceof Node))
                        return createElement('div');
                    return createElement('div', '', undefined, UnitsMap.get(tag.units)?.abbrev);

                },
                width: 'minmax(90px, 1fr)'
            },
            {
                header: 'Role',
                info: (tag) => {
                    if (!(tag instanceof Node))
                        return createElement('div');
                    return createElement('div', '', undefined, serializeRolesToCSV(tag.roles));
                },
                width: 'minmax(160px, 1fr)'
            },
            {
                header: 'Logged',
                info: (tag) => {
                    if (!(tag instanceof Node && !TagFilter.isFolder(tag)))
                        return createElement('div');
                    return createElement('div', '', undefined, tag.flags & NodeFlags.NF_LOG ? 'TRUE' : 'FALSE');
                },
                width: 'minmax(90px, 1fr)'
            },
            {
                header: 'Writeable',
                info: tag => {
                    if (!(tag instanceof Node && !TagFilter.isFolder(tag)))
                        return createElement('div');
                    return createElement('div', '', undefined, tag.isWriteable ? 'TRUE' : 'FALSE');
                },
                width: 'minmax(120px, 1fr)'
            },
            {
                header: 'Eng. Min',
                info: tag => {
                    if (!(tag instanceof Node && typeof tag.engMin !== 'undefined'))
                        return createElement('div');
                    return createElement('div', '', undefined, tag.engMin.toString());
                },
                width: 'minmax(90px, 1fr)'
            },
            {header: 'Eng. Max', info: tag => {
                if (!(tag instanceof Node && typeof tag.engMax !== 'undefined'))
                    return createElement('div');
                return createElement('div', '', undefined, tag.engMax.toString());
            }, width: 'minmax(120px, 1fr)'},
        ]
        let properties: TreeViewProperties = {
            type: TreeViewTypes.TVT_FINDER,
            folder: this.device.tree.nodes[0],
            //infoColumns: innerWidth > 620 ? infoColumns : undefined
        }
        this.treeView           = new TreeView(properties).initialize(this.wrapper);
    }

    resize(): void {
        if (this.fInitialized)
        {
            this.wrapper.removeChildren();
            this.buildTree();
        }
    }
}