import {Widget} from "./widget";
import owner from '../owner'
import {createElement} from './elements';
import {Node, NodeQuality, NodeFlags} from './node';
import './spinner.css';
import assert from './debug';
import { UnitsMap, convert } from "./widgets/lib/tagunits";

//Converts an html element into a numeric spin control attaches it to a LiveData node:

// Element:
// This is the DOM element that will be converted to a text display:

// Properties:
// node:			(required)				numeric tag node
// redXMask:		(default: 0)			node quality flags for which to suppress the red x
// fNoButtons:		(default: false)		do not show increment/decrement buttons
// displayUnits:	(default: node units)	Change the displayed units for the gauge
// fShowUnits:		(default: false)		if true, show units

// Spinner inherits Widget:
export default class Spinner extends Widget {
	constructor(element, properties) {
		super();
		// 'element' is required:
		assert (element, 'element is a required property');
		assert (element.parentNode, 'element must be connected to DOM so that CSS info is available.');
		this.element = element;

		this.registerAsWidget(element);			// register this element as a widget

		// 'properties' is optional -- if defaults are all acceptable, properties is not necessary.
		// First step: Establish default properties:
		this.redXMask		= 0;		// Do not suppress red x for any node quality flags (show red x for all node quality flags)

		// Second step: copy properties over to the bar graph object:
		this.copy(properties);
	};

	initialize() {
		assert(this.node, 'Spinner requires a node.');
		assert(this.node.isWriteable, 'Spinner nodes should be writable');

		this.spinnerInput = createElement('input', this.fNoClass ? '' : 'spinner', this.element, undefined, {'autocomplete'     : 'off',
																						'autocapitalize'   : 'off',
																						'autocorrect'      : 'off',
																						'spellcheck'       : 'false',
																						'type'             : 'number'});	// Create the input element
		this.spinnerInput.onmousedown = (e) => e.stopPropagation();
		if (this.width) this.spinnerInput.style.width = this.width + 'px';

		this.displayUnits = (typeof this.displayUnits !== 'undefined') ? this.displayUnits : this.node.units;
		this.conversion = convert(1, this.node.units, this.displayUnits);

		if (this.fShowUnits)
			createElement('label', 'spinner-units', this.element).textContent = UnitsMap.get(this.displayUnits).abbrev;

		if (this.node.flags & NodeFlags.NF_RANGE) {	// Use node range:
			this.spinnerInput.min = this.node.engMin*this.conversion;
			this.spinnerInput.max = this.node.engMax*this.conversion;
		}

		// Use node resolution as step size:
		if (this.node.flags & NodeFlags.NF_RESOLUTION)
			this.spinnerInput.step = this.node.resolution*this.conversion;

		if (this.fNoButtons || !(this.node.flags & NodeFlags.NF_RESOLUTION))
			this.spinnerInput.hidespinbuttons = true;	// Remove increment/decrement buttons

		this.spinnerInput.disabled = !this.node.couldBeWritten();	// If they can't ever write this node, make it obviously disabled

		// Set up the value change callbacks
		this.spinnerInput.addEventListener('blur',		this.onBlur.bind(this));		// So we can clear the modified flag and reset the value
		this.spinnerInput.addEventListener('keydown',	this.onKeyPress.bind(this));	// So we can submit the new data value when the user hits enter
		this.spinnerInput.addEventListener('change',		this.onInput.bind(this));		// So we can set the modified flag
		this.spinnerInput.addEventListener('focus', ()=>console.log('focused'))

		// Connect node, and pass in the top element to receive the red x.
		if (this.node)
			this.node.subscribe(this, this.element, this.redXMask);
	};

	update (node) {	// node value/quality changed:
		assert (this.node === node);			// Only one node should be calling us back
		if (node.quality != NodeQuality.NQ_GOOD || this.fModifed)
			return;

		this.spinnerInput.value = (node.getValue(this.fPending)*this.conversion).toFixed(node.digits); // set the value
	};

	destroy (node) {	// Undo pretty much everything we did in initialize() (Though we do leave some random members lying around):
		this.element.removeChildren();  // Remove the input element that we added
		this.node.unsubscribe(this);
		this.unregisterAsWidget();
	};

	onBlur(event) {	// Focus shifted away from the spinner
		if ((!this.fWritten && !owner.settingsManager) || this.spinnerInput == '')// If we haven't just made a write
			this.update(this.node);	// Update the value displayed
		else if (owner.settingsManager) {
			if (this.node.getValue(true) != this.spinnerInput.valueAsNumber)
				this.submit(this.spinnerInput.valueAsNumber);
		}
		this.fWritten = false;		// reset the written flag
	};

	onKeyPress(evt) {		// User pressed a key:
		if (evt.keyCode == 13 || evt.keyCode == 9) {		// The enter key or the tab key
			this.submit(this.spinnerInput.valueAsNumber);	// Call the update method when they hit enter
			evt.preventDefault();		// Prevent the enter key from giving us the onInput call back
			this.fWritten = true;		// Set a flag the says not to update on the blur
			this.spinnerInput.blur();	// Lose focus after submitting the value
		}
	};

	onInput(evt) {		// The value in the spinner has just changed
		if (!this.node.hasWritePermission())	// If writes are not enabled
			this.submit(this.spinnerInput.valueAsNumber);
		};

	submit(value) {		// We need to update the value in the spinner
		if (owner.settingsManager) {
			var result = this.setSpinner(value);	// Update the value in the spinner
			owner.settingsManager.pushPending(this.node, result/this.conversion, this);
		}
		else if (this.node.hasWritePermission()){
			var result = this.setSpinner(value);		// Update the value in the spinner
			this.node.setValue(result/this.conversion);
		} else
			this.update(this.node);	// Update the node
	};

	setSpinner(value) {
		if (this.node.flags & NodeFlags.NF_RANGE) {	// Clamp value to within range:
			if (value < this.node.engMin*this.conversion)
				value = this.node.engMin*this.conversion;
			else if (value > this.node.engMax*this.conversion)
				value = this.node.engMax*this.conversion;
		}

		if ((this.node.flags & NodeFlags.NF_RESOLUTION) && (this.node.resolution > 0))
			value = Math.round(value / this.node.resolution/this.conversion) * this.node.resolution*this.conversion;
		this.spinnerInput.value = value.toFixed(this.node.digits);	// Set the value
		return value;
	};

	setEnabled(value) {
		this.spinnerInput.disabled = value;
	}

	reinitialize() {
		if (this.node) {
            this.node.unsubscribe(this);
            this.node.subscribe(this, this.span, this.redXMask)
        }
	}
};
